// @flow
import React from 'react';
import { Text, LayoutItem } from '@audi/audi-ui-react';
import SCLink from '../sc-link';
import './index.css';

type Props = {
  text: String,
  href: String,
  handleClose: Function,
  isExternal?: Boolean,
  onClick?: Function
}

function NavMenuItem({
  text,
  href,
  handleClose,
  isExternal = false,
  onClick = () => {},
}: Props) {
  return (
    <div>
      <SCLink
        href={href}
        isExternal={isExternal}
        onClick={onClick}
        className="nav-menu-item"
      >
        <LayoutItem onClick={handleClose}>
          <Text variant="copy1" as="p" className="nav-menu-text">{text}</Text>
        </LayoutItem>
      </SCLink>
    </div>
  );
}

export default NavMenuItem;
