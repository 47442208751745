// @flow
import React, { useState, useEffect } from 'react';
import {
  Text, TextField, Layout, Button,
} from '@audi/audi-ui-react';
import { connect } from 'react-redux';
import { updateUser } from '../../../../actions/account';
import CheckboxOptional from '../../../../components/checkbox-optional';

import './index.css';

type User = {
  first_name: string,
  last_name: string,
  phone_number: string,
  email: string,
  silvercar_marketing: Boolean,
  third_party_marketing: Boolean,
};

type ProfileProps = {
  user: User,
  updateUser: (User) => void,
};

const Profile: React.FC = (props: ProfileProps) => {
  const {
    user,
    updateUser,
  } = props;

  const [formState, setFormState] = useState({});
  const [phoneNumberIsValid, setPhoneNumberIsValid] = useState(true);
  const [stateChanged, setStateChanged] = useState(false);

  const isValidPhoneNumber = (phoneNumber) => {
    if (phoneNumber === '') return false;
    return phoneNumber.length >= 10 && !/[a-z]/i.test(phoneNumber);
  };

  const isDisabled = !(formState.first_name && formState.last_name && formState.phone_number);

  useEffect(() => {
    setFormState({ ...user });
  }, [user]);

  const handleChange = (e) => {
    const { checked, name, type } = e.target;
    let { value } = e.target;
    value = type === 'checkbox' ? checked : value;
    setStateChanged(true);

    setFormState(prevState => ({ ...prevState, [name]: value }));

    if (name === 'phone_number') {
      setPhoneNumberIsValid(isValidPhoneNumber(value));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    updateUser({ ...formState });
  };

  const resetState = () => setFormState({ ...user });

  return (
    <div className="account-page-tab-content" id="profile">
      <Layout
        direction="column"
        justify="start"
      >
        <Text as="h1" variant="order2" weight="bold" spaceStackEnd="xl">Profile</Text>
        <Layout
          className="flex-container"
        >
          <Layout
            direction="column"
            justify="start"
          >
            <Text as="h2" variant="order4" weight="bold" spaceStackEnd="m">Renter&apos;s information</Text>
            <Text as="p" variant="copy1" spaceStackEnd="xxxl">
              Please enter your first and last name,
              and your mobile number for texting and instructions.
            </Text>
            <TextField
              inputId="text-field__email"
              label="Email address"
              name="email"
              value={formState.email || ''}
              readOnly
              labelEffect={formState.email ? 'none' : 'float'}
              spaceStackEnd="l"
              required
            />
            <TextField
              inputId="text-field__first-name"
              label="First name"
              name="first_name"
              value={formState.first_name || ''}
              onChange={e => handleChange(e)}
              labelEffect={formState.first_name ? 'none' : 'float'}
              spaceStackEnd="l"
              required
            />
            <TextField
              inputId="text-field__last-name"
              label="Last name"
              name="last_name"
              value={formState.last_name || ''}
              onChange={e => handleChange(e)}
              labelEffect={formState.last_name ? 'none' : 'float'}
              spaceStackEnd="l"
              required
            />
            <TextField
              inputId="text-field__mobile-number"
              label="Mobile number"
              name="phone_number"
              value={formState.phone_number || ''}
              onChange={e => handleChange(e)}
              invalid={!phoneNumberIsValid}
              validationMessage="Mobile Number must be at least 10 numbers in length."
              labelEffect={formState.phone_number ? 'none' : 'float'}
              spaceStackEnd="l"
              required
            />
          </Layout>
          <hr />
          <Layout
            direction="column"
            justify="start"
          >
            <Text as="p" variant="order4" weight="bold" spaceStackEnd="m">Email preferences</Text>
            <Text as="p" variant="copy1" spaceStackEnd="xxxl">
              Please confirm if you&apos;d like to receive
              emails from Audi on demand and our partners.
            </Text>
            <CheckboxOptional
              name="silvercar_marketing"
              checked={formState.silvercar_marketing}
              onChange={e => handleChange(e)}
              inputId="silvercar-checkbox"
              spaceStackEnd="xl"
            >
              Audi on demand emails
            </CheckboxOptional>
            <CheckboxOptional
              name="third_party_marketing"
              checked={formState.third_party_marketing}
              inputId="thirdparty-checkbox"
              onChange={e => handleChange(e)}
            >
              Partner emails
            </CheckboxOptional>
          </Layout>
        </Layout>
        <section className="button-container">
          <Button
            name="cancel"
            onClick={resetState}
            variant="secondary"
            className="profile-button cancel-button"
            disabled={
              !stateChanged
            }
          >
            Cancel
          </Button>
          <Button
            className="profile-button"
            name="save"
            type="save"
            variant="primary"
            disabled={
              !stateChanged
              || isDisabled
              || !phoneNumberIsValid
            }
            onClick={e => handleSubmit(e)}
          >
            Save
          </Button>
        </section>
      </Layout>
    </div>
  );
};

export const mapStateToProps = state => ({
  user: state.accountReducer.user,
});

export const mapDispatchToProps = dispatch => ({
  updateUser: user => dispatch(updateUser(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
