// @flow
import { Divider, Text } from '@audi/audi-ui-react';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Box } from '@material-ui/core';
import {
  extendRental,
  updateBooking,
  updateBookingFleet,
  updateCoverage,
} from '../../../actions/booking/modify';
import SCDialog from '../../sc-dialog';
import ModifiedPrice from './modified-price';
import PreviousPrice from './previous-price';
import './index.css';

type Props = {
  booking: Object,
  isSubmitting: boolean,
  handleClose: Function,
  extendRental: Function,
  modifiedBooking: Object,
  modifyBookingModalVisible: boolean,
  updateBooking: Function,
  updateBookingFleet: Function,
  updateCoverage: Function,
}

export class ModifyBookingModal extends Component<Props> {
  handleClose = () => {
    const { handleClose } = this.props;
    handleClose();
  }

  handleUpdate = () => {
    const {
      booking: {
        selectedFleet: bookingFleet,
      },
      extendRental,
      modifiedBooking,
      updateBooking,
      updateBookingFleet,
      updateCoverage,
    } = this.props;

    const {
      id,
      type,
      selectedFleet: modifiedFleet,
    } = modifiedBooking;

    if (type === 'upcoming') {
      if (bookingFleet !== modifiedFleet) {
        updateBookingFleet(id, modifiedFleet);
      } else {
        updateBooking(id, modifiedBooking);
      }
    } else if (type === 'active') {
      extendRental(id, modifiedBooking);
    } else if (type === 'coverage') {
      updateCoverage(id, modifiedBooking);
    }
  };

  render() {
    const {
      booking,
      isSubmitting,
      modifiedBooking,
      modifyBookingModalVisible,
    } = this.props;

    return (
      <SCDialog
        buttonClasses="modify-reservation-buttons"
        open={modifyBookingModalVisible}
        onClose={this.handleClose}
        title="Modify reservation"
        centerButtons
        showCloseButton
        showRightButton
        rightButtonDisabled={isSubmitting}
        rightButtonText="Confirm"
        onRightButtonClicked={this.handleUpdate}
      >
        <div className="modify-reservation-modal-content">
          <Text variant="copy1" as="p">
          This update will change the price of your reservation.
          Please confirm to save the updated details.
          </Text>
          <Box mt={2.5} mb={3.75}>
            <PreviousPrice total={booking.pricing.total} />
            <Divider spaceStackStart="m" spaceStackEnd="m" />
            <ModifiedPrice total={modifiedBooking.pricing.total} />
          </Box>
        </div>
      </SCDialog>
    );
  }
}

export const mapStateToProps = state => ({
  booking: state.bookingReducer.modifyBooking.booking,
  isSubmitting: state.bookingReducer.modifyBooking.isSubmitting,
  modifiedBooking: state.bookingReducer.modifyBooking.modifiedBooking,
  modifyBookingModalVisible: state.bookingReducer.modifyBooking.modifyBookingModalVisible,
});

export const mapDispatchToProps = dispatch => ({
  extendRental: (id, modifiedBooking) => dispatch(extendRental(id, modifiedBooking)),
  updateBooking: (id, modifiedBooking) => dispatch(updateBooking(id, modifiedBooking)),
  updateBookingFleet: (id, fleetId) => dispatch(updateBookingFleet(id, fleetId)),
  updateCoverage: (id, modifiedBooking) => dispatch(updateCoverage(id, modifiedBooking)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ModifyBookingModal);
