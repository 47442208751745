// @flow
import React, { Component } from 'react';
import { TextField } from 'material-ui';
import Button from '@material-ui/core/Button';
import HTTP from '../../../utils/http';

type Props = {};
type State = {
  password: string,
  confirmPassword: string,
};

class ConfirmPasswordForm extends Component<Props, State> {
  constructor(props: Object) {
    super(props);

    this.state = {
      password: '',
      confirmPassword: '',
    };
  }

  handleUpdatePassword(e: Object) {
    this.setState({ password: e.target.value });
  }

  handleUpdateConfirmPassword(e: Object) {
    this.setState({ confirmPassword: e.target.value });
  }


  render() {
    const { password, confirmPassword } = this.state;
    return (
      <div className="sc-form">
        <section>
          <header>
            Confirm Password
          </header>
          <section>
            <TextField
              value={password}
              onChange={e => this.handleUpdatePassword(e)}
              label="New Password"
            />
            <br />
            <TextField
              value={confirmPassword}
              onChange={e => this.handleUpdateConfirmPassword(e)}
              label="Confirm New Password"
              type="password"
            />
            <br />
          </section>
          <footer>
            <Button
              onClick={() => HTTP.users.postConfirm(this.state)}
            >
              Sign In
            </Button>
          </footer>
        </section>
      </div>
    );
  }
}

export default ConfirmPasswordForm;
