// @flow
import React from 'react';
import { connect } from 'react-redux';
import { Text } from '@audi/audi-ui-react';
import './index.css';

type Props = {
  userAudiOwnerProgramDays: number,
}

export const AudiOwnerProgramInfo = ({
  userAudiOwnerProgramDays,
}: Props) => (
  <div className="sc-audi-owner-program">
    <div>
      <span className="program-name">
        <Text as="p" variant="copy1" weight="bold">
          Always Audi Days balance:
          {' '}
          { userAudiOwnerProgramDays }
          { ' ' }
          Days
        </Text>
      </span>
    </div>
  </div>
);

export const mapStateToProps = state => ({
  userAudiOwnerProgramDays: state.accountReducer.userAudiDays.remaining,
});

export default connect(mapStateToProps, null)(AudiOwnerProgramInfo);
