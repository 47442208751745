// @flow
import { Slider } from '@audi/audi-ui-react';
import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import moment from 'moment-timezone';
import { formatTime } from '../../utils/formatters';

type TimeSliderProps = {
  className?: string;
  initialTime?: string;
  location: Object;
  setTime: Function;
  times: Object;
  type: string;
  disabled: boolean;
}

const TimeSlider: React.FC<TimeSliderProps> = (props: TimeSliderProps) => {
  const {
    className,
    location,
    setTime,
    times,
    type,
    initialTime,
    disabled,
  } = props;

  const sliderId = `${type}-time-slider`;
  const sliderLabel = type === 'pickup' ? 'Start' : 'Stop';

  const getSliderTimesArray = () => times.filter(time => time.available).map((time, index) => (
    {
      fullDateTime: time.start_at,
      label: formatTime(time.start_at, location.time_zone),
      value: index,
    }
  ));

  const sliderTimesArray = getSliderTimesArray();

  const getSliderStartingPosition = useCallback(() => {
    const index = sliderTimesArray.findIndex(time => time.fullDateTime === initialTime);
    if (index >= 0) return index;
    return Math.round(times.length / 2);
  }, [initialTime, sliderTimesArray, times]);

  const [sliderPosition, setSliderPosition] = useState(getSliderStartingPosition());

  const handleOnChange = (value) => {
    const time = moment(sliderTimesArray[value].fullDateTime).tz(location.time_zone);
    setSliderPosition(value);
    setTime(time);
  };

  const getSliderTimes = (value) => {
    const findObj = sliderTimesArray.find(time => time.value === value);
    return findObj ? findObj.label : sliderTimesArray.at(-1).label;
  };

  const usePreviousValue = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  };

  const previousSliderPosition = usePreviousValue(sliderPosition);
  const previousSliderTimes = usePreviousValue(sliderTimesArray);
  const previousTimes = usePreviousValue(times);

  useEffect(() => {
    if (previousTimes !== times) {
      let sliderValue;
      if (previousSliderPosition && previousSliderTimes) {
        sliderValue = sliderTimesArray.find(
          (time) => {
            if (previousSliderTimes[previousSliderPosition]) {
              return time.label === previousSliderTimes[previousSliderPosition].label;
            }
            return null;
          },
        );
      }
      if (!sliderValue) {
        sliderValue = sliderTimesArray[getSliderStartingPosition()];
      }
      if (sliderValue && sliderValue.fullDateTime) {
        setTime(
          moment(sliderValue.fullDateTime).tz(location.time_zone),
        );
      }
    }
  }, [times,
    getSliderStartingPosition,
    location,
    previousSliderPosition,
    previousSliderTimes,
    setTime,
    sliderTimesArray,
    previousTimes,
  ]);

  return (
    <Slider
      className={className}
      id={sliderId}
      data-testid={sliderId}
      label={sliderLabel}
      max={sliderTimesArray[sliderTimesArray.length - 1].value}
      min={sliderTimesArray[0].value}
      onChange={value => handleOnChange(value)}
      spaceStackEnd="xl"
      step={1}
      value={sliderPosition}
      valueFormat={value => getSliderTimes(value)}
      disabled={disabled}
    />
  );
};

export default TimeSlider;
