/* eslint-disable no-use-before-define */
// @flow
import 'moment-timezone';
import moment from 'moment';
import HTTP from '../utils/http';
import Storage from '../utils/storage';
import StorageTypes from '../utils/storage/constants';
import * as FeatureTypes from './features/constants';
import * as AccountActions from './account';
import * as BookingActions from './booking';
import * as Auth from '../components/auth';
import { getErrorMessage, setToastTimeout } from '../utils';
import history from '../history';
import * as ModalTypes from './modal/constants';
import Cookies from '../utils/services/cookies';
import AuthTypes from '../utils/services/constants';
import * as datadog from '../utils/datadog';

export const INIT_APP = 'INIT_APP';
export const INIT_APP_COMPLETE = 'INIT_APP_COMPLETE';

export const LOG_DEBUG = 'LOG_DEBUG';

export const LOGIN = 'LOGIN';
export const CALLBACK = 'CALLBACK';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';

export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_ERROR = 'LOGIN_ERROR';

export const RESET_DEBUG = 'RESET_DEBUG';
export const RESET_USER_POINTS = 'RESET_USER_POINTS';
export const RESET_USER_AUDI_DAYS = 'RESET_USER_AUDI_DAYS';

export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';

export const SET_LOGGED_IN_TRUE = 'SET_LOGGED_IN_TRUE';

export const TOGGLE_SIDENAV = 'TOGGLE_SIDENAV';

export const TOGGLE_CHEVRON = 'TOGGLE_CHEVRON';

export const SET_QUERY_PARAMS = 'SET_QUERY_PARAMS';

export const REDIRECT_TO_CMS = 'REDIRECT_TO_CMS';

const DATE_LENGTH = 10;

const env = (process && process.env && process.env.REACT_APP_API_HOST) ? process.env.REACT_APP_API_HOST : '';

export const redirectPath = process.env.NODE_ENV !== 'development' ? `https://${env}.com` : '/';

export const setCurbsideText = (payload: string) => (dispatch: Function) => {
  Promise.resolve().then(() => {
    dispatch({
      type: ModalTypes.SET_CURBSIDE_TEXT,
      payload,
    });
  });
};

export const resetCurbsideText = () => (dispatch: Function) => {
  Promise.resolve().then(() => {
    dispatch({
      type: ModalTypes.RESET_CURBSIDE_TEXT,
    });
  });
};

export function logDebug(payload: Object) {
  setToastTimeout(resetDebug());
  return {
    type: 'LOG_DEBUG',
    payload,
  };
}

export const loginSuccess = (payload: Object) => (
  // eslint-disable-next-line no-unused-vars
  (dispatch: Function, getState: Function) => (
    Promise.resolve().then(() => {
      // TODO temporary workaround due to cross origin issue for browsers with different domain
      // const isAuth0EEnabled = getState().featureReducer.isAuth0Enabled;
      const isAuth0EEnabled = false;
      if (!isAuth0EEnabled) {
        Cookies.add(AuthTypes.authToken, payload.authentication_token);
      }
      const sixMonthFromNowInSeconds = moment()
        .add(6, 'month')
        .format('X');
      const sixMonthsFromNowCookieExpiration = `expires=${moment
        .unix(sixMonthFromNowInSeconds)
        .utc()}`;

      Cookies.add(AuthTypes.isLoggedIn, true);
      Cookies.add(AuthTypes.sessionExpiresAt, sixMonthFromNowInSeconds,
        sixMonthsFromNowCookieExpiration);

      dispatch(isAuth0Enabled(true));
      return dispatch({
        type: LOGIN_SUCCESS,
        payload,
      });
    })
  )
);

export const isAuth0Enabled = payload => ({
  type: FeatureTypes.FEATURES_IS_AUTH0_ENABLED,
  payload,
});

export const loginError = (payload: Object) => (
  (dispatch: Function) => (
    Promise.resolve().then(() => {
      dispatch({
        type: LOGIN_ERROR,
        payload: getErrorMessage(payload),
      });
    })
  )
);

export const login = (payload: Object, inBooking: boolean) => (
  // eslint-disable-next-line no-unused-vars
  (dispatch: Function, getState: Function) => {
    // TODO temporary workaround due to cross origin issue for browsers with different domain
    // const isAuth0Enabled = getState().featureReducer.isAuth0Enabled;
    const isAuth0Enabled = false;
    if (!isAuth0Enabled) {
      return HTTP.login.post(payload)
        .then(response => dispatch(loginSuccess(response.data)))
        .then(() => dispatch(AccountActions.getUserData()))
        .then(() => dispatch(BookingActions.getBookings(1)))
        .then(() => dispatch(BookingActions.getRentals(1)))
        .then(() => dispatch(BookingActions.putGDS(1)))
        .then(() => dispatch(BookingActions.hideModals(1)))
        .catch(error => dispatch(loginError(error)));
    }
    Storage.set(StorageTypes.inBooking, inBooking);
    return Auth.login(payload)
      .catch((error) => {
        dispatch(loginError(error));
      });
  }
);

export const callback = () => (dispatch: Function) => Auth.handleAuthentication()
  .then(response => dispatch(loginSuccess(response)))
  .then(() => dispatch(AccountActions.getUserData()))
  .then(() => dispatch(BookingActions.getBookings(1)))
  .then(() => dispatch(BookingActions.getRentals(1)))
  .then(() => dispatch(BookingActions.putGDS(1)))
  .then(() => dispatch(BookingActions.hideModals(1)))
  .catch((error) => {
    dispatch(loginError(error));
    dispatch(logout(false));
  });

export const redirectToCms = (redirect = true) => (
  (dispatch: Function) => (
    Promise.resolve().then(() => {
      if (process.env.NODE_ENV !== 'development' && redirect) {
        window.location = `https://www.${env}.com`;
      } else {
        history.push('/');
      }
      return dispatch({
        type: REDIRECT_TO_CMS,
      });
    })
  )
);

export const resetUserPoints = () => (
  (dispatch: Function) => {
    Storage.remove('userPoints');
    dispatch({
      type: RESET_USER_POINTS,
    });
  }
);

export const resetUserAudiDays = () => (
  (dispatch: Function) => {
    Storage.remove('userDays');
    dispatch({
      type: RESET_USER_AUDI_DAYS,
    });
  }
);

export const logoutSuccess = () => (
  (dispatch: Function) => (
    Promise.resolve().then(() => {
      Cookies
        .add(AuthTypes.isLoggedIn, false)
        .remove(AuthTypes.first_name)
        .remove(AuthTypes.last_name);
      dispatch(resetUserPoints());
      dispatch(resetUserAudiDays());
      dispatch(BookingActions.resetEarningPoints());
      dispatch(isAuth0Enabled(false));
      dispatch({
        type: LOGOUT_SUCCESS,
      });
    })
  )
);

export const logoutError = (payload: Object) => (
  (dispatch: Function) => (
    Promise.resolve().then(() => (
      dispatch({
        type: LOGOUT_ERROR,
        payload: getErrorMessage(payload),
      })
    ))
  )
);

export const logout = (redirect = true) => (
  // eslint-disable-next-line no-unused-vars
  (dispatch: Function, getState: Function) => {
    // TODO temporary workaround due to cross origin issue for browsers with different domain
    // const isAuth0Enabled = getState().featureReducer.isAuth0Enabled;
    const isAuth0Enabled = false;
    if (!isAuth0Enabled) {
      Cookies.remove(AuthTypes.authToken);
      return dispatch(logoutSuccess())
        .then(() => dispatch(redirectToCms(redirect)))
        .catch(error => dispatch(logoutError(error)));
    }
    Auth.logout();
    datadog.removeUser();
    return dispatch(logoutSuccess())
      .catch(error => dispatch(logoutError(error)));
  }
);

export const checkForLoggedIn = () => (
  (dispatch: Function) => (
    Promise.resolve().then(() => {
      if (Auth.isLoggedIn()) {
        Cookies.add(AuthTypes.isLoggedIn, true);
        dispatch({ type: SET_LOGGED_IN_TRUE });
      } else {
        Cookies.add(AuthTypes.isLoggedIn, false);
      }
    })
  )
);

export const handleQueryParams = (params: Object) => (
  (dispatch: Function) => {
    const {
      from,
      pickup,
      promo_code,
      reset_password_token,
      to,
      email,
    } = params;
    if (promo_code) {
      dispatch(BookingActions.getPromo(promo_code));
    }
    if (reset_password_token) {
      dispatch(AccountActions.setResetPasswordToken(reset_password_token));
    }
    if (email) {
      dispatch(AccountActions.setResetPasswordEmail(email));
    }
    if (pickup) {
      if (!from && !to) {
        dispatch(BookingActions.loadBookingFromLocation(params));
      } else if (from && to) {
        if (from.length === DATE_LENGTH && to.length === DATE_LENGTH) {
          dispatch(BookingActions.loadBookingFromURLDates(params));
        } else {
          dispatch(BookingActions.loadBookingFromURLDateTimes(params));
        }
      }
    }
  }
);

export const resetDebug = () => ({
  type: 'RESET_DEBUG',
});

export const saveQueryParams = (payload: Object) => ({
  type: SET_QUERY_PARAMS,
  payload,
});

export const setQueryParams = (payload: Object) => (
  (dispatch: Function) => {
    // If query params exist, handle them accordingly
    if (Object.keys(payload).length) {
      return dispatch(handleQueryParams(payload));
    }
    return false;
  }
);

export const toggleSideNav = () => ({
  type: TOGGLE_SIDENAV,
});

export const toggleChevron = () => ({
  type: TOGGLE_CHEVRON,
});

export const initApp = (query: Object) => (
  async (dispatch: Function, getStore: Function) => {
    await dispatch({ type: INIT_APP });
    await dispatch(saveQueryParams(query));
    await checkForLoggedIn()(dispatch);
    await BookingActions.getLocations(1)(dispatch, getStore);
  }
);

export const initAppComplete = () => (
  async (dispatch: Function, getStore: Function) => {
    const { queryParams } = getStore().appReducer;
    await BookingActions.initBooking()(dispatch, getStore);
    setQueryParams(queryParams)(dispatch);
    if (Auth.isLoggedIn()) {
      dispatch(AccountActions.getUserData());
    }
    dispatch({
      type: INIT_APP_COMPLETE,
    });
  }
);
