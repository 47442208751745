// @flow
import React from 'react';
import { Text } from '@audi/audi-ui-react';

import './index.css';

type InsufficientPointsTextProps = {
  isMobile: boolean;
}

const InsufficientPointsText = (props: InsufficientPointsTextProps) => {
  const { isMobile } = props;
  const variant = isMobile ? 'copy3' : 'copy2';

  return (
    <Text
      as="p"
      className="insufficient-points-text"
      variant={variant}
    >
      You do not have enough points to book this vehicle
    </Text>
  );
};

export default InsufficientPointsText;
