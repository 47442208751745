/* eslint-disable max-len */
// @flow
import { Text } from '@audi/audi-ui-react';
import { useMediaQuery } from '@material-ui/core';
import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { connect, useSelector } from 'react-redux';
import {
  setVehicle,
  setSelectedFleetPricing,
} from '../../../actions/booking';
import Loading from '../../../components/loading';
import ModifySelectionInputs from '../../../components/modify-vehicle-select';
import RebrandingMobileHeader from '../../../components/rebranding-mobile-header';
import AudiOwnerProgramInfo from '../../../components/sc-audi-owner-program';
import LoyaltyProgramInfo from '../../../components/sc-loyalty-program';
import { SCVehicle } from '../../../components/vehicle';
import {
  getIsLoggedIn,
  getQueryParams,
  getActiveBooking,
  getIsLoading,
  getFleetAvailability,
  getFleetPricingValue,
  getEarningPoints,
  getRemainingAudiDays,
  getUserLoyaltyPoints,
} from '../../../selectors';
import vehicles from '../../../utils/data/vehicleList';
import vehicleSIPPCodes from '../../../utils/data/vehicleSIPPCodes';
import { formatMobileDateTime } from '../../../utils/formatters';

import AlwaysIncludedHeader from './always-included-header';
import LoginPrompt from './login-prompt';

import './vehicle-select.css';

type Props = {
  setVehicle: (vehicle: Object) => void,
  setSelectedFleetPricing: (value: string) => void,
  nextStep: () => void,
  prevStep: () => void,
};

export const VehicleSelect = (props: Props) => {
  const {
    setVehicle,
    setSelectedFleetPricing,
    nextStep,
    prevStep,
  } = props;

  const {
    dropoffDate,
    dropoffTime,
    pickupDate,
    pickupLocation,
    pickupTime,
  } = useSelector(getActiveBooking);

  const earningPoints = useSelector(getEarningPoints);
  const fleetAvailability = useSelector(getFleetAvailability);
  const fleetPricingValue = useSelector(getFleetPricingValue);
  const isLoading = useSelector(getIsLoading);
  const isLoggedIn = useSelector(getIsLoggedIn);
  const queryParams = useSelector(getQueryParams);
  const userAudiOwnerProgramDays = useSelector(getRemainingAudiDays);
  const userLoyaltyPoints = useSelector(getUserLoyaltyPoints);
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('xs'));
  const pageIsLoading = !pickupLocation.time_zone || isLoading;
  const hasAudiOwnerDays = userAudiOwnerProgramDays > 0 && isLoggedIn;

  const handleClick = (vehicle, pricingValue = {}) => {
    setVehicle(vehicle);
    setSelectedFleetPricing(pricingValue);
    if (nextStep) {
      nextStep();
    }
  };

  const renderMobileHeader = () => {
    const location = pickupLocation && pickupLocation.multi_car_display_name;
    const pickupDateTime = formatMobileDateTime(pickupDate, pickupTime, pickupLocation.time_zone);
    const dropoffDateTime = formatMobileDateTime(dropoffDate, dropoffTime, pickupLocation.time_zone);

    return (
      <RebrandingMobileHeader
        showBackButton
        onBackButtonClicked={prevStep}
        title={location}
        secondTitle={`${pickupDateTime} - ${dropoffDateTime}`}
      />
    );
  };

  const renderVehicles = () => {
    if (!fleetAvailability) return null;

    return fleetAvailability.map((fleet, idx) => {
      const { vehicle: { model }, id, available } = fleet;
      const targetVehicle = model.toLowerCase();
      const vehicle = {
        ...vehicles.find(v => v.data_key.toLowerCase() === targetVehicle),
        fleet_id: 0,
      };

      vehicle.fleet_id = id;

      const pricingValue = fleetPricingValue.find(fpv => fpv.id === id);

      const canSpendPoints = available
        && pricingValue
        && pricingValue.points.partial_spend.eligible;

      // if there's a pricing value, simulate a click event for whichever
      // vehicle matches the carclass code from the queryParams.
      if ((pricingValue || {})
        && queryParams
        && vehicleSIPPCodes
        && vehicleSIPPCodes[vehicle.data_key]
        && vehicleSIPPCodes[vehicle.data_key].includes(queryParams.carclass)) {
        handleClick(vehicle, pricingValue);
      }

      const hasAudiOwnerDays = userAudiOwnerProgramDays > 0;

      return (
        <SCVehicle
          vehicleUnavailable={!available}
          disabled={!earningPoints && !canSpendPoints}
          key={idx}
          onClick={selectedVehicle => (
            handleClick(selectedVehicle, pricingValue)
          )}
          canSpendPoints={canSpendPoints}
          vehicle={vehicle}
          pricing={pricingValue || {}}
          hasAudiOwnerDays={hasAudiOwnerDays}
          userAudiOwnerProgramDays={userAudiOwnerProgramDays}
          earningPoints={earningPoints}
          isLoggedIn={isLoggedIn}
          isMobile={isMobile}
        />
      );
    });
  };

  const renderLocationDateTimeFields = () => (
    <div className={`location-date-time-fields ${hasAudiOwnerDays ? 'owner-program' : ''}`}>
      <header>
        {isMobile ? renderMobileHeader() : <ModifySelectionInputs />}
      </header>
    </div>
  );

  const renderSelectYourModelSection = () => (isMobile ? (
    <div className="select-model-subsection">
      <Text as="h1" variant="order2" weight="bold">Select your model</Text>
      <AlwaysIncludedHeader />
      { !isLoggedIn && <LoginPrompt /> }
      { isLoggedIn && hasAudiOwnerDays && <AudiOwnerProgramInfo /> }
      { isLoggedIn && !hasAudiOwnerDays && (
      <LoyaltyProgramInfo
        userLoyaltyPoints={userLoyaltyPoints}
        isLoggedIn={isLoggedIn}
      />
      )}
    </div>
  ) : (
    <div className="select-model-subsection">
      <div className="title-and-included-options">
        <Text as="h1" variant="display2" weight="bold">Select your model</Text>
        <AlwaysIncludedHeader />
      </div>
      <div className={`${hasAudiOwnerDays ? 'audi-owner-days' : ''}`}>
        { !isLoggedIn && <LoginPrompt /> }
        { isLoggedIn && hasAudiOwnerDays && <AudiOwnerProgramInfo /> }
        { isLoggedIn && !hasAudiOwnerDays && (
          <LoyaltyProgramInfo
            userLoyaltyPoints={userLoyaltyPoints}
            isLoggedIn={isLoggedIn}
          />
        )}
      </div>
    </div>
  ));

  return (
    <section className="sc-vehicle-select">
      <HelmetProvider>
        <Helmet>
          <title>Choose a Vehicle | Audi on demand</title>
        </Helmet>
      </HelmetProvider>
      {pageIsLoading
        ? <Loading />
        : (
          <div>
            <section className="section-container">
              { renderLocationDateTimeFields() }
              <div className="section-header">
                { renderSelectYourModelSection() }
              </div>
              { renderVehicles() }
            </section>
          </div>
        )
      }
    </section>
  );
};

export const mapDispatchToProps = dispatch => ({
  setSelectedFleetPricing: pricingValue => dispatch(setSelectedFleetPricing(pricingValue)),
  setVehicle: vehicle => dispatch(setVehicle(vehicle)),
});

export default connect(null, mapDispatchToProps)(VehicleSelect);
