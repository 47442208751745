// @flow
import React from 'react';
import { Button, Text } from '@audi/audi-ui-react';
import { Link } from 'react-router-dom';

import vehicleList from '../../../../utils/data/vehicleList';
import {
  formatAddress, formatCurrency, formatDateTime, reformatPhoneNumber,
} from '../../../../utils/formatters';
import { getReservationDetailsHref } from '../../../../containers/reservations/reservation-detail/helpers';
import { idFromUrl } from '../index';
import './index.css';

const Detail = ({
  name, title, value, children,
}: {
    name: string,
    title: string,
    value?: string,
    children?: React.children
}) => (
  <section className={name}>
    <Text variant="copy2" as="p">{title}</Text>
    {value && <Text variant="order4" as="h4">{value}</Text>}
    {children && children}
  </section>
);

const SmallDetail = ({
  name, title, children,
}: {
    name: string,
    title: string,
    children?: React.children
}) => (
  <section className={name}>
    <Text variant="copy1" as="p">{title}</Text>
    {children && children}
  </section>
);

const ReservationCard = ({
  booking: {
    links,
    pickup_at: start,
    dropoff_at: stop,
    confirmation_token,
    id,
    pricing,
  },
  fleets,
  current_booking_fleet_id,
  locationMap,
  reservationState,
}: {
    booking: {
        links: [{ href: string, rel: string }],
        pickup_at: string,
        dropoff_at: string,
        confirmation_token: string,
        id: string,
        pricing: Object,
    },
    locationMap: Object,
    fleets: Fleet[],
    current_booking_fleet_id: string,
    reservationState: string,
}) => {
  const {
    multi_car_display_name: location_name,
    address: {
      city, state, line1, line2, zip,
    },
    phone_number,
    hours,
    time_zone,
  } = locationMap[
    idFromUrl(links.find(link => link.rel === 'location').href)
  ] || { address: {} };

  const activeReservation = reservationState === 'active';
  const upcomingReservation = reservationState === 'upcoming';
  const requestLink = 'https://www.audiondemand.com/support/new-request/';

  const { make, model } = (
    fleets.find(fleet => String(fleet.id) === current_booking_fleet_id) || {}).vehicle
    || {};
  const vehicle = vehicleList
    .find(({ model: m, data_key }) => model === m || model === data_key) || {};

  const formattedAddress = formatAddress(line1, line2, city, state, zip);

  const renderDealershipHours = () => {
    if (!hours) {
      return null;
    }
    const dealershipTimes = hours.split('\n');
    const dateTimes = [];
    const days = [];
    const times = [];
    dealershipTimes.forEach((i) => {
      const [dateTime, day, time] = i.match(/^(\D*)(\d+[\w\W]*)/);
      dateTimes.push(dateTime);
      days.push(day);
      times.push(time);
    });
    if (days.length === times.length && days.length > 0 && times.length > 0) {
      return days.map((day, index) => (
        <section className="dealership_day_times" key={index}>
          <Text variant="copy2" as="p">{day}</Text>
          <Text variant="copy2" as="p">{times[index]}</Text>
        </section>
      ));
    }
    return dateTimes.map(dateTime => <Text variant="copy2" as="p">{dateTime}</Text>);
  };

  return (
    <section className={`reservation_container ${reservationState}`}>
      <section className="location_name">
        <Text as="h2" variant="order2" weight="bold">{location_name}</Text>
        <Text as="p" variant="copy1">
          {`${city}/${state}`}
        </Text>
      </section>
      <section className="start_container">
        <Detail name="start_day" title="Start" value={start && formatDateTime(start, { time_zone }, 'MM/DD/YY')} />
        <SmallDetail
          name="start_time"
          title={start && formatDateTime(start, { time_zone }, 'hh:mmA').toLowerCase()}
        />
      </section>
      <section className="stop_container">
        <Detail name="stop_day" title="Stop" value={stop && formatDateTime(stop, { time_zone }, 'MM/DD/YY')} />
        <SmallDetail
          name="stop_time"
          title={stop && formatDateTime(stop, { time_zone }, 'hh:mmA').toLowerCase()}
        />
      </section>
      {model && (
        <section className="vehicle_info_container">
          <Detail name="vehicle_model" title="Vehicle" value={`${make} ${vehicle.model}`} />
          <SmallDetail name="vehicle_description" title={vehicle.description} />
        </section>
      )}
      <section className="current_booking_car_image">
        {model && (
        <img
          src={vehicle.main_image}
          alt={`a car with the ${make} and model ${model}`}
        />
        )}
      </section>
      <Detail name="confirmation_token" title="Confirmation number" value={confirmation_token} />
      {!!activeReservation && (
        <>
          <Detail name="dealership_contact" title="Dealership contact">
            <Text variant="copy2" as="p" className="dealership_address">
              <a href={`https://maps.google.com/?q=${formattedAddress}`}>{formattedAddress}</a>
            </Text>
            <Text variant="copy2" as="p" className="dealership_phone">
              <a href={`tel:+${phone_number}`}>{reformatPhoneNumber(phone_number)}</a>
            </Text>
          </Detail>
          <Detail name="dealership_hours" title="Dealership hours">
            {renderDealershipHours()}
          </Detail>
        </>
      )}
      {!!upcomingReservation && (
        <>
          <section className="estimated_container">
            <Detail name="estimate_total" title="Estimated total" value={formatCurrency(pricing.total)} />
          </section>
          <section className="reservation_actions">
            <section className="view_details_action">
              <Link
                to={
                                    getReservationDetailsHref({
                                      id,
                                      type: reservationState,
                                    })}
                style={{
                  textDecoration: 'none',
                }}
              >
                <Button variant="primary" size="small">View details</Button>
              </Link>
            </section>
            <section className="request_extras_action">
              <Button
                href={requestLink}
                size="small"
                variant="text"
              >
                                Request extras (child seat, etc.)
              </Button>
            </section>
          </section>
        </>
      )}
    </section>
  );
};

export default ReservationCard;
