// @flow
import { Text } from '@audi/audi-ui-react';
import { Box } from '@material-ui/core';
import React from 'react';
import { formatNumberWithCommas } from '../../../utils/formatters';
import { EarnTotalProps } from './types';

const EarnTotal: React.FC<EarnTotalProps> = (props: EarnTotalProps): React.ReactElement => {
  const {
    disabled,
    earningPoints,
    hasAudiOwnerDays,
    isMobile,
    points,
    vehicleUnavailable,
  } = props;

  if (hasAudiOwnerDays || !points || vehicleUnavailable || !earningPoints) return null;

  const { earn_total } = points;
  const variant = isMobile ? 'copy3' : 'copy2';
  const disabledClass = disabled || '';

  return (
    <Box
      className={`earn-total ${disabledClass}`}
    >
      <Text
        as="p"
        spaceStackEnd={isMobile ? '' : 'xl'}
        spaceStackStart={isMobile ? '' : 'xl'}
        variant={variant}
      >
        { isMobile ? '| Earn' : 'Earn' }
        {' '}
        { formatNumberWithCommas(earn_total) }
        {' '}
        pts
      </Text>
    </Box>
  );
};

export default EarnTotal;
