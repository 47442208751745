// @flow
import { Button, Text } from '@audi/audi-ui-react';
import React from 'react';
import { connect } from 'react-redux';
import {
  Box,
} from '@material-ui/core';

import SCDialog from '../../sc-dialog';
import { closeIncompleteProfileModal } from '../../../actions/modal';
import history from '../../../history';

type Props = {
  incompleteProfileModalVisible: boolean,
  closeIncompleteProfileModal: () => void,
}

export function ProfileIncompleteModal(props: Props) {
  const { closeIncompleteProfileModal, incompleteProfileModalVisible } = props;
  function completeProfile() {
    closeIncompleteProfileModal();
    history.push('/account');
  }
  return (
    <SCDialog
      onClose={() => closeIncompleteProfileModal()}
      open={incompleteProfileModalVisible}
      showCloseButton
      title="Complete your account"
    >
      <Box mb={3.125}>
        <Text variant="copy1" as="p">
          Before you start your first rental, we&apos;ll need your
          Driver&apos;s License and Credit Card details.
        </Text>
      </Box>
      <div>
        <Button variant="primary" onClick={completeProfile} type="submit">Get started</Button>
      </div>
    </SCDialog>
  );
}

export const mapStateToProps = state => ({
  incompleteProfileModalVisible: state.modalReducer.incompleteProfileModalVisible,
});

export const mapDispatchToProps = dispatch => ({
  closeIncompleteProfileModal: () => dispatch(closeIncompleteProfileModal()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileIncompleteModal);
