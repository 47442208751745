/*
  This library wraps the vendor lib @datadog/browser-rum, to
  consolidate configuration, and prevent issues in environments
  where the agent should not be running to save cost
*/
import { datadogRum } from '@datadog/browser-rum';

const DATA_DOG_APP_ID = '40b40f0c-b3c1-4196-9bc7-fe7d624749c8';
const DATA_DOG_CLIENT_TOKEN = 'pub44ede1228cf96293c363690d2bc3855f';
const DATA_DOG_SITE = 'datadoghq.com';
const DATA_DOG_SERVICE = 'rac-web-driver';

const isActive = () => process.env.REACT_APP_DATA_DOG_ENABLED === 'true';

let userAttributes = {};

const init = (appEnv = 'development', appVersion = 'no-version') => {
  if (isActive()) {
    datadogRum.init({
      applicationId: DATA_DOG_APP_ID,
      clientToken: DATA_DOG_CLIENT_TOKEN,
      site: DATA_DOG_SITE,
      service: DATA_DOG_SERVICE,
      env: appEnv,
      version: appVersion,
      sessionSampleRate: 100,
      sessionReplaySampleRate: 20,
      trackResources: true,
      trackLongTasks: true,
      trackUserInteractions: true,
    });
    datadogRum.addRumGlobalContext('User-Agent', window.navigator.userAgent);
    datadogRum.startSessionReplayRecording();
    userAttributes = {};
  } else {
    // eslint-disable-next-line no-console
    console.info(
      'DATADOG: Skipping Initialization - REACT_APP_DATA_DOG_ENABLED env var not set',
    );
  }
};

const isEmployee = (email = '') => email.includes('dealerware') || email.includes('silvercar');

const setUserAttributes = (options) => {
  if (isActive()) {
    // DataDog setUser normally overwrites the whole user, so we maintain existing attributes
    userAttributes = {
      ...userAttributes,
      ...options,
    };
    userAttributes.isEmployee = isEmployee(userAttributes.email);
    datadogRum.setUser(userAttributes);
  }
};

const removeUser = () => {
  if (isActive()) {
    userAttributes = {};
    datadogRum.removeUser();
  }
};

const getInstance = () => datadogRum;

export const notifyDatadog = (error) => {
  datadogRum.addError(error);
};

export {
  init, setUserAttributes, removeUser, getInstance,
};
