// @flow
import { Text, List, ListItem } from '@audi/audi-ui-react';
import React from 'react';
import { makeStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import '../modal.css';
import './index.css';
import { toggleLoyaltyInfoModal } from '../../../actions/booking';
import SCDialog from '../../sc-dialog';
import SCLink from '../../sc-link';

type Props = {
  loyaltyInfoModalVisible: boolean,
  toggleLoyaltyInfoModal: () => void,
}

export function LoyaltyProgramModal(props: Props) {
  const {
    loyaltyInfoModalVisible,
    toggleLoyaltyInfoModal,
  } = props;

  const useStyles = makeStyles({
    dialog: {
      height: '836px',
      width: '532px',
      display: 'block',
      padding: '50px !important',
    },
  });

  const loyaltyTermsHref = 'https://support.audiondemand.com/hc/en-us/articles/204790284-Terms-of-Use#premium-rewards-program';

  const classes = useStyles();

  return (
    <SCDialog
      open={loyaltyInfoModalVisible}
      title="Premium Rewards"
      titleVariant="order2"
      onClose={toggleLoyaltyInfoModal}
      showCloseButton
      classes={{ paper: classes.dialog }}
    >
      <div className="loyalty-program-modal">

        <section className="loyalty-program-modal-options">
          <Text variant="order3" as="h3" weight="bold">Earn</Text>
          <Text as="p" variant="copy1">Earn points for completed rentals.</Text>
          <Text variant="order3" as="h3" weight="bold">Spend</Text>
          <Text as="p" variant="copy1">Spend points on future rentals.</Text>
        </section>

        <ul>
          <Text as="p" variant="copy1" weight="bold">Please note:</Text>
          <Text as="p" variant="copy1">
            <List variant="bullet">
              <ListItem>
            Points apply to base rental rates only.
              </ListItem>
              <ListItem>
            When paying with points, there may be a remaining
            amount due for taxes, insurance coverage and other fees.
              </ListItem>
              <ListItem>
            Earned points will be added to your
            points balance when rental is complete.
              </ListItem>
              <ListItem>
            Spent points will be deducted from your
            points balance when rental is booked.
              </ListItem>
              <ListItem>
            Your points never expire as long as
            you earn or spend points within a 5 year window.
              </ListItem>
              <ListItem>
            Your points must cover at least 50% of the base rental
            rate to use points. You will earn points for the
            remaining base rental rate not covered by your points.
              </ListItem>
            </List>
          </Text>
          <SCLink
            href={loyaltyTermsHref}
            isExternal
            className="link"
          >
            <Text as="p" variant="copy1">
              Terms and limitations apply
            </Text>
          </SCLink>
        </ul>
      </div>
    </SCDialog>
  );
}

export const mapStateToProps = state => ({
  loyaltyInfoModalVisible: state.bookingReducer.loyaltyInfoModalVisible,
});

export const mapDispatchToProps = dispatch => ({
  toggleLoyaltyInfoModal: () => dispatch(toggleLoyaltyInfoModal()),
});


export default connect(mapStateToProps, mapDispatchToProps)(LoyaltyProgramModal);
