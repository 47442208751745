// @flow
import { combineReducers } from 'redux';
import accountReducer from './account';
import bookingReducer from './booking';
import modalReducer from './modals';
import reservationReducer from './reservation';
import featureReducer from './features';
import subscriptionReducer from './subscription';
import * as Actions from '../actions';
import Cookies from '../utils/services/cookies';
import AuthTypes from '../utils/services/constants';
import * as AccountTypes from '../actions/account/constants';

export type State = {
  authToken: string,
  debugAction: string,
  debugMessage: string,
  httpResponseError: boolean,
  httpResponseStatusMessage: string,
  isLoggedIn: boolean,
  isReady: boolean,
  queryParams: Object,
  sideNavVisible: boolean,
  chevronExpanded: boolean,
}

export const initialState: State = {
  authToken: Cookies.get(AuthTypes.authToken) || '',
  debugAction: '',
  debugMessage: '',
  httpResponseError: false,
  httpResponseStatusMessage: '',
  isLoggedIn: false,
  isReady: false,
  queryParams: {},
  sideNavVisible: false,
  chevronExpanded: false,
};

export function appReducer(state: State = initialState, action: Object) {
  switch (action.type) {
    case Actions.INIT_APP_COMPLETE:
      return {
        ...state,
        isReady: true,
      };
    case Actions.LOG_DEBUG:
      return {
        ...state,
        debugAction: action.payload.debugAction,
        debugMessage: action.payload.debugMessage,
      };
    case Actions.LOGIN_ERROR:
      return {
        ...state,
        isLoggedIn: false,
        authToken: '',
        httpResponseError: true,
        httpResponseStatusMessage: action.payload,
      };
    case Actions.SET_QUERY_PARAMS:
      return {
        ...state,
        queryParams: action.payload,
      };
    case Actions.SET_LOGGED_IN_TRUE:
      return {
        ...state,
        isLoggedIn: true,
      };
    case Actions.LOGIN_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        authToken: action.payload.authToken,
        httpResponseError: false,
        httpResponseStatusMessage: '',
      };
    case Actions.LOGOUT_SUCCESS:
      return {
        ...state,
        isLoggedIn: false,
        authToken: '',
      };
    case AccountTypes.POST_CONFIRM_ACCOUNT_ERROR:
      return {
        ...state,
        httpResponseError: true,
        httpResponseStatusMessage: action.payload,
      };
    case Actions.RESET_DEBUG:
      return {
        ...state,
        debugAction: initialState.debugAction,
        debugMessage: initialState.debugMessage,
      };
    case Actions.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        httpResponseError: false,
        httpResponseStatusMessage: 'Your password has been reset. Please log in.',
      };
    case Actions.TOGGLE_SIDENAV:
      return {
        ...state,
        sideNavVisible: !state.sideNavVisible,
      };
    case Actions.TOGGLE_CHEVRON:
      return {
        ...state,
        chevronExpanded: !state.chevronExpanded,
      };
    default:
      return state;
  }
}

const rootReducer = combineReducers({
  appReducer,
  accountReducer,
  bookingReducer,
  modalReducer,
  reservationReducer,
  featureReducer,
  subscriptionReducer,
});

export default rootReducer;
