// @flow
import { Text } from '@audi/audi-ui-react';
import React from 'react';
import { useMediaQuery } from '@material-ui/core';
import { formatCurrency } from '../../../../../../utils/formatters';
import './index.css';

type EstimatedTotalProps = {
    total: string,
    per_day: number;
}

const EstimatedTotal = (props: EstimatedTotalProps) => {
  const {
    total, per_day,
  } = props;

  const isMobile = useMediaQuery(theme => theme.breakpoints.down('xs'));

  return (
    <div className="estimated-total">
      <Text as="p" variant="copy2">
        Estimated total
      </Text>
      <Text as="h2" variant="order2">
        {formatCurrency(total)}
      </Text>
      <Text as="p" variant="copy1" spaceStackEnd={isMobile ? 'xxxl' : ''}>
      Avg. daily rate of
        {' '}
        {formatCurrency(per_day)}
      </Text>
    </div>
  );
};

export default EstimatedTotal;
