// @flow
import React from 'react';
import { Text } from '@audi/audi-ui-react';

type HeaderProps = {
  isMobile: boolean;
  type: string;
}

const Header = ({ isMobile, type }: HeaderProps) => (
  <header>
    <Text
      as="h2"
      variant={isMobile ? 'order2' : 'display2'}
      weight="bold"
    >
      {`Modify ${type}`}
    </Text>
  </header>
);

export default Header;
