// @flow
import React from 'react';
import SignupForm from '../../components/forms/signup';

function Signup() {
  return (
    <div>
      <SignupForm />
    </div>
  );
}

export default Signup;
