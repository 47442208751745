// @flow
import { Text, ContentCard, AudiPlatformProvider } from '@audi/audi-ui-react';
import React from 'react';
import { Box, Container } from '@material-ui/core';
import AudiLogo from '../../assets/images/icons/ic-audi.svg';
import { FAQ_LINK, SUPPORT_LINK } from './constants';
import './index.css';

type Props = {
  title: string,
  bodyText: string,
};

const AppMessage = (props: Props) => {
  const { title, bodyText } = props;

  const faq = (
    <Text as="p" spaceInlineEnd="l" spaceStackEnd="xl" variant="copy2">
      Visit our
      {' '}
      <a href={FAQ_LINK} target="_blank" rel="noopener noreferrer">FAQ here</a>
      .
    </Text>
  );

  const support = (
    <Text as="p" spaceInlineEnd="l" spaceStackEnd="xl" variant="copy2">
      Contact
      {' '}
      <a href={SUPPORT_LINK} target="_blank" rel="noopener noreferrer">Audi on demand Support</a>
      .
    </Text>
  );

  return (
    <AudiPlatformProvider>
      <div className="main-container">
        <Container className="app-message-container">
          <ContentCard className="card-container">
            <Box className="message-container">
              <Box className="icon-container">
                <Text as="span">
                  <img className="icon" src={AudiLogo} alt="Audi on demand" />
                </Text>
                <Text className="audi-text-logo" spaceInlineStart="xs" as="span" variant="copy1">
                  <Text
                    spaceInlineEnd="xxs"
                    as="strong"
                    variant="copy1"
                    weight="bold"
                  >
                    Audi
                  </Text>
                  on demand
                </Text>
              </Box>
              <Text
                className="text-center"
                as="h2"
                variant="display2"
                spaceStackEnd="m"
              >
                {title}
              </Text>
              <Text
                spaceStackEnd="xl"
                className="message text-center"
                as="h4"
                variant="order4"
              >
                {bodyText}
              </Text>
              <Box className="links-container">
                {faq}
                {support}
              </Box>
            </Box>
          </ContentCard>
        </Container>
      </div>
    </AudiPlatformProvider>
  );
};

export default AppMessage;
