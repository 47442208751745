// @flow
import { Button } from '@audi/audi-ui-react';
import React from 'react';
import { InsufficientPointsButtonProps } from './types';

const InsufficientPointsButton: React.FC<InsufficientPointsButtonProps> = (
  props: InsufficientPointsButtonProps,
): React.ReactElement => {
  const { isMobile, onClick, vehicle } = props;

  return (
    <Button
      disabled
      name="insufficient-points-button"
      className="insufficient-points-button"
      onClick={() => onClick(vehicle)}
      variant="primary"
      size="small"
      spaceStackStart={isMobile ? '' : 's'}
    >
      Select
    </Button>
  );
};

export default InsufficientPointsButton;
