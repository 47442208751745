// @flow
import { Text } from '@audi/audi-ui-react';
import React from 'react';
import { formatLocationName } from '../../../utils/formatters';

type ConfirmVehicleLocationProps = {
    pickupLocation: Object,
}

const ConfirmVehicleLocation = (props: ConfirmVehicleLocationProps) => {
  const {
    pickupLocation,
  } = props;

  return (
    <>
      <Text as="p" variant="copy2">
        Location
      </Text>
      <Text as="h2" variant="order2" spaceStackEnd="xxxl">
        {formatLocationName(pickupLocation)}
      </Text>
    </>
  );
};

export default ConfirmVehicleLocation;
