// @flow
import * as SubscriptionTypes from '../../actions/subscription/constants';

export type State = {
  activeBooking: Object,
  coverage: Array<Object>,
  httpResponseError: boolean,
  httpResponseStatusMessage: string,
  estimatedTotal: string,
  isLoading: boolean,
  fleets: Array<Object>,
  fleetPricingOptions: Object,

  locations: Object,
  loginModalVisible: boolean,
};

export const initialState: State = {
  activeBooking: {
    coverage: {
      type: '',
      coverageType: '',
      coverageOptions: [],
      carrier: '',
      policyNumber: '',
    },
    selectedFleetPricingOption: [],
    dropoffDate: '',
    pickupLocation: {},
    pickupDate: '',
    vehicle: {
      fleet_id: 0,
    },
    isProcessing: false,
  },
  costSummaryModalVisible: false,
  estimatedTotalModalVisible: false,
  coverage: [],
  fleets: [],
  fleetPricingOptions: [],
  httpResponseError: false,
  httpResponseStatusMessage: '',
  estimatedTotal: '',
  isLoading: false,
  locations: {
    isLoading: false,
    page: 0,
    mergedLocations: [],
    locationMap: new Map(),
    noLocationsFound: false,
    results: [],
  },
  loginModalVisible: false,
};

function subscriptionReducer(state: State = initialState, action: Object) {
  switch (action.type) {
    case SubscriptionTypes.GET_FLEET_PRICING_OPTIONS_SUCCESS:
      return {
        ...state,
        fleetPricingOptions: action.payload,
        isLoading: false,
        activeBooking: {
          ...state.activeBooking,
          isProcessing: false,
        },
      };
    case SubscriptionTypes.SET_SELECTED_FLEET_PRICING_OPTION:
      return {
        ...state,
        activeBooking: {
          ...state.activeBooking,
          selectedFleetPricingOption: action.payload,
        },
      };

    case SubscriptionTypes.GET_FLEETS_BY_LOCATION_ID_SUCCESS:
      return {
        ...state,
        fleets: action.payload,
      };
    case SubscriptionTypes.GET_COVERAGE_OPTIONS_ERROR:
      return {
        ...state,
        coverage: [],
        httpResponseError: true,
        httpResponseStatusMessage: action.payload,
      };
    case SubscriptionTypes.GET_COVERAGE_OPTIONS_SUCCESS:
      return {
        ...state,
        coverage: action.payload,
        httpResponseError: false,
        httpResponseStatusMessage: '',
      };
    case SubscriptionTypes.GET_LOCATIONS_SUCCESS:
      return {
        ...state,
        locations: {
          ...state.locations,
          ...action.payload,
          noLocationsFound: false,
        },
      };
    case SubscriptionTypes.SET_COVERAGE:
      return {
        ...state,
        activeBooking: {
          ...state.activeBooking,
          coverage: {
            ...state.activeBooking.coverage,
            coverageOptions:
              initialState.activeBooking.coverage.coverageOptions,
            ...action.payload,
          },
        },
      };
    case SubscriptionTypes.SET_COVERAGE_OPTIONS:
      return {
        ...state,
        activeBooking: {
          ...state.activeBooking,
          coverage: {
            ...state.activeBooking.coverage,
            coverageOptions: [...action.payload],
          },
        },
      };
    case SubscriptionTypes.SET_DROPOFF_DATE:
      return {
        ...state,
        activeBooking: {
          ...state.activeBooking,
          dropoffDate: action.payload,
          pickupTime: initialState.activeBooking.pickupTime,
          dropoffTime: initialState.activeBooking.dropoffTime,
          coverage: {
            ...initialState.activeBooking.coverage,
          },
          vehicle: {
            ...initialState.activeBooking.vehicle,
          },
        },
      };
    case SubscriptionTypes.SET_PICKUP_DATE:
      return {
        ...state,
        activeBooking: {
          ...state.activeBooking,
          pickupDate: action.payload,
          pickupTime: initialState.activeBooking.pickupTime,
          dropoffDate: initialState.activeBooking.dropoffDate,
          dropoffTime: initialState.activeBooking.dropoffTime,
          coverage: {
            ...initialState.activeBooking.coverage,
          },
          selectedFleetPricing: initialState.activeBooking.selectedFleetPricing,
          vehicle: {
            ...initialState.activeBooking.vehicle,
          },
        },
      };
    case SubscriptionTypes.SET_PICKUP_LOCATION:
      return {
        ...initialState,
        locations: state.locations,
        bookings: state.bookings,
        activeBooking: {
          ...initialState.activeBooking,
          pickupLocation: action.payload,
          promo: state.activeBooking.promo,
          selectedFleetPricing: initialState.activeBooking.selectedFleetPricing,
        },
      };
    case SubscriptionTypes.SET_LOCATIONS:
      return {
        ...state,
        locations: {
          ...state.locations,
          mergedLocations: action.payload,
        },
      };
    case SubscriptionTypes.SET_VEHICLE:
      return {
        ...state,
        activeBooking: {
          ...state.activeBooking,
          vehicle: action.payload,
          coverage: initialState.activeBooking.coverage,
        },
      };
    case SubscriptionTypes.SET_ESTIMATED_TOTAL:
      return {
        ...state,
        estimatedTotal: action.payload,
      };
    case SubscriptionTypes.SET_ESTIMATED_MONTHLY_TOTAL:
      return {
        ...state,
        estimatedMonthlyTotal: action.payload,
      };
    case SubscriptionTypes.SEND_ORDER_TO_ZENDESK:
      return {
        ...state,
        isLoading: true,
      };
    case SubscriptionTypes.SEND_ORDER_TO_ZENDESK_SUCCESS:
      return {
        ...state,
        isLoading: false,
        httpResponseError: false,
        httpResponseStatusMessage: 'Success',
      };
    case SubscriptionTypes.SEND_ORDER_TO_ZENDESK_ERROR:
      return {
        ...state,
        isLoading: false,
        httpResponseError: true,
        httpResponseStatusMessage: action.payload,
      };
    default:
      return state;
  }
}

export default subscriptionReducer;
