// @flow
import React from 'react';
import { connect } from 'react-redux';
import { Text } from '@audi/audi-ui-react';
import { getHomeUrl } from '../../utils/http/config';
import { trackBookingStart as trackBookingStartAction } from '../../actions/booking';
import { isLoggedIn } from '../auth';
import './index.css';
import AccountDropDownMenu from '../account-drop-down-menu';
import AudiLogo from '../../assets/images/icons/ic-audi.svg';
import DesktopBookingButton from './desktop-booking-button';
import Login from './login';
import AudiText from './audi-text';

type Props = {
  pathname: string,
  trackBookingStart: () => {},
}

export function NavHeader(props: Props) {
  const {
    pathname,
    trackBookingStart,
  } = props;

  const editingReservation = pathname && (pathname.includes('edit-date') || pathname.includes('edit-coverage') || pathname.includes('booking') || pathname.includes('long-term-drive'));

  return (
    <div
      className="header-outer-box"
    >
      <div
        className="header-inner-box"
        role="navigation"
      >
        <a
          href={getHomeUrl()}
          aria-label="home"
          className="audi-logo-container"
        >
          <img
            src={AudiLogo}
            alt="Audi on demand"
            className="audi-logo"
          />
        </a>
        {isLoggedIn() ? <span className="audi-text-outer-logged-in"><AudiText /></span> : <span className="audi-text-outer"><AudiText /></span> }
        <a className="audi-owners" href={`${getHomeUrl()}always-audi/`}>
          <Text as="p" variant="copy1" className="grey-font">Audi Owners</Text>
        </a>
        {isLoggedIn()
          ? <span className="nav-account-dropdown"><AccountDropDownMenu /></span>
          : <span className="nav-login"><Login /></span>
          }
        {!editingReservation
            && <span className="booking"><DesktopBookingButton trackBookingStart={trackBookingStart} /></span>
          }
      </div>
    </div>
  );
}

export const mapDispatchToProps = dispatch => ({
  trackBookingStart: () => dispatch(trackBookingStartAction()),
});

export default connect(null, mapDispatchToProps)(NavHeader);
