// @flow
import { BORDER_RADIUS, COLORS } from '../constants';

const MuiButton = {
  root: {
    whiteSpace: 'nowrap',
    fontWeight: '100',
    fontSize: '1.125rem',
    minWidth: '9.6875rem',
    height: '3.125rem',
    borderRadius: BORDER_RADIUS,
    boxShadow: 'none !important',
    color: COLORS.PRIMARY_CONTRAST,
    backgroundColor: COLORS.PRIMARY,
    textTransform: 'capitalize',
    '&:only-child': {
      marginLeft: 'unset',
    },
    '&$disabled': {
      backgroundColor: COLORS.GREY3,
      color: COLORS.PRIMARY_CONTRAST,
    },
    '&:hover': {
      textDecoration: 'underline',
      textUnderlineOffset: '2px',
      backgroundColor: COLORS.PRIMARY,
      '&.Mui-disabled': {
        backgroundColor: COLORS.GREY3,
        color: COLORS.PRIMARY_CONTRAST,
      },
      '@media (hover:none)': {
        backgroundColor: COLORS.PRIMARY,
      },
    },
  },
  contained: {
    color: COLORS.PRIMARY_CONTRAST,
    backgroundColor: COLORS.PRIMARY,
    '&:disabled': {
      backgroundColor: COLORS.GREY3,
      color: COLORS.PRIMARY_CONTRAST,
    },
    '&:hover': {
      textDecoration: 'underline',
      textUnderlineOffset: '2px',
      backgroundColor: COLORS.PRIMARY,
    },
  },
  outlined: {
    color: COLORS.SECONDARY_CONTRAST,
    borderColor: COLORS.SECONDARY_CONTRAST,
    backgroundColor: COLORS.SECONDARY,
    '&:disabled': {
      color: COLORS.GREY3,
      backgroundColor: COLORS.SECONDARY,
      borderColor: COLORS.GREY3,
    },
    '&:hover': {
      color: COLORS.PRIMARY,
      textDecoration: 'underline',
      textUnderlineOffset: '2px',
      backgroundColor: COLORS.PRIMARY_CONTRAST,
    },
  },
  textPrimary: {
    color: COLORS.SECONDARY_CONTRAST,
    backgroundColor: COLORS.SECONDARY,
    textDecoration: 'underline',
    fontSize: '0.8rem',
    lineHeight: '1.125rem',
    '&:hover': {
      backgroundColor: COLORS.SECONDARY,
      textDecoration: 'underline',
      textUnderlineOffset: '2px',
    },
  },
  label: {
    padding: '0 1rem',
  },
};

export default MuiButton;
