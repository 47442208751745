// @flow
import { getLocationFromLinks } from '../../../actions/booking/utils';
import { formatCurrency, formatDateTime } from '../../../utils/formatters';

export default function mapReservationData(data) {
  const { rentals, locations } = data;
  return rentals.map((rental) => {
    const { links } = rental;
    const locationId = getLocationFromLinks(links);
    const location = locations ? locations[locationId] : undefined;
    return {
      id: rental.id,
      state: rental.state,
      confirmationNumber: rental.confirmation_token,
      city: location ? location.name : '',
      started: location ? formatDateTime(rental.pickup_at, location, 'MM / DD / YY') : '',
      returned: location ? formatDateTime(rental.dropoff_at, location, 'MM / DD / YY') : '',
      total: formatCurrency(rental.pricing.total),
    };
  });
}
