// @flow
/* eslint quote-props: 0 */

import { COLORS } from './constants';

const theme = {
  easing: {
    easeInOut: 'cubic-bezier(0.75, 0.02, 0.5, 1)',
  },
  palette: {
    tonalOffset: 0.2,
    background: {
      paper: COLORS.WHITE,
      default: COLORS.WHITE,
    },
    contrastThreshold: 3,
    grey: {
      '1': COLORS.GREY1,
      '2': COLORS.GREY2,
      '3': COLORS.GREY3,
      '4': COLORS.GREY4,
      '5': COLORS.GREY5,
      '6': COLORS.GREY6,
    },
    gradient: 'linear-gradient(to bottom, rgba(0, 0, 0, 0)',
    success: COLORS.CONFIRM,
    divider: 'rgba(0, 0, 0, 0.12)',
    secondary: {
      main: COLORS.SECONDARY,
      contrastText: COLORS.SECONDARY_CONTRAST,
    },
    primary: {
      main: COLORS.GREY2,
      contrastText: COLORS.PRIMARY_CONTRAST,
    },
    common: {
      black: COLORS.BLACK,
      white: COLORS.WHITE,
    },
    error: {
      main: COLORS.ALERT,
      contrastText: COLORS.WHITE,
    },
    action: {
      hoverOpacity: 0.08,
    },
  },
  spacing: 16,
  focused: COLORS.PRIMARY,
  themeName: 'Silvercar',
};

export default theme;
