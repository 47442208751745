// @flow
import { COLORS } from '../constants';

const MuiMenuItem = {
  root: {
    '&:hover': {
      backgroundColor: `${COLORS.PRIMARY} !important`,
      color: `${COLORS.PRIMARY_CONTRAST} !important`,
    },
    fontSize: '0.875rem',
    color: COLORS.PRIMARY,
    padding: '2rem 2rem',
    '@media (min-width:600px)': {
      fontSize: '0.875rem',
    },
    '@media (min-width:960px)': {
      fontSize: '0.875rem',
    },
    '@media (min-width:1280px)': {
      fontSize: '0.875rem',
    },
  },
};

export default MuiMenuItem;
