const Coverage = {
  CORPORATE: 'corporate_coverage',
  PERSONAL: 'personal_coverage',
  SILVERCAR: 'silvercar',
};

export const coverageStrings = {
  addProtection: 'Insurance Coverage',
  autoLabel: 'Auto physical damage',
  autoCoverageHint: 'This option covers your Audi rental.',
  completeCoverageHint: 'Complete coverage for personal & property, auto, and liability.',
  customerResponsibleLabel: 'You are currently responsible for all damage and theft to the rental vehicle.',
  learnMore: 'Learn more about Audi on demand Protection',
  liabilityLabel: 'Liability',
  liabilityCoverageHint: 'Covers against claims made by a third party for bodily injury and damage to their vehicle or other property.',
  modifyProtection: 'Modify protection',
  personalAccidentAndPropertyCoverageHint: 'Personal accident insurance - Provides limited medical expense coverage in the event of an accident. Personal effects coverage - covers damage to your personal property in the vehicle at the time of loss.',
  personalAndPropertyLabel: 'Personal accident insurance / Personal effects coverage',
  protectRentalFirstParagraph: 'You must provide personal or corporate full coverage auto insurance that extends to rental vehicles and meets applicable state minimums.',
  protectRentalSecondParagraph: 'Proof of valid insurance is required and we do not accept alternative Coverage such as through credit cards. Audi on demand does not Provide insurance for purchase.',
  protectRental: 'Protect your rental. Limit your financial exposure to accidental damages or theft of your vehicle.',
  selectCoverage: 'Select coverage',
  stateSpecificCoverageNotice: 'Liability and Personal accident insurance / Personal effects coverage protections are not available from Audi on demand in Massachusetts, New York, and Virginia.',
  zeroFinancialResponsibilityLabel: 'Zero financial responsibility',
  noCoverageWarningPrefix: 'Please ensure your insurance carrier or credit card issuer provides adequate car rental coverage. See the ',
  noCoverageWarningPolicyLink: 'rental agreement',
  noCoverageWarningSuffix: ' section 12 and review with your insurance carrier or credit card issuer for clarification',
};

export default Coverage;
