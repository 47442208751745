// @flow
import { Button, Text, TextField } from '@audi/audi-ui-react';
import { Box } from '@material-ui/core';
import React, { Component } from 'react';
import { ValidatorForm } from 'react-material-ui-form-validator';
import FormWrapper from '../../layout/form-wrapper';
import { validatePassword } from '../../../utils/validators';
import { ResetPasswordFormProps, ResetPasswordFormState } from './types';

class ResetPasswordForm extends Component<
 ResetPasswordFormProps,
 ResetPasswordFormState
> {
  constructor(props: Object) {
    super(props);

    this.state = {
      password: '',
      confirmPassword: '',
      disabled: true,
      isValidPassword: true,
      isPasswordMatch: true,
    };
  }

  handleChange(event: Object) {
    const { name, value } = event.target;
    let { isValidPassword, isPasswordMatch } = this.state;
    isValidPassword = name === 'password' ? validatePassword(value) : isValidPassword;
    isPasswordMatch = name === 'confirmPassword'
      ? this.isPasswordMatch(value)
      : isPasswordMatch;
    this.setState({ [name]: value, isValidPassword, isPasswordMatch });
  }

  isPasswordMatch(confirmPassword: string) {
    const { password } = this.state;
    const isPasswordMatch = confirmPassword === password;

    if (isPasswordMatch) {
      this.setState({ disabled: false });
    }

    return isPasswordMatch;
  }

  handleSubmit(event: Object) {
    event.preventDefault();
    const { password } = this.state;
    const { authenticationToken, onSubmit } = this.props;
    const data = {
      password,
      token: authenticationToken,
    };
    onSubmit(data);
  }

  render() {
    const {
      password,
      confirmPassword,
      disabled,
      isValidPassword,
      isPasswordMatch,
    } = this.state;

    return (
      <Box mt="10.37rem" mb="19.063rem">
        <FormWrapper>
          <Text as="h2" variant="order2" weight="bold">
            Reset password
          </Text>
          <ValidatorForm onSubmit={event => this.handleSubmit(event)}>
            <Box mt="1.9375rem" mb="1.4375rem">
              <TextField
                inputId="text-field__password"
                label="Password"
                name="password"
                onChange={e => this.handleChange(e)}
                required
                type="password"
                value={password}
                invalid={!isValidPassword}
                validationMessage="Please enter a password that contains at least one upper and lowercase letter, and at least one number."
                labelEffect={password ? 'none' : 'float'}
              />
            </Box>
            <TextField
              inputId="text-field__confirm-password"
              label="Confirm password"
              onChange={e => this.handleChange(e)}
              name="confirmPassword"
              type="password"
              value={confirmPassword}
              invalid={!isPasswordMatch}
              validationMessage="passwords do not match"
              labelEffect={confirmPassword ? 'none' : 'float'}
              required
            />
            <Box mt="2.5625rem">
              <Button
                disabled={disabled || !isPasswordMatch}
                type="submit"
                variant="primary"
                stretch
              >
                  Reset password
              </Button>
            </Box>
          </ValidatorForm>
        </FormWrapper>
      </Box>
    );
  }
}

export default ResetPasswordForm;
