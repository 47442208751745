// @flow
import { combineReducers } from 'redux';
import * as ModalTypes from '../../actions/modal/constants';

type Action = {
  type: string,
  payload?: any
}

function curbsideText(state: string = '', action: Action): string {
  switch (action.type) {
    case ModalTypes.SET_CURBSIDE_TEXT:
      if (!action.payload) return state;
      return action.payload;
    case ModalTypes.RESET_CURBSIDE_TEXT:
      return '';
    default:
      return state;
  }
}

function recentBookingToken(state: string = '', action: Action): string {
  switch (action.type) {
    case ModalTypes.SET_RECENT_BOOKING_TOKEN:
      if (!action.payload) return state;
      return action.payload;
    default:
      return state;
  }
}

export default combineReducers({
  curbsideText,
  recentBookingToken,
});
