/* eslint-disable import/prefer-default-export */
import moment from 'moment';
import store from '../store';
// import states from '../../../utils/data/statesList';
import { statesName } from './data/statesList';

export const defaultError = 'Sorry. Something is amiss on our side.';
export const networkError = 'Unable to complete operation due to a bad internet connection.';
export const coverageWarningTypes = [
  'A5C',
  'Q7',
];

export function shouldShowCoverageWarning(coverageType, vehicleType) {
  return ['personal', 'corporate'].includes(coverageType)
    && coverageWarningTypes.includes(vehicleType);
}

export function getErrorMessage(action) {
  let err = defaultError;
  if (action
    && action.payload
    && action.payload.response
    && action.payload.response.data
    && action.payload.response.data.message) {
    err = action.payload.response.data.message;
  } else if (action
    && action.response
    && action.response.data
    && action.response.data.message) {
    err = action.response.data.message;
  } else if (action
    && action.payload
    && action.payload.message
    && action.payload.message === 'Network Error'
  ) {
    err = networkError;
  } else if (action && action.error_description) {
    err = 'Invalid email or password.';
  }
  return err;
}

export function sortLocations(locations, sortBy) {
  return locations.sort((a, b) => {
    const valueA = a.address[sortBy].toLowerCase();
    const valueB = b.address[sortBy].toLowerCase();
    // eslint-disable-next-line no-nested-ternary
    return (valueA < valueB) ? -1 : (valueA > valueB) ? 1 : 0;
  });
}

export function groupLocationsByStateName(locations) {
  const locationObject = {};

  locations.forEach((location) => {
    let currentState = location.address && location.address.state;

    if (currentState) {
      currentState = statesName[currentState].name;
      if (currentState in locationObject) {
        locationObject[currentState] = [...locationObject[currentState], location];
      } else {
        locationObject[currentState] = [location];
      }
    }
  });

  return Object.entries(locationObject);
}

export function getLocationIdsFromLocation(location) {
  const ids = [location.id];
  if (location.multi_car_location_ids) {
    ids.push(...location.multi_car_location_ids);
  }
  return ids;
}

function isExactMatch(searchValue, match) {
  if (searchValue && match) {
    return searchValue.toLowerCase() === match;
  }
  return false;
}

export function findMatchedLocationList(locationList, value) {
  const [stateName, locations] = locationList;
  const found = locations.filter(location => findMatchedLocation(location, stateName, value));
  if (found.length) {
    return locationList;
  }
  return false;
}

export function findMatchedLocation(
  {
    multi_car_display_name,
    airport_code, address,
    name,
  },
  stateName,
  value,
) {
  const {
    city,
    line1,
    state,
    zip,
  } = address;
  const formattedValue = value.toLowerCase();
  let isFound = name.toLowerCase().includes(formattedValue);
  let exactMatch = isExactMatch(name, formattedValue);

  if (!exactMatch && !isFound) {
    isFound = airport_code ? airport_code.toLowerCase().includes(formattedValue) : false;
    exactMatch = isExactMatch(airport_code, formattedValue);
  }

  if (!exactMatch && !isFound) {
    isFound = zip.toLowerCase().includes(formattedValue);
    exactMatch = isExactMatch(zip, formattedValue);
  }

  if (!exactMatch && !isFound) {
    isFound = city.toLowerCase().includes(formattedValue);
    exactMatch = isExactMatch(city, formattedValue);
  }

  if (!exactMatch && !isFound) {
    isFound = state.toLowerCase().includes(formattedValue);
    exactMatch = isExactMatch(state, formattedValue);
  }

  if (!exactMatch && !isFound) {
    isFound = stateName.toLowerCase().includes(formattedValue);
    exactMatch = isExactMatch(stateName, formattedValue);
  }

  if (!exactMatch && !isFound) {
    isFound = line1.toLowerCase().includes(formattedValue);
    exactMatch = isExactMatch(line1, formattedValue);
  }

  if (!exactMatch && !isFound) {
    isFound = multi_car_display_name.toLowerCase().includes(formattedValue);
    exactMatch = isExactMatch(multi_car_display_name, formattedValue);
  }

  return exactMatch || isFound;
}

/**
 * Constructs an s3 image URL.
 * @param {string} fileName - Static image fileName
 */
export function getStaticImage(fileName) {
  const env = (process.env.REACT_APP_API_HOST === 'rac-tst') ? 'development' : 'production';
  return `https://s3.amazonaws.com/${env}-silvercar-static-assets/assets/web-assets/${fileName}`;
}

/**
 * Constructs an s3 image URL.
 * @param {object} location - Silvercar Location. Default: aus.
 * @param {string} assetName - Image type: 'active', 'intrip', or 'dropoff'. Default: 'active'
 * @param {boolean} landscape - Image orientation
 */
export function getLocationImageFromLocation(location, landscape = false, assetName = 'upcoming') {
  const env = (process.env.REACT_APP_API_HOST === 'rac-tst') ? 'development' : 'production';
  const assetCode = (location && location.asset_code) ? location.asset_code : 'aus';
  const layout = !landscape ? '2x' : 'web';
  return `https://s3.amazonaws.com/${env}-silvercar-static-assets/assets/location-assets/${assetCode}_${assetName}_${layout}.jpg`;
}

/**
 * Sets a timeout for Toast Messages used for async replies
 * @param {function} dispatch - Silvercar Location. Default: aus.
 * @param {const} action - the name of the action that will be dispatched
 * @param {number} timeout - the length of the new timer
 */
export function setToastTimeout(action) {
  window.timeout = 5000;
  window.clearTimeout(window.timer);
  window.timer = setTimeout(() => store.dispatch(action), window.timeout);
}

export function getMonthsBetween(startDate, stopDate) {
  return moment(stopDate).diff(moment(startDate), 'months');
}

const PromoMessages = [
  'not valid for this user',
  'earliest_date or latest_date must be set',
  'must be before',
  'must be after',
  'Promo code cannot be used for your account',
  'Promo code does not exist',
  'only valid for your first rental',
  'is not valid for',
  'Only valid for GDS Reservations',
  'Has exceeded max uses',
  'Rental duration must be at least',
  'not valid for this reservation',
  'Promo code successfully redeemed',
  'Your promo code has been removed',
];

export function isPromoMessage(message) {
  return PromoMessages.some(pm => (
    message.includes(pm)
  ));
}

export function moveToTop() {
  window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
}
