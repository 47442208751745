/* eslint-disable no-nested-ternary */
// @flow
import React, { useEffect, useState } from 'react';
import {
  Tab, TabGroup, Text,
} from '@audi/audi-ui-react';
import { connect } from 'react-redux';
import './index.css';
import EmptyBooking from '../booking/empty-booking';
import Loading from '../../loading';
import ProfileIncompleteModal from '../../modals/profile-incomplete';
import SpentLoyaltyPointsModal from '../../modals/spent-loyalty-points';
import theme from '../../../theme';
import {
  trackBookingStart, getUpcomingFleetById,
} from '../../../actions/booking';
import PastReservations from './past-reservations';
import mapReservationData from './reservationDataMapper';
import ReservationCard from './active-and-upcoming-reservations';

// This function parses the id from an API provided link
// * Function should throw an error if the link is not a valid URL
// * Function should throw an error if there is no id in the link
// those two states represent a bug in the API, and we should know about it
// we get payloads like `type BookingResponse`
export const idFromUrl = str => /\d+$/.exec((new URL(str)).pathname)[0];

type BookingResponse = {
    "id": number,
    "reservation_type": string, // "ReservationTypes::PayLater", unknown enum
    "state": string, // "booked", unknown enum
    "pickup_at": string, // "2022-10-10T16:30:00Z",
    "dropoff_at": string, // "2022-10-13T04:30:00Z",
    "confirmation_token": string,
    "token": string,
    "local_rental": boolean,
    "self_service": boolean,
    "pricing": {
        "total": string, // "742.79",
        "per_day": number,
        "currency_code": string, // "USD", unknown enum
    },
    "promotion": boolean,
    "coverages": [
        {
            "id": number,
            "type": string, // unknown enum, "everything_you_need",
            "name": string,
            "carrier": string,
            "policy_number": number
        }
    ],
    "flight": boolean,
    "links": [
        {
            "href": string, // "https://api.rac-tst.com/fleets/52",
            "rel": string, // "fleet"
        },
    ]
}
// the location and fleet are not defined as properties of the reservation, but as links

type Bookings = {
    results: BookingResponse[],
    page: boolean,
    isLoading: boolean,
}

type Fleet = { make: string, model: string }

type Props = {
    bookings: Bookings,
    locationMap: Object,
    rentals: Object,
    trackBookingStart: Function,
    fleets: [Fleet],
    upcomingRentalFleets: Function,
}

export function ReservationsList(props: Props) {
  const {
    bookings,
    locationMap,
    rentals,
    trackBookingStart,
    fleets,
    upcomingRentalFleets,
  } = props;

  const activeRentals = rentals.results.filter(rental => rental.state.toLowerCase() === 'started');
  const activeRental = (activeRentals && activeRentals.length > 0) ? activeRentals[0] : undefined;
  const pastRentals = rentals.results.filter(rental => rental.state.toLowerCase() !== 'started');

  const screenVisible = activeRentals.length
        || bookings.results.length
        || pastRentals.length
        || bookings.page;

  const [tabState, setTabState] = useState('tab-1');
  const [isMobile, setIsMobile] = useState(false);
  const updateDimensions = () => {
    setIsMobile(window.innerWidth < theme.breakpoints.values.md);
  };

  useEffect(() => {
    updateDimensions();
    window.addEventListener('resize', updateDimensions);
  }, [isMobile]);

  function findFleetId(bookingOrRental) {
    return bookingOrRental && bookingOrRental.links
      ? idFromUrl(bookingOrRental.links.find(link => link.rel === 'fleet').href)
      : undefined;
  }

  useEffect(() => {
    if (!!bookings && !bookings.isLoading) {
      const activeRentalFleetId = findFleetId(activeRental);
      const upcomingRentalFleetIds = bookings
        .results.map(booking => idFromUrl(booking.links.find(link => link.rel === 'fleet').href));
      const ids = activeRentalFleetId
        ? [...new Set([activeRentalFleetId, ...upcomingRentalFleetIds])]
        : [...new Set([...upcomingRentalFleetIds])];
      upcomingRentalFleets(ids);
    }
  }, [bookings, activeRental, upcomingRentalFleets]);

  function renderUpcomingRentals() {
    return (bookings.isLoading && !bookings.page
      ? (
        <Loading isLoading={bookings.isLoading} />
      ) : (
        <section className="rentalContainer">
          {bookings.results.length === 0 ? (
            <EmptyBooking
              className="empty"
              trackBookingStart={trackBookingStart}
              title="Looks like you don&#39;t have any upcoming reservations."
            />
          )
            : (Object.keys(locationMap).length > 0 && (
            <section className="upcomingRentalContainer">
              {bookings.results.map((booking, idx) => (
                <ReservationCard
                  booking={booking}
                  fleets={fleets}
                  current_booking_fleet_id={findFleetId(booking)}
                  locationMap={locationMap}
                  key={idx}
                  reservationState="upcoming"
                />
              ))}
            </section>
            ))}
        </section>
      )
    );
  }

  function renderPastRentals() {
    return (pastRentals && !!pastRentals.length ? (
      <section className="rentalContainer">
        <PastReservations
          pastRentals={mapReservationData({ rentals: pastRentals, locations: locationMap })}
        />
      </section>
    ) : (
      <section className="rentalContainer">
        <EmptyBooking
          className="empty"
          trackBookingStart={trackBookingStart}
          title="Looks like you don&#39;t have any past reservations."
        />
      </section>
    )
    );
  }

    type ReservationTabPanelContentProps = {
        selectedTab: string;
    }

    const ReservationTabPanelContent: React.FC<ReservationTabPanelContentProps> = (
      props: ReservationTabPanelContentProps,
    ) => {
      const { selectedTab } = props;
      switch (selectedTab) {
        default:
        case 'tab-1':
          return renderUpcomingRentals();
        case 'tab-2':
          return renderPastRentals();
      }
    };

    function renderReservationContainer() {
      return (
        <div className="container">
          <section className="title">
            <Text
              as="h1"
              variant="order1"
              weight="bold"
              spaceStackEnd="s"
            >
                        Reservations
            </Text>
          </section>
          {activeRental && (
            <section className="today">
              <Text
                as="h3"
                variant="order3"
                weight="bold"
                spaceStackEnd="s"
              >
                            Today
              </Text>

              <ReservationCard
                booking={activeRental}
                fleets={fleets}
                current_booking_fleet_id={findFleetId(activeRental)}
                locationMap={locationMap}
                reservationState="active"
              />

            </section>
          )}
          <section>
            <TabGroup
              className="tabGroup"
              onSelect={setTabState}
              selected={tabState}
              size={isMobile ? 'small' : 'large'}
            >
              <Tab id="tab-1">Upcoming reservations</Tab>
              <Tab id="tab-2">Past reservations</Tab>
            </TabGroup>
            <ReservationTabPanelContent selectedTab={tabState} />
          </section>
        </div>
      );
    }

    if (!screenVisible) {
      return <Loading />;
    }

    return (
      <div className="sc-reservation-list">
        <ProfileIncompleteModal />
        <SpentLoyaltyPointsModal />
        {renderReservationContainer()}
      </div>
    );
}

export const mapStateToProps = state => ({
  bookings: state.bookingReducer.bookings,
  locations: state.bookingReducer.locations,
  rentals: state.bookingReducer.rentals,
  locationMap: state.bookingReducer.locations.locationMap,
  fleets: state.bookingReducer.upcomingRentalFleets,
});

export const mapDispatchToProps = dispatch => ({
  trackBookingStart: () => dispatch(trackBookingStart()),
  upcomingRentalFleets: fleetIds => dispatch(getUpcomingFleetById(fleetIds)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReservationsList);
