// @flow
import React, { Component } from 'react';
import numeral from 'numeral';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Text } from '@audi/audi-ui-react';
import { FlatButton, FontIcon } from 'material-ui';
import {
  getFleetById,
  toggleDeleteBookingModalVisible,
} from '../../../actions/booking';
import { setModifiedBooking } from '../../../actions/booking/modify';

import { getLocationImageFromLocation } from '../../../utils';
import { formatCurrency, formatDateTime } from '../../../utils/formatters';
import DeleteBookingModal from '../../modals/delete-booking';
import FlightDetailsForm from '../../forms/flight-details';

import SCList from '../../ui/sc-list';
import SCAddress from '../../ui/sc-address';
import SCLink from '../../ui/sc-link';

import './index.css';
import { getFleetFromLinks } from '../../../actions/booking/utils';
import { getReservationDetailsHref } from '../../../containers/reservations/reservation-detail/helpers';
import UpcomingReservation from './upcoming-reservation';
import vehicles from '../../../utils/data/vehicleList';
import Loading from '../../loading';

const UPCOMING_RESERVATION = 'upcoming';

type Props = {
  booking: Object,
  fleets: Object,
  getFleetById: Function,
  location: Object,
  receipt: Object,
  reservationState: String,
  setModifiedBooking: Function,
  toggleDeleteBookingModalVisible: Function,
}

type ActiveAndUpcomingProps = {
  listItems: Array<Object>,
  booking: Object,
  reservationState: String,
}

type PreviousRentalListProps = {
  receipt: Object,
}

type CoverageOptionsProps = {
  item: Object,
}

export function CoverageOptions(props: CoverageOptionsProps) {
  const { item } = props;
  return (
    <div>
      {item.value.map((subitem, idxx) => {
        const {
          carrier,
          name,
          policy_number,
        } = subitem;
        const category = (name === 'Personal' || name === 'Corporate') ? name : 'Silvercar';

        if (category === 'Silvercar') {
          if (idxx === 0) {
            return (
              <div key={idxx}>
                <div>{ category }</div>
                <div>{ name }</div>
              </div>
            );
          }
          return (
            <div key={idxx}>
              { name }
            </div>
          );
        }
        return (
          <div key={idxx}>
            <div>{ category }</div>
            <div>
              <span>{ carrier }</span>
              <span>{ policy_number && ` - ${policy_number}` }</span>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export function ActiveAndUpcomingRentalList(props: ActiveAndUpcomingProps) {
  const { listItems, reservationState, booking } = props;
  return (
    <div>
      {listItems.map((item, idx) => (
        <div className="list-item-row" key={idx}>
          <div>{item.label}</div>
          {Array.isArray(item.value)
            ? (
              <div className="coverage">
                {reservationState === 'upcoming'
                  ? (
                    <Link
                      to={
                        getReservationDetailsHref({
                          id: booking.id,
                          path: 'edit-coverage',
                          type: reservationState,
                        })
                      }
                    >
                      <CoverageOptions item={item} />
                    </Link>
                  ) : <CoverageOptions item={item} />
                }
              </div>
            ) : <div>{item.value}</div>
          }
        </div>
      ))}
    </div>
  );
}

export function PreviousRentalList(props: PreviousRentalListProps) {
  const { receipt } = props;
  const { pricing } = receipt;
  const tripDetails = receipt.trip_details;
  const airportCode = tripDetails.pickup_airport_code;

  const topListItems = [
    {
      label: 'Total Charges',
      value: numeral(receipt.total).format('$0.00'),
    },
    {
      label: 'Confirmation Number',
      value: tripDetails.confirmation_number,
    },
  ];

  const tripDetailsListItems = [
    {
      label: 'Location',
      value: `${tripDetails.pickup_city} ${airportCode ? `(${airportCode})` : ''}`,
    },
    {
      label: 'Pick Up',
      value: tripDetails.pickup_at,
    },
    {
      label: 'Drop Off',
      value: tripDetails.dropoff_at,
    },
    {
      label: 'Duration',
      value: tripDetails.duration,
    },
  ];

  const summaryItems = [
    {
      label: 'Total Charges',
      value: numeral(receipt.total).format('$0.00'),
    },
    {
      label: 'Remaining Balance',
      value: receipt.balance,
    },
  ];

  return (
    <div>
      <SCList header="" variant="card" items={topListItems} heavyPadding={false} topBorder={false} />
      <SCList header="Trip Details" variant="" items={tripDetailsListItems} heavyPadding={false} topBorder={false} />
      <SCList header="Rental" variant="" items={pricing.rental.items} heavyPadding={false} topBorder={false} />
      {pricing.coverage ? <SCList header="Coverage Breakdown" variant="" items={pricing.coverage.items} heavyPadding={false} topBorder={false} /> : null}
      {pricing.discount ? <SCList header="Discounts" variant="" items={pricing.discount.items} heavyPadding={false} topBorder={false} /> : null}
      <SCList header="Fees and Concessions" variant="" items={pricing.fees_and_concessions.items} heavyPadding={false} topBorder={false} />
      {pricing.tolls ? <SCList header="Tolls" variant="" items={pricing.tolls.items} heavyPadding={false} topBorder={false} /> : null}
      <SCList header="Taxes" variant="" items={pricing.taxes.items} heavyPadding={false} topBorder={false} />
      <SCList header="Summary" variant="" items={summaryItems} heavyPadding={false} topBorder={false} />
    </div>
  );
}

export function FlightDetails() {
  return (
    <div>
      <div className="list-item-row flight">
        <div>My flight information</div>
        <div>
          <FlightDetailsForm />
        </div>
      </div>
    </div>
  );
}


export class SingleReservation extends Component<Props> {
  componentDidMount() {
    const {
      fleets,
      booking,
      getFleetById,
    } = this.props;

    const fleetId = getFleetFromLinks(booking.links);
    if (fleetId && !fleets[fleetId]) getFleetById(fleetId);
  }

  UNSAFE_componentWillMount() {
    const { setModifiedBooking } = this.props;
    setModifiedBooking();
  }

  render() {
    const {
      booking,
      fleets,
      location,
      reservationState,
      receipt,
      toggleDeleteBookingModalVisible,
    } = this.props;

    const {
      dropoff_at,
      pickup_at,
    } = booking;

    const isAirport = !!location.airport_code;
    const locationIcon = isAirport ? 'local_airport' : 'location_city';
    const fleetId = getFleetFromLinks(booking.links);
    const isRentalAgreement = ({ rel }) => rel === 'rental_agreement';
    const { href: rentalAgreementUrl } = booking.links.find(isRentalAgreement);
    const fleet = fleetId
      ? fleets[fleetId]
      : null;

    if (booking && location && fleet) {
      const pickup = formatDateTime(pickup_at, location);
      const dropoff = formatDateTime(dropoff_at, location);

      const fuelPolicyUrl = 'https://support.audiondemand.com/hc/en-us/articles/204984014-What-is-Silvercar-s-Fair-Fueling-Promise';

      const fuelPolicyOrCancel = () => {
        switch (reservationState) {
          case 'upcoming':
            return (
              <FlatButton
                onClick={() => toggleDeleteBookingModalVisible()}
              >
                Cancel Booking
              </FlatButton>
            );
          case 'previous':
            return (
              <SCLink
                href={fuelPolicyUrl}
                text="Fuel Policy"
              />
            );
          default:
            return null;
        }
      };

      let imgType = '';
      switch (reservationState) {
        case 'upcoming':
          imgType = 'upcoming';
          break;
        case 'active':
          imgType = 'intrip';
          break;
        case 'previous':
          imgType = 'dropoff';
          break;
        default:
      }

      const listItems = [
        {
          label: 'Confirmation Number',
          value: booking.confirmation_token,
        },
        {
          label: 'Coverage',
          value: booking.coverages,
        },
        {
          label: 'Estimated Total',
          value: formatCurrency(booking.pricing.total),
        },
      ];

      const airportCodeString = location.airport_code ? `(${location.airport_code})` : '';
      const requestLink = 'https://www.audiondemand.com/support/new-request/';
      const fleetType = `Audi ${fleet.vehicle.model.toUpperCase()}`;
      const vehicle = fleets[fleetId]
        && vehicles.find(v => v.data_key.toUpperCase() === fleets[fleetId].vehicle.model);

      if (reservationState === UPCOMING_RESERVATION) {
        return (
          <UpcomingReservation
            booking={booking}
            location={location}
            vehicle={vehicle}
            rentalAgreementUrl={rentalAgreementUrl}
            requestLink={requestLink}
            reservationState={reservationState}
            isAirport={isAirport}
          />
        );
      }
      return (
        <div className="single-reservation">
          <DeleteBookingModal
            id={booking.id}
            confirmation_token={booking.confirmation_token}
            total={booking.pricing.total}
          />
          <header>
            <Text as="h4" variant="order1" style={{ textTransform: 'capitalize' }}>
              {reservationState}
              {' '}
              Reservation
            </Text>
          </header>
          <div className="card card-white">
            <div>
              <header style={{ backgroundImage: `url(${getLocationImageFromLocation(location, true, imgType)})` }}>
                <Text as="h5" variant="order2" weight="bold">{`${location.multi_car_display_name} ${airportCodeString}`}</Text>
                <Text as="p" variant="copy1" className="location-description">
                  <FontIcon style={{ fontSize: 10, color: 'white' }}>
                    <i className="material-icons">{locationIcon}</i>
                  </FontIcon>
                  {location.description}
                </Text>
              </header>
              <div>
                <aside className="card">
                  <div>
                    <Text as="p" variant="copy1">Pick Up</Text>
                    {reservationState === 'upcoming'
                      ? (
                        <Text as="p" variant="copy2">
                          <Link
                            to={
                              getReservationDetailsHref({
                                id: booking.id,
                                path: 'edit-date',
                                type: reservationState,
                              })
                            }
                          >
                            {pickup}
                          </Link>
                        </Text>
                      )
                      : <Text as="p" variant="copy2">{pickup}</Text>
                    }
                  </div>
                  <div>
                    <Text as="p" variant="copy1">Drop Off</Text>
                    {(reservationState === 'upcoming' || reservationState === 'active')
                      ? (
                        <Text as="p" variant="copy2">
                          <Link
                            to={
                              getReservationDetailsHref({
                                id: booking.id,
                                path: 'edit-date',
                                type: reservationState,
                              })
                            }
                          >
                            {dropoff}
                          </Link>
                        </Text>
                      ) : <Text as="p" variant="copy2">{dropoff}</Text>
                    }
                  </div>
                  <div>
                    <Text as="p" variant="copy1">Vehicle Type</Text>
                    {/*
                       Since we are using the location to determine the vehicle type,
                       we are hardcoding the make here. We need to @TODO have a discussion on how to
                       futureproof this when we inevitably add non-audi vehicles.
                    */}
                    <p>
                      {reservationState === 'upcoming'
                        ? (
                          <Link
                            to={
                              getReservationDetailsHref({
                                id: booking.id,
                                path: 'edit-vehicle',
                                type: reservationState,
                              })
                            }
                          >
                            { fleetType }
                          </Link>
                        ) : fleetType
                      }
                    </p>
                  </div>
                  <SCAddress address={location.address} />
                  {reservationState === 'upcoming'
                    && (
                      <SCLink
                        href={requestLink}
                        additionalText="(child seats, etc.)"
                        linkText="Request Extras"
                      />
                    )
                  }
                </aside>
                <div>
                  {reservationState === 'previous'
                    ? (
                      <PreviousRentalList
                        receipt={receipt}
                        location={location}
                      />
                    ) : (
                      <ActiveAndUpcomingRentalList
                        listItems={listItems}
                        booking={booking}
                        reservationState={reservationState}
                      />
                    )
                  }
                  {(reservationState === 'upcoming' && isAirport) && (
                    <FlightDetails />
                  )}
                  <div className="single-reservation-links">
                    <div>
                      {fuelPolicyOrCancel()}
                    </div>
                    <div>
                      <a
                        target="_blank"
                        href={rentalAgreementUrl}
                        rel="noopener noreferrer"
                      >
                        Rental Agreement
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
    return <Loading />;
  }
}

export const mapStateToProps = state => ({
  fleets: state.bookingReducer.fleets,
});

export const mapDispatchToProps = dispatch => ({
  setModifiedBooking: () => dispatch(setModifiedBooking()),
  toggleDeleteBookingModalVisible: () => dispatch(toggleDeleteBookingModalVisible()),
  getFleetById: fleetId => dispatch(getFleetById(fleetId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SingleReservation);
