// @flow
import React, { useEffect } from 'react';
import { useMediaQuery } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { TextField, Text } from '@audi/audi-ui-react';
import TotalsItem from '../../TotalsItem';
import SCDialog from '../../sc-dialog';
import { getFleetById, toggleReservationReviewModalVisible } from '../../../actions/booking';
import CostBreakdown from '../../cost-breakdown';
import NumberDisplay from '../../number-display';
import { formatNewDateTime } from '../../../utils/formatters';
import vehicles from '../../../utils/data/vehicleList';
import {
  getBookingFromModified,
  getReceiptFromModified,
  getReservationReviewModalVisible,
  getLocationMap,
  getFleets,
} from '../../../selectors';
import { getReceipt, getRental } from '../../../actions/booking/modify';
import { getLocationFromLinks, getFleetFromLinks } from '../../../actions/booking/utils';
import './index.css';

type Props = {
  reservationID: String,
  reservationType: String,
}

const ReservationReviewModal = (props: Props) => {
  const { reservationID, reservationType } = props;
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const booking = useSelector(getBookingFromModified);
  const { links } = booking;
  const receipt = useSelector(getReceiptFromModified) || {};
  const locationMap = useSelector(getLocationMap);
  const fleetsObject = useSelector(getFleets);
  const fleet = Object.values(fleetsObject)[0] || {};
  const reservationReviewModalVisible = useSelector(getReservationReviewModalVisible);
  const dispatch = useDispatch();

  let carName;
  let carPriceTotal;
  let locationObject = {};
  let location;
  let taxesAndFees;
  let coveragePrice;
  let coverageTitle;
  let coveragePriceDaily;
  let daysRented;
  let discounts;
  let timeZone = 'est';
  let rentalAgreementURL;

  if (Object.keys(fleet).length) {
    const carObject = vehicles.find(v => v.data_key.toUpperCase() === fleet.vehicle.model);
    carName = `Audi ${carObject.model}`;
  }

  if (Object.keys(links).length) {
    locationObject = locationMap[getLocationFromLinks(links)];
    const isRentalAgreement = ({ rel }) => rel === 'rental_agreement';
    const rentalAgreement = links.find(isRentalAgreement);
    rentalAgreementURL = rentalAgreement.href;
  }

  if (Object.keys(locationObject).length) {
    location = locationObject.multi_car_display_name;
    timeZone = locationObject.time_zone;
  }

  if (Object.keys(receipt).length) {
    carPriceTotal = receipt.pricing.rental.total;
    daysRented = receipt.trip_details.duration.split(' ');
    discounts = receipt.pricing.discount;
    taxesAndFees = {
      label: 'Taxes & fees',
      total: receipt.pricing.taxes.total + receipt.pricing.fees_and_concessions.total,
      items: [...receipt.pricing.taxes.items, ...receipt.pricing.fees_and_concessions.items],
    };
  }

  const pickupDateTime = formatNewDateTime(booking.pickupDate, booking.pickupTime, timeZone);
  const dropoffDateTime = formatNewDateTime(booking.dropoffDate, booking.dropoffTime, timeZone);
  const carPriceDaily = Math.round(carPriceTotal / daysRented[0]);

  useEffect(() => {
    dispatch(getRental(reservationID, reservationType));
    dispatch(getReceipt(reservationID, reservationType));
  }, [reservationID, reservationType, dispatch]);

  useEffect(() => {
    const fleetID = getFleetFromLinks(links);
    if (fleetID) {
      dispatch(getFleetById(fleetID));
    }
  }, [links, dispatch]);

  const coverageOptions = receipt.pricing.coverage;

  if (coverageOptions.items) {
    coveragePrice = coverageOptions.total;
    coveragePriceDaily = Math.round(coveragePrice / daysRented[0]);
    coverageTitle = coverageOptions.items.length > 2 ? 'Zero Financial Responsibility' : 'Coverage Options';
  }

  const handleReservationReviewModal = () => {
    dispatch(toggleReservationReviewModalVisible());
  };

  return (
    <SCDialog
      open={reservationReviewModalVisible}
      onClose={handleReservationReviewModal}
      width="sm"
      fullWidth
      title="Receipt"
      showCloseButton
    >
      <div className="reservation-review-modal-container">
        <section className="location-section">
          <TextField
            className="location-text-field"
            inputId="location-select"
            label="Location"
            labelEffect="none"
            readOnly
            required
            value={location}
          />
          <div className="date">
            <TextField
              inputId="pickup-date-time"
              label="Start"
              labelEffect="none"
              readOnly
              required
              value={pickupDateTime}
            />
            <div className="stop-container">
              <TextField
                inputId="return-date-time"
                label="Stop"
                labelEffect="none"
                readOnly
                required
                value={dropoffDateTime}
              />
            </div>
          </div>
        </section>
        <section className="totals-section">
          <div className="totalItem">
            <TotalsItem
              label="Vehicle"
              item={carName}
              dailyPrice={carPriceDaily}
              price={carPriceTotal}
            />
          </div>
          <div className="totalItem">
            {coverageOptions && (
            <TotalsItem
              label="Protection"
              item={coverageTitle}
              dailyPrice={coveragePriceDaily}
              price={coveragePrice}
            />
            )}
            {coverageOptions && coverageOptions.items.map(x => (
              <TotalsItem subItem={x.label} />
            ))}
          </div>
          <div className="totalItem">
            {discounts && (
            <TotalsItem
              label="Discounts"
              item="Total Discount"
              price={discounts.total}
            />
            )}
            {discounts && discounts.items.map(x => (
              <TotalsItem subItem={`-${x.label}`} />
            ))}
          </div>
        </section>
        <section className="total-taxes">
          <CostBreakdown item={taxesAndFees} />
        </section>
        <section className="total-footer">
          <div className="misc-info">
            <Text as="p" variant="copy3">
              Confirmation Number:
              {booking.confirmation_token}
            </Text>
            <Text as="p" variant="copy3">
              Remaining Balance:
              {receipt.balance}
            </Text>
            <Text as="p" variant="copy3">
              <a href={rentalAgreementURL} target="_blank" rel="noreferrer noopener">Rental Agreement</a>
            </Text>
          </div>
          <div className="summary-total">
            <Text as="p" variant="copy2" spaceStackEnd="xs" spaceInlineEnd={isMobile ? 'xxxs' : 'xl'}>Total Charges</Text>
            <NumberDisplay className="number" value={booking.pricing.total} dollarVariant="order1" />
          </div>
        </section>
      </div>
    </SCDialog>
  );
};

export default ReservationReviewModal;
