// @flow
import {
  Button, Pagination,
  Table, TableBody, TableCell, TableColumnHeadingCell, TableHeader, TableRow, Text,
} from '@audi/audi-ui-react';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toggleReservationReviewModalVisible } from '../../../../actions/booking';
import ReservationReviewModal from '../../../modals/reservation-review';
import './index.css';

interface PastRentals {
    id: string,
    state: string,
    confirmationNumber: string;
    city: string;
    started: string;
    returned: string;
    total: string;
}

export interface PastReservationProps {
    pastRentals: [PastRentals]
}

export default function PastReservations(props: PastReservationProps) {
  const confirmationTitle = 'Confirmation number';
  const cityTitle = 'City';
  const startedTitle = 'Started';
  const returnedTitle = 'Returned';
  const totalTitle = 'Total';
  const actionsTitle = 'Actions';
  const pageSize = 15;

  const { pastRentals } = props;
  const dispatch = useDispatch();


  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageItems, setPageItems] = useState(pastRentals.slice(0, pageSize));
  const [selectedReservation, setSelectedReservation] = useState(null);
  const [selectedReservationType, setSelectedReservationType] = useState('');

  const handleReservationReviewModal = (resID, resType) => {
    setSelectedReservation(resID);
    setSelectedReservationType(resType);
    dispatch(toggleReservationReviewModalVisible());
  };

  useEffect(() => {
    const numberOfRentals = pastRentals.length;
    const numberOfPages = Math.ceil(numberOfRentals / pageSize);
    setTotalPages(numberOfPages);
  }, [pastRentals]);

  useEffect(() => {
    const pageItemsStartIndex = pageSize * (currentPage - 1);
    const pageItemsEndIndex = pageItemsStartIndex + pageSize;
    setPageItems(pastRentals.slice(pageItemsStartIndex, pageItemsEndIndex));
  }, [currentPage, pastRentals]);

  function renderHeader(headerText) {
    return (
      <TableColumnHeadingCell>
        <Text
          as="span"
          variant="copy1"
          weight="bold"
        >
          {headerText}
        </Text>
      </TableColumnHeadingCell>
    );
  }

  function renderColumn(value, heading) {
    return (
      <TableCell
        columnHeading={heading}
      >
        <Text
          as="span"
          variant="copy1"
        >
          {value}
        </Text>
      </TableCell>
    );
  }

  function renderActionsColumn(id, reservationState) {
    return (
      <TableCell
        columnHeading={actionsTitle}
      >
        <Button variant="text" size="large" onClick={() => { handleReservationReviewModal(id, reservationState); }}>View details</Button>
      </TableCell>

    );
  }

  return (
    <section className="pastReservationSection">
      <Table>
        <TableHeader>
          <TableRow>
            {renderHeader(confirmationTitle)}
            {renderHeader(cityTitle)}
            {renderHeader(startedTitle)}
            {renderHeader(returnedTitle)}
            {renderHeader(totalTitle)}
            {renderHeader(actionsTitle)}
          </TableRow>
        </TableHeader>
        <TableBody>
          {pageItems.map(rental => (
            <TableRow key={rental.confirmationNumber}>
              {renderColumn(rental.confirmationNumber, confirmationTitle)}
              {renderColumn(rental.city, cityTitle)}
              {renderColumn(rental.started, startedTitle)}
              {renderColumn(rental.returned, returnedTitle)}
              {renderColumn(rental.total, totalTitle)}
              {renderActionsColumn(rental.id, rental.state)}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Pagination
        className="paginationControl"
        count={totalPages}
        onChange={nextPage => setCurrentPage(nextPage)}
        page={currentPage}
      />
      <ReservationReviewModal
        reservationID={selectedReservation}
        reservationType={selectedReservationType}
      />
    </section>
  );
}
