import React, { Component } from 'react';
import { connect } from 'react-redux';
import { callback } from '../../actions';
import Loading from '../../components/loading';

interface Props {
    callback: () => void,
  }

export class Callback extends Component<Props> {
  componentDidMount() {
    const { callback } = this.props;
    callback();
  }

  render() {
    return <Loading />;
  }
}

export const mapDispatchToProps = dispatch => ({
  callback: () => dispatch(callback()),
});

export default connect(null, mapDispatchToProps)(Callback);
