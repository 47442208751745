// @flow
import { COLORS } from '../constants';

const Mui = {
  root: {
    '&:selected': {
      backgroundColor: `${COLORS.PRIMARY} !important`,
      color: `${COLORS.PRIMARY_CONTRAST} !important`,
      '&:hover': {
        backgroundColor: `${COLORS.PRIMARY} !important`,
        color: `${COLORS.PRIMARY_CONTRAST} !important`,
      },
    },
    '&$focusVisible': {
      display: 'none',
    },
  },
};

export default Mui;
