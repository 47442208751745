// @flow
import { combineReducers } from 'redux';
import * as ModalTypes from '../../actions/modal/constants';

export type State = {
  coverageOptionsModalVisible: boolean,
  curbsideAvailableModalVisible: boolean,
  incompleteProfileHasBeenPrompted: boolean,
  incompleteProfileModalVisible: boolean,
};

type Action = {
  type: string,
  payload?: any
}

function coverageOptionsModalVisible(state: boolean = false, action: Action) {
  switch (action.type) {
    case ModalTypes.CLOSE_COVERAGE_OPTIONS_MODAL:
      return false;
    case ModalTypes.OPEN_COVERAGE_OPTIONS_MODAL:
      return true;
    default:
      return state;
  }
}

function curbsideAvailableModalVisible(state: boolean = false, action: Action): boolean {
  switch (action.type) {
    case ModalTypes.CLOSE_CURBSIDE_AVAILABLE_MODAL:
      return false;
    case ModalTypes.OPEN_CURBSIDE_AVAILABLE_MODAL:
      return true;
    default:
      return state;
  }
}

function incompleteProfileModalVisible(state: boolean = false, action: Action): boolean {
  switch (action.type) {
    case ModalTypes.CLOSE_INCOMPLETE_PROFILE_MODAL:
      return false;
    case ModalTypes.OPEN_INCOMPLETE_PROFILE_MODAL:
      return true;
    default:
      return state;
  }
}

function incompleteProfileHasBeenPrompted(state: boolean = false, action: Action) {
  switch (action.type) {
    case ModalTypes.OPEN_INCOMPLETE_PROFILE_MODAL:
      return true;
    default:
      return state;
  }
}

function vehicleSelectModalVisible(state: boolean = false, action: Action) {
  switch (action.type) {
    case ModalTypes.OPEN_VEHICLE_MODAL_VISIBLE:
      return true;
    case ModalTypes.CLOSE_VEHICLE_MODAL_VISIBLE:
      return false;
    default:
      return state;
  }
}

export default combineReducers({
  coverageOptionsModalVisible,
  curbsideAvailableModalVisible,
  incompleteProfileHasBeenPrompted,
  incompleteProfileModalVisible,
  vehicleSelectModalVisible,
});
