import React from 'react';
import { Text } from '@audi/audi-ui-react';
import { coverageStrings } from '../constants';
import history from '../../../history';

const HeaderTitle = () => {
  const {
    location: {
      pathname,
    },
  } = history;
  const isModify = pathname.includes('edit-coverage');
  const headerText = isModify ? coverageStrings.modifyProtection : coverageStrings.addProtection;

  return (
    <Text as="h1" variant="order2" weight="bold" spaceStackEnd="xl">
      {headerText}
    </Text>
  );
};

export default HeaderTitle;
