// @flow
import React from 'react';
import { connect } from 'react-redux';
import { postConfirmAccount } from '../../actions/account';
import parseQueryString from '../../utils/parsers';

type Props = {
  postConfirmAccount: () => void,
}

function handleQueryString(props: Props) {
  const queryString = props.location ? parseQueryString(props.location.search) : '';
  if (queryString) {
    props.postConfirmAccount(queryString);
  }
}

export function ConfirmAccount(props) {
  handleQueryString(props);
  return (
    <div />
  );
}

export const mapDispatchToProps = dispatch => ({
  postConfirmAccount: queryString => dispatch(postConfirmAccount(queryString)),
});

export default connect(null, mapDispatchToProps)(ConfirmAccount);
