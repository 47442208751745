// @flow
import { Text } from '@audi/audi-ui-react';
import React from 'react';
import { formatNumberWithCommas } from '../../utils/formatters/index';
import SCLoyaltyToggle from '../sc-loyalty-toggle';

import './index.css';

type Props = {
  userLoyaltyPoints: number,
}

const className = 'loyalty-points-info';

const LoyaltyProgramInfo = ({ userLoyaltyPoints }: Props) => (
  <div className={className}>
    <div className={`${className}-toggle`}>
      <SCLoyaltyToggle />
    </div>
    <div className={`${className}-total`}>
      <Text as="span" variant="copy1" weight="bold">Your Points Balance: </Text>
      <Text as="p" variant="copy1" weight="bold" spaceInlineStart="s" spaceInlineEnd="xs">
        { formatNumberWithCommas(userLoyaltyPoints) }
        {' '}
        pts
      </Text>
    </div>
  </div>
);

export default LoyaltyProgramInfo;
