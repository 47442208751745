// @flow
import React from 'react';
import { Text } from '@audi/audi-ui-react';
import '../index.css';

type Props = {
  href: string,
  isMobile: boolean,
  title: string
};

const FooterLink = ({ href, isMobile, title }: Props) => {
  const variant = isMobile ? 'copy1' : 'copy2';
  return (
    <a
      className="global-footer-link"
      href={href}
      rel="noopener noreferrer"
      title={title}
    >
      <Text as="p" className="global-footer-link-text" variant={variant}>
        {title}
      </Text>
    </a>
  );
};

export default FooterLink;
