// @flow
import React from 'react';
import numeral from 'numeral';
import './sc-list.css';

type Item = {
  label: string,
  secondary_label?: string,
  total?: string | number,
  value?: string
}

type SCListItemProps = {
  item: Item,
}

// Single list item component. Takes a label and a value, and
// displays them in a flexed row.
export function SCListItem(props: SCListItemProps) {
  const {
    item: {
      label,
      secondary_label,
      total,
      value,
    },
  } = props;
  return (
    <div className="sc-list-item">
      <div>
        <div>
          {label}
        </div>
        {secondary_label
          ? <div>{secondary_label}</div>
          : null
        }
      </div>
      <div>
        {total
          ? numeral(total).format('$0.00')
          : value
        }
      </div>
    </div>
  );
}

// Component that takes a header and a list item (with a value and a label).
// Also takes a 'variant' prop that acts as a className for the list div.
// Should iterate over items and return the block.
type SCListProps = {
  header: string,
  items: Array<Item>,
  variant: string,
  heavyPadding: boolean,
  topBorder: boolean,
}

export default function SCList(props: SCListProps) {
  const {
    header,
    items,
    variant,
    topBorder,
    heavyPadding,
  } = props;

  const borderClass = topBorder ? 'border-top' : '';
  const paddingClass = heavyPadding ? 'heavy-padding' : '';
  const variantClass = variant || '';

  return (
    <div className={`sc-list ${variantClass} ${borderClass} ${paddingClass}`}>
      {header
        ? <header>{header}</header>
        : null
      }
      {items.map((item, idx) => (
        <SCListItem key={idx} item={item} />
      ))}
    </div>
  );
}
