// @flow
import React, { Fragment, useEffect, useRef } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { useSelector, connect } from 'react-redux';
import { Box, useMediaQuery } from '@material-ui/core';
import { Button, TextField } from '@audi/audi-ui-react';
import { useTheme } from '@material-ui/core/styles';
import { getIsLoading } from '../../../../selectors';
import { Focus, Section } from '../constants';

import './index.css';

interface Props extends RouteComponentProps {
  activeSection: string;
  clearLocation: () => void;
  continueDisabled: boolean;
  focusedField: string;
  locationName: string;
  locationChange: (location: string) => void;
  handleFocus: (focus: string) => void;
  pickupDisabled: boolean;
  pickupDate: string;
  returnDisabled: boolean;
  returnDate: string;
  onContinue: () => void;
  errorMessage: string;
}

const SelectionInputs = ({
  activeSection,
  clearLocation,
  continueDisabled,
  focusedField,
  locationName,
  locationChange,
  handleFocus,
  pickupDisabled,
  pickupDate,
  returnDisabled,
  filteredLocations,
  returnDate,
  httpResponseStatusMessage,
  onContinue,
  errorMessage,
}: Props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const pickupRef = useRef(null);
  const returnRef = useRef(null);

  const isLoading = useSelector(getIsLoading);

  let combinedError = errorMessage || httpResponseStatusMessage || null;

  const filteredErrors = [
    'Sorry. Something is amiss on our side.',
    'Your reservation has been cancelled.',
  ];

  if (
    combinedError
    && filteredErrors.some(pm => combinedError.includes(pm))
  ) {
    combinedError = null;
  }

  const setFocus = () => {
    switch (focusedField) {
      case Focus.PICKUP: {
        return pickupRef.current.focus();
      }
      case Focus.RETURN: {
        return returnRef.current.focus();
      }
      default:
        return () => false;
    }
  };

  const handleLocationFocus = () => {
    handleFocus(Focus.LOCATION);
    clearLocation();
  };

  const dateTimeInputProps = {
    readOnly: true,
  };

  const showDateTimeInputs = !isMobile || activeSection !== Section.LOCATION;

  useEffect(setFocus, handleLocationFocus, [focusedField]);

  return (
    <Box
      display="flex"
      flex="1"
      flexDirection="row"
      justifyContent="space-between"
      flexWrap="wrap"
      mt={isMobile ? 0 : 2.5}
      ml={0.337}
      mb={isMobile ? 0 : 1}
    >
      <Box className="booking-location">
        <TextField
          autoComplete="off"
          type="text"
          inputMode="text"
          inputId="location-select"
          className="fix-red-label"
          label="City, State, Zip"
          invalid={!filteredLocations.length}
          labelEffect={locationName ? 'none' : 'float'}
          value={locationName}
          onChange={e => locationChange(e.target.value)}
          onFocus={handleLocationFocus}
          required
          validationMessage="No results found"
        />
      </Box>
      {showDateTimeInputs && (
        <Fragment>
          <Box className="booking-pickup-date">
            <TextField
              autoComplete="off"
              inputId="pickup-date-time"
              label="Start"
              value={pickupDate}
              onFocus={() => handleFocus(Focus.PICKUP)}
              disabled={pickupDisabled}
              inputRef={pickupRef}
              inputProps={dateTimeInputProps}
              required
              labelEffect={pickupDate ? 'none' : 'float'}
            />
          </Box>
          <Box className="booking-dropoff-date">
            <TextField
              autoComplete="off"
              inputId="return-date-time"
              label="Stop"
              value={returnDate}
              onFocus={() => handleFocus(Focus.RETURN)}
              disabled={returnDisabled}
              inputRef={returnRef}
              inputProps={dateTimeInputProps}
              invalid={combinedError}
              validationMessage={combinedError}
              required
              labelEffect={returnDate ? 'none' : 'float'}
            />
          </Box>
        </Fragment>
      )}
      {!isMobile && (
        <Button
          className="continue-btn"
          disabled={continueDisabled}
          onClick={onContinue}
          loading={isLoading}
          type="submit"
          variant="primary"
        >
          Continue
        </Button>
      )}
    </Box>
  );
};

export const mapStateToProps = state => ({
  httpResponseStatusMessage: state.bookingReducer.httpResponseStatusMessage,
});

export default withRouter(connect(mapStateToProps, null)(SelectionInputs));
