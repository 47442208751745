// @flow
import React from 'react';
import { useSelector } from 'react-redux';
import {
  Box,
} from '@material-ui/core';
import { Button, TextField } from '@audi/audi-ui-react';
import { getActiveBooking } from '../../selectors';
import { formatNewDateTime } from '../../utils/formatters';
import './index.css';
import history from '../../history';

const ModifySelectionInputs = () => {
  const {
    dropoffDate,
    dropoffTime,
    pickupDate,
    pickupLocation,
    pickupTime,
  } = useSelector(getActiveBooking);

  let location = pickupLocation && pickupLocation.multi_car_display_name;
  if (pickupLocation && pickupLocation.airport_code) location += ` (${pickupLocation.airport_code})`;
  const pickupDateTime = formatNewDateTime(pickupDate, pickupTime, pickupLocation.time_zone);
  const dropoffDateTime = formatNewDateTime(dropoffDate, dropoffTime, pickupLocation.time_zone);

  const handleClick = () => history.push('/booking/location-date-time');

  return (
    <>
      <Box
        display="flex"
        flex="1"
        flexDirection="row"
        justifyContent="space-between"
        width="100%"
        alignItems="center"
      >
        <TextField
          inputId="location-select"
          required
          label="City"
          readOnly
          labelEffect="none"
          value={location}
          className="location-text-field"
        />
        <TextField
          inputId="pickup-date-time"
          required
          label="Start"
          readOnly
          value={pickupDateTime}
          labelEffect="none"
        />
        <TextField
          inputId="return-date-time"
          label="Stop"
          value={dropoffDateTime}
          readOnly
          required
          labelEffect="none"
        />
        <Button onClick={handleClick} type="submit" variant="secondary" size="small">
        Modify
        </Button>
      </Box>
      <div className="border" />
    </>
  );
};

export default ModifySelectionInputs;
