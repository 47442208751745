// @flow
import React from 'react';
import { useSelector } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { getIsReady } from '../../../../../selectors';
import Loading from '../../../../loading';
import UpcomingVehicleLocation from './header';
import ConfirmVehicleBooked from '../../../../confirmed-booking-details/vehicle-booked';
import ConfirmationNumber from '../../../../confirmed-booking-details/confirmation-number';
import ConfirmBookingDateTime from './date-times';
import ConfirmAudiProtection from '../../../../confirmed-booking-details/audi-protection';
import RequestPickUpDelivery from '../../../../confirmed-booking-details/request-delivery-buttons';
import ManageReservations from '../../../../confirmed-booking-details/manage-reservation';
import BackLink from '../back-link';
import EstimatedTotal from './estimated-total';
import './index.css';

interface Props extends RouteComponentProps {
  confirmationNumber: string;
  dropoffDateTime: string;
  pickupDateTime: string;
  pickupLocation: Object;
  vehicle: Object;
  coverageOptions: string;
  id: string;
  reservationState: string;
  onCancellationClick: () => void;
  per_day: number;
  total: string;
  location: string;
}

const UpcomingConfirmedBookingDetails = (props: Props) => {
  const {
    confirmationNumber,
    dropoffDateTime,
    vehicle,
    pickupLocation,
    pickupDateTime,
    coverageOptions,
    id,
    reservationState,
    onCancellationClick,
    per_day,
    total,
    location,
    recentBookingToken,
  } = props;

  const isReady = useSelector(getIsReady);

  if (!(isReady && pickupLocation.time_zone && vehicle)) {
    return <Loading />;
  }


  return (
    <div className="upcoming-booking-container">
      <BackLink title="Reservations" />
      <UpcomingVehicleLocation pickupLocation={pickupLocation} location={location} />
      <div className="upcoming-booking-subheader">
        <div className="upcoming-booking-border" />
        <ManageReservations
          id={id}
          reservationState={reservationState}
          onCancellationClick={onCancellationClick}
        />
      </div>
      <div className="upcoming-booking-details">
        <div className="upcoming-booking-details-first-column">
          <ConfirmBookingDateTime
            pickupLocation={pickupLocation}
            pickupDateTime={pickupDateTime}
            dropoffDateTime={dropoffDateTime}
          />
          <ConfirmVehicleBooked vehicle={vehicle} />
        </div>
        <div className="upcoming-booking-details-second-column">
          <ConfirmationNumber confirmationNumber={confirmationNumber} />
          <EstimatedTotal per_day={per_day} total={total} />
        </div>
        <div className="upcoming-booking-details-third-column">
          <ConfirmAudiProtection coverageOptions={coverageOptions} />
        </div>
      </div>
      <RequestPickUpDelivery recentBookingToken={recentBookingToken} />
      <img className="upcoming-booking-details-img" src={vehicle.confirmation_image} alt="Booking" />
    </div>
  );
};

export default withRouter(UpcomingConfirmedBookingDetails);
