// @flow
import bookings from './bookings';
import coverage from './coverage';
import creditCards from './credit-cards';
import driversLicenses from './drivers-licenses';
import fleets from './fleets';
import hypermedia from './hypermedia';
import locations from './locations';
import login from './login';
import promotions from './promotions';
import rentals from './rentals';
import users from './users';
import userPoints from './user-points';
import userAudiDays from './user-audi-days';
import longTermDrive from './long-term-drive';

const HTTP: Object = {
  bookings,
  coverage,
  creditCards,
  driversLicenses,
  fleets,
  hypermedia,
  locations,
  login,
  promotions,
  rentals,
  users,
  userPoints,
  userAudiDays,
  longTermDrive,
};

export default HTTP;
