// @flow
import { apiUrl, headers } from '../config';
import axiosInstance from '../axiosInstance';

function getSubscriptionOptions(fleetId: Number, pickup: string) {
  return axiosInstance({
    method: 'GET',
    url: `${apiUrl.v2}/fleets/${fleetId}/coverages/subscription?pickup_on=${pickup}`,
    headers: headers(),
  });
}

function getPayLaterOptions(fleetId: Number, pickup: string) {
  return axiosInstance({
    method: 'GET',
    url: `${apiUrl.v2}/fleets/${fleetId}/coverages/pay_later?pickup_on=${pickup}`,
    headers: headers(),
  });
}

const coverage: Object = {
  getSubscriptionOptions,
  getPayLaterOptions,
};

export default coverage;
