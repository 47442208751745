// @flow
import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { resetPassword, resetPasswordSuccess, validateResetPasswordToken } from '../../actions/account';
import ResetPasswordForm from '../../components/forms/reset-password';
import HTTPFormStatus from '../../components/forms/http-form-status';
import Loading from '../../components/loading';
import * as AccountTypes from '../../actions/account/constants';

type Props = {
  resetPassword: Function,
  validateResetPasswordToken: Function,
  resetPasswordSuccess: Function,
  resetPasswordToken: string,
  resetPasswordEmail: string,
  httpResponseStatusMessage: string,
  httpResponseError: string,
  authenticationToken: string,
}

export function ResetPassword(props: Props) {
  const {
    httpResponseStatusMessage,
    httpResponseError,
    resetPassword,
    resetPasswordSuccess,
    resetPasswordToken,
    resetPasswordEmail,
    authenticationToken,
    validateResetPasswordToken,
  } = props;
  const [isLoading, setIsLoading] = useState(true);

  const validateToken = useCallback(
    async (tokenValidationData: Object) => {
      const response = await validateResetPasswordToken(tokenValidationData);
      if (response.type === AccountTypes.VALIDATE_RESET_PASSWORD_TOKEN_ERROR) {
        resetPasswordSuccess();
      }
      setIsLoading(false);
    },
    [resetPasswordSuccess, validateResetPasswordToken],
  );

  useEffect(() => {
    if (resetPasswordToken && resetPasswordEmail) {
      const tokenValidationData = {
        token: resetPasswordToken,
        email: resetPasswordEmail,
      };
      validateToken(tokenValidationData);
    }
  }, [resetPasswordToken, resetPasswordEmail, validateToken]);

  if (isLoading) {
    return (
      <Loading />
    );
  }

  return (
    <div>
      {httpResponseStatusMessage && (
        <HTTPFormStatus
          category=""
          type={httpResponseError
            ? 'error'
            : 'success'
          }
          message={httpResponseStatusMessage}
        />
      )}
      <ResetPasswordForm
        onSubmit={resetPassword}
        authenticationToken={authenticationToken}
      />
    </div>
  );
}

export const mapStateToProps = state => ({
  resetPasswordToken: state.accountReducer.resetPasswordToken,
  resetPasswordEmail: state.accountReducer.resetPasswordEmail,
  httpResponseError: state.accountReducer.httpResponseError,
  httpResponseStatusMessage: state.accountReducer.httpResponseStatusMessage,
  authenticationToken: state.accountReducer.authenticationToken,
});

export const mapDispatchToProps = dispatch => ({
  resetPassword: queryParams => dispatch(resetPassword(queryParams)),
  resetPasswordSuccess: () => dispatch(resetPasswordSuccess()),
  validateResetPasswordToken: queryParams => dispatch(validateResetPasswordToken(queryParams)),
});


export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
