// @flow
import React from 'react';
import { formatCurrencyShort } from '../../../utils/formatters';

type RemainingVehicleCostProps = {
  total: string;
}

const RemainingVehicleCost = ({ total }: RemainingVehicleCostProps) => (
  <>{ formatCurrencyShort(total) }</>
);

export default RemainingVehicleCost;
