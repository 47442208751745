// @flow
import React, { Fragment } from 'react';
import '../modal.css';
import { CostSummaryData } from './cost-summary-data';

export function CostSummaryToggle() {
  return (
    <Fragment>
      <CostSummaryData />
    </Fragment>
  );
}

export default CostSummaryToggle;
