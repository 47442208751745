// @flow
import { createSelector } from 'reselect';
import { State as BookingReducer } from '../reducers/booking';
import { sortLocations, groupLocationsByStateName } from '../utils';

const bookingReducer = (state: { bookingReducer: BookingReducer}) => state.bookingReducer;

const getMergedLocations = (
  bookingReducer: BookingReducer,
) => {
  const sortedLocation = bookingReducer.locations.mergedLocations.sort((a, b) => {
    const valueA = a.multi_car_display_name.toLowerCase();
    const valueB = b.multi_car_display_name.toLowerCase();
    // eslint-disable-next-line no-nested-ternary
    return (valueA < valueB) ? -1 : (valueA > valueB) ? 1 : 0;
  });

  return sortLocations(sortedLocation, 'state');
};

const groupLocationsByState = (
  bookingReducer: BookingReducer,
) => {
  const locations = getMergedLocations(bookingReducer);
  return groupLocationsByStateName(locations);
};

const getPickupLocation = (
  bookingReducer: BookingReducer,
) => bookingReducer.activeBooking.pickupLocation;

const activeBooking = (
  bookingReducer: BookingReducer,
) => bookingReducer.activeBooking;

const curbsideDetails = (
  bookingReducer: BookingReducer,
) => bookingReducer.activeBooking.curbsideDetails;

const bookingFromModified = (
  bookingReducer: BookingReducer,
) => bookingReducer.modifyBooking.booking;

const receiptFromModified = (
  bookingReducer: BookingReducer,
) => bookingReducer.modifyBooking.receipt;

const joinReserveBooking = (
  bookingReducer: BookingReducer,
) => bookingReducer.joinReserveBooking;

const locationMap = (
  bookingReducer: BookingReducer,
) => bookingReducer.locations.locationMap;

const thirdPartyBooking = (
  bookingReducer: BookingReducer,
) => bookingReducer.thirdPartyBooking;

const earningPoints = (
  bookingReducer: BookingReducer,
) => bookingReducer.loyalty.earningPoints;

const coverage = (
  bookingReducer: BookingReducer,
) => bookingReducer.coverage;

const activeBookingCoverage = (
  bookingReducer: BookingReducer,
) => bookingReducer.activeBooking.coverage;

const availability = (
  bookingReducer: BookingReducer,
) => bookingReducer.availability;

const isLoading = (
  bookingReducer: BookingReducer,
) => bookingReducer.isLoading;

const fleetAvailability = (
  bookingReducer: BookingReducer,
) => bookingReducer.fleetAvailability;

const fleetPricing = (
  bookingReducer: BookingReducer,
) => bookingReducer.fleetPricing;

const selectedFleetPricing = (
  bookingReducer: BookingReducer,
) => bookingReducer.activeBooking.selectedFleetPricing;

const hasValidCarrierAndPolicyNumber = (bookingReducer: BookingReducer) => {
  const { coverage } = bookingReducer.activeBooking;
  return !!(coverage.carrier && coverage.policyNumber);
};

const promo = (
  bookingReducer: BookingReducer,
) => bookingReducer.activeBooking.promo;

const isProcessing = (
  bookingReducer: BookingReducer,
) => bookingReducer.activeBooking.isProcessing;

const httpResponseError = (
  bookingReducer: BookingReducer,
) => bookingReducer.httpResponseError;

const httpResponseStatusMessage = (
  bookingReducer: BookingReducer,
) => bookingReducer.httpResponseStatusMessage;

const partnerLocationModalVisibility = (
  bookingReducer: BookingReducer,
) => bookingReducer.partnerLocationModalVisible;

const thirdPartyConfirmationNumber = (
  bookingReducer: BookingReducer,
) => bookingReducer.thirdPartyConfirmationNumber;

const fleets = (
  bookingReducer: BookingReducer,
) => bookingReducer.fleets;

const estimatedTotalModalVisible = (
  bookingReducer: BookingReducer,
) => bookingReducer.estimatedTotalModalVisible;

const reservationReviewModalVisible = (
  bookingReducer: BookingReducer,
) => bookingReducer.reservationReviewModalVisible;

const costSummaryModalVisible = (
  bookingReducer: BookingReducer,
) => bookingReducer.costSummaryModalVisible;

export const getHttpResponseError = createSelector(
  bookingReducer,
  httpResponseError,
);

export const getHttpResponseStatusMessage = createSelector(
  bookingReducer,
  httpResponseStatusMessage,
);

export const getPartnerLocationModalVisibility = createSelector(
  bookingReducer,
  partnerLocationModalVisibility,
);

export const getLocations = createSelector(
  bookingReducer,
  getMergedLocations,
);

export const getGroupLocationsByState = createSelector(
  bookingReducer,
  groupLocationsByState,
);

export const getLocationMap = createSelector(
  bookingReducer,
  locationMap,
);

export const getActiveBooking = createSelector(
  bookingReducer,
  activeBooking,
);

export const getCurbsideDetails = createSelector(
  bookingReducer,
  curbsideDetails,
);

export const getBookingFromModified = createSelector(
  bookingReducer,
  bookingFromModified,
);

export const getReceiptFromModified = createSelector(
  bookingReducer,
  receiptFromModified,
);

export const getIsLoading = createSelector(
  bookingReducer,
  isLoading,
);

export const getActiveBookingPickupLocation = createSelector(
  bookingReducer,
  getPickupLocation,
);

export const getEarningPoints = createSelector(
  bookingReducer,
  earningPoints,
);

export const getCoverage = createSelector(
  bookingReducer,
  coverage,
);

export const getActiveBookingCoverage = createSelector(
  bookingReducer,
  activeBookingCoverage,
);

export const getAvailability = createSelector(
  bookingReducer,
  availability,
);

export const getFleetAvailability = createSelector(
  bookingReducer,
  fleetAvailability,
);

export const getFleetPricingValue = createSelector(
  bookingReducer,
  fleetPricing,
);

export const getSelectedFleetPricing = createSelector(
  bookingReducer,
  selectedFleetPricing,
);

export const getIsValidCarrierAndPolicyNumber = createSelector(
  bookingReducer,
  hasValidCarrierAndPolicyNumber,
);

export const getActiveBookingPromo = createSelector(
  bookingReducer,
  promo,
);

export const getActiveBookingIsProcessing = createSelector(
  bookingReducer,
  isProcessing,
);

export const getBookingHasError = createSelector(
  bookingReducer,
  httpResponseError,
);

export const getThirdPartyBooking = createSelector(
  bookingReducer,
  thirdPartyBooking,
);

export const getThirdPartyConfirmationNumber = createSelector(
  bookingReducer,
  thirdPartyConfirmationNumber,
);

export const getJoinReserveBooking = createSelector(
  bookingReducer,
  joinReserveBooking,
);

export const getFleets = createSelector(
  bookingReducer,
  fleets,
);

export const getEstimatedTotalModalVisible = createSelector(
  bookingReducer,
  estimatedTotalModalVisible,
);

export const getReservationReviewModalVisible = createSelector(
  bookingReducer,
  reservationReviewModalVisible,
);

export const getCostSummaryModalVisible = createSelector(
  bookingReducer,
  costSummaryModalVisible,
);
