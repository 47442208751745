// @flow

const MuiLink = {
  root: {
    textDecoration: 'underline',
    fontSize: 'inherit',
    color: 'inherit',
  },
  underlineHover: {
    textDecoration: 'underline',
  },
};

export default MuiLink;
