// @flow
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import EstimatedTotalCash from './estimated-total-cash';
import SCDialog from '../../sc-dialog';
import { toggleEstimatedTotalModalVisible } from '../../../actions/booking';
import {
  getEstimatedTotalModalVisible,
} from '../../../selectors';

const EstimatedTotalModal = () => {
  const estimatedTotalModalVisible = useSelector(getEstimatedTotalModalVisible);
  const dispatch = useDispatch();

  const handleTotalEstimateModal = () => {
    dispatch(toggleEstimatedTotalModalVisible());
  };

  return (
    <SCDialog
      open={estimatedTotalModalVisible}
      onClose={handleTotalEstimateModal}
      width="sm"
      fullWidth
      title="Estimated total"
      showCloseButton
    >
      <EstimatedTotalCash />
    </SCDialog>
  );
};

export default EstimatedTotalModal;
