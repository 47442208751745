// @flow
export default class Vehicle {
  constructor(vehicle) {
    const {
      make,
      model,
    } = vehicle;

    this.make = make;
    this.model = model;
  }
}
