// @flow
import {
  Accordion, AccordionSection, audiDarkTheme, ThemeProvider,
} from '@audi/audi-ui-react';
import React from 'react';
import FooterLink from '../footer-link';
import LogoText from '../logo-text';
import navConfig from '../navConfig';
import '../index.css';

type FooterProps = {
  pathname: string
}

const MobileFooter = ({ pathname: pathName = 'none' }: FooterProps) => {
  const isHidden = (pathName.includes('booking') || pathName.includes('edit-') || pathName.includes('long-term-drive'));

  if (isHidden) { return null; }

  return (
    <ThemeProvider theme={audiDarkTheme}>
      <footer>
        <div className="global-footer-container-mobile">
          <div className="footer-branding">
            <LogoText />
          </div>
          <Accordion>
            {navConfig.map(cat => (
              <AccordionSection
                headingLevel="h2"
                key={cat.catName}
                label={cat.catName}
              >
                {cat.links.map(link => (
                  <FooterLink href={link.url} isMobile key={link.url} title={link.title} />
                ))}
              </AccordionSection>
            ))}
          </Accordion>
        </div>
      </footer>
    </ThemeProvider>
  );
};

export default MobileFooter;
