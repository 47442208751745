// @flow
import Menu from '@material-ui/core/Menu';
import React, { useRef, useState } from 'react';
import { connect } from 'react-redux';
import { AudiPlatformProvider } from '@audi/audi-ui-react';
import { logout } from '../../actions';
import SCRewardsItem from '../ui/sc-rewards-item';
import AccountMenu from './account-menu';
import NavMenuItem from './nav-menu-item';
import './index.css';

type Props = {
  user: object,
  logout: () => void,
  userLoyaltyPoints: number,
  userAudiOwnerProgramDays: number,
}

export function AccountDropDownMenu(props: Props) {
  function logout() {
    props.logout();
    if (accountDropDown && accountDropDown.current) {
      accountDropDown.current.className = accountDropDown.current.className.replace(' open', '');
    }
    setAnchorEl(null);
  }

  const {
    user,
    userLoyaltyPoints,
    userAudiOwnerProgramDays,
  } = props;

  const accountDropDown = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClose = () => {
    if (accountDropDown && accountDropDown.current) {
      accountDropDown.current.className = accountDropDown.current.className.replace(' open', '');
    }
    setAnchorEl(null);
  };

  const handleClick = () => {
    accountDropDown.current.className = `${accountDropDown.current.className} open`;
    setAnchorEl(accountDropDown.current);
  };

  return (
    <AudiPlatformProvider>
      <AccountMenu
        handleClick={handleClick}
        forwardRef={accountDropDown}
        user={user}
        className="grey-font"
      />
      <Menu
        id="account"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: -10,
          horizontal: 'right',
        }}
        getContentAnchorEl={null}
        PaperProps={{
          style: {
            width: '14rem',
            alignItems: 'right',
            display: 'flex',
            columnGap: '2rem',
            height: '15rem',
            overflow: 'hidden',
          },
        }}
      >
        <SCRewardsItem
          userLoyaltyPoints={userLoyaltyPoints}
          userAudiOwnerProgramDays={userAudiOwnerProgramDays}
          isGlobalNav
        />
        <NavMenuItem
          text="Reservations"
          href="/reservations"
          onClick={handleClose}
          handleClose={handleClose}
        />
        <NavMenuItem
          text="Account"
          href="/account"
          handleClose={handleClose}
          onClick={handleClose}
        />
        <NavMenuItem
          text="Log out"
          href=""
          handleClose={handleClose}
          isExternal
          onClick={logout}
        />
      </Menu>
    </AudiPlatformProvider>
  );
}

export const mapDispatchToProps = dispatch => ({
  logout: () => dispatch(logout()),
});

export const mapStateToProps = state => ({
  user: state.accountReducer.user,
  userLoyaltyPoints: state.accountReducer.userPoints.total,
  userAudiOwnerProgramDays: state.accountReducer.userAudiDays.remaining,
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountDropDownMenu);
