import { notifyDatadog } from './datadog';

/**
 * Cleans up the component trace that comes out of a React error.
 *
 * From: https://github.com/bugsnag/bugsnag-js/blob/f220383cb175cc779d5e6f7d015a8f71946f5a28/packages/plugin-react/src/index.js#L43-L50
 */
export const formatComponentStackTrace = (str) => {
  const lines = str.split(/\s*\n\s*/g);
  let ret = '';
  // eslint-disable-next-line no-plusplus
  for (let line = 0, len = lines.length; line < len; line++) {
    if (lines[line].length) ret += `${ret.length ? '\n' : ''}${lines[line]}`;
  }
  return ret;
};

export const noticeError = (error) => {
  notifyDatadog(error);
};
