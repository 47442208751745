// @flow
import { Text } from '@audi/audi-ui-react';
import * as React from 'react';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import {
  Box,
  IconButton,
} from '@material-ui/core';

interface Props {
  title?: string,
  secondTitle?: string,
  showBackButton?: Boolean,
  onBackButtonClicked?: () => void,
}

export const RebrandingMobileHeader = ({
  title = '',
  secondTitle = '',
  showBackButton,
  onBackButtonClicked,
}: Props) => {
  const BackButton = () => (
    <IconButton
      aria-label="back"
      onClick={onBackButtonClicked}
      style={{
        position: 'absolute',
        left: '0',
      }}
    >
      <ArrowBackIosIcon fontSize="large" />
    </IconButton>
  );

  return (
    <Box
      width="100%"
      display="flex"
      my="1.5rem"
      justifyContent="center"
      position="relative"
      alignItems="center"
    >
      {showBackButton && <BackButton />}
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <Text
          as="p"
          variant="copy1"
          weight="bold"
        >
          {title}
        </Text>
        <Text
          as="p"
          variant="copy2"
        >
          {secondTitle}
        </Text>
      </Box>
    </Box>
  );
};

export default RebrandingMobileHeader;
