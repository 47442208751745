// @flow
import { apiUrl } from '../config';
import axiosInstance from '../axiosInstance';

const submitLongTermDriveSubscription = (data: Object) => axiosInstance({
  method: 'POST',
  url: apiUrl.zendeskProd,
  data,
});

const longTermDrive: Object = { submitLongTermDriveSubscription };

export default longTermDrive;
