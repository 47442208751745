// @flow
import { Text } from '@audi/audi-ui-react';
import React from 'react';
import { connect } from 'react-redux';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { useMediaQuery, IconButton } from '@material-ui/core';
import { toggleChevron } from '../../../../actions';
import './index.css';

type Props = {
  chevronExpanded: boolean,
  toggleChevron: () => void,
}

export const AlwaysIncludedHeader = (props: Props) => {
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const { chevronExpanded, toggleChevron } = props;

  return (
    <div>
      { isMobile ? (
        <div className="whats-included-mobile">
          <div>
            <Text as="h2" variant="copy1" weight="bold">See what&apos;s included</Text>
            <IconButton onClick={toggleChevron} className="clickable">
              {chevronExpanded
                ? <ChevronRightIcon className="chevron icon-sizing chevron-up" />
                : <ChevronRightIcon className="chevron icon-sizing chevron-down" />
                      }
            </IconButton>
          </div>
          {chevronExpanded && (
          <div className="included-services">
            <span>
              <Text as="p" variant="copy2">Roadside Assistance</Text>
            </span>
            <span>
              <Text as="p" variant="copy2">Apple Carplay</Text>
            </span>
            <span>
              <Text as="p" variant="copy2">Android Auto</Text>
            </span>
          </div>
          )}
        </div>
      ) : (
        <div className="whats-included-desktop">
          <Text as="p" variant="copy1">Roadside Assistance   |   Apple Carplay   |   Android Auto  </Text>
        </div>
      )}
    </div>
  );
};

export const mapDispatchToProps = dispatch => ({
  toggleChevron: () => dispatch(toggleChevron()),
});

export const mapStateToProps = state => ({
  chevronExpanded: state.appReducer.chevronExpanded,
});

export default connect(mapStateToProps, mapDispatchToProps)(AlwaysIncludedHeader);
