// @flow
import { Text } from '@audi/audi-ui-react';
import React from 'react';
import { formatCurrencyShort } from '../../../utils/formatters';

import './index.css';

type TotalPriceProps = {
  disabled: string;
  earningPoints: boolean;
  hasAudiOwnerDays: boolean;
  isMobile: boolean;
  showPrice: boolean;
  total: string;
}

const TotalPrice = (props: TotalPriceProps) => {
  const {
    disabled,
    earningPoints,
    hasAudiOwnerDays,
    isMobile,
    showPrice,
    total,
  } = props;

  if (!showPrice || !earningPoints) return null;

  const variant = isMobile ? 'copy3' : 'copy2';
  const disabledClass = disabled || '';

  return (
    <Text
      as="p"
      className={`pricing-totals ${disabledClass}`}
      variant={variant}
    >
      {formatCurrencyShort(total)}
      {' '}
      {!hasAudiOwnerDays ? 'total' : null}
      &#160;
    </Text>
  );
};

export default TotalPrice;
