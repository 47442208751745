// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Text, Button } from '@audi/audi-ui-react';
import { Box } from '@material-ui/core';
import {
  getLocationsFleetAvailability,
  setVehicle,
  setSelectedFleetPricing,
  toggleUpdateBookingModalVisible,
  resetLocationsFleetAvailability,
} from '../../../../actions/booking/modify';
import history from '../../../../history';
import vehicles from '../../../../utils/data/vehicleList';

import { SCVehicle } from '../../../vehicle';
import Loading from '../../../loading';

import './index.css';
import { getLocationFromLinks } from '../../../../actions/booking/utils';

type Props = {
  booking: Object,
  fleetAvailability: Object,
  fleetPricingValue: Array<Object>,
  getLocationsFleetAvailability: (params: Object) => void,
  isLoggedIn: boolean,
  setVehicle: () => void,
  setSelectedFleetPricing: () => void,
  toggleModal: () => void,
  resetLocationsFleetAvailability: (params: Object) => void,
};

export class VehicleSelect extends Component<Props> {
  componentDidMount() {
    const {
      booking: { pickup_at, dropoff_at, links },
      fleetAvailability,
      getLocationsFleetAvailability,
    } = this.props;

    const locationId = getLocationFromLinks(links);

    if (!fleetAvailability) {
      getLocationsFleetAvailability({
        pickup_at,
        dropoff_at,
        location_ids: [locationId],
      });
    }
  }

  componentWillUnmount() {
    const { fleetAvailability, resetLocationsFleetAvailability } = this.props;

    if (fleetAvailability) resetLocationsFleetAvailability();
  }

  handleClick = (vehicle, pricingValue = {}) => {
    const { setVehicle, setSelectedFleetPricing, toggleModal } = this.props;

    setVehicle(vehicle.fleet_id);
    setSelectedFleetPricing(pricingValue);
    toggleModal();
  };

  renderVehicles = () => {
    const {
      booking: { pricing: currentPricing, selectedFleet },
      fleetAvailability,
      fleetPricingValue,
      isLoggedIn,
    } = this.props;

    const pathName = history.location.pathname;

    if (!fleetAvailability) return null;

    return fleetAvailability.map((fleet, idx) => {
      const {
        vehicle: { model },
        id,
        available,
      } = fleet;
      const targetVehicle = model.toLowerCase();
      const vehicle = {
        ...vehicles.find(v => v.data_key.toLowerCase() === targetVehicle),
        fleet_id: id,
      };

      const isSelected = id === +selectedFleet;
      const pricingValue = isSelected
        ? {
          ...currentPricing,
          average_base_price: currentPricing.per_day,
        }
        : fleetPricingValue[id];

      return (
        <SCVehicle
          vehicleUnavailable={isSelected ? false : !available}
          key={idx}
          hideButton={false}
          onClick={selectedVehicle => this.handleClick(selectedVehicle, pricingValue)
          }
          canSpendPoints={false}
          vehicle={vehicle}
          pathName={pathName}
          pricing={pricingValue || {}}
          showLoyaltyPoints={false}
          hasAudiOwnerDays={false}
          userAudiOwnerProgramDays={0}
          earningPoints
          isLoggedIn={isLoggedIn}
          currentlySelected={isSelected}
        />
      );
    });
  };

  render() {
    const { fleetPricingValue } = this.props;

    return (
      <Box className="modify-vehicle-container">
        <div className="header-container">
          <header>
            <Text as="h1" variant="order2" weight="bold">
              Modify your vehicle
            </Text>
          </header>
          <Button
            className="modify-vehicle-cancel-button"
            variant="secondary"
            name="cancel"
            onClick={() => history.goBack()}
            size="small"
          >
            Cancel
          </Button>
        </div>
        <section className="sc-vehicle-select modify">
          <div>
            <div className="section-container">
              <>
                {fleetPricingValue.length <= 0 ? (
                  <Loading />
                ) : (
                  this.renderVehicles()
                )}
              </>
            </div>
          </div>
        </section>
      </Box>
    );
  }
}

export const mapDispatchToProps = dispatch => ({
  getLocationsFleetAvailability: params => dispatch(getLocationsFleetAvailability(params)),
  setSelectedFleetPricing: pricingValue => dispatch(setSelectedFleetPricing(pricingValue)),
  setVehicle: vehicle => dispatch(setVehicle(vehicle)),
  toggleModal: () => dispatch(toggleUpdateBookingModalVisible()),
  resetLocationsFleetAvailability: () => dispatch(resetLocationsFleetAvailability()),
});

export const mapStateToProps = state => ({
  booking: state.bookingReducer.modifyBooking.booking,
  fleetAvailability: state.bookingReducer.modifyBooking.fleetAvailability,
  fleetPricingValue: state.bookingReducer.modifyBooking.fleetPricing,
  isLoggedIn: state.appReducer.isLoggedIn,
});

export default connect(mapStateToProps, mapDispatchToProps)(VehicleSelect);
