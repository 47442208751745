// @flow
import React from 'react';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { Text } from '@audi/audi-ui-react';
import SCLink from '../../../sc-link';

type Props = {
  title: string,
}

function BackLink({
  title,
}: Props) {
  return (
    <Box
      style={{
        display: 'flex',
        alignItems: 'center',
        marginBottom: '1.5rem',
      }}
    >
      <a
        href="/reservations"
        aria-label="back-to-reservations"
      >
        <IconButton
          aria-label="back-icon"
          style={{
            paddingBottom: '0.125rem',
            transform: 'rotate(180deg)',
          }}
        >
          <ChevronRightIcon />
        </IconButton>
      </a>
      <SCLink
        noBorder
        href="/reservations"
        aria-label="back-to-reservations"
      >
        <Text
          id="back-link-title"
          as="p"
          variant="copy2"
          spaceStackStart="xxs"
        >
          {title}
        </Text>
      </SCLink>
    </Box>
  );
}

export default BackLink;
