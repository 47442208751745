// @flow
import {
  Button, Text, TextField, Select,
} from '@audi/audi-ui-react';
import { Box } from '@material-ui/core';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { connect, useSelector } from 'react-redux';
import { getDriversLicense } from '../../../../selectors/account';
import { getUserAccountData } from '../../../../actions/account';
import {
  postDriversLicense,
  updateDriversLicense,
} from '../../../../actions/account/drivers-license';

import { validateDate } from '../../../../utils/validators';

import { states } from '../../../../utils/data/statesList';
import countries from '../../../../utils/data/countriesList';
import './index.css';

type State = {
  id?: number,
  name: string,
  number: string,
  country: string,
  state: string,
  born_on: string,
  expires_on: string,
  stateChanged: boolean,
  valid_born_on: boolean,
  valid_expires_on: boolean,
};

const initialState = {
  id: 0,
  name: '',
  number: '',
  country: '',
  state: '',
  born_on: '',
  expires_on: '',
  stateChanged: false,
  valid_born_on: true,
  valid_expires_on: true,
};

type Props = {
  drivers_license: State,
  getUserAccountData: () => Promise<void>,
  updateDriversLicense: (State) => void,
  postDriversLicense: (State) => void,
};

export const DriversLicense = (props: Props) => {
  const [formState, setFormState] = useState(initialState);
  const driversLicense = useSelector(getDriversLicense);

  useEffect(() => {
    ValidatorForm.addValidationRule('validateDate', validateDate);
    ValidatorForm.addValidationRule('hasValue', value => !!value);
    setFormState(prevState => ({ ...prevState, ...formatState(driversLicense) }));
  }, [driversLicense]);

  const resetState = () => {
    const { drivers_license } = props;
    setFormState({
      ...formState,
      valid_born_on: true,
      valid_expires_on: true,
      stateChanged: false,
      ...formatState(drivers_license),
    });
  };

  const formatState = (state: State) => {
    const born_on = moment(state.born_on);
    const expires_on = moment(state.expires_on);

    return {
      ...state,
      born_on: born_on.isValid() ? born_on.format('MM/DD/YYYY') : '',
      expires_on: expires_on.isValid() ? expires_on.format('MM/DD/YYYY') : '',
      stateChanged: false,
    };
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    let { valid_expires_on, valid_born_on } = formState;

    if (name === 'born_on') {
      valid_born_on = validateDate(value);
    }

    if (name === 'expires_on') {
      valid_expires_on = validateDate(value);
    }

    setFormState({
      ...formState,
      valid_expires_on,
      valid_born_on,
      stateChanged: true,
      [name]: value,
    });
  };

  const handleValueValidation = (e) => {
    const { name, value } = e.target;
    const validNameState = `isValid${name.charAt(0).toUpperCase() + name.slice(1)}`;
    setFormState(previousState => ({ ...previousState, [validNameState]: !value }));
  };

  const isDisabled = () => {
    const {
      name, number, state, country, born_on, expires_on,
    } = formState;

    return !(name && number && state && country && born_on && expires_on);
  };

  const submitForm = (e) => {
    e.preventDefault();
    const { id } = formState;
    const { getUserAccountData, updateDriversLicense, postDriversLicense } = props;

    if (id) {
      updateDriversLicense(formatState(formState));
    } else {
      postDriversLicense(formatState(formState));
      getUserAccountData();
    }
    setFormState({ ...formState, stateChanged: false });
  };

  const {
    name,
    number,
    country,
    state,
    born_on,
    expires_on,
    stateChanged,
    valid_born_on,
    valid_expires_on,
    isValidName,
    isValidNumber,
    isValidCountry,
    isValidState,
    isValidBorn_on,
    isValidExpires_on,
  } = formState;

  return (
    <div className="account-page-tab-content" id="drivers-license">
      <Text as="h2" variant="order2" weight="bold" spaceStackEnd="xl">
        Driver&apos;s license
      </Text>
      <Text as="h4" variant="order4" weight="bold" spaceStackEnd="m">
        License details
      </Text>
      <Text as="p" spaceStackEnd="xxxl" variant="copy1">
        You&apos;ll need to have a valid driver&apos;s license, and a matching
        credit card.
      </Text>
      <ValidatorForm onSubmit={event => submitForm(event)}>
        <div className="flex-container">
          <div className="first-column">
            <Box mb="1.875rem" height="3.563rem">
              <TextField
                inputId="text-field_name"
                label="Name on driver's license"
                value={name}
                name="name"
                onChange={handleChange}
                validators={['required', 'hasValue']}
                required
                invalid={isValidName}
                onBlur={handleValueValidation}
                validationMessage="Please enter your name."
                labelEffect={name ? 'none' : 'float'}
              />
            </Box>
            <Box mb="1.875rem" height="3.563rem">
              <TextField
                inputId="text-field_number"
                label="Driver's license number"
                value={number}
                name="number"
                onChange={handleChange}
                required
                invalid={isValidNumber}
                onBlur={handleValueValidation}
                validationMessage="Please enter your driver's license number."
                labelEffect={number ? 'none' : 'float'}
              />
            </Box>
            <Box mb="1.875rem">
              <TextField
                inputId="text-field_born_on"
                label="Date of birth (MM/DD/YYYY)"
                value={born_on}
                name="born_on"
                id="born-on"
                onChange={handleChange}
                required
                invalid={!valid_born_on || isValidBorn_on}
                onBlur={handleValueValidation}
                validationMessage="Please enter a valid date in MM/DD/YYYY format."
                labelEffect={born_on ? 'none' : 'float'}
              />
            </Box>
          </div>
          <div className="second-column">
            <Box mb="1.875rem" height="3.563rem">
              <Select
                inputId="select__country"
                value={country}
                name="country"
                label="Country"
                onChange={handleChange}
                required
                invalid={isValidCountry}
                onBlur={handleValueValidation}
                validationMessage="Please select a country."
                className={`${country ? 'label-animation' : ''}`}
              >
                {countries.map(c => (
                  <option value={c.code} key={c.code}>
                    {c.name}
                  </option>
                ))}
              </Select>
            </Box>

            {country === 'US' ? (
              <Box mb="1.875rem" height="3.563rem">
                <Select
                  inputId="select__state"
                  value={state}
                  name="state"
                  label="State"
                  onChange={handleChange}
                  invalid={isValidState}
                  onBlur={handleValueValidation}
                  required
                  validationMessage="Please select a state."
                  className={`${state ? 'label-animation' : ''}`}
                >
                  {states.map(s => (
                    <option value={s.abbreviation} key={s.abbreviation}>
                      {s.name}
                    </option>
                  ))}
                </Select>
              </Box>
            ) : (
              <Box mb="1.875rem" height="3.563rem">
                <TextField
                  inputId="text-field_state"
                  label="State"
                  value={state}
                  name="state"
                  onChange={handleChange}
                  validators={['required', 'hasValue']}
                  required
                  invalid={isValidState}
                  onBlur={handleValueValidation}
                  validationMessage="Please enter a state."
                  labelEffect={state ? 'none' : 'float'}
                />
              </Box>
            )}
            <Box mb="1.875rem" height="3.563rem">
              <TextField
                inputId="text-field_expires_on"
                label="Expiration date (MM/DD/YYYY)"
                value={expires_on}
                name="expires_on"
                onChange={handleChange}
                required
                invalid={!valid_expires_on || isValidExpires_on}
                onBlur={handleValueValidation}
                validationMessage="Please enter a valid date in MM/DD/YYYY format."
                labelEffect={expires_on ? 'none' : 'float'}
              />
            </Box>
          </div>
        </div>
        <section id="drivers-license-button-section">
          <Button
            disabled={!stateChanged}
            className="drivers-license-button"
            name="cancel"
            onClick={() => resetState()}
            variant="secondary"
          >
            Cancel
          </Button>
          <Button
            className="drivers-license-button"
            disabled={
              !stateChanged
              || isDisabled()
              || !valid_born_on
              || !valid_expires_on
            }
            name="submit"
            type="submit"
            variant="primary"
          >
            Save
          </Button>
        </section>
      </ValidatorForm>
    </div>
  );
};

export const mapStateToProps = state => ({
  drivers_license: state.accountReducer.drivers_license,
});

export const mapDispatchToProps = dispatch => ({
  getUserAccountData: () => dispatch(getUserAccountData()),
  postDriversLicense: license => dispatch(postDriversLicense(license)),
  updateDriversLicense: license => dispatch(updateDriversLicense(license)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DriversLicense);
