// @flow
import { Text } from '@audi/audi-ui-react';
import { Box } from '@material-ui/core';
import React, { Component } from 'react';
import CurrentlySelectedButton from './buttons/currently-selected';
import ChangeVehicleButton from './buttons/change-vehicle';
import InsufficientPointsButton from './buttons/insufficient-points';
import SelectVehicleButton from './buttons/select-vehicle';
import VehicleUnavailableButton from './buttons/vehicle-unavailable';
import CostAfterDays from './cost-after-days';
import CostInPoints from './cost-in-points';
import CostPerDay from './cost-per-day';
import EarnTotal from './earn-total';
import LoyaltyPointsMessaging from './loyalty-points-messaging';
import ModelAndDescription from './model-and-description';
import RemainingVehicleCost from './remaining-vehicle-cost';
import VehicleUnavailableNotice from './vehicle-unavailable-notice';
import TotalPrice from './total-price';
import { SCVehicleProps } from './types';

import './index.css';

export class SCVehicle extends Component<SCVehicleProps> {
  checkVehicleAvailability = () => {
    const { vehicleUnavailable, disabled } = this.props;
    return vehicleUnavailable || disabled ? 'disabled' : '';
  }

  renderButton() {
    const {
      canSpendPoints,
      currentlySelected,
      changeVehicle,
      earningPoints,
      onClick,
      pathName,
      vehicleUnavailable,
      hideButton,
      vehicle,
      isMobile,
    } = this.props;
    if (hideButton) return null;

    if (currentlySelected) return <CurrentlySelectedButton />;

    if (changeVehicle) return <ChangeVehicleButton />;

    if (vehicleUnavailable) {
      return (
        <div>
          <VehicleUnavailableButton
            isMobile={isMobile}
            onClick={onClick}
            pathName={pathName}
            vehicle={vehicle}
          />
        </div>
      );
    }

    if (!(canSpendPoints || earningPoints)) {
      return (
        <div>
          <InsufficientPointsButton
            isMobile={isMobile}
            onClick={onClick}
            vehicle={vehicle}
          />
        </div>
      );
    }

    return (
      <div>
        <SelectVehicleButton onClick={onClick} pathName={pathName} vehicle={vehicle} />
      </div>
    );
  }

  render() {
    const {
      earningPoints,
      hasAudiOwnerDays,
      hideButton,
      isLoggedIn,
      isMobile,
      pricing,
      userAudiOwnerProgramDays,
      vehicleUnavailable,
      vehicle,
    } = this.props;

    const {
      average_base_price, duration, points, total,
    } = pricing;
    const { model, main_image, description } = vehicle;

    const showPrice = (pricing && (average_base_price || total));
    const loyaltyPointsClassName = earningPoints ? 'earn' : 'spend';
    const alwaysAudiRentalDuration = duration > userAudiOwnerProgramDays;
    const audiDaysCoverBooking = duration <= userAudiOwnerProgramDays;
    const numberOfAlwaysAudiDaysUsed = alwaysAudiRentalDuration
      ? userAudiOwnerProgramDays
      : duration;
    const dailyCost = (points && !earningPoints)
      ? (Math.floor(points.spend_total / duration))
      : average_base_price;
    const showCostAfterPoints = showPrice && !earningPoints && isLoggedIn;

    const remainingVehicleCost = <RemainingVehicleCost total={total} />;

    const loyaltyPricing = !hideButton && !vehicleUnavailable && (
      <section className={`pricing ${loyaltyPointsClassName}`}>
        <CostPerDay
          cost={dailyCost}
          disabled={this.checkVehicleAvailability()}
          earningPoints={earningPoints}
          showPrice={showPrice}
        />
        <CostInPoints
          disabled={this.checkVehicleAvailability()}
          earningPoints={earningPoints}
          isLoggedIn={isLoggedIn}
          isMobile={isMobile}
          pricing={pricing}
        />
        <div className="total-and-earn-points">
          <TotalPrice
            disabled={this.checkVehicleAvailability()}
            earningPoints={earningPoints}
            hasAudiOwnerDays={hasAudiOwnerDays}
            isMobile={isMobile}
            showPrice={showPrice}
            total={total}
          />
          <EarnTotal
            disabled={this.checkVehicleAvailability()}
            earningPoints={earningPoints}
            hasAudiOwnerDays={hasAudiOwnerDays}
            isMobile={isMobile}
            points={points}
            vehicleUnavailable={vehicleUnavailable}
          />
        </div>
      </section>
    );

    const alwaysAudiPricing = !hideButton && !vehicleUnavailable && (
      <section className="pricing owner-program">
        <div className="pricing-info-audi-days">
          <Text as="h2" variant="order2" weight="bold">{remainingVehicleCost}</Text>
          <Text as="span" variant="order2" className="plus">+</Text>
          <Text as="span" variant="copy1" className="days-bottom-margin">days</Text>
          <Text as="h2" variant="order2" weight="bold">
            { numberOfAlwaysAudiDaysUsed }
          </Text>
        </div>
        <EarnTotal
          earningPoints={earningPoints}
          hasAudiOwnerDays={hasAudiOwnerDays}
          isMobile={isMobile}
          points={points}
          vehicleUnavailable={vehicleUnavailable}
        />
      </section>
    );

    return (
      <section className="sc-vehicle">
        <header>
          <ModelAndDescription
            className="model-and-description mobile"
            description={description}
            isMobile={isMobile}
            model={model}
          />
          <Box position="relative">
            <img
              className={`vehicle-image ${this.checkVehicleAvailability()}`}
              src={main_image}
              alt={model}
            />
          </Box>
        </header>
        <section className="pricing-and-availability-info">
          <div className="pricing-container-top">
            <div className="model-and-description">
              { !isMobile && (
                <ModelAndDescription
                  description={description}
                  isMobile={isMobile}
                  model={model}
                />
              )}
            </div>
            <div className={vehicleUnavailable ? 'cost-and-points' : 'cost-and-points-available'}>
              { isMobile && (<div>{this.renderButton()}</div>) }
              <div className="righthand-of-card">
                {vehicleUnavailable && isMobile && <VehicleUnavailableNotice isMobile={isMobile} />}
                {pricing && !hasAudiOwnerDays
                  ? loyaltyPricing
                  : alwaysAudiPricing
                }
                {vehicleUnavailable && !isMobile
                  && <VehicleUnavailableNotice isMobile={isMobile} />
                }
                <div className="cost-after-days">
                  <CostAfterDays
                    hasAudiOwnerDays={hasAudiOwnerDays}
                    vehicleUnavailable={vehicleUnavailable}
                    hasEnoughPoints={audiDaysCoverBooking}
                  />
                </div>
              </div>
            </div>
          </div>
          { !isMobile && (
            <div className="pricing-container-bottom">
              <div className="loyalty-info-and-button">
                <LoyaltyPointsMessaging
                  disabled={this.checkVehicleAvailability()}
                  earningPoints={earningPoints}
                  points={points}
                  remainingVehicleCost={remainingVehicleCost}
                  showCostAfterPoints={showCostAfterPoints}
                />
                { this.renderButton() }
              </div>
            </div>
          )}
        </section>
        { isMobile && (
          <LoyaltyPointsMessaging
            disabled={this.checkVehicleAvailability()}
            earningPoints={earningPoints}
            isMobile
            points={points}
            remainingVehicleCost={remainingVehicleCost}
            showCostAfterPoints={showCostAfterPoints}
          />
        ) }
      </section>
    );
  }
}

SCVehicle.defaultProps = {
  currentlySelected: false,
  changeVehicle: false,
  disabled: false,
  vehicleUnavailable: false,
};

export default SCVehicle;
