// @flow
export interface IAuthentication {
    authenticated: boolean;
    expiresAt?: number;
    authToken?: string;
  }

export default class Authentication {
  expiresAt: number;

  authToken: string;

  authenticated: boolean;

  isAuthenticated: boolean;

  constructor(authentication: IAuthentication) {
    const {
      expiresAt = 0,
      authToken = '',
      authenticated = false,
    } = authentication;

    this.expiresAt = expiresAt;
    this.authToken = authToken;
    this.authenticated = authenticated;
    this.isAuthenticated = !!this.authToken;
  }
}
