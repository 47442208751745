// @flow
import { Tab, TabGroup } from '@audi/audi-ui-react';
import React, { useEffect, useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { connect } from 'react-redux';
import HTTPFormStatus from '../../components/forms/http-form-status';
import theme from '../../theme';
import AccountTabPanelContent from './account-tab-panel-content';
import Greeting from './greeting';
import PoliciesLink from './policies-link';

import './index.css';

type Props = {
  httpResponseError: boolean,
  httpResponseStatusMessage: string,
  user: Object,
};

export function Account(props: Props) {
  const {
    httpResponseError,
    httpResponseStatusMessage,
    user,
  } = props;

  const httpFormStatusType = httpResponseError ? 'error' : 'success';
  const [isMobile, setIsMobile] = useState(false);
  const [tabState, setTabState] = useState('tab-1');
  const updateDimensions = () => {
    setIsMobile(window.innerWidth < theme.breakpoints.values.md);
  };

  useEffect(() => {
    updateDimensions();
    window.addEventListener('resize', updateDimensions);
  }, [isMobile]);

  return (
    <div className="sc-account">
      <HelmetProvider>
        <Helmet>
          <title>My Account | Audi on demand</title>
        </Helmet>
      </HelmetProvider>
      {httpResponseStatusMessage && (
        <HTTPFormStatus
          category=""
          type={httpFormStatusType}
          message={httpResponseStatusMessage}
        />
      )}
      <div className="row">
        <div className="inner">
          <section>
            <Greeting firstName={user.first_name} />
          </section>
          <section>
            <TabGroup
              className="account-navigation-tab"
              onSelect={setTabState}
              scrollButtons="auto"
              selected={tabState}
              size={isMobile ? 'small' : 'large'}
            >
              <Tab id="tab-1">Profile</Tab>
              <Tab id="tab-2">Credit card</Tab>
              <Tab id="tab-3">Driver&#039;s license</Tab>
              <Tab id="tab-4">Always Audi Program</Tab>
              <PoliciesLink size={isMobile ? 'small' : 'medium'} />
            </TabGroup>
            <AccountTabPanelContent selectedTab={tabState} />
          </section>
        </div>
      </div>
    </div>
  );
}

export const mapStateToProps = state => ({
  httpResponseError: state.accountReducer.httpResponseError,
  httpResponseStatusMessage: state.accountReducer.httpResponseStatusMessage,
  user: state.accountReducer.user,
  userAudiOwnerProgramDays: state.accountReducer.userAudiDays.remaining,
});

export default connect(mapStateToProps)(Account);
