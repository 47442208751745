// @flow

const props = {
  MuiButton: {
    disableFocusRipple: true,
  },
  MuiInput: {
    disableUnderline: true,
  },
};

export default props;
