// @flow
import { COLORS } from '../constants';

const MuiCheckbox = {
  root: {
    verticalAlign: 'center',
    fontSize: '1.5rem',
  },
  colorPrimary: {
    color: COLORS.PRIMARY,
    '&:hover': {
      backgroundColor: 'unset',
    },
    '&.Mui-checked': {
      color: COLORS.PRIMARY,
      '&:hover': {
        backgroundColor: 'unset !important',
      },
    },
  },
  colorSecondary: {
    color: COLORS.PRIMARY,
    '&:hover': {
      backgroundColor: 'unset',
    },
    '&.Mui-checked': {
      color: COLORS.PRIMARY,
      '&:hover': {
        backgroundColor: 'unset !important',
      },
    },
  },
};

export default MuiCheckbox;
