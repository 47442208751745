// @flow
import { states } from '../data/statesList';

export function searchArrayOfObjectsForValue(valueToFind: string, searchableArray: Array<Object>) {
  return searchableArray.findIndex(obj => obj.multi_car_display_name === valueToFind);
}

// Takes an array of locations and returns the locations in a
// new array where "bookable: true"
export function filterBookableLocations(locations: Array<Object>) {
  return locations.filter(({ bookable }) => bookable);
}

export function filterCoverageOptions(options: Array<Object>) {
  if (options.includes('everything_you_need')) {
    return ['everything_you_need'];
  }
  return options;
}

export function findStateAbbreviation(name: String) {
  const state = states.find(state => state.name === name);
  return state && state.abbreviation;
}
