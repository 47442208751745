// @flow
import React from 'react';
import { FontIcon } from 'material-ui';
import './sc-address.css';

type Props = {
  address: Object,
}

function SCAddress(props: Props) {
  const { address } = props;
  const {
    latitude,
    longitude,
    line1,
    line2,
    city,
    state,
    zip,
  } = address;

  const locationMapLink = `https://www.google.com/maps/?q=${latitude},${longitude}`;

  return (
    <a href={locationMapLink} target="_blank" className="sc-address" rel="noopener noreferrer">
      <address>
        <div>
          <FontIcon style={{ fontSize: 12 }}>
            <i className="material-icons">location_on</i>
          </FontIcon>
        </div>
        <div>
          <div>
            Audi on demand Address
          </div>
          <div>{line1}</div>
          {line2 ? <div>{line2}</div> : null}
          <div>{`${city}, ${state} ${zip}`}</div>
        </div>
      </address>
    </a>
  );
}

export default SCAddress;
