// @flow
import React from 'react';
import AlwaysAudiProgram from './always-audi-program';
import CreditCard from './credit-card';
import DriversLicense from './drivers-license';
import Profile from './profile';

type AccountTabPanelContentProps = {
  selectedTab: string;
}

const AccountTabPanelContent: React.FC<AccountTabPanelContentProps> = (
  props: AccountTabPanelContentProps,
) => {
  const { selectedTab } = props;

  switch (selectedTab) {
    default:
    case 'tab-1':
      return <Profile />;
    case 'tab-2':
      return <CreditCard />;
    case 'tab-3':
      return <DriversLicense />;
    case 'tab-4':
      return <AlwaysAudiProgram />;
  }
};

export default AccountTabPanelContent;
