// @flow
import { Text } from '@audi/audi-ui-react';
import React from 'react';
import { connect } from 'react-redux';

type User = {
  first_name: string,
  email: string,
}
type GreetingProps = {
  user: User;
}

const Greeting: React.FC<GreetingProps> = (props: GreetingProps) => {
  const { user } = props;
  return (
    <Text
      as="h1"
      variant="order1"
      weight="bold"
      spaceStackEnd="s"
    >
      { user.first_name ? `Hello, ${user.first_name}` : `Hello, ${user.email}` }
    </Text>
  );
};

const mapStateToProps = state => ({
  user: state.accountReducer.user,
});

export default connect(mapStateToProps)(Greeting);
