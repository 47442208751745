// @flow
import HTTP from '../../../utils/http';
import { getErrorMessage } from '../../../utils';
import { getUserAccountData } from '..';
import * as CreditCardsTypes from './constants';

// GET BRAINTREE DATA ////////////////

export const creditCardsFetchBraintreeTokenSuccess = (payload: Object) => (
  (dispatch: Function) => (
    Promise.resolve().then(() => (
      dispatch({
        type: CreditCardsTypes.CREDIT_CARDS_FETCH_BRAINTREE_TOKEN_SUCCESS,
        payload,
      })
    ))
  )
);

export const creditCardsFetchBraintreeTokenError = (payload: Object) => (
  (dispatch: Function) => (
    Promise.resolve().then(() => (
      dispatch({
        type: CreditCardsTypes.CREDIT_CARDS_FETCH_BRAINTREE_TOKEN_ERROR,
        payload: getErrorMessage(payload),
      })
    ))
  )
);

export const creditCardsFetchBraintreeToken = () => (
  (dispatch: Function) => (
    HTTP.creditCards.getBrainTreeClientToken({})
      .then(response => dispatch(creditCardsFetchBraintreeTokenSuccess(response.data)))
      .catch(error => dispatch(creditCardsFetchBraintreeTokenError(error)))
  )
);

// GET CREDIT CARD DATA ////////////////
export const creditCardsFetchDataSuccess = (payload: Object) => (
  (dispatch: Function) => (
    Promise.resolve().then(() => (
      dispatch({
        type: CreditCardsTypes.CREDIT_CARDS_FETCH_DATA_SUCCESS,
        payload,
      })
    ))
  )
);

export const creditCardsFetchDataError = (payload: Object) => (
  (dispatch: Function) => (
    Promise.resolve().then(() => (
      dispatch({
        type: CreditCardsTypes.CREDIT_CARDS_FETCH_DATA_ERROR,
        payload: getErrorMessage(payload),
      })
    ))
  )
);

export const creditCardsFetchData = () => (
  (dispatch: Function) => (
    HTTP.creditCards.get()
      .then(response => dispatch(creditCardsFetchDataSuccess(response.data)))
      .catch(error => dispatch(creditCardsFetchDataError(error)))
  )
);

// CREATE CREDIT CARD ////////////////
export const creditCardCreateSuccess = (payload: Object) => (
  (dispatch: Function) => (
    Promise.resolve().then(() => (
      dispatch({
        type: CreditCardsTypes.CREDIT_CARD_CREATE_SUCCESS,
        payload,
      })
    ))
  )
);

export const creditCardCreateError = (payload: Object) => (
  (dispatch: Function) => (
    Promise.resolve().then(() => (
      dispatch({
        type: CreditCardsTypes.CREDIT_CARD_CREATE_ERROR,
        payload: getErrorMessage(payload),
      })
    ))
  )
);

export const creditCardFullCreate = (data: Object, hfi: Object, cardholder_name: string) => (
  async (dispatch: Function) => (
    Promise.resolve().then(() => (
      hfi.tokenize((tokenizeErr, payload) => {
        if (tokenizeErr) {
          return dispatch(creditCardCreateError(tokenizeErr));
        }
        return HTTP.creditCards.post({
          address: {
            ...data.billing_address,
          },
          name: cardholder_name,
          nonce: payload.nonce,
        })
          .then(response => dispatch(creditCardCreateSuccess(response.data)))
          .then(() => dispatch(creditCardsFetchData()))
          .then(() => dispatch(getUserAccountData()))
          .catch(error => dispatch(creditCardCreateError(error)));
      })
    ))
  )
);
