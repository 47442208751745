// @flow
import { Button, Text } from '@audi/audi-ui-react';
import { Box, Container } from '@material-ui/core';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import './index.css';

function ThankYou(props: RouteComponentProps) {
  function handleClick() {
    props.history.push('/login');
  }

  return (
    <Container maxWidth="sm">
      <Box className="thank-you-form" mt="10.37rem" mb="15.063rem">
        <Text as="h2" variant="order2" weight="bold">Thank you</Text>
        <Box mt="0.625rem" mb="2.5625rem">
          <Text as="p" variant="copy1">
            We just sent you a confirmation email.
            Please click the link in the email to confirm your account.
          </Text>
        </Box>
        <Button
          className="thank-you-form-button"
          name="continue"
          onClick={handleClick}
          variant="secondary"
        >
          Got it
        </Button>
      </Box>
    </Container>
  );
}

export default ThankYou;
