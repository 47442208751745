// @flow
import { Button } from '@audi/audi-ui-react';
import React from 'react';
import history from '../../../../history';

const ChangeVehicleButton: React.FC = (): React.ReactElement => {
  const handleClick = () => history.push('/booking/vehicle');
  return (

    <Button
      variant="secondary"
      name="change-selected-vehicle-button"
      onClick={handleClick}
      className="change-vehicle-button"
      size="small"
    >
    Change vehicle
    </Button>
  );
};

export default ChangeVehicleButton;
