export function createCoveragesObject(arg) {
  return {
    type: arg,
  };
}

export function handleCarrierCoverage(coverage, arg) {
  const { carrier, policyNumber } = coverage;

  return [{
    type: arg,
    carrier,
    policy_number: policyNumber,
  }];
}

export function handleSilvercarCoverage(coverage) {
  const coverages = [];
  const { coverageOptions } = coverage;
  if (coverageOptions.includes('everything_you_need')) {
    const opt = createCoveragesObject('everything_you_need');
    coverages.push(opt);
  } else {
    coverageOptions.forEach((option) => {
      const opt = createCoveragesObject(option);
      coverages.push(opt);
    });
  }

  return coverages;
}

export function setupCoverages(coverage) {
  if (coverage) {
    switch (coverage.coverageType) {
      case 'silvercar':
        return handleSilvercarCoverage(coverage);
      case 'personal_coverage':
        return handleCarrierCoverage(coverage, 'personal_coverage');
      case 'corporate_coverage':
        return handleCarrierCoverage(coverage, 'corporate_coverage');
      default:
        return [];
    }
  }
  // default return, ESLint is complaining but this should never be reached.
  return null;
}
