// @flow
import { useMediaQuery } from '@material-ui/core';
import React from 'react';
import Box from '@material-ui/core/Box';
import { connect, useSelector } from 'react-redux';
import { getLocationFromLinks } from '../../actions/booking/utils';
import ConfirmedBookingDetails from '../../components/confirmed-booking-details';
import HTTPFormStatus from '../../components/forms/http-form-status';
import Loading from '../../components/loading';
import DeleteBookingModal from '../../components/modals/delete-booking';
import { getDesktopStyles } from './styles';
import {
  getHttpResponseError,
  getHttpResponseStatusMessage,
  getJoinReserveBooking,
  getLocationMap,
  getFleetPricingValue,
  getEarningPoints,
} from '../../selectors';
import vehicles from '../../utils/data/vehicleList';
import ConfirmBookingApp from '../../components/confirmed-booking-app';

type Props = {
  recentBookingToken: string,
}

export function ReservationConfirmed(props: Props) {
  const { recentBookingToken } = props;
  const httpResponseError = useSelector(getHttpResponseError);
  const httpResponseStatusMessage = useSelector(getHttpResponseStatusMessage);
  const locationMap = useSelector(getLocationMap);
  const joinReserveBooking = useSelector(getJoinReserveBooking);
  const earningPoints = useSelector(getEarningPoints);
  const fleetPricing = useSelector(getFleetPricingValue);
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));

  const {
    containerStyles,
  } = getDesktopStyles();

  if (joinReserveBooking) {
    const {
      pickup_at,
      dropoff_at,
      confirmation_token,
      location,
      pricing,
      token,
      links,
      coverages,
    } = joinReserveBooking;
    const {
      vehicle: {
        model,
      },
    } = location;
    const vehicle = vehicles.find(v => v.data_key.toUpperCase() === model);

    const { points } = fleetPricing.find(fp => fp.id === joinReserveBooking.location.id);
    const locationId = getLocationFromLinks(links);
    const pickupLocation = (locationMap[locationId]) ? locationMap[locationId] : {
      airport_code: '',
      time_zone: 'America/Chicago',
      multi_car_display_name: '',
    };

    const coverageOpt = coverages.map(c => c.type).toString();

    return (
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          flex: '1',
        }}
      >
        <DeleteBookingModal
          confirmation_token={confirmation_token}
          tempAuthToken={token}
          total={pricing.total}
        />
        {httpResponseStatusMessage && (
          <HTTPFormStatus
            category=""
            type={httpResponseError ? 'error' : 'success'}
            message={httpResponseStatusMessage}
          />
        )}
        <Box {...containerStyles}>
          <ConfirmedBookingDetails
            dropoffDateTime={dropoff_at}
            earningPoints={earningPoints}
            points={points}
            id={joinReserveBooking.id}
            coverageOptions={coverageOpt}
            pickupLocation={pickupLocation}
            reservationState="upcoming"
            pickupDateTime={pickup_at}
            recentBookingToken={recentBookingToken}
            vehicle={vehicle}
            isMobile={isMobile}
            confirmationNumber={joinReserveBooking.confirmation_token}
          />
          <ConfirmBookingApp />
        </Box>
      </Box>
    );
  }
  return <Loading />;
}

export const mapStateToProps = state => ({
  recentBookingToken: state.reservationReducer.recentBookingToken,
});

export default connect(mapStateToProps, null)(ReservationConfirmed);
