// @flow
import { Button, Text, TextField } from '@audi/audi-ui-react';
import { Box } from '@material-ui/core';
import React, { Component } from 'react';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { connect } from 'react-redux';
import { forgotPassword } from '../../../actions/account';
import FormWrapper from '../../layout/form-wrapper';
import '../forms.css';
import HTTPFormStatus from '../http-form-status';
import { ForgotPasswordFormProps, ForgotPasswordFormState } from './types';
import { validateEmail } from '../../../utils/validators';

export class ForgotPasswordForm extends Component<
  ForgotPasswordFormProps,
  ForgotPasswordFormState
> {
  constructor(props: Object) {
    super(props);

    this.state = {
      email: '',
      isValidEmail: true,
    };
  }

  handleUpdateEmail(event: Object) {
    const { name, value } = event.target;
    let { isValidEmail } = this.state;
    isValidEmail = name === 'email' ? validateEmail(value) : isValidEmail;
    this.setState({ [name]: value, isValidEmail });
  }

  render() {
    const { email, isValidEmail } = this.state;
    const { forgotPassword, httpResponseStatusMessage, httpResponseError } = this.props;
    const disabled = !email || !isValidEmail;

    return (
      <div>
        {httpResponseStatusMessage && (
          <HTTPFormStatus
            category=""
            type={httpResponseError ? 'error' : 'success'}
            message={httpResponseStatusMessage}
          />
        )}
        <Box mt="10.37rem" mb="19.813rem">
          <FormWrapper>
            <Text as="h2" variant="order2" weight="bold">
              Forgot password
            </Text>
            <Box mt="0.625rem" mb="2.5rem">
              <Text as="p" variant="copy1">
                Please enter your email so we can send you a password reset
                link.
              </Text>
            </Box>
            <ValidatorForm onSubmit={() => forgotPassword({ email })}>
              <TextField
                inputId="text-field__email"
                label="Email Address"
                name="email"
                type="email"
                inputMode="email"
                invalid={!isValidEmail}
                required
                value={email}
                onChange={e => this.handleUpdateEmail(e)}
                validationMessage="Please enter a valid email address."
                labelEffect={email ? 'none' : 'float'}
              />
              <Box mt="2.5625rem">
                <Button
                  disabled={disabled}
                  type="submit"
                  variant="primary"
                  stretch
                >
                  Send reset link
                </Button>
              </Box>
            </ValidatorForm>
          </FormWrapper>
        </Box>
      </div>
    );
  }
}

export const mapStateToProps = state => ({
  httpResponseError: state.accountReducer.httpResponseError,
  httpResponseStatusMessage: state.accountReducer.httpResponseStatusMessage,
});

export const mapDispatchToProps = dispatch => ({
  forgotPassword: email => dispatch(forgotPassword(email)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordForm);
