// @flow
import { AudiPlatformProvider } from '@audi/audi-ui-react';
import React, { Component } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { MuiThemeProvider, withTheme } from '@material-ui/core/styles';
import { MuiThemeProvider as V0MuiThemeProvider } from 'material-ui/styles';
import { connect } from 'react-redux';
import { initApp, toggleSideNav } from './actions';

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
// polyfill support for IE11
import 'regenerator-runtime/runtime';
import 'react-app-polyfill/stable';

import { isLoggedIn } from './components/auth';
import Account from './containers/account';
import Booking from './containers/booking';
// import Subscription from './containers/subscription';
import ConfirmAccount from './containers/confirm-account';
import ReservationConfirmed from './containers/reservation-confirmed';
import ConfirmPassword from './containers/confirm-password';
import ForgotPassword from './containers/forgot-password';
import NavHeader from './components/nav-header';
import NavMobile from './components/nav-mobile';
import HTTPFormStatus from './components/forms/http-form-status';
import Login from './containers/login';
import Logout from './containers/logout';
import Callback from './containers/callback';
import SetPassword from './containers/set-password';
import ResendConfirmation from './containers/resend-confirmation';
import ResetPassword from './containers/reset-password';
import Reservations from './containers/reservations';
import SideNav from './components/sidenav';
import Signup from './containers/signup';
import FourOhFour from './containers/four-oh-four';
import CurbsideRequest from './components/forms/curbside-request';
import ThankYou from './components/thank-you';
import GoogleTagManager from './components/googleTagManager';

import parseQueryString from './utils/parsers';
import theme from './theme';

import './App.css';
import Footer from './components/footer';
import MobileFooter from './components/footer/mobile-footer';

type Props = {
  debugAction: string,
  debugMessage: string,
  location: Object,
  initApp: Function,
  history: Object,
  sideNavVisible: boolean,
  theme: Object,
  toggleSideNav: Function,
}

// eslint-disable-next-line react/prop-types
function PrivateRoute({ component: RComponent, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props: { location: Object }) => {
        const queryParams = props.location ? decodeURIComponent(props.location.search) : '';
        const qs = parseQueryString(queryParams);
        let redirect = <Redirect to="/login" />;
        if (props.location.search && !qs.type) {
          redirect = <Redirect to="/booking" />;
          return redirect;
        }
        return (
          isLoggedIn()
            ? <RComponent {...props} />
            : redirect
        );
      }}
    />
  );
}

export class App extends Component<Props> {
  constructor(props: Object) {
    super(props);
    const queryParams = props.location ? decodeURIComponent(props.location.search) : '';
    const query = queryParams ? parseQueryString(queryParams) : '';
    props.initApp(query);

    this.state = {
      isMobile: false,
    };
  }

  componentDidMount() {
    this.updateDimensions();
    window.addEventListener('resize', this.updateDimensions.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions.bind);
  }

  updateDimensions() {
    const {
      sideNavVisible,
      theme,
      toggleSideNav,
    } = this.props;
    const isMobile = window.innerWidth < theme.breakpoints.values.md;
    this.setState({
      isMobile,
    });
    if (!isMobile && sideNavVisible) toggleSideNav();
  }

  render() {
    const {
      debugAction,
      debugMessage,
      sideNavVisible,
      history: {
        location: {
          pathname,
        },
      },
    } = this.props;
    const { isMobile } = this.state;
    const debugText = debugMessage.toString();

    return (
      <AudiPlatformProvider>
        <GoogleTagManager />
        <MuiThemeProvider theme={theme}>
          <V0MuiThemeProvider>
            <div
              className="App"
              aria-disabled={sideNavVisible}
            >
              <a className="skip-link" href="#content">Skip to content</a>
              <SideNav pathname={pathname} />
              <div>
                {isMobile
                  ? <NavMobile pathname={pathname} />
                  : <NavHeader pathname={pathname} />
                }
                <main id="content" role="main">
                  {process.env.REACT_APP_API_HOST === 'rac-tst'
                    && debugMessage && (
                      <HTTPFormStatus
                        type="error"
                        category={debugAction}
                        message={debugText}
                      />
                  )}
                  <Switch>
                    <PrivateRoute exact path="/" component={Reservations} />
                    <PrivateRoute path="/account" component={Account} />
                    <Route path="/booking/curbside/:token" component={CurbsideRequest} />
                    <Route path="/booking" component={Booking} />
                    <Route path="/long-term-drive">
                      <Redirect to="/" />
                    </Route>
                    <Route path="/confirm-password" component={ConfirmPassword} />
                    <Route path="/set-password" component={SetPassword} />
                    <Route path="/reset-password" component={ResetPassword} />
                    <Route path="/confirm-account" component={ConfirmAccount} />
                    <Route path="/reservation-confirmed" component={ReservationConfirmed} />
                    <Route path="/forgot-password" component={ForgotPassword} />
                    <Route path="/login">
                      {isLoggedIn() ? <Redirect path="/" /> : <Login />}
                    </Route>
                    <PrivateRoute path="/logout" component={Logout} />
                    <Route path="/callback" component={Callback} />
                    <Route path="/resend-confirmation" component={ResendConfirmation} />
                    <PrivateRoute path="/reservations" component={Reservations} />
                    <Route path="/signup/thankyou" component={ThankYou} />
                    <Route path="/signup" component={Signup} />
                    <Route component={FourOhFour} />
                  </Switch>
                </main>
                {isMobile ? <MobileFooter pathname={pathname} /> : <Footer pathname={pathname} />}
              </div>
            </div>
          </V0MuiThemeProvider>
        </MuiThemeProvider>
      </AudiPlatformProvider>
    );
  }
}

export const mapDispatchToProps = dispatch => ({
  initApp: query => dispatch(initApp(query)),
  toggleSideNav: () => dispatch(toggleSideNav()),
});

export const mapStateToProps = state => ({
  debugAction: state.appReducer.debugAction,
  debugMessage: state.appReducer.debugMessage,
  sideNavVisible: state.appReducer.sideNavVisible,
});

export default withTheme(connect(mapStateToProps, mapDispatchToProps)(App));
