// @flow
import { createSelector } from 'reselect';
import { State as SubscriptionReducer } from '../reducers/subscription';
import { getMonthsBetween, sortLocations, groupLocationsByStateName } from '../utils';

const subscriptionReducer = (state: {
  subscriptionReducer: SubscriptionReducer,
}) => state.subscriptionReducer;

const subscriptionLocations = (
  subscriptionReducer: SubscriptionReducer,
) => {
  const sortedLocation = subscriptionReducer.locations.mergedLocations.sort((a, b) => {
    const valueA = a.multi_car_display_name.toLowerCase();
    const valueB = b.multi_car_display_name.toLowerCase();
    // eslint-disable-next-line no-nested-ternary
    return (valueA < valueB) ? -1 : (valueA > valueB) ? 1 : 0;
  });

  return sortLocations(sortedLocation, 'state');
};

const groupLocationsByState = (
  subscriptionReducer: SubscriptionReducer,
) => {
  const locations = subscriptionLocations(subscriptionReducer);
  return groupLocationsByStateName(locations);
};

const getPickupLocation = (
  subscriptionReducer: SubscriptionReducer,
) => subscriptionReducer.activeBooking.pickupLocation;

const activeSubscriptionBooking = (
  subscriptionReducer: SubscriptionReducer,
) => subscriptionReducer.activeBooking;

const isSubscriptionLoading = (
  subscriptionReducer: SubscriptionReducer,
) => subscriptionReducer.isLoading;

export const getActiveSubscriptionBooking = createSelector(
  subscriptionReducer,
  activeSubscriptionBooking,
);

export const getActiveSubscriptionBookingPickupLocation = createSelector(
  subscriptionReducer,
  getPickupLocation,
);

export const getIsSubscriptionLoading = createSelector(
  subscriptionReducer,
  isSubscriptionLoading,
);

export const getSubscriptionLocations = createSelector(
  subscriptionReducer,
  subscriptionLocations,
);

export const getSubscriptionGroupLocationsByState = createSelector(
  subscriptionReducer,
  groupLocationsByState,
);

const selectedFleetPricingOption = (
  subscriptionReducer: SubscriptionReducer,
) => subscriptionReducer.activeBooking.selectedFleetPricingOption;


export const getSelectedFleetPricingOption = createSelector(
  subscriptionReducer,
  selectedFleetPricingOption,
);

const selectedCoverageOptionsTotal = (
  subscriptionReducer: SubscriptionReducer,
) => {
  let selectedCoverageNames = subscriptionReducer.activeBooking.coverage.coverageOptions;

  if (selectedCoverageNames.includes('everything_you_need')) {
    selectedCoverageNames = ['everything_you_need'];
  }

  return subscriptionReducer.coverage
    .filter(coverage => selectedCoverageNames.includes(coverage.type))
    .reduce((accumulator, currentValue) => accumulator + +currentValue.price, 0);
};

export const getSelectedCoverageOptionsTotal = createSelector(
  subscriptionReducer,
  selectedCoverageOptionsTotal,
);

const estimatedTotal = (
  subscriptionReducer: SubscriptionReducer,
) => {
  const { pickupDate, dropoffDate } = activeSubscriptionBooking(subscriptionReducer);
  const coverageTotal = selectedCoverageOptionsTotal(subscriptionReducer);
  const { average_base_price } = selectedFleetPricingOption(subscriptionReducer);
  const monthsInBooking = Math.max(1, getMonthsBetween(pickupDate, dropoffDate));

  return (average_base_price * monthsInBooking) + (coverageTotal * monthsInBooking * 30);
};

const estimatedMonthlyTotal = (
  subscriptionReducer: SubscriptionReducer,
) => {
  const coverageTotal = selectedCoverageOptionsTotal(subscriptionReducer);
  const { average_base_price } = selectedFleetPricingOption(subscriptionReducer);

  return average_base_price + (coverageTotal * 30);
};

const hasValidCarrierAndPolicyNumber = (subscriptionReducer: SubscriptionReducer) => {
  const { coverage } = subscriptionReducer.activeBooking;
  return !!(coverage.carrier && coverage.policyNumber);
};

export const getEstimatedTotal = createSelector(
  subscriptionReducer,
  estimatedTotal,
);

export const getEstimatedMonthlyTotal = createSelector(
  subscriptionReducer,
  estimatedMonthlyTotal,
);

export const getIsValidatedCarrierAndPolicyNumber = createSelector(
  subscriptionReducer,
  hasValidCarrierAndPolicyNumber,
);
