// @flow
import { Text } from '@audi/audi-ui-react';
import React from 'react';

const LogInLink: React.FC = (): React.ReactElement => (
  <Text as="p" variant="copy2">
    Already a member?
    {' '}
    <a href="/login">
      Log in
    </a>
  </Text>
);

export default LogInLink;
