// @flow
import React from 'react';
import { Text } from '@audi/audi-ui-react';

import './index.css';

type CostAfterDaysProps = {
  hasAudiOwnerDays: boolean;
  vehicleUnavailable: boolean;
  hasEnoughPoints: boolean;
}

const CostAfterDays = (props: CostAfterDaysProps) => {
  const { hasAudiOwnerDays, vehicleUnavailable, hasEnoughPoints = false } = props;

  if (!hasAudiOwnerDays || vehicleUnavailable) return null;
  return (
    <>
      {hasEnoughPoints ? (
        <Text
          as="p"
          className="days-total"
          spaceStackStart="xxxl"
          variant="copy2"
        >
        Your Always Audi rental days cover this booking.
        </Text>
      ) : null}
      <Text
        as="p"
        className="days-total"
        variant="copy2"
        spaceStackStart={hasEnoughPoints ? '' : 'xxxl'}
        spaceStackEnd="xl"
      >
        The dollar amount is for taxes & fees, and any
        days over your balance.
      </Text>
    </>
  );
};

export default CostAfterDays;
