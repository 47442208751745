import React from 'react';
import { Text } from '@audi/audi-ui-react';
import './index.css';

import { useSelector } from 'react-redux';
import {
  getBookingHasError,
  getHttpResponseStatusMessage,
} from '../../../../selectors';

const FooterStatusBar = () => {
  const bookingError = useSelector(getBookingHasError);
  const statusMessage = useSelector(getHttpResponseStatusMessage);
  if (!bookingError && statusMessage) {
    return (
      <div className="footer-status-bar">
        <Text variant="copy2">{ statusMessage }</Text>
      </div>
    );
  }

  return null;
};

export default FooterStatusBar;
