// @flow

import { createMuiTheme, responsiveFontSizes } from '@material-ui/core';
import theme from './theme';
import overrides from './overrides';
import typography from './typography';
import props from './props';

export default responsiveFontSizes(
  createMuiTheme({
    ...theme,
    overrides,
    typography,
    props,
  }),
);
