// @flow
import { COLORS } from '../constants';

const MuiInput = {
  root: {
    marginTop: '0',
    marginBottom: '1.56rem',
    fontSize: '1.125rem',
    borderBottom: `1px solid ${COLORS.PRIMARY}`,
    '&.Mui-focused': {
      borderBottom: 'none',
      boxShadow: `0px 8px ${COLORS.PRIMARY}`,
    },
    '&.Mui-disabled': {
      borderBottomStyle: 'solid',
      borderBottomColor: COLORS.GREY4,
    },
    // iPhone 5 cuts off input text on some screens
    '@media (max-width: 340px)': {
      fontSize: '1rem',
    },
  },
};

export default MuiInput;
