// @flow
import Links from '../shared/links';
import Vehicle from './vehicle';

export default class Fleet {
  constructor(fleet: Object) {
    const {
      id,
      vehicle,
      links,
    } = fleet;

    this.id = id;
    this.vehicle = new Vehicle(vehicle);
    this.links = new Links(links);
  }
}
