import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, TextField } from '@audi/audi-ui-react';
import { getPromo, resetPromo } from '../../../../actions/booking';
import { isPromoMessage } from '../../../../utils';

import {
  getActiveBookingPromo,
  getSelectedFleetPricing,
  getBookingHasError,
  getHttpResponseStatusMessage,
} from '../../../../selectors';

import './index.css';

const PromoField = () => {
  const dispatch = useDispatch();
  const { total } = useSelector(getSelectedFleetPricing);
  const { promo_code } = useSelector(getActiveBookingPromo);
  const bookingError = useSelector(getBookingHasError);
  const statusMessage = useSelector(getHttpResponseStatusMessage);
  const [localPromoCode, updateLocalPromoCode] = useState('');
  const [isSubmitting, updateIsSubmitting] = useState(false);
  const isInvalid = bookingError && isPromoMessage(statusMessage);

  const handleAddPromoCode = () => {
    if (!localPromoCode) {
      dispatch(resetPromo());
    } else {
      updateIsSubmitting(true);
      dispatch(getPromo(localPromoCode));
    }
  };

  const handleValidPromoCode = () => {
    updateIsSubmitting(false);
  };

  useEffect(() => updateIsSubmitting(false), [bookingError]);
  // wait until the new price is returned before updating
  useEffect(handleValidPromoCode, [total]);
  // if a promo_code already exists in redux set it to the local state
  useEffect(() => updateLocalPromoCode(promo_code), [promo_code]);

  return (
    <>
      <TextField
        required
        className="promo-code-input"
        inputId="promo-code"
        label="Enter promo code*"
        value={localPromoCode}
        invalid={isInvalid}
        validationMessage={statusMessage}
        hint="* only one promo code may be applied at a time"
        onChange={(e) => {
          updateLocalPromoCode(e.target.value);
        }}
        labelEffect={localPromoCode ? 'none' : 'float'}
      />
      <Button
        className="promo-button"
        variant="secondary"
        size="small"
        disabled={promo_code === localPromoCode}
        loading={isSubmitting}
        onClick={handleAddPromoCode}
      >
          Apply
      </Button>
    </>
  );
};

export default PromoField;
