export const getReservationDetailsHref = ({
  id, path, type,
}) => {
  const baseHref = `/reservations/${id}`;
  const hrefPath = path ? `/${path}` : '';
  const typeParam = type ? `?type=${type}` : '';

  return `${baseHref}${hrefPath}${typeParam}`;
};

export default {
  getReservationDetailsHref,
};
