// @flow
import { audiDarkTheme, ThemeProvider } from '@audi/audi-ui-react';
import React from 'react';
import FooterHeader from './footer-header';
import FooterLink from './footer-link';
import LogoText from './logo-text';
import navConfig from './navConfig';
import './index.css';

type FooterProps = {
  pathname: string
}

const Footer = ({ pathname: pathName = 'none' }: FooterProps) => {
  const isHidden = (pathName.includes('booking') || pathName.includes('edit-') || pathName.includes('long-term-drive'));

  if (isHidden) { return null; }

  return (
    <ThemeProvider theme={audiDarkTheme}>
      <footer>
        <div className="global-footer-container">
          <div className="footer-branding">
            <LogoText />
          </div>
          <div className="footer-navigation">
            {navConfig.map(cat => (
              <div className="menu-container" key={cat.catName}>
                <FooterHeader title={cat.catName} />
                {cat.links.map(link => (
                  <FooterLink href={link.url} key={link.url} title={link.title} />
                ))}
              </div>
            ))}
          </div>
        </div>
      </footer>
    </ThemeProvider>
  );
};

export default Footer;
