// @flow
import { apiUrl, headers } from '../config';
import axiosInstance from '../axiosInstance';

function post(data: Object) {
  return axiosInstance({
    method: 'POST',
    url: `${apiUrl.v2}/login`,
    headers: headers(),
    data,
  });
}

const login: Object = {
  post,
};

export default login;
