// @flow
import moment from 'moment';
import axios from 'axios';
import Cookies from '../services/cookies';
import AuthTypes from '../services/constants';
import store from '../../store';
import auth from '../../components/auth/auth';
import env from '../environment';

const {
  auth: {
    clientId: clientID,
  },
} = env();


export const userSessionIsValid = (tokenExpirationDate, sessionExpirationDate) => (
  moment(tokenExpirationDate)
    .isBefore(sessionExpirationDate)
);

export const reloadSession = () => {
  Cookies.removeAllCookies();
  return auth.logout({
    returnTo: window.location.origin,
    clientID,
  });
};

const requestSuccessHandler = (requestConfig) => {
  const authToken = Cookies.get(AuthTypes.authToken);
  // TODO temporary workaround due to cross origin issue for browsers with different domain
  // const { isAuth0Enabled } = store.getState().featureReducer;
  const isAuth0Enabled = false;
  const newConfig = { ...requestConfig };

  if (authToken) {
    newConfig.headers.Authorization = isAuth0Enabled
      ? `Bearer ${authToken}`
      : authToken;
  }
  return newConfig;
};
const requestErrorHandler = error => Promise.reject(error);

const responseSuccessHandler = response => response;
const responseErrorHandler = (error) => {
  const originalRequest = error.config;
  const authExpired = error.response.status === 401;
  const { isAuth0Enabled: auth0IsEnabled } = store.getState().featureReducer;

  if (authExpired && auth0IsEnabled) {
    auth.checkSession({}, (error, authResult) => {
      if (!authResult) {
        return authResult;
      }
      const { idToken: authToken, idTokenPayload } = authResult;
      const { exp: expiresAt } = idTokenPayload;
      const tokenExpirationDate = moment
        .unix(expiresAt);
      const sessionExpirationDate = moment
        .unix(Cookies.get(AuthTypes.sessionExpiresAt));

      if (!userSessionIsValid(tokenExpirationDate, sessionExpirationDate)) {
        return reloadSession();
      }

      if (authToken) {
        Cookies.add(AuthTypes.authToken, authToken);
        originalRequest.headers.Authorization = `Bearer ${authToken}`;
        return axios(originalRequest);
      }
      return authResult;
    });
  }
  return Promise.reject(error);
};

export default {
  requestInterceptor: {
    requestSuccessHandler,
    requestErrorHandler,
  },
  responseInterceptor: {
    responseSuccessHandler,
    responseErrorHandler,
  },
};
