// @flow
import React from 'react';
import { connect } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import SCDialog from '../../sc-dialog';
import '../modal.css';
import {
  createBooking,
  toggleTexasWaiverModalVisible,
  toggleJoinReserveModalVisible,
} from '../../../actions/booking';
import { setupCoverages } from '../../coverage/utils';
import { initialState } from '../../../reducers/booking';

type Props = {
  texasWaiverModalVisible: boolean,
  isLoggedIn: boolean,
  toggleTexasWaiverModalVisible: Function,
  toggleJoinReserveModalVisible: Function,
  activeBooking: Object,
  createBooking: Function,
  earningPoints: boolean,
}

export function TexasWaiverModal(props: Props) {
  const {
    texasWaiverModalVisible,
    toggleTexasWaiverModalVisible,
    toggleJoinReserveModalVisible,
    activeBooking,
    createBooking,
    isLoggedIn,
    earningPoints,
  } = props;

  const {
    pickupTime,
    dropoffTime,
    vehicle,
    coverage,
    pickupLocation: {
      curbside_detail,
    },
    promo: {
      id: promoId,
    },
  } = activeBooking;

  const {
    activeBooking: {
      promo: {
        id: initialPromo,
      },
    },
  } = initialState;

  const curbsideActive = curbside_detail && curbside_detail.active;
  const curbsideText = curbsideActive && curbside_detail.description;
  const fleetId = vehicle ? vehicle.fleet_id : null;
  const promoCode = promoId || initialPromo;

  const booking = {
    pickup_at: pickupTime,
    dropoff_at: dropoffTime,
    coverages: setupCoverages(coverage),
    promo_code_id: promoCode,
    points: !earningPoints,
  };

  function handleCreateBooking() {
    toggleTexasWaiverModalVisible(false);
    if (isLoggedIn) {
      createBooking(fleetId, booking, curbsideActive, curbsideText);
    } else {
      toggleJoinReserveModalVisible();
    }
  }

  return (
    <SCDialog
      open={texasWaiverModalVisible}
      onClose={toggleTexasWaiverModalVisible}
      title="Waiver"
      showCloseButton
      showLeftButton
      leftButtonText="Cancel"
      onLeftButtonClicked={() => toggleTexasWaiverModalVisible(false)}
      leftButtonVariant="outlined"
      showRightButton
      rightButtonText="Ok"
      onRightButtonClicked={() => handleCreateBooking()}
      rightButtonVariant="contained"
    >
      <Typography variant="body1">
        You have selected a Collision Damage Waiver as part of your Silvercar insurance package.
        The State of Texas requires us to show you the following disclosure:
      </Typography>
      <br />
      <Typography
        variant="body1"
        style={{
          fontSize: '0.875rem',
        }}
      >
        NOTICE: Your rental agreement offers, for an additional charge, an optional waiver
        to cover all or a part of your responsibility for damage to or loss of the vehicle.
        Before deciding whether to purchase the waiver, you may wish to determine whether
        your own automobile insurance or credit card agreement provides you coverage for
        rental vehicle damage or loss and determine the amount of the deductible under
        your own insurance coverage. The purchase of the waiver is not mandatory.
        The waiver is not insurance.
      </Typography>
    </SCDialog>
  );
}

export const mapStateToProps = state => ({
  isLoggedIn: state.appReducer.isLoggedIn,
  activeBooking: state.bookingReducer.activeBooking,
  texasWaiverModalVisible: state.bookingReducer.texasWaiverModalVisible,
  earningPoints: state.bookingReducer.loyalty.earningPoints,
});

export const mapDispatchToProps = dispatch => ({
  toggleJoinReserveModalVisible: () => dispatch(toggleJoinReserveModalVisible()),
  toggleTexasWaiverModalVisible: () => dispatch(toggleTexasWaiverModalVisible()),
  createBooking: (
    fleetId,
    booking,
    curbsideActive,
    curbsideText,
  ) => dispatch(createBooking(fleetId, booking, curbsideActive, curbsideText)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TexasWaiverModal);
