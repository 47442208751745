// @flow
/* eslint-disable import/prefer-default-export */
import { createSelector } from 'reselect';

import type { State as ModalReducer } from '../reducers/modals';

const modalReducer = (state: { modalReducer: ModalReducer }) => state.modalReducer;
const coverageOptionsVisible = (state: ModalReducer) => state.coverageOptionsModalVisible;

export const getCoverageOptionsModalVisible = createSelector(
  modalReducer,
  coverageOptionsVisible,
);
