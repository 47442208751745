// @flow
import Cookies from '../../utils/services/cookies';
import AuthTypes from '../../utils/services/constants';
import auth from './auth';
import Authentication from '../../utils/models/auth/authentication';
import env from '../../utils/environment';
import store from '../../store';

const {
  auth: {
    clientId,
    realm,
  },
} = env();

export const getAuthentication = () => {
  const expiresAt = Number.parseInt(Cookies.get(AuthTypes.expiresAt), 10) || 0;
  const authToken = Cookies.get(AuthTypes.authToken) || '';
  const authenticated = !!Cookies.get(AuthTypes.authenticated) || false;
  return new Authentication({
    expiresAt,
    authToken,
    authenticated,
  });
};

export function setAuthentication(authentication: Authentication) {
  Cookies.add(AuthTypes.expiresAt, authentication.expiresAt.toString());
  Cookies.add(AuthTypes.authToken, authentication.authToken);
  Cookies.add(AuthTypes.authenticated, authentication.authenticated.toString());
}

export const handleAuthentication = () => new Promise((resolve, reject) => {
  auth.parseHash((error: any, authResult: any) => {
    if (authResult && authResult.accessToken) {
      setAuthentication(
        new Authentication({
          expiresAt: authResult.expiresIn * 1000 + Date.now(),
          authToken: authResult.idToken,
          authenticated: true,
        }),
      );
      resolve(getAuthentication());
    } else {
      reject(error);
    }
  });
});

export const login = (data: Object) => new Promise((resolve, reject) => {
  const { email, password } = data;
  auth.login({
    email,
    password,
    realm,
  }, error => reject(error));
});

export const logout = () => {
  Cookies.remove(AuthTypes.expiresAt);
  Cookies.remove(AuthTypes.authToken);
  Cookies.remove(AuthTypes.authenticated);

  auth.logout({
    returnTo: `${window.location.protocol}//${window.location.host}`,
    clientID: clientId,
  });
};

function getToken() {
  return store.getState().appReducer.authToken || Cookies.get(AuthTypes.authToken);
}

export const isLoggedIn = () => {
  // TODO temporary workaround due to cross origin issue for browsers with different domain
  // const isAuth0Enabled = getState().featureReducer.isAuth0Enabled;
  const isAuth0Enabled = false;
  if (!isAuth0Enabled) {
    return !!getToken();
  }
  return getAuthentication().isAuthenticated;
};
