// @flow
import { Text } from '@audi/audi-ui-react';
import React from 'react';
import { Link } from 'react-router-dom';
import { SCLinkProps } from './types';

const SCLink: React.FC<SCLinkProps> = (props: SCLinkProps): React.ReactElement => {
  const {
    children,
    className,
    href,
    isExternal,
    onClick,
    title,
  } = props;

  return (
    <Text
      as="span"
    >
      { isExternal ? (
        <a
          className={className}
          href={href}
          onClick={onClick}
          rel="noopener noreferrer"
          title={title}
          target="_blank"
        >
          {children}
        </a>
      ) : (
        <Link
          className={className}
          onClick={onClick}
          rel={href ? 'noopener noreferrer' : null}
          title={title}
          to={href}
        >
          {children}
        </Link>
      )}
    </Text>
  );
};

export default SCLink;
