// @flow
import React from 'react';
import { connect } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import {
  toggleCoverageWarningModalVisible,
  createBooking,
  toggleJoinReserveModalVisible,
} from '../../../actions/booking';
import { setupCoverages } from '../../coverage/utils';
import { initialState } from '../../../reducers/booking';
import SCDialog from '../../sc-dialog';

type Props = {
  coverageWarningModalVisible: boolean,
  isLoggedIn: boolean,
  toggleCoverageWarningModalVisible: Function,
  toggleJoinReserveModalVisible: Function,
  activeBooking: Object,
  createBooking: Function,
  earningPoints: boolean,
}

export function CoverageWarningModal(props: Props) {
  const {
    coverageWarningModalVisible,
    toggleCoverageWarningModalVisible,
    toggleJoinReserveModalVisible,
    activeBooking,
    createBooking,
    isLoggedIn,
    earningPoints,
  } = props;

  const {
    pickupTime,
    dropoffTime,
    vehicle,
    coverage,
    pickupLocation: {
      curbside_detail,
    },
    promo: {
      id: promoId,
    },
  } = activeBooking;

  const {
    activeBooking: {
      promo: {
        id: initialPromo,
      },
    },
  } = initialState;

  const curbsideActive = curbside_detail && curbside_detail.active;
  const curbsideText = curbsideActive && curbside_detail.description;
  const fleetId = vehicle ? vehicle.fleet_id : null;
  const promoCode = promoId || initialPromo;

  const booking = {
    pickup_at: pickupTime,
    dropoff_at: dropoffTime,
    coverages: setupCoverages(coverage),
    promo_code_id: promoCode,
    points: !earningPoints,
  };

  function handleCancel() {
    toggleCoverageWarningModalVisible();
  }

  function handleCreateBooking() {
    toggleCoverageWarningModalVisible(false);
    if (isLoggedIn) {
      createBooking(fleetId, booking, curbsideActive, curbsideText);
    } else {
      toggleJoinReserveModalVisible();
    }
  }

  const rentalAgreement = (
    <Typography variant="body1">
      Please ensure your insurance carrier or credit card issuer provides
      adequate car rental coverage. See the
      {' '}
      <a
        href="https://api.silvercar.com/agreements/rental_agreement"
        target="_blank"
        rel="noopener noreferrer"
      >
        rental agreement
      </a>
      {' '}
      section 12 for detail and review with your insurance carrier or credit card
      issuer for clarification.
    </Typography>
  );

  return (
    <SCDialog
      open={coverageWarningModalVisible}
      onClose={toggleCoverageWarningModalVisible}
      title="Check Your Coverage Amounts"
      showCloseButton
      showLeftButton
      leftButtonText="Cancel"
      leftButtonVariant="outlined"
      onLeftButtonClicked={handleCancel}
      showRightButton
      rightButtonText="Ok"
      rightButtonVariant="contained"
      onRightButtonClicked={handleCreateBooking}
    >
      { rentalAgreement }
    </SCDialog>
  );
}

export const mapStateToProps = state => ({
  isLoggedIn: state.appReducer.isLoggedIn,
  activeBooking: state.bookingReducer.activeBooking,
  coverageWarningModalVisible: state.bookingReducer.coverageWarningModalVisible,
  earningPoints: state.bookingReducer.loyalty.earningPoints,
});

export const mapDispatchToProps = dispatch => ({
  toggleJoinReserveModalVisible: () => dispatch(toggleJoinReserveModalVisible()),
  toggleCoverageWarningModalVisible: () => dispatch(toggleCoverageWarningModalVisible()),
  createBooking: (
    fleetId,
    booking,
    curbsideActive,
    curbsideText,
  ) => dispatch(createBooking(fleetId, booking, curbsideActive, curbsideText)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CoverageWarningModal);
