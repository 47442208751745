// @flow
import {
  Button, Text, TextField,
} from '@audi/audi-ui-react';
import { Box } from '@material-ui/core';
import React, { Component } from 'react';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { validatePassword } from '../../../utils/validators';
import FormWrapper from '../../layout/form-wrapper';
import { SetPasswordFormProps, SetPasswordFormState } from './types';
import CheckboxOptional from '../../checkbox-optional';
import './index.css';

export class SetPasswordForm extends Component<
  SetPasswordFormProps,
  SetPasswordFormState
> {
  constructor(props: Props) {
    super(props);
    this.state = {
      password: '',
      confirmPassword: '',
      disabled: true,
      isValidPassword: true,
      isPasswordMatch: true,
      silvercar_marketing: true,
      third_party_marketing: true,
    };
  }

  handleChange(event: Object) {
    const { name, value } = event.target;
    let { isValidPassword, isPasswordMatch } = this.state;
    isValidPassword = name === 'password' ? validatePassword(value) : isValidPassword;
    isPasswordMatch = name === 'confirmPassword'
      ? this.isPasswordMatch(value)
      : isPasswordMatch;
    this.setState({ [name]: value, isValidPassword, isPasswordMatch });
  }

  isPasswordMatch(confirmPassword: string) {
    const { password } = this.state;
    const isPasswordMatch = confirmPassword === password;

    if (isPasswordMatch) {
      this.setState({ disabled: false });
    }

    return isPasswordMatch;
  }

  handleSubmit(event: Object) {
    event.preventDefault();
    const {
      queryParams: { confirmation_token },
      postConfirmAccount,
    } = this.props;
    const { password, silvercar_marketing, third_party_marketing } = this.state;
    const data = {
      confirmation_token,
      password,
      silvercar_marketing,
      third_party_marketing,
    };
    postConfirmAccount(data);
  }

  handleScMarketingCheck(value: boolean) {
    this.setState({ silvercar_marketing: value });
  }

  handleThirdPartyCheck(value: boolean) {
    this.setState({ third_party_marketing: value });
  }

  render() {
    const {
      confirmPassword,
      disabled,
      password,
      isValidPassword,
      isPasswordMatch,
      silvercar_marketing,
      third_party_marketing,
    } = this.state;
    return (
      <Box className="set-password-form" mt="10.37rem" mb="11.93rem">
        <FormWrapper>
          <Text as="h2" variant="order2" weight="bold">
          Set password
          </Text>
          <Box mt="0.625rem" mb="1rem">
            <Text as="p" variant="copy1">
            To confirm your account, please set your password. Password must be
            at least eight characters and include an uppercase, a lowercase and
            a number.
            </Text>
          </Box>
          <ValidatorForm onSubmit={event => this.handleSubmit(event)}>
            <Box mb="2rem">
              <TextField
                inputId="text-field__password"
                label="Password"
                name="password"
                onChange={e => this.handleChange(e)}
                required
                type="password"
                value={password}
                invalid={!isValidPassword}
                validationMessage="Please enter a password that contains at least one upper and lowercase letter, and at least one number."
                labelEffect={password ? 'none' : 'float'}
              />
            </Box>

            <Box mb="1.4375rem">
              <TextField
                inputId="text-field__confirm-password"
                label="Confirm Password"
                onChange={e => this.handleChange(e)}
                name="confirmPassword"
                type="password"
                value={confirmPassword}
                invalid={!isPasswordMatch}
                validationMessage="Passwords do not match"
                labelEffect={confirmPassword ? 'none' : 'float'}
                required
              />
            </Box>
            <Box mb="2.5rem">
              <CheckboxOptional
                name="marketing-check"
                inputId="marketing-check-checkbox"
                defaultChecked
                value={silvercar_marketing}
                onChange={this.handleScMarketingCheck}
              >
              Yes, send me Audi on Demand offers
              </CheckboxOptional>
              <Box mt="2.25rem">
                <CheckboxOptional
                  name="partner-check"
                  inputId="partner-check-checkbox"
                  defaultChecked
                  value={third_party_marketing}
                  onChange={this.handleThirdPartyCheck}
                >
               Yes, send me partner offers
                </CheckboxOptional>
              </Box>
            </Box>
            <Button
              disabled={disabled || !isPasswordMatch}
              name="submit"
              type="submit"
              variant="primary"
              stretch
            >
            Confirm account
            </Button>
          </ValidatorForm>
        </FormWrapper>
      </Box>
    );
  }
}

export default SetPasswordForm;
