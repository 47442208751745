// @flow
import { Text } from '@audi/audi-ui-react';
import React from 'react';
import { formatLocationName } from '../../../../../../utils/formatters';

type UpcomingVehicleLocationProps = {
    pickupLocation: string,
}

const UpcomingVehicleLocation = (props: UpcomingVehicleLocationProps) => {
  const {
    pickupLocation,
  } = props;

  return (
    <>
      <Text as="h2" variant="order2">
        {formatLocationName(pickupLocation)}
      </Text>
      <Text as="p" variant="copy1">
        {pickupLocation.description}
      </Text>
    </>
  );
};

export default UpcomingVehicleLocation;
