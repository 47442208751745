// @flow
import { Text, TextField, Button } from '@audi/audi-ui-react';
import { useMediaQuery } from '@material-ui/core';
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { createBooking, toggleCostSummaryModalVisible } from '../../../../actions/booking';
import { setupCoverages } from '../../../coverage/utils';
import {
  getActiveBooking, getEarningPoints, getIsLoggedIn, getRemainingAudiDays,
} from '../../../../selectors';
import CostBreakdown from '../../../cost-breakdown';
import history from '../../../../history';
import { formatNewDateTime } from '../../../../utils/formatters';
import NumberDisplay from '../../../number-display';
import PointsEarned from '../../../points-earned';
import PromoCode from '../../../promo-code';
import TotalsItem from '../../../TotalsItem';
import { getFilteredDiscount } from '../../../../actions/booking/utils';

import './index.css';

export function CostSummaryData() {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(getIsLoggedIn);
  const activeBooking = useSelector(getActiveBooking);
  const userAudiOwnerProgramDays = useSelector(getRemainingAudiDays);
  const earningPoints = useSelector(getEarningPoints);
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const {
    promo,
    vehicle,
    selectedFleetPricing,
    coverage,
    dropoffDate,
    dropoffTime,
    pickupDate,
    pickupLocation,
    pickupTime,
  } = activeBooking;
  const {
    receipt,
    total,
    duration,
    points,
  } = selectedFleetPricing;
  const location = pickupLocation && pickupLocation.multi_car_display_name;
  let pickupDateTime;
  let dropoffDateTime;
  if (pickupLocation && Object.keys(pickupLocation).length !== 0) {
    pickupDateTime = formatNewDateTime(pickupDate, pickupTime, pickupLocation.time_zone);
    dropoffDateTime = formatNewDateTime(dropoffDate, dropoffTime, pickupLocation.time_zone);
  }

  const { discount, loyalty } = receipt;
  const { earn_total, partial_spend, spend_total } = points;
  const { points_spend } = partial_spend;
  const pointsEarned = !earningPoints || userAudiOwnerProgramDays > 0 ? 0 : earn_total;

  if (!receipt) {
    return null;
  }

  const carName = `Audi ${vehicle.model}`;
  const carPrice = receipt.rental.total;
  const curbsideActive = pickupLocation.curbside_detail && pickupLocation.curbside_detail.active;
  const curbsideDescription = curbsideActive && pickupLocation.curbside_detail.description;
  const { fleet_id } = vehicle;

  let coveragePriceDaily;
  const coverageOptions = receipt.coverage;
  let coveragePrice;
  let coverageTitle;

  let filteredDiscount = [];
  if (discount) {
    filteredDiscount = getFilteredDiscount(discount);
  }

  if (coverageOptions) {
    coveragePrice = coverageOptions.total;
    coveragePriceDaily = Math.round(coveragePrice / duration);
    coverageTitle = coverageOptions.items.length > 2 ? 'Zero Financial Responsibility' : 'Coverage Options';
  }

  const taxesAndFeesTotal = () => {
    let total = 0;
    if (receipt && receipt.taxes) {
      total += receipt.taxes.total || 0;
    }
    if (receipt && receipt.fees_and_concessions) {
      total += receipt.fees_and_concessions.total || 0;
    }
    return total;
  };

  const taxesAndFees = {
    label: 'Taxes & fees',
    total: taxesAndFeesTotal(),
    items: [
      ...(receipt.taxes ? receipt.taxes.items : []),
      ...(receipt.fees_and_concessions ? receipt.fees_and_concessions.items : []),
    ],
  };

  const booking = {
    pickup_at: pickupTime,
    dropoff_at: dropoffTime,
    coverages: setupCoverages(coverage),
    promo_code_id: promo.id,
    points: !earningPoints,
  };

  const handleModify = () => {
    history.push('/booking/location-date-time');
    dispatch(toggleCostSummaryModalVisible());
  };
  const handleConfirm = () => {
    dispatch(createBooking(fleet_id, booking, curbsideActive, curbsideDescription));
  };
  const handleCancel = () => dispatch(toggleCostSummaryModalVisible());

  if (!receipt || !activeBooking) { return null; }

  const renderLocationDateTime = () => (
    <div>
      <TextField
        className="cost-summary-location-text-field"
        inputId="location-select"
        data-testid="location-select"
        label="Location"
        labelEffect="none"
        readOnly
        required
        value={location}
      />
      <div className="date">
        <TextField
          inputId="pickup-date-time"
          data-testid="pickup-date-time"
          label="Start"
          labelEffect="none"
          readOnly
          required
          value={pickupDateTime}
        />
        <div className="stop-container">
          <TextField
            inputId="return-date-time"
            data-testid="return-date-time"
            label="Stop"
            labelEffect="none"
            readOnly
            required
            value={dropoffDateTime}
          />
        </div>
        {isMobile && (
        <Button
          className="summary-modify"
          onClick={handleModify}
          variant="secondary"
          size="small"
        >
              Modify
        </Button>
        )}
      </div>
    </div>
  );

  const renderVehicle = () => {
    const cashDayRate = carPrice / duration;
    const pointDayRate = Math.round(spend_total / duration);
    let remaining;

    if (receipt && loyalty) {
      remaining = receipt.rental.total + loyalty.total;
    }

    const userHasAudiDays = userAudiOwnerProgramDays > 0;
    const hasEnoughAudiDays = userHasAudiDays && duration <= userAudiOwnerProgramDays;
    const additionalDays = userHasAudiDays && duration > userAudiOwnerProgramDays
      ? duration - userAudiOwnerProgramDays : null;
    const audiDaysUsed = userHasAudiDays && hasEnoughAudiDays ? duration : userAudiOwnerProgramDays;

    return (
      <div>
        {earningPoints ? (
          <TotalsItem
            label="Vehicle"
            item={carName}
            dailyPrice={cashDayRate}
            price={carPrice}
            hasAudiDays={userHasAudiDays}
            audiDaysUsed={audiDaysUsed}
            additionalDays={additionalDays}
          />
        ) : (
          <div>
            <TotalsItem
              label="Vehicle"
              item={carName}
            />
            {partial_spend.percentage === 100 ? (
              <TotalsItem
                item="Your points cover 100%"
                dailyPrice={pointDayRate}
                point={points_spend}
              />
            ) : (
              <div>
                <TotalsItem item="Points required" point={spend_total} dailyPrice={pointDayRate} />
                <TotalsItem
                  item={`Your points cover ${partial_spend.percentage}%`}
                  point={`-${points_spend}`}
                />
                <TotalsItem
                  subItem="Remaining balance after points"
                  price={remaining}
                />
              </div>
            )}
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="summary-total-modal-container">
      <div className="summary-modal-header">
        <Text
          as="h1"
          variant="order2"
          weight="bold"
          spaceStackEnd="l"
        >
          Summary
        </Text>
        {!isMobile && (
          <Button onClick={handleModify} type="button" variant="secondary" size="small">
            Modify
          </Button>
        )}
      </div>
      <section className="total-header">
        {renderLocationDateTime()}
      </section>
      <section className="totals-section">
        <div className="totalItem">
          {renderVehicle()}
        </div>
        <div className="totalItem">
          {coverageOptions && (
            <TotalsItem
              label="Protection"
              item={coverageTitle}
              dailyPrice={coveragePriceDaily}
              price={coveragePrice}
            />
          )}
          {coverageOptions && coverageOptions.items.map((x, index) => (
            <React.Fragment key={index}><TotalsItem subItem={x.label} /></React.Fragment>
          ))}
        </div>
      </section>
      <section className="total-taxes">
        <CostBreakdown item={taxesAndFees} />
      </section>
      <section className="total-promo" />
      {filteredDiscount && (partial_spend.percentage < 100 || earningPoints) && (
        <div className="totalItem">
          {filteredDiscount.map((ad, index) => (
            <React.Fragment key={index}>
              <PromoCode
                discount={ad.total}
              />
            </React.Fragment>
          ))}
        </div>
      )}
      <section className="total-footer">
        <div className="points">
          <PointsEarned points={pointsEarned} />
        </div>
        <div className="summary-total">
          <Text as="p" variant="copy2" spaceStackEnd="xs" spaceInlineEnd={isMobile ? 'xxxs' : 'xl'}>{isMobile ? 'Est. total ' : 'Estimated total'}</Text>
          <NumberDisplay className="number" value={total} dollarVariant="order2" />
        </div>
      </section>
      <section className="receipt-section" />
      {isLoggedIn && (
        <section className="totals-buttons">
          <Button
            className="confirm-booking-button"
            variant="primary"
            type="submit"
            onClick={handleConfirm}
          >
            Confirm booking
          </Button>
          <Button
            name="cancel"
            variant="secondary"
            onClick={handleCancel}
          >
            Cancel
          </Button>
        </section>
      )}
    </div>
  );
}

export default CostSummaryData;
