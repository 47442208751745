// @flow
import React from 'react';
import { Text } from '@audi/audi-ui-react';

import './index.css';

type Props = {
  discount: Number
}

export function PromoCode(props: Props) {
  const { discount } = props;
  const formattedDiscount = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(discount);
  return (
    <div className="promo-item-row">
      <div className="item-name">
        <Text as="h2" variant="copy2">Promo code</Text>
      </div>
      <div className="discount-items">
        <Text as="span" variant="copy1">{discount && formattedDiscount}</Text>
      </div>
    </div>
  );
}

export default PromoCode;
