// @flow
import React from 'react';
import { Text } from '@audi/audi-ui-react';
import NumberDisplay from '../../../number-display';
import './index.css';

type ModifiedPriceProps = {
  total: string;
};

const ModifiedPrice = ({ total }: ModifiedPriceProps) => (
  <div className="modified-price">
    <Text variant="copy2" as="span">
      Modified
    </Text>
    <NumberDisplay
      centVariant="copy2"
      customClassName="modified-price-number-display"
      dollarVariant="order2"
      value={total}
    />
  </div>
);

export default ModifiedPrice;
