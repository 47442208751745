// @flow
import { Text } from '@audi/audi-ui-react';
import React from 'react';
import { formatNumberWithCommas } from '../../../utils/formatters';

import './index.css';

type Points = {
  spend_total: number,
}

type CostInPointsProps = {
  disabled: string;
  earningPoints: boolean;
  isLoggedIn: boolean;
  isMobile: boolean;
  pricing: {
    points: Points;
  }
};

const CostInPoints = (props: CostInPointsProps) => {
  const {
    disabled,
    earningPoints,
    isLoggedIn,
    isMobile,
    pricing,
    pricing: {
      points,
    },
  } = props;

  if (!points) return null;
  const { spend_total } = points;

  const showVehicleInPoints = points
    && pricing
    && !earningPoints
    && isLoggedIn;

  if (!showVehicleInPoints) return null;

  const variant = isMobile ? 'copy3' : 'copy2';
  const disabledClass = disabled || '';

  return (
    <div className={`spend-points ${disabledClass}`}>
      <Text as="span" variant={variant}>
        {`${formatNumberWithCommas(spend_total)} pts total`}
      </Text>
    </div>
  );
};

export default CostInPoints;
