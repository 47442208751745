// @flow
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { toggleLoyaltyInfoModal } from '../../actions/booking';
import LoyaltyProgramModal from '../modals/loyalty-program';
import './index.css';

type Props = {
  toggleLoyaltyInfoModal: () => void,
  loyaltyInfoModalVisible: boolean,
}

export function SCLoyaltyToolTip(props: Props) {
  const {
    toggleLoyaltyInfoModal,
    loyaltyInfoModalVisible,
  } = props;

  return (
    <Fragment>
      <IconButton
        aria-label="Open loyalty program information modal"
        onClick={toggleLoyaltyInfoModal}
      >
        <Icon className="loyalty-program-tooltip-icon">
          info_outlined
        </Icon>
      </IconButton>
      <LoyaltyProgramModal
        toggleLoyaltyInfoModal={toggleLoyaltyInfoModal}
        loyaltyInfoModalVisible={loyaltyInfoModalVisible}
      />
    </Fragment>
  );
}

export const mapStateToProps = state => ({
  loyaltyInfoModalVisible: state.bookingReducer.loyaltyInfoModalVisible,
});

export const mapDispatchToProps = dispatch => ({
  toggleLoyaltyInfoModal: () => dispatch(toggleLoyaltyInfoModal()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SCLoyaltyToolTip);
