/* eslint-disable no-underscore-dangle,function-paren-newline,import/no-named-as-default */
/* Only doing the above for react devtools having a naming convention we have no control over */
import { compose, createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';

import { FacebookPixelMiddleware } from './events/fb-pixel';
import { GoogleTagManagerMiddleware } from './events/gtm';
import rootReducer from './reducers';

const middleware = applyMiddleware(
  thunk,
  FacebookPixelMiddleware,
  GoogleTagManagerMiddleware,
);

const actionSanitizer = action => (
  action.type && action.subType
    ? { ...action, type: `${action.type}: ${action.subType}` }
    : action
);

const store = createStore(
  rootReducer,
  compose(
    middleware,
    window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__({
      actionSanitizer,
    }) : f => f,
  ),
);

export default store;
