// @flow
import moment from 'moment/moment';
import HTTP from '../../../utils/http';
import { getErrorMessage } from '../../../utils';
import { userNetworkReset } from '../index';
import * as DriversLicenseTypes from './constants';

// GET DRIVERS LICENSE DATA ////////////////
export const getDriversLicenseSuccess = (payload: Object) => ({
  type: DriversLicenseTypes.GET_DRIVERS_LICENSE_SUCCESS,
  payload,
});

export const getDriversLicenseError = (payload: Object) => ({
  type: DriversLicenseTypes.GET_DRIVERS_LICENSE_ERROR,
  payload: getErrorMessage(payload),
});

export const getDriversLicense = () => (
  (dispatch: Function) => (
    HTTP.driversLicenses.get()
      .then((resp) => {
        const { data } = resp;
        data.born_on = moment(data.born_on).format('YYYY-MM-DD');
        data.expires_on = moment(data.expires_on).format('YYYY-MM-DD');

        dispatch(getDriversLicenseSuccess(data));
      })
      .catch(error => dispatch(getDriversLicenseError(error)))
  )
);

// CREATE DRIVERS LICENSE DATA ////////////////
export const postDriversLicenseSuccess = (payload: Object, dispatch: Function) => {
  /* istanbul ignore next */
  setTimeout(() => dispatch(userNetworkReset()), 5000);
  return {
    type: DriversLicenseTypes.POST_DRIVERS_LICENSE_SUCCESS,
    payload,
  };
};

export const postDriversLicenseError = (payload: Object, dispatch: Function) => {
  /* istanbul ignore next */
  setTimeout(() => dispatch(userNetworkReset()), 5000);
  return {
    type: DriversLicenseTypes.POST_DRIVERS_LICENSE_ERROR,
    payload: getErrorMessage(payload),
  };
};

export const postDriversLicense = (data: Object) => {
  const submitData = data;
  submitData.born_on = moment(submitData.born_on).format('YYYY-MM-DD');
  submitData.expires_on = moment(submitData.expires_on).format('YYYY-MM-DD');
  return (dispatch: Function) => HTTP.driversLicenses.post(submitData)
    .then(() => dispatch(postDriversLicenseSuccess(submitData, dispatch)))
    .catch(error => dispatch(postDriversLicenseError(error, dispatch)));
};

// EDIT DRIVERS LICENSE DATA ////////////////
export const updateDriversLicenseSuccess = (payload: Object, dispatch: Function) => {
  /* istanbul ignore next */
  setTimeout(() => dispatch(userNetworkReset()), 5000);
  return {
    type: DriversLicenseTypes.UPDATE_DRIVERS_LICENSE_SUCCESS,
    payload,
  };
};

export const updateDriversLicenseError = (payload: Object, dispatch: Function) => {
  /* istanbul ignore next */
  setTimeout(() => dispatch(userNetworkReset()), 5000);
  return {
    type: DriversLicenseTypes.UPDATE_DRIVERS_LICENSE_ERROR,
    payload: getErrorMessage(payload),
  };
};

export const updateDriversLicense = (data: Object) => {
  const submitData = data;
  submitData.born_on = moment(submitData.born_on).format('YYYY-MM-DD');
  submitData.expires_on = moment(submitData.expires_on).format('YYYY-MM-DD');
  return (dispatch: Function) => HTTP.driversLicenses.put(submitData)
    .then(() => dispatch(updateDriversLicenseSuccess(submitData, dispatch)))
    .catch(error => dispatch(updateDriversLicenseError(error, dispatch)));
};
