// @flow
import { Button } from '@audi/audi-ui-react';
import React from 'react';

const CurrentlySelectedButton: React.FC = (): React.ReactElement => (
  <Button
    fullWidth
    disabled
    variant="primary"
    name="currently-selected-vehicle-button"
    className="currently-selected-button"
    size="small"
  >
    Selected
  </Button>
);

export default CurrentlySelectedButton;
