import * as FeatureTypes from '../../actions/features/constants';

export const initialState = {
  isAuth0Enabled: false,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case FeatureTypes.FEATURES_IS_AUTH0_ENABLED:
      return {
        ...state,
        isAuth0Enabled: payload,
      };
    default:
      return state;
  }
}
