// @flow
export const CREATE_CURBSIDE_REQUEST_ERROR = 'CREATE_CURBSIDE_REQUEST_ERROR';
export const CREATE_CURBSIDE_REQUEST_SUCCESS = 'CREATE_CURBSIDE_REQUEST_SUCCESS';
export const CLOSE_CURBSIDE_AVAILABLE_MODAL = 'CLOSE_CURBSIDE_AVAILABLE_MODAL';
export const OPEN_CURBSIDE_AVAILABLE_MODAL = 'OPEN_CURBSIDE_AVAILABLE_MODAL';
export const CLOSE_INCOMPLETE_PROFILE_MODAL = 'CLOSE_INCOMPLETE_PROFILE_MODAL';
export const OPEN_INCOMPLETE_PROFILE_MODAL = 'OPEN_INCOMPLETE_PROFILE_MODAL';
export const CLOSE_COVERAGE_OPTIONS_MODAL = 'CLOSE_COVERAGE_OPTIONS_MODAL';
export const OPEN_COVERAGE_OPTIONS_MODAL = 'OPEN_COVERAGE_OPTIONS_MODAL';
export const TRACK_DELIVERY_REQUEST_START = 'TRACK_DELIVERY_REQUEST_START';
export const TRACK_DELIVERY_REQUEST_DECLINE = 'TRACK_DELIVERY_REQUEST_DECLINE';
export const TRACK_DELIVERY_REQUEST_SUBMIT = 'TRACK_DELIVERY_REQUEST_SUBMIT';
export const TRACK_DELIVERY_REQUEST_CANCEL = 'TRACK_DELIVERY_REQUEST_CANCEL';
export const RESET_CURBSIDE_TEXT = 'RESET_CURBSIDE_TEXT';
export const SET_CURBSIDE_TEXT = 'SET_CURBSIDE_TEXT';
export const SET_RECENT_BOOKING_TOKEN = 'SET_RECENT_BOOKING_TOKEN';
export const OPEN_VEHICLE_MODAL_VISIBLE = 'OPEN_VEHICLE_MODAL_VISIBLE';
export const CLOSE_VEHICLE_MODAL_VISIBLE = 'CLOSE_VEHICLE_MODAL_VISIBLE';
