// @flow
import { Reducer } from './constants';

export const setLocation = (payload: Object) => ({
  type: Reducer.SET_LOCATION,
  payload,
});

export const clearLocation = () => ({
  type: Reducer.CLEAR_LOCATION,
});

export const setActiveSection = (payload: []) => ({
  type: Reducer.SET_ACTIVE_SECTION,
  payload,
});

export const setFilteredLocations = (payload: []) => ({
  type: Reducer.SET_FILTERED_LOCATIONS,
  payload,
});

export const setFocus = (payload: string) => ({
  type: Reducer.SET_FOCUS,
  payload,
});

export const setHover = (payload: string | null) => ({
  type: Reducer.SET_HOVER,
  payload,
});

export const setLocationName = (payload: string) => ({
  type: Reducer.SET_LOCATION_NAME,
  payload,
});

export const setPickupDate = (payload: string) => ({
  type: Reducer.SET_PICKUP_DATE,
  payload,
});

export const setReturnDate = (payload: string) => ({
  type: Reducer.SET_RETURN_DATE,
  payload,
});

export const setPickupTime = (payload: string) => ({
  type: Reducer.SET_PICKUP_TIME,
  payload,
});

export const setReturnTime = (payload: string) => ({
  type: Reducer.SET_RETURN_TIME,
  payload,
});

export const disableSubmit = () => ({
  type: Reducer.DISABLE_SUBMIT,
});

export const enableSubmit = () => ({
  type: Reducer.ENABLE_SUBMIT,
});
