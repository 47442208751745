import { Text } from '@audi/audi-ui-react';
import React from 'react';
import SCLoyaltyToolTip from '../../../../components/sc-loyalty-tool-tip';

const LoginPrompt = () => (
  <div className="login-container">
    <SCLoyaltyToolTip />
    <Text
      as="h1"
      className="login-to-pay-textfield"
      spaceInlineStart="s"
      variant="copy1"
    >
      <a
        className="login-to-pay-link"
        href="/login"
      >
        Log in
      </a>
      {' '}
      to pay with points
    </Text>
  </div>
);

export default LoginPrompt;
