// @flow
import { createSelector } from 'reselect';

import type { State as AppReducer } from '../reducers';

const appReducer = (state: { appReducer: AppReducer }) => state.appReducer;
const getToken = (appReducer: AppReducer) => appReducer.authToken;
const isReady = (appReducer: AppReducer) => appReducer.isReady;
const isLoggedIn = (appReducer: AppReducer) => appReducer.isLoggedIn;
const queryParams = (appReducer: AppReducer) => appReducer.queryParams;

export const getAuthToken = createSelector(
  appReducer,
  getToken,
);

export const getIsReady = createSelector(
  appReducer,
  isReady,
);

export const getIsLoggedIn = createSelector(
  appReducer,
  isLoggedIn,
);

export const getQueryParams = createSelector(
  appReducer,
  queryParams,
);
