import { Text } from '@audi/audi-ui-react';
import Box from '@material-ui/core/Box';
import {
  useMediaQuery,
} from '@material-ui/core';
import React from 'react';
import JoinReserveForm from '../forms/join-reserve';

export function Join() {
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('xs'));

  return (
    <Box>
      {!isMobile ? (
        <Text
          as="h1"
          variant="order2"
          weight="bold"
          spaceStackEnd="s"
        >
        You&#39;re almost there!
        </Text>
      ) : (
        <Text
          as="h1"
          variant="order2"
          weight="bold"
          spaceStackEnd="s"
        >
          Almost there!
        </Text>
      )}
      <Text as="p" variant="copy1" spaceStackEnd="l">
        Please submit an email and phone number to complete your booking.
        If that email is already connected to an Audi on demand profile,
        we&#39;ll add the booking to that account.
      </Text>
      <Box mt="8px" />
      <JoinReserveForm />
    </Box>
  );
}

export default Join;
