// @flow

const MuiSelect = {
  select: {
    '&:focus': {
      backgroundColor: 'unset',
    },
  },
};

export default MuiSelect;
