import { setupCoverages } from '../../../components/coverage/utils';
import { initialState } from '../../../reducers/booking';

// eslint-disable-next-line import/prefer-default-export
export function generateBooking(bookingReducer) {
  // @TODO handle below to set params in new booking object for POST
  // going to eventually grab the "quoted", "utm_medium", and "utm_source" params
  // from the below queryParams object to send with a new rental
  const { activeBooking, stagedBooking } = bookingReducer;
  const {
    pickupTime,
    dropoffTime,
    vehicle,
    coverage,
    promo,
  } = activeBooking;
  const { staged } = stagedBooking;

  // get fleet_id from vehicle
  const fleetId = vehicle ? vehicle.fleet_id : null;
  const promoId = promo ? promo.id : initialState.activeBooking.promo;

  return {
    staged,
    fleetId,
    payload: {
      pickup_at: pickupTime,
      dropoff_at: dropoffTime,
      coverages: setupCoverages(coverage),
      promo_code_id: promoId,
    },
  };
}

export function getFilteredDiscount(discount) {
  const filterString = 'premium rewards (';
  const audiDaysFilter = 'always audi days applied';
  return discount.items.filter(
    el => el.label.toLowerCase().indexOf(filterString) === -1,
  ).filter(
    el => el.label.toLowerCase().indexOf(audiDaysFilter) === -1,
  );
}

// TODO: format links in models instead of helper functions
export const getValueFromLinks = (value, links) => {
  const link = links
    ? links.find(({ rel }) => rel === value)
    : {};

  return (link && link.href)
    ? link.href.split('/').pop()
    : 0;
};

export const getLocationFromLinks = links => getValueFromLinks('location', links);
export const getFleetFromLinks = links => getValueFromLinks('fleet', links);

export const getLocationfromBooking = (booking, locationMap) => {
  if (booking.links) {
    const locationId = getLocationFromLinks(booking.links);
    const location = locationMap[locationId];
    return location;
  }
  return null;
};
