// @flow
import React from 'react';
import {
  AudiPlatformProvider, Button, Text,
} from '@audi/audi-ui-react';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import './index.css';

type Props = {
  buttonClasses?: string,
  children: React.ReactNode,
  onClose: () => void,
  open: boolean,
  showCloseButton: boolean,
  title: string,
  width: boolean | string,
  fullWidth: boolean,
  titleIcon?: React.ReactNode,
  titleVariant?: string,
  showLeftButton?: boolean,
  leftButtonText?: string,
  onLeftButtonClicked?: () => void,
  rightButtonDisabled?: boolean,
  showRightButton?: boolean,
  rightButtonText?: string,
  onRightButtonClicked?: () => void,
  classProp: string,
}

export default function SCDialog(props: Props) {
  const {
    buttonClasses = '',
    children,
    onClose,
    open,
    showCloseButton,
    title,
    titleIcon = null,
    titleVariant = 'order3',
    showLeftButton = false,
    leftButtonText = '',
    onLeftButtonClicked = () => { },
    showRightButton = false,
    rightButtonDisabled = false,
    rightButtonText = '',
    onRightButtonClicked = () => { },
    width = 'sm',
    fullWidth = false,
    classProp = 'dialog',
  } = props;

  return (
    <Dialog
      onClose={onClose}
      open={open}
      className={classProp}
      fullWidth={fullWidth}
      maxWidth={width}
      BackdropProps={{ invisible: true }}
    >
      <AudiPlatformProvider>
        {showCloseButton && (
          <div className="dialogHeader dialogClose">
            <IconButton aria-label="close modal" onClick={onClose}>
              <CloseIcon fontSize="large" />
            </IconButton>
          </div>
        )}
        {title && (
          <div className="dialogHeader dialogTitle">
            <Text as="h2" variant={titleVariant} weight="bold" spaceStackEnd="xl">
              {title}
            </Text>
            {titleIcon}
          </div>
        )}
        <div className="dialogContent">
          {children}
        </div>
        {(showLeftButton || showRightButton) && (
          <div className={buttonClasses}>
            {showLeftButton && (
              <Button
                variant="secondary"
                onClick={onLeftButtonClicked}
                size="small"
                spaceInlineEnd="xs"
                spaceStackStart="xxl"
              >
                {leftButtonText}
              </Button>
            )}
            {showRightButton && (
              <Button
                disabled={rightButtonDisabled}
                variant="primary"
                size="small"
                onClick={onRightButtonClicked}
                spaceStackStart="xxl"
              >
                {rightButtonText}
              </Button>
            )}
          </div>
        )}
      </AudiPlatformProvider>
    </Dialog>
  );
}
