// @flow
import React from 'react';
import ReservationDropDown from './reservation-dropdown-menu';
import './index.css';

type Props = {
  id: string;
  reservationState: string;
  onCancellationClick: () => void;
}

export const ManageReservations = (props: Props) => {
  const {
    id, reservationState, onCancellationClick,
  } = props;


  return (
    <div className="manage-reservations-container">
      {
        <div className="manage-reservation-section">
          <ReservationDropDown
            id={id}
            reservationState={reservationState}
            onCancellationClick={onCancellationClick}
          />
        </div>
      }
    </div>

  );
};

export default ManageReservations;
