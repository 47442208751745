// @flow
import React, { Component } from 'react';
import {
  Button, List, ListItem, Text, TextField,
  Select,
} from '@audi/audi-ui-react';
import { connect } from 'react-redux';
import { ValidatorForm } from 'react-material-ui-form-validator';
import setupBraintree from './braintreeHandler';
import { creditCardsFetchData, creditCardFullCreate } from '../../../actions/account/credit-cards';
import countries from '../../../utils/data/countriesList';
import { states } from '../../../utils/data/statesList';
import Storage from '../../../utils/storage';

import './credit-card.css';

type Address = {
  line1: string,
  line2: string,
  city: string,
  state: string,
  country: string,
  zip: string,
}

type State = {
  disabled: boolean,
  cardholder_name: string,
  address: Address,
  stateChanged: boolean,
}

type Props = {
  braintreeToken: string,
  creditCardsFetchData: () => Promise<void>,
  creditCardFullCreate: ({billing_address: Address}, Object, string) => void,
  creditCard: Object,
}

// TODO: The tests for this file return a false positive
// need to fix and add them back when the credit card form is fixed [AOD-1041]
export class CreditCardForm extends Component<Props, State> {
  constructor(props: Object) {
    super(props);

    this.state = {
      cardholder_name: '',
      address: {
        line1: '',
        line2: '',
        city: '',
        state: '',
        country: 'US',
        zip: '',
      },
      disabled: true,
      stateChanged: false,
    };
    (this: any).addUserInfoToState = this.addUserInfoToState.bind(this);
    (this: any).resetState = this.resetState.bind(this);
    (this: any).onSubmitCard = this.onSubmitCard.bind(this);
    (this: any).handleCreditCardChange = this.handleCreditCardChange.bind(this);
    (this: any).isDisabled = this.isDisabled.bind(this);
  }

  componentDidMount() {
    const { creditCardsFetchData } = this.props;
    creditCardsFetchData().then(() => this.addUserInfoToState());
  }

  componentDidUpdate(props: Props) {
    const { braintreeToken, creditCard } = props;
    const { onSubmitCard, handleCreditCardChange } = this;

    setupBraintree(braintreeToken, onSubmitCard, creditCard, handleCreditCardChange);
  }

  async onSubmitCard(hostedFieldsInstance: Object) {
    const { address, cardholder_name } = this.state;
    const { creditCardFullCreate } = this.props;
    /* @ULTRAHAXX */
    // Storing the cardholder_name so it doesn't flash when a new card is added.
    // When creating a new card, the props are passed in when other actions are fired,
    // so the creditCard variable will come in empty as it hasn't resolved yet. In that
    // instance, there's no cardholder_name, so I'm saving the users' entry into local
    // storage on submit, and drawing from that if there's no card.
    // @TODO figure out a good time to clear this from storage.
    Storage.set('chName', cardholder_name);
    /* END HAXX */

    const billingAddress = {
      billing_address: { ...address },
    };

    creditCardFullCreate(billingAddress, hostedFieldsInstance, cardholder_name);
    this.setState({ disabled: true });
  }

  handleCreditCardChange(isValid: boolean) {
    // handle CC change here. This function is passed into braintree handler
    if (isValid) {
      this.setState({ disabled: false });
    } else {
      this.setState({ disabled: true });
    }
  }

  addUserInfoToState() {
    const { creditCard } = this.props;
    const state: {
      cardholder_name: string,
      address?: Address
    } = {
      cardholder_name: creditCard ? creditCard.name : Storage.get('chName'),
    };

    if (creditCard.address && Object.keys(creditCard.address).length !== 0) {
      state.address = creditCard.address;
    }
    return this.setState(state);
  }

  isDisabled() {
    // "Judge not this code, lest ye be judged;" : StackOverflow 29:11
    // This is a real dumb hardcoded check for all required fields, and
    // I'm adding this for 2 reasons:
    // 1) I'm a bad developer
    // 2) We have to check against the combined Braintree and local forms for validation,
    // so using the material-ui-whathaveyou-validator doesn't work to the fullest.
    const {
      disabled,
      cardholder_name,
      address: {
        line1,
        city,
        state,
        country,
        zip,
      },
    } = this.state;

    if (disabled) {
      return true;
    }
    return !(!disabled
      && cardholder_name
      && line1
      && city
      && state
      && country
      && zip
    );
  }

  resetState() {
    const {
      creditCardsFetchData,
      creditCard: {
        address,
      },
    } = this.props;
    creditCardsFetchData();
    const billingAddress = { ...address };
    this.setState(prevState => ({
      disabled: true,
      stateChanged: false,
      cardholder_name: prevState.cardholder_name,
      address: billingAddress,
    }));
  }

  render() {
    const {
      address,
      cardholder_name,
      stateChanged,
    } = this.state;
    return (
      <>
        <Text
          as="h2"
          variant="order2"
          weight="bold"
        >
    Credit card
        </Text>
        <ValidatorForm id="cc-form" onSubmit={() => false}>
          <section id="credit-card-form">
            <section id="details-section">
              <Text
                as="h2"
                variant="order4"
                weight="bold"
              >
                Credit Card details
              </Text>
              <List variant="bullet" textVariant="copy2" spaceStackStart="m">
                <ListItem>
                  Updates to your credit card will not affect in-progress reservations
                </ListItem>
                <ListItem>
                  The name on your credit card needs to match your driver&lsquo;s license.
                </ListItem>
              </List>
              <TextField
                inputId="text-field_cardholder_name"
                name="name"
                label="Name on card"
                onChange={e => this.setState({
                  cardholder_name: e.target.value,
                  stateChanged: true,
                })}
                value={cardholder_name || ''}
                required
                validationMessage="Name is required"
                spaceStackStart="xl"
                labelEffect={cardholder_name ? 'none' : 'float'}
              />

              {/* CARD STUFF */}
              <div className="hosted-field-container">
                <div
                  id="credit-card-number"
                  className="bt-label"
                >
                  Credit card number
                  <div
                    id="card-number"
                    aria-labelledby="credit-card-number"
                    className="hosted-field"
                  />
                </div>
              </div>
              <div className="hosted-field-container">
                <div
                  id="credit-card-expiration-date"
                  className="bt-label"
                >
                  Expiration
                  <div
                    id="expiration-date"
                    aria-labelledby="credit-card-expiration-date"
                    className="hosted-field"
                  />
                </div>
              </div>
              {/* END CARD STUFF */}
            </section>
            <section id="address-section">
              <Text
                as="h2"
                variant="order4"
                weight="bold"
              >
                Billing address
              </Text>
              <TextField
                inputId="text-field_address_line1"
                label="Address 1"
                onChange={e => this.setState({
                  address: {
                    ...address,
                    line1: e.target.value,
                  },
                  stateChanged: true,
                })}
                name="line1"
                value={address.line1 || ''}
                required
                validationMessage="Address is required"
                spaceStackStart="l"
                labelEffect={address.line1 ? 'none' : 'float'}
              />
              <TextField
                inputId="text-field_address_line2"
                label="Address 2"
                onChange={e => this.setState({
                  address: {
                    ...address,
                    line2: e.target.value,
                  },
                  stateChanged: true,
                })}
                name="line2"
                value={address.line2 || ''}
                spaceStackStart="s"
                labelEffect={address.line2 ? 'none' : 'float'}
              />
              <div className="half-section">
                <TextField
                  className="half"
                  inputId="text-field_city"
                  label="City"
                  onChange={e => this.setState({
                    address: {
                      ...address,
                      city: e.target.value,
                    },
                    stateChanged: true,
                  })}
                  name="city"
                  required
                  validationMessage="City is required"
                  value={address.city || ''}
                  labelEffect={address.city ? 'none' : 'float'}
                />

                {address.country === 'US'
                  ? (
                    <Select
                      inputId="select_state"
                      value={address.state || ''}
                      name="state"
                      label="State"
                      onChange={event => this.setState({
                        address: {
                          ...address,
                          state: event.target.value,
                        },
                        stateChanged: true,
                      })}
                      required
                      validationMessage="State is required"
                      className={`${address.state ? 'label-animation' : ''} half`}
                    >
                      {states.map(s => (
                        <option
                          value={s.abbreviation}
                          key={s.abbreviation}
                        >
                          {s.name}
                        </option>
                      ))}
                    </Select>
                  ) : (
                    <TextField
                      inputId="text-field_state"
                      className="half"
                      id="province"
                      label="Province/Region"
                      onChange={e => this.setState({
                        address: {
                          ...address,
                          state: e.target.value,
                        },
                        stateChanged: true,
                      })}
                      name="state"
                      required
                      validationMessage="Province/Region is required"
                      value={address.state || ''}
                      labelEffect={address.state ? 'none' : 'float'}
                    />
                  )
          }
              </div>
              <div className="half-section">
                <TextField
                  className="half"
                  inputId="text-field_zip"
                  label="Postal Code"
                  onChange={e => this.setState({
                    address: {
                      ...address,
                      zip: e.target.value,
                    },
                    stateChanged: true,
                  })}
                  name="zip"
                  required
                  validationMessage="Postal Code is required"
                  value={address.zip || ''}
                  labelEffect={address.zip ? 'none' : 'float'}
                />
                <Select
                  inputId="select_country"
                  value={address.country}
                  name="country"
                  label="Country"
                  onChange={event => this.setState({
                    address: {
                      ...address,
                      country: event.target.value,
                    },
                    stateChanged: true,
                  })}
                  required
                  validationMessage="Country is required"
                  className={`${address.country ? 'label-animation' : ''} half`}
                >
                  {countries.map(c => (
                    <option
                      value={c.code}
                      key={c.code}
                    >
                      {c.name}
                    </option>
                  ))}
                </Select>
              </div>
            </section>
          </section>
          <section id="credit-card-button-section">
            <Button
              disabled={!stateChanged}
              name="cancel"
              onClick={() => this.resetState()}
              variant="secondary"
              className="credit-card-button"
            >
              Cancel
            </Button>
            <Button
              disabled={this.isDisabled()}
              name="save"
              type="submit"
              variant="primary"
              className="credit-card-button"
            >
              Save
            </Button>
          </section>
        </ValidatorForm>
      </>
    );
  }
}

export const mapStateToProps = state => ({
  braintreeToken: state.accountReducer.braintreeToken,
  billingAddress: state.accountReducer.creditCard.address,
  creditCard: state.accountReducer.creditCard,
});

export const mapDispatchToProps = dispatch => ({
  creditCardsFetchData: () => dispatch(creditCardsFetchData()),
  creditCardFullCreate: (
    billingAddress,
    hostedFieldsInstance,
    cardholder_name,
  ) => dispatch(creditCardFullCreate(billingAddress, hostedFieldsInstance, cardholder_name)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreditCardForm);
