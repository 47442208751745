// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import { openIncompleteProfileModal } from '../../actions/modal';

import HTTPFormStatus from '../../components/forms/http-form-status';
import ReservationList from '../../components/reservations/reservation-list';

import './index.css';
import ReservationDetail from './reservation-detail';

type Props = {
  curbsideModalOpen: boolean,
  currentUserId: number,
  httpResponseError: boolean,
  httpResponseStatusMessage: string,
  incompleteProfileHasBeenPrompted: boolean,
  openIncompleteProfileModal: () => void,
  userFetchResolved: boolean,
  userProfileComplete: boolean
}

export class Reservations extends Component<Props> {
  componentDidMount() {
    this.checkProfileIncompleteStatus();
  }

  componentDidUpdate() {
    const { currentUserId } = this.props;
    if (currentUserId !== 0) {
      this.checkProfileIncompleteStatus();
    }
  }

  checkProfileIncompleteStatus = () => {
    const {
      curbsideModalOpen,
      incompleteProfileHasBeenPrompted,
      userFetchResolved,
      userProfileComplete,
      openIncompleteProfileModal,
    } = this.props;

    if (
      userFetchResolved
      && !incompleteProfileHasBeenPrompted
      && !curbsideModalOpen
      && !userProfileComplete
    ) {
      openIncompleteProfileModal();
    }
  }

  render() {
    const {
      httpResponseError,
      httpResponseStatusMessage,
    } = this.props;

    return (
      <div className="sc-reservations">
        <HelmetProvider>
          <Helmet>
            <title>My Reservations | Audi on demand</title>
          </Helmet>
        </HelmetProvider>

        {httpResponseStatusMessage && (
          <HTTPFormStatus
            category=""
            type={httpResponseError ? 'error' : 'success'}
            message={httpResponseStatusMessage}
          />
        )}
        <Route exact path="/" component={ReservationList} />
        <Route exact path="/reservations" component={ReservationList} />
        <Route path="/reservations/:id" component={ReservationDetail} />
      </div>
    );
  }
}

export const mapStateToProps = state => ({
  curbsideModalOpen: state.modalReducer.curbsideAvailableModalVisible,
  currentUserId: state.accountReducer.user.id,
  httpResponseError: state.bookingReducer.httpResponseError,
  httpResponseStatusMessage: state.bookingReducer.httpResponseStatusMessage,
  incompleteProfileHasBeenPrompted: state.modalReducer.incompleteProfileHasBeenPrompted,
  userFetchResolved: state.accountReducer.userFetchResolved,
  userProfileComplete: state.accountReducer.user.complete_profile,
});

export const mapDispatchToProps = dispatch => ({
  openIncompleteProfileModal: () => dispatch(openIncompleteProfileModal()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Reservations);
