// @flow
import React from 'react';
import { isPromoMessage, moveToTop } from '../../../utils';
import './index.css';

type Props = {
  category: string,
  type: string,
  message: string,
}

function HTTPFormStatus(props: Props) {
  const { category, type, message } = props;
  if (!isPromoMessage(message)) {
    moveToTop();
    return (
      <div className={`http-form-status ${type}`}>
        {category && (
        <h2>
          {category}
        </h2>
        )}
        <div>
          { message }
        </div>
      </div>
    );
  }
  return null;
}

export default HTTPFormStatus;
