// @flow
import { Text } from '@audi/audi-ui-react';
import React from 'react';
import SCLoyaltyToolTip from '../../sc-loyalty-tool-tip';
import { formatNumberWithCommas } from '../../../utils/formatters';
import './index.css';

type ConfirmBookingPointsProps = {
  earningPoints: boolean,
  earn_total: ?number,
}

const ConfirmBookingPoints = (props: ConfirmBookingPointsProps) => {
  const { earningPoints, earn_total } = props;
  return (
    <>
      <div className="confirm-booking-points">
        <Text className="points-text" as="p" variant="copy2" spaceStackStart="l">
        Points pending
        </Text>
        <SCLoyaltyToolTip />
      </div>
      <Text className="points-total" as="p" variant="copy1">
        {earningPoints ? formatNumberWithCommas(earn_total) : 0}
      </Text>
    </>
  );
};
export default ConfirmBookingPoints;
