// @flow
import { COLORS } from './constants';

const typography = {
  fontFamily: 'sba',
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightHeavy: 700,
  fontSize: 16,
  useNextVariants: true,
  h1: {
    fontSize: '4.5rem',
    fontWeight: 900,
  },
  h2: {
    fontSize: '2.25rem',
    fontWeight: 900,
  },
  h3: {
    fontSize: '2rem',
    fontWeight: 900,
    textTransform: 'capitalize',
  },
  h4: {
    fontSize: '1.5rem',
    fontWeight: 900,
    textTransform: 'capitalize',
  },
  h5: {
    fontSize: '1.25rem',
    fontWeight: 700,
  },
  h6: {
    fontSize: '0.875rem',
    fontWeight: 900,
  },
  subtitle1: {
    fontSize: '0.875rem',
  },
  subtitle2: {
    fontSize: '0.875rem',
    color: COLORS.GREY2,
    fontWeight: 300,
  },
  body1: {
    fontSize: '1.125rem',
  },
  body2: {
    fontSize: '1rem',
  },
  caption: {
    fontSize: '0.75rem',
    fontWeight: 400,
  },
};

export default typography;
