// @flow
import { apiUrl, headers } from '../config';
import axiosInstance from '../axiosInstance';

function availability(params) {
  const { id } = params;
  return axiosInstance({
    url: `${apiUrl.v2}/rentals/${+id}/availability`,
    headers: headers(),
    params,
    data: null,
  });
}

function get(pg) {
  const page = pg || 1;

  return axiosInstance({
    method: 'GET',
    url: `${apiUrl.v2}/rentals?page=${+page}&sort=-dropoff_at`,
    headers: headers(),
  });
}

function getById(id: Number | String) {
  return axiosInstance({
    method: 'GET',
    url: `${apiUrl.v2}/rentals/${+id}`,
    headers: headers(),
  });
}

function getReceipt(id: Number | String) {
  return axiosInstance({
    method: 'GET',
    url: `${apiUrl.v2}/rentals/${+id}/receipt`,
    headers: headers(),
  });
}

function getRentalPricing(params: Object) {
  const { id } = params;
  return axiosInstance({
    method: 'GET',
    url: `${apiUrl.v2}/rentals/${id}/pricing`,
    headers: headers(),
    params,
  });
}


function extendRental(id: number, data) {
  return axiosInstance({
    method: 'PUT',
    url: `${apiUrl.v2}/rentals/${+id}/extend`,
    headers: headers(),
    data,
  });
}
const rentals: Object = {
  availability,
  get,
  getById,
  getReceipt,
  getRentalPricing,
  extendRental,
};

export default rentals;
