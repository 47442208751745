import auth0 from 'auth0-js';
import env from '../../utils/environment';

const {
  auth: {
    clientId,
    domain,
    redirect,
  },
} = env();

const auth = new auth0.WebAuth({
  clientID: clientId,
  domain,
  responseType: 'token id_token',
  redirectUri: redirect,
  scope: 'openid profile email',
});

export default auth;
