import React from 'react';
import ConfirmPasswordForm from '../../components/forms/confirm-password';

function ConfirmPassword() {
  return (
    <div>
      <ConfirmPasswordForm />
    </div>
  );
}


export default ConfirmPassword;
