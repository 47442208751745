// @flow

const MuiFormControlLabel = {
  labelPlacementStart: {
    justifyContent: 'space-between',
    marginLeft: '0px',
  },
  label: {
    verticalAlign: 'middle',
  },
};

export default MuiFormControlLabel;
