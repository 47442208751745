// @flow
import { headers } from '../config';
import axiosInstance from '../axiosInstance';

export function get(url: string) {
  return axiosInstance({
    url,
    headers: headers(),
  });
}

export function post(url: string, data: Object) {
  return axiosInstance({
    url,
    method: 'POST',
    headers: headers(),
    data,
  });
}

export function put(url: string, data: Object) {
  return axiosInstance({
    url,
    method: 'PUT',
    headers: headers(),
    data,
  });
}

const hypermedia = {
  get,
  post,
  put,
};

export default hypermedia;
