// @flow
import * as Actions from '../../actions';
import * as CreditCardsTypes from '../../actions/account/credit-cards/constants';
import * as DriversLicenseTypes from '../../actions/account/drivers-license/constants';
import * as AccountTypes from '../../actions/account/constants';

type User = {
  id: number,
  email: string,
  first_name: string,
  last_name: string,
  phone_number: string,
  silvercar_marketing: boolean,
  third_party_marketing: boolean,
  complete_profile: boolean,
  visa_signature: boolean,
  photo_url: string,
  confirmation_token: string,
  created_at: string,
  confirmed_at: string,
}

type CreditCard = {
  expires_on: string,
  last_four: string,
  name: string,
  type: string,
  address: {
    line1: string,
    line2: string,
    city: string,
    state: string,
    country: string,
    zip: string,
  },
}

export type State = {
  user: User,
  creditCard: CreditCard,
  httpResponseError: boolean,
  httpResponseStatusMessage: string,
  incompleteProfileModalVisible: boolean,
  userAudiDays: {
    remaining: number | null,
  },
  userPoints: {
    total: number,
  }
}

export const initialState: State = {
  incompleteProfileModalVisible: false,
  httpResponseError: false,
  httpResponseStatusMessage: '',
  resetPasswordToken: null,
  userFetchResolved: false,
  user: {
    id: 0,
    email: '',
    first_name: '',
    last_name: '',
    phone_number: '',
    silvercar_marketing: false,
    third_party_marketing: false,
    complete_profile: false,
    visa_signature: false,
    photo_url: '',
    confirmation_token: '',
    created_at: '',
    confirmed_at: '',
  },
  userAudiDays: {
    remaining: null,
  },
  userPoints: {
    total: null,
  },
  details: {
    email: '',
    first_name: '',
    last_name: '',
    mobile_number: '',
    loading: false,
    status: '',
    message: '',
  },
  drivers_license: {
    name: '',
    number: '',
    state: '',
    country: '',
    born_on: '',
    expires_on: '',
  },
  braintreeToken: '',
  creditCard: {
    expires_on: '',
    last_four: '',
    name: '',
    type: '',
    address: {
      line1: '',
      line2: '',
      city: '',
      state: '',
      country: '',
      zip: '',
    },
  },
  advocate: {
    code: '',
  },
};

export function accountReducer(state: State = initialState, action: Object) {
  switch (action.type) {
    case AccountTypes.SET_RESET_PASSWORD_TOKEN:
      return {
        ...state,
        resetPasswordToken: action.payload,
      };

    case AccountTypes.SET_RESET_PASSWORD_EMAIL:
      return {
        ...state,
        resetPasswordEmail: action.payload,
      };

    case AccountTypes.USER_NETWORK_RESET:
      return {
        ...state,
        httpResponseError: false,
        httpResponseStatusMessage: '',
      };
    case AccountTypes.GET_USER_SUCCESS:
      return {
        ...state,
        userFetchResolved: true,
        user: action.payload,
      };
    case AccountTypes.GET_USER_AUDI_DAYS_SUCCESS:
      return {
        ...state,
        userAudiDays: action.payload,
      };
    case AccountTypes.GET_USER_AUDI_DAYS_ERROR:
      return {
        ...state,
        httpResponseError: true,
      };
    case AccountTypes.GET_USER_POINTS_SUCCESS:
      return {
        ...state,
        userPoints: action.payload,
      };
    case AccountTypes.GET_USER_POINTS_ERROR:
      return {
        ...state,
        httpResponseError: true,
        httpResponseStatusMessage: action.payload,
      };
    case Actions.RESET_USER_POINTS:
      return {
        ...state,
        userPoints: initialState.userPoints,
      };
    case Actions.RESET_USER_AUDI_DAYS:
      return {
        ...state,
        userAudiDays: initialState.userAudiDays,
      };
    case AccountTypes.UPDATE_USER_ERROR:
      return {
        ...state,
        httpResponseError: true,
        httpResponseStatusMessage: action.payload,
      };
    case AccountTypes.UPDATE_USER_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          first_name: action.payload.first_name,
          last_name: action.payload.last_name,
          phone_number: action.payload.phone_number,
          silvercar_marketing: action.payload.silvercar_marketing,
          third_party_marketing: action.payload.third_party_marketing,
        },
        httpResponseError: false,
        httpResponseStatusMessage: 'Success',
      };
    case AccountTypes.SUBMIT_SIGNUP_SUCCESS:
      return {
        ...state,
        user: action.payload,
        httpResponseError: false,
        httpResponseStatusMessage: AccountTypes.SUBMIT_SIGNUP_SUCCESS,
      };
    case AccountTypes.SUBMIT_SIGNUP_ERROR:
      return {
        ...state,
        httpResponseError: true,
        httpResponseStatusMessage: action.payload,
      };
    case AccountTypes.FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        httpResponseError: false,
        httpResponseStatusMessage: `Thank you! We sent password reset instructions to ${action.payload.email}.`,
      };
    case AccountTypes.FORGOT_PASSWORD_ERROR:
      return {
        ...state,
        httpResponseError: true,
        httpResponseStatusMessage: action.payload,
      };

    case AccountTypes.VALIDATE_RESET_PASSWORD_TOKEN_SUCCESS:
      return {
        ...state,
        httpResponseError: false,
        authenticationToken: action.payload,
      };

    case AccountTypes.VALIDATE_RESET_PASSWORD_TOKEN_ERROR:
      return {
        ...state,
        httpResponseError: true,
        httpResponseStatusMessage: action.payload,
      };

    case AccountTypes.RESET_PASSWORD_ERROR:
      return {
        ...state,
        httpResponseError: true,
        httpResponseStatusMessage: action.payload,
      };
    case Actions.LOGOUT_SUCCESS:
      return {
        ...state,
        user: initialState.user,
      };
    case DriversLicenseTypes.GET_DRIVERS_LICENSE_SUCCESS:
      return {
        ...state,
        drivers_license: {
          ...action.payload,
        },
      };
    case DriversLicenseTypes.POST_DRIVERS_LICENSE_ERROR:
      return {
        ...state,
        httpResponseError: true,
        httpResponseStatusMessage: action.payload,
      };
    case DriversLicenseTypes.UPDATE_DRIVERS_LICENSE_ERROR:
      return {
        ...state,
        httpResponseError: true,
        httpResponseStatusMessage: action.payload,
      };
    case DriversLicenseTypes.UPDATE_DRIVERS_LICENSE_SUCCESS:
      return {
        ...state,
        drivers_license: {
          ...action.payload,
        },
        httpResponseError: false,
        httpResponseStatusMessage: 'Success',
      };
    case DriversLicenseTypes.POST_DRIVERS_LICENSE_SUCCESS:
      return {
        ...state,
        drivers_license: {
          ...action.payload,
        },
        httpResponseError: false,
        httpResponseStatusMessage: 'Success',
      };
    case CreditCardsTypes.CREDIT_CARDS_FETCH_BRAINTREE_TOKEN_SUCCESS:
      return {
        ...state,
        braintreeToken: action.payload.token,
      };
    case CreditCardsTypes.CREDIT_CARDS_FETCH_DATA_SUCCESS:
      return {
        ...state,
        creditCard: action.payload,
      };
    case CreditCardsTypes.CREDIT_CARD_CREATE_ERROR:
      return {
        ...state,
        httpResponseError: true,
        httpResponseStatusMessage: action.payload,
      };
    case CreditCardsTypes.CREDIT_CARD_CREATE_SUCCESS:
      return {
        ...state,
        creditCard: {
          ...state.creditCard,
          address: action.payload.address,
        },
        httpResponseError: false,
        httpResponseStatusMessage: 'Success',
      };
    case AccountTypes.CLAIM_AUDI_OWNER_CODE:
      return {
        ...state,
        isLoading: true,
      };
    case AccountTypes.CLAIM_AUDI_OWNER_CODE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        httpResponseError: false,
        httpResponseStatusMessage:
          'Success! Your code has been validated and claimed!',
      };
    case AccountTypes.CLAIM_AUDI_OWNER_CODE_ERROR:
      return {
        ...state,
        isLoading: false,
        httpResponseError: true,
        httpResponseStatusMessage: action.payload,
      };
    default:
      return state;
  }
}

export default accountReducer;
