// @flow
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@audi/audi-ui-react';
import { useMediaQuery } from '@material-ui/core';
import { toggleEstimatedTotalModalVisible } from '../../../actions/booking';
import { getActiveBooking } from '../../../selectors';
import EstimatedTotalModal from './estimated-total-modal';

const EstimatedTotalToggle = () => {
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const dispatch = useDispatch();
  const label = isMobile ? 'Est. total' : 'Estimated total';
  const buttonSize = isMobile ? 'small' : 'medium';
  const activeBooking = useSelector(getActiveBooking);
  const { selectedFleetPricing } = activeBooking;
  const { receipt } = selectedFleetPricing;

  if (!receipt) return null;

  const handleTotalEstimateModal = () => {
    dispatch(toggleEstimatedTotalModalVisible());
  };

  return (
    <>
      <Button variant="text" size={buttonSize} onClick={handleTotalEstimateModal}>
        {label}
      </Button>
      <EstimatedTotalModal />
    </>
  );
};

export default EstimatedTotalToggle;
