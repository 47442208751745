// @flow
import { Text, List, ListItem } from '@audi/audi-ui-react';
import React from 'react';
import {
  Box,
} from '@material-ui/core';
import { COLORS } from '../../../theme/constants';

const rentalAgreement = (
  <a
    rel="noopener noreferrer"
    href="https://api.silvercar.com/agreements/rental_agreement"
    target="_new_window"
  >
      Rental Agreement
  </a>
);

const StyledUl = ({ children }: { children: Node }) => (
  <Text variant="copy1" as="span">
    {children}
  </Text>
);

const CoverageDetails = () => (
  <Box mb={3.125}>
    <Box borderBottom={`1px solid ${COLORS.GREY3}`} pb={1.25}>
      <Text variant="copy1" as="p">
        Here are our coverage options explained in the simplest terms we can think of.
        We highly recommend that you check out the Learn More descriptions from the
        insurance company and our
        {' '}
        {rentalAgreement}
        {' '}
        for a more in-depth review.
      </Text>
    </Box>
    <Box borderBottom={`1px solid ${COLORS.GREY3}`} pb={1.25}>
      <Box py={1.25}>
        <Text variant="order3" as="h3" weight="bold">
          Personal Accident Insurance (PAI) / Personal Effects Coverage (PEC)
        </Text>
      </Box>
      <Text variant="copy1" as="p">
        This option includes Personal Accident Insurance and Personal Effects Coverage.
        You may be reimbursed for damage or loss of personal property and/or for medical expenses
        in the unfortunate event you are involved in an accident.
        <br />
        <br />
        Here
        {'’'}
        s what each part covers:
      </Text>
      <Box py={1.25}>
        <Text variant="copy1" as="h4" weight="bold">
          Personal Accident Insurance:
        </Text>
      </Box>
      <StyledUl>
        <List variant="bullet">
          <ListItem>
          $125,000 Accidental Death Benefit to passenger
          </ListItem>
          <ListItem>
          $2,500 Accidental Medical Expense
          </ListItem>
          <ListItem>
          $500 Ambulance Benefit
          </ListItem>
          <ListItem>
          $500,000 PAI Aggregate Limit
          </ListItem>
        </List>
      </StyledUl>
      <Box py={1.25}>
        <Text variant="copy1" as="h4" weight="bold">
          Personal Effects Coverage:
        </Text>
      </Box>
      <StyledUl>
        <List variant="bullet">
          <ListItem>
          $2,000 PEC Aggregate Limit
          </ListItem>
        </List>
      </StyledUl>
    </Box>
    <Box borderBottom={`1px solid ${COLORS.GREY3}`} pb={1.25}>
      <Box py={1.25}>
        <Text variant="order3" as="h3" weight="bold">
          Auto Physical Damage
        </Text>
      </Box>
      <Text variant="copy1" as="p">
        This option covers your Audi rental. Selecting this option means that we will assume
        all loss or damage to the car during your rental, with some exceptions as detailed
        in the
        {' '}
        {rentalAgreement}
        .
      </Text>
      <Box py={1.25}>
        <Text variant="copy1" as="h4" weight="bold">
          What this means to you:
        </Text>
      </Box>
      <StyledUl>
        <List variant="bullet">
          <ListItem>
          Zero financial responsibility for vehicle
          </ListItem>
          <ListItem>
          Zero out of pocket expenses
          </ListItem>
        </List>
      </StyledUl>
    </Box>
    <Box py={1.25}>
      <Text variant="order3" as="h3" weight="bold">
        Liability
      </Text>
    </Box>
    <Text variant="copy1" as="p">
    Coverage: Supplemental Liability Insurance
    is an optional coverage
    that protects the rentee against third party liability claims as a result
    of an accident while the rentee was operating the rental auto.
    SLI provides excess coverage over the underlying insurance/or self-insured
    statutory limits required in the executed and signed rental agreement.
    SLI DOES NOT PROVIDE PRIMARY LIABILITY COVERAGE TO THE RENTEE
    nor does it provide any uninsured/underinsured motorist
      {' & '}
    Personal Injury Protection coverage.
    SLI covers the difference up to $500,000 Combined Single Limit
    in excess of the limits of liability provided by all underlying insurance
    available or statutory limits.
    </Text>
  </Box>
);

export default CoverageDetails;
