// @flow
import { Button } from '@audi/audi-ui-react';
import React from 'react';
import { withRouter } from 'react-router-dom';
import './index.css';

const requestLink = 'https://www.audiondemand.com/support/new-request/';

const RequestPickUpDelivery = () => (
  <div className="request-pick-up-delivery-buttons">
    <Button
      href={requestLink}
      size="small"
      variant="secondary"
      spaceStackEnd="xxxl"
      newWindow
    >
        Request extras (child seat, etc.)
    </Button>
  </div>
);


export default withRouter(RequestPickUpDelivery);
