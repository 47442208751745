// @flow
import React from 'react';
import { Box } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

type Props = {
  padding?: number,
  size?: number,
}

export function Loading({ size = 48, padding = 3 }: Props) {
  return (
    <Box
      display="flex"
      justifyContent="center"
      p={padding}
    >
      <CircularProgress color="primary" size={size} thickness={4} />
    </Box>
  );
}

export default Loading;
