export const MODIFY_BOOKING = 'MODIFY_BOOKING';
export const CLEAR_BOOKING = 'CLEAR_BOOKING';
export const GET_BOOKING = 'GET_BOOKING';
export const GET_BOOKING_ERROR = 'GET_BOOKING_ERROR';
export const GET_BOOKING_SUCCESS = 'GET_BOOKING_SUCCESS';
export const GET_BOOKING_PRICING = 'GET_BOOKING_PRICING';
export const GET_BOOKING_PRICING_ERROR = 'GET_BOOKING_PRICING_ERROR';
export const GET_BOOKING_PRICING_SUCCESS = 'GET_BOOKING_PRICING_SUCCESS';
export const GET_BOOKING_AVAILABILITY_ERROR = 'GET_BOOKING_AVAILABILITY_ERROR';
export const GET_BOOKING_AVAILABILITY_SUCCESS = 'GET_BOOKING_AVAILABILITY_SUCCESS';
export const GET_COVERAGE_OPTIONS_SUCCESS = 'GET_COVERAGE_OPTIONS_SUCCESS';
export const GET_COVERAGE_OPTIONS_ERROR = 'GET_COVERAGE_OPTIONS_ERROR';
export const GET_COVERAGE_OPTIONS_LOADING = 'GET_COVERAGE_OPTIONS_LOADING';
export const GET_COVERAGE_PRICING_ERROR = 'GET_COVERAGE_PRICING_ERROR';
export const GET_COVERAGE_PRICING_SUCCESS = 'GET_COVERAGE_PRICING_SUCCESS';
export const GET_FLEET_PRICING_SUCCESS = 'GET_FLEET_PRICING_SUCCESS';
export const GET_FLEET_PRICING_ERROR = 'GET_FLEET_PRICING_ERROR';
export const GET_LOCATIONS_FLEET_AVAILABILITY_SUCCESS = 'GET_LOCATIONS_FLEET_AVAILABILITY_SUCCESS';
export const GET_LOCATIONS_FLEET_AVAILABILITY_ERROR = 'GET_LOCATIONS_FLEET_AVAILABILITY_ERROR';
export const RESET_LOCATIONS_FLEET_AVAILABILITY = 'RESET_LOCATIONS_FLEET_AVAILABILITY';
export const GET_RECEIPT_ERROR = 'GET_RECEIPT_ERROR';
export const GET_RECEIPT_SUCCESS = 'GET_RECEIPT_SUCCESS';
export const GET_RENTAL_AVAILABILITY_ERROR = 'GET_RENTAL_AVAILABILITY_ERROR';
export const GET_RENTAL_AVAILABILITY_SUCCESS = 'GET_RENTAL_AVAILABILITY_SUCCESS';
export const GET_RENTAL_PRICING = 'GET_RENTAL_PRICING';
export const GET_RENTAL_PRICING_ERROR = 'GET_RENTAL_PRICING_ERROR';
export const GET_RENTAL_PRICING_SUCCESS = 'GET_RENTAL_PRICING_SUCCESS';
export const NETWORK_ERROR = 'NETWORK_ERROR';
export const NETWORK_RESET = 'NETWORK_RESET';
export const SET_MODIFY_BOOKING_SUBMITTING = 'SET_MODIFY_BOOKING_SUBMITTING';
export const SET_COVERAGE = 'SET_COVERAGE';
export const SET_COVERAGE_OPTIONS = 'SET_COVERAGE_OPTIONS';
export const SET_DROPOFF_DATE = 'SET_DROPOFF_DATE';
export const SET_DROPOFF_TIME = 'SET_DROPOFF_TIME';
export const SET_MODIFIED_BOOKING = 'SET_MODIFIED_BOOKING';
export const SET_MODIFIED_BOOKING_PRICING = 'SET_MODIFIED_BOOKING_PRICING';
export const SET_PICKUP_LOCATION = 'SET_PICKUP_LOCATION';
export const SET_PICKUP_DATE = 'SET_PICKUP_DATE';
export const SET_PICKUP_TIME = 'SET_PICKUP_TIME';
export const SET_SELECTED_FLEET_PRICING = 'SET_SELECTED_FLEET_PRICING';
export const SET_VEHICLE = 'SET_VEHICLE';
export const TOGGLE_MODIFY_BOOKING_MODAL_VISIBLE = 'TOGGLE_MODIFY_BOOKING_MODAL_VISIBLE';
export const UPDATE_BOOKING_CANCEL = 'UPDATE_BOOKING_CANCEL';
export const UPDATE_BOOKING_ERROR = 'UPDATE_BOOKING_ERROR';
export const UPDATE_BOOKING_SUCCESS = 'UPDATE_BOOKING_SUCCESS';
export const UPDATE_BOOKING_FLEET_ERROR = 'UPDATE_BOOKING_FLEET_ERROR';
export const UPDATE_BOOKING_FLEET_SUCCESS = 'UPDATE_BOOKING_FLEET_SUCCESS';
export const UPDATE_COVERAGE_SUCCESS = 'UPDATE_COVERAGE_SUCCESS';
export const UPDATE_FLIGHT_DETAILS_SUCCESS = 'UPDATE_FLIGHT_DETAILS_SUCCESS';
export const UPDATE_FLIGHT_DETAILS_ERROR = 'UPDATE_FLIGHT_DETAILS_ERROR';
