import { COLORS } from '../../theme/constants';

export const getMobileStyles = ({ isReview }) => ({
  containerStyles: {
    display: 'flex',
    flexDirection: 'column',
  },
  headerStyles: {
    display: isReview ? 'block' : 'none',
  },
  costSummaryStyles: {
    flex: 1,
    pb: 1,
    marginX: 1.25,
  },
  requestExtrasStyles: {
    margin: '0 1.25rem 1rem',
    padding: '1.5rem 0',
    borderTop: `1px solid ${COLORS.GREY3}`,
    borderBottom: `1px solid ${COLORS.GREY3}`,
    display: 'flex',
    justifyContent: 'space-between',
    minHeight: '1.5rem',
  },
  confirmationNumberStyles: {
    margin: '0.75rem 0 1.25rem',
    padding: '0 1.25rem',
    display: 'flex',
    justifyContent: 'space-between',
    minHeight: '1.5rem',
  },
});

export const getDesktopStyles = () => ({
  containerStyles: {
    display: 'flex',
    maxWidth: '83rem',
    m: '0 auto 4rem',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  headerStyles: {
    flex: '1 0 100%',
    mb: 2.25,
  },
  costSummaryStyles: {
    flex: 1,
    border: `1px solid ${COLORS.GREY3}`,
    ml: 1,
    px: 3.75,
    py: 3.75,
    width: 'calc(50% - 7.5rem)',
    height: 'fit-content',
  },
  requestExtrasStyles: {
    flex: 1,
    borderTop: `1px solid ${COLORS.GREY3}`,
    borderBottom: `1px solid ${COLORS.GREY3}`,
    borderLeft: `1px solid ${COLORS.GREY3}`,
    borderRight: `1px solid ${COLORS.GREY3}`,
    margin: '0 1rem 0 0',
    p: 3.125,
    height: 'fit-content',
    display: 'flex',
    justifyContent: 'space-between',
  },
  confirmationNumberStyles: {},
});
