import moment from 'moment-timezone';
import numeral from 'numeral';

export function formatCurrency(arg) {
  return numeral(arg).format('$0,0.00');
}

export function formatCurrencyDollar(arg) {
  return numeral(arg).format('$0');
}

export function formatCurrencyCents(arg) {
  return numeral(arg).format('.00');
}

export function formatCurrencyRaw(arg) {
  return numeral(arg).format('0,0.00');
}

export function formatCurrencyShort(arg) {
  return numeral(arg).format('$0,0');
}

export const formatNumberWithCommas = arg => numeral(arg).format('0,0');

// eslint-disable-next-line consistent-return
export function formatDateTime(dateTime, location, pattern = 'MMM D @ h:mm A') {
  if (moment(dateTime).tz(location.time_zone)) {
    return moment(dateTime).tz(location.time_zone).format(pattern);
  }
}

export function formatDate(dateTime, format = 'MM/DD/YY') {
  return moment(dateTime).format(format);
}

export const formatUserNameOrEmail = (user) => {
  if (!user) return null;
  const { first_name, last_name, email } = user;
  const text = first_name && last_name
    ? `${first_name} ${last_name}`
    : email;
  return text;
};

export function formatLocationName(loc) {
  return `${loc.multi_car_display_name}${loc.airport_code ? ` (${loc.airport_code})` : ''}`;
}

export function formatSubscribableLocationName(loc) {
  return `${loc.multi_car_display_name} - ${loc.description}`;
}

export function filterSubscribableLocations(locs) {
  if (!Array.isArray(locs)) return [];

  return locs.filter((values) => {
    const locations = values[1];
    const matches = locations.filter(location => location.subscribable);
    if (matches.length) return locations;
    return false;
  });
}

export const formatTime = (time, timeZone) => moment(time).tz(timeZone).format('h:mma');

export const formatNewDateTime = (date, time, timeZone) => `${moment(date).format('MM/DD/YY')} at ${formatTime(time, timeZone)}`;

export const formatMobileDateTime = (date, time, timeZone) => `${moment(date).format('MM/DD')} , ${formatTime(time, timeZone)}`;

export const removeSnakeCase = (value) => {
  if (!value) return '';
  return value.split('_').join(' ');
};

export const capitalizeEachWord = (value) => {
  if (!value) return '';

  const trimmedValue = value.trim();

  if (!trimmedValue) return '';

  const words = trimmedValue.split(' ');

  const formatArray = words.map(word => (
    word.replace(word[0], word[0].toUpperCase())
  ));

  return formatArray.join(' ');
};

export const hasHyphens = value => value.includes('-');

export const formatPhoneNumber = (phoneNumber) => {
  // eslint-disable-next-line no-restricted-globals
  if ((phoneNumber.length !== 10) || isNaN(phoneNumber)) {
    return phoneNumber;
  }

  let index = 0;
  return '(XXX) XXX-XXXX'.replace(/X/g, () => {
    const digit = phoneNumber[index];
    index += 1;
    return digit;
  });
};

// TODO - Consolidate with formatPhoneNumber which doesn't handle i18n numbers
export const reformatPhoneNumber = (phoneNumber) => {
  // Filter only numbers from the input
  const cleaned = (`${phoneNumber}`).replace(/\D/g, '');

  // Check if the input is of correct
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    // Remove the matched extension code
    // Change this to format for any country code.
    const intlCode = (match[1] ? '+1 ' : '');
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
  }

  return phoneNumber;
};

export const formatAddress = (line1, line2, city, state, zip) => {
  if (line2) {
    return `${line1} ${line2} ${city}, ${state} ${zip}`;
  }
  return `${line1} ${city}, ${state} ${zip}`;
};
