// @flow
import React from 'react';
import CostAfterPoints from './cost-after-points';
import InsufficientPointsText from './insufficient-points-text';
import LoyaltyProgressText from './loyalty-progress-text';
import './index.css';

type Points = {
  earn_total: number;
  spend_total: number;
  partial_spend: {
    percentage: number;
  };
};

type LoyaltyPointsMessagingProps = {
  disabled: string;
  earningPoints: boolean;
  isMobile: boolean;
  points: Points;
  remainingVehicleCost: React.ReactNode;
  showCostAfterPoints: boolean;
}

const LoyaltyPointsMessaging = (props: LoyaltyPointsMessagingProps) => {
  const {
    disabled,
    earningPoints,
    isMobile,
    points,
    remainingVehicleCost,
    showCostAfterPoints,
  } = props;

  const sufficientPoints = () => {
    let enoughPoints = true;

    if (points && !earningPoints) {
      enoughPoints = points.partial_spend.percentage >= 50;
    }

    return enoughPoints;
  };

  const pointsMessaging = sufficientPoints() ? (
    <CostAfterPoints
      disabled={disabled}
      isMobile={isMobile}
      showCostAfterPoints={showCostAfterPoints}
      vehicleCost={remainingVehicleCost}
    />
  ) : <InsufficientPointsText isMobile={isMobile} />;

  return (
    <div className="loyalty-points-messaging">
      <LoyaltyProgressText
        earningPoints={earningPoints}
        isMobile={isMobile}
        points={points}
      />
      {pointsMessaging}
    </div>
  );
};

export default LoyaltyPointsMessaging;
