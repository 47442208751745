// @flow
import { Text } from '@audi/audi-ui-react';
import React from 'react';

import './index.css';

type Points = {
  earn_total: number,
  spend_total: number,
  partial_spend: {
    percentage: number,
  }
}

type LoyaltyProgressTextProps = {
  earningPoints: boolean;
  isMobile: boolean;
  points: Points;
}

const LoyaltyProgressText = (props: LoyaltyProgressTextProps) => {
  const { earningPoints, isMobile, points } = props;

  if (earningPoints || !points) {
    return null;
  }

  const { partial_spend: { percentage } } = points;

  let insufficientPoints = false;
  if (percentage < 50) {
    insufficientPoints = true;
  }

  const message = insufficientPoints
    ? 'Your points cover less than 50% of this booking'
    : `Your points cover ${percentage}% of this booking`;

  return (
    <div className="loyalty-progress-text">
      <Text
        as="p"
        className={insufficientPoints ? 'insufficient-points-text' : ''}
        variant={isMobile ? 'copy3' : 'copy2'}
      >
        {message}
      </Text>
    </div>
  );
};

export default LoyaltyProgressText;
