// @flow
import { Text } from '@audi/audi-ui-react';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { toggleDeleteBookingModalVisible, deleteBooking, deleteBookingByToken } from '../../../actions/booking';
import SCDialog from '../../sc-dialog';

type Props = {
  toggleDeleteBookingModalVisible: Function,
  deleteBookingModalVisible: boolean,
  deleteBooking: Function,
  deleteBookingByToken: Function,
  confirmation_token: number,
  id: number,
  tempAuthToken: string,
  total: number,
}

export const DeleteBookingModal = (props: Props) => {
  const [deleted, updateDeleted] = useState(false);

  const {
    id,
    total,
    tempAuthToken,
    deleteBookingByToken,
    deleteBooking,
    deleteBookingModalVisible,
    toggleDeleteBookingModalVisible,
    confirmation_token,
  } = props;

  const handleDelete = () => {
    if (!deleted) {
      updateDeleted(true);
      if (tempAuthToken) {
        deleteBookingByToken(confirmation_token, tempAuthToken, total);
      } else {
        deleteBooking(id, confirmation_token, total);
      }
    }
  };

  return (
    <SCDialog
      leftButtonVariant="outlined"
      leftButtonText="No"
      onClose={toggleDeleteBookingModalVisible}
      onLeftButtonClicked={toggleDeleteBookingModalVisible}
      onRightButtonClicked={handleDelete}
      open={deleteBookingModalVisible}
      rightButtonText="Yes, cancel"
      showCloseButton
      showLeftButton
      showRightButton
      title="Cancel reservation"
    >
      <Text variant="copy1" as="p">
        Are you sure you would like to cancel this reservation?
      </Text>
    </SCDialog>
  );
};

export const mapStateToProps = state => ({
  deleteBookingModalVisible: state.bookingReducer.deleteBookingModalVisible,
});

export const mapDispatchToProps = dispatch => ({
  toggleDeleteBookingModalVisible: () => dispatch(toggleDeleteBookingModalVisible()),
  deleteBookingByToken: (
    confirmation_token,
    tempAuthToken,
    total,
  ) => dispatch(deleteBookingByToken(confirmation_token, tempAuthToken, total)),
  deleteBooking: (
    id,
    confirmation_token,
    total,
  ) => dispatch(deleteBooking(id, confirmation_token, total)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DeleteBookingModal);
