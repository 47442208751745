// @flow
import { Button, Layout } from '@audi/audi-ui-react';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Box } from '@material-ui/core';
import {
  getCoverageOptions,
  getCoveragePricing,
  setCoverage,
  setCoverageOptions,
} from '../../../../actions/booking/modify';
import history from '../../../../history';
import theme from '../../../../theme';
import { COLORS } from '../../../../theme/constants';
import CoverageOptionsModal from '../../../modals/coverage-options';
import Loading from '../../../loading';
import SelectCoverage from '../../../coverage-select';

import './index.css';
import { getFleetById } from '../../../../actions/booking';

type State = {
  autoOnlyState: boolean,
  isDirty: boolean,
};

type Props = {
  booking: Object,
  coverage: Array<Object>,
  coverageLoading: boolean,
  getCoverageOptions: () => void,
  getCoveragePricing: () => void,
  location: Object,
  modifiedBooking: Object,
  setCoverage: () => void,
  setCoverageOptions: () => void,
  fleets: Object,
  getFleetById: (number) => void,
};

const containerStyles = {
  width: '100%',
  mx: 'auto',
  px: 1,
  pb: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  style: {
    boxSizing: 'border-box',
  },
};

export class ReservationEditCoverage extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      autoOnlyState: false,
      isDirty: false,
    };
  }

  handleSubmit = async () => {
    const { booking, getCoveragePricing } = this.props;

    const {
      coverageSelection: { coverageOptions, coverageType },
      pricing,
      id,
    } = booking;

    await getCoveragePricing({
      id,
      pricing,
      coverages: coverageOptions,
      coverageType,
    });
  };

  handleUpdateCoverageType = (coverage) => {
    const { booking: { coverageSelection }, setCoverage } = this.props;
    const { coverageType, carrier, policy_number } = coverageSelection;

    if (coverageType !== coverage.coverageType
      || carrier !== coverage.carrier
      || policy_number !== coverage.policyNumber) {
      const isDirty = coverage.carrier && coverage.policyNumber;
      this.setState({ isDirty }, () => setCoverage({
        ...coverage, policy_number: coverage.policyNumber,
      }));
    }
  };

  UNSAFE_componentWillReceiveProps(props: Object) {
    const { location } = props;

    const pickupState = location.address ? location.address.state : null;
    const isAutoOnlyState = ['MA', 'NY', 'VA'].includes(pickupState);

    this.setState({
      autoOnlyState: isAutoOnlyState,
    });
  }

  UNSAFE_componentWillMount() {
    const {
      getCoverageOptions,
      booking: { pickupDate },
      modifiedBooking,
      getFleetById,
    } = this.props;
    // Get Coverage from the endpoint
    getCoverageOptions(modifiedBooking.selectedFleet, pickupDate);
    getFleetById(modifiedBooking.selectedFleet);
  }

  render() {
    const {
      booking: { coverageSelection },
      coverage,
      coverageLoading,
      modifiedBooking,
      fleets,
      setCoverageOptions,
    } = this.props;
    const { autoOnlyState, isDirty } = this.state;

    const isMobile = window.innerWidth < theme.breakpoints.values.sm;

    // duration is not an available prop inside the modifiedBooking
    // state therefore it is necessary to calculate it here
    const { dropoffTime, pickupTime } = modifiedBooking;
    const duration = moment
      .duration(moment(dropoffTime).diff(moment(pickupTime)))
      .days();

    const activeCoverage = {
      ...coverageSelection,
      policyNumber: coverageSelection.policy_number,
    };

    const fleet = fleets && modifiedBooking ? fleets[modifiedBooking.selectedFleet] : {};
    const vehicleKey = fleet && fleet.vehicle && fleet.vehicle.model ? fleet.vehicle.model : '';

    return (
      <Box
        className="modify-coverage-container"
        height="100%"
        bgcolor={COLORS.WHITE}
      >
        {coverageLoading ? (
          <Loading />
        ) : (
          <Box {...containerStyles}>
            <CoverageOptionsModal />
            <Box width="100%">
              <SelectCoverage
                autoOnlyState={autoOnlyState}
                duration={duration}
                handleUpdateCoverageType={this.handleUpdateCoverageType}
                updateCoverageOptions={(NewCoverage) => {
                  this.setState({ isDirty: true });
                  setCoverageOptions(NewCoverage);
                }
                }
                activeCoverage={activeCoverage}
                coverages={coverage}
                vehicleKey={vehicleKey}
              />
              <Layout
                className="button-container"
                align={isMobile ? 'baseline' : 'end'}
                justify={isMobile ? 'center' : 'end'}
              >
                <Button
                  className="modify-coverage-continue-button"
                  variant="primary"
                  name="submit"
                  disabled={!isDirty}
                  onClick={this.handleSubmit}
                >
                  Continue
                </Button>
                <Button
                  className="modify-coverage-cancel-button"
                  variant="secondary"
                  name="cancel"
                  onClick={() => history.goBack()}
                >
                  Cancel
                </Button>
              </Layout>
            </Box>
          </Box>
        )}
      </Box>
    );
  }
}

export const mapStateToProps = state => ({
  coverage: state.bookingReducer.modifyBooking.coverage,
  coverageLoading: state.bookingReducer.modifyBooking.coverageLoading,
  modifiedBooking: state.bookingReducer.modifyBooking.modifiedBooking,
  fleets: state.bookingReducer.fleets,
});

export const mapDispatchToProps = dispatch => ({
  getCoverageOptions: (fleetId, pickup) => dispatch(getCoverageOptions(fleetId, pickup)),
  getCoveragePricing: pricing => dispatch(getCoveragePricing(pricing)),
  setCoverage: coverage => dispatch(setCoverage(coverage)),
  setCoverageOptions: options => dispatch(setCoverageOptions(options)),
  getFleetById: id => dispatch(getFleetById(id)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ReservationEditCoverage);
