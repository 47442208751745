// @flow
import { COLORS } from '../constants';

const MuiPaper = {
  root: {
    color: COLORS.BLACK,
  },
};

export default MuiPaper;
