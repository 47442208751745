// @flow
import { Checkbox, CheckboxProps } from '@audi/audi-ui-react';
import React from 'react';
import './index.css';

interface CheckboxOptionalProps extends CheckboxProps {
  customClassName?: string;
}

const CheckboxOptional: React.FC<CheckboxOptionalProps> = (props: CheckboxOptionalProps) => {
  const {
    children, customClassName, inputId, checked,
  } = props;
  const classNames = customClassName ? `${customClassName} soft-optional` : 'soft-optional';

  return (
    <Checkbox
      className={classNames}
      data-testid={inputId}
      aria-checked={checked}
      {...props}
    >
      {children}
    </Checkbox>
  );
};

export default CheckboxOptional;
