// @flow
import { initialState as modifyBookingInitialState, modifyBookingReducer } from './modify';
import * as ModalTypes from '../../actions/modal/constants';
import * as BookingTypes from '../../actions/booking/constants';
import * as BookingModifyTypes from '../../actions/booking/modify/constants';

export type State = {
  activeBooking: Object,
  audiOwnerProgramModalVisible: boolean,
  availability: Object,
  bookings: Object,
  coverage: Array<Object>,
  coverageWarningModalVisible: boolean,
  costSummaryModalVisible: boolean,
  estimatedTotalModalVisible: boolean,
  reservationReviewModalVisible: boolean,
  deleteBookingModalVisible: boolean,
  httpResponseError: boolean,
  httpResponseStatusMessage: string,
  isLoading: boolean,
  joinReserveBooking: null,
  joinReserveModalVisible: boolean,
  locations: Object,
  loginModalVisible: boolean,
  partnerLocationModalVisible: boolean,
  loyalty: Object,
  loyaltyInfoModalVisible: boolean,
  modifyBooking: Object,
  rentals: Object,
  spentLoyaltyPointsModalVisible: boolean,
  stagedBooking: Object,
  texasWaiverModalVisible: boolean,
  thirdPartyBooking: Object,
  thirdPartyConfirmationNumber: string,
}

export const initialState: State = {
  activeBooking: {
    coverage: {
      type: '',
      coverageType: '',
      coverageOptions: [],
      policyNumber: '',
    },
    dropoffDate: '',
    dropoffTime: '',
    pickupLocation: {},
    pickupDate: '',
    pickupTime: '',
    vehicle: {
      fleet_id: 0,
    },
    promo: {
      id: '',
      promo_code: '',
    },
    selectedFleetPricing: [],
    isProcessing: false,
    curbsideDetails: null,
  },
  availability: {},
  audiOwnerProgramModalVisible: false,
  bookings: {
    isLoading: false,
    page: 0,
    results: [],
  },
  costSummaryModalVisible: false,
  estimatedTotalModalVisible: false,
  reservationReviewModalVisible: false,
  coverage: [],
  fleetAvailability: null,
  fleetPricing: [],
  fleets: {},
  upcomingRentalFleets: [],
  joinReserveBooking: null,
  joinReserveModalVisible: false,
  httpResponseError: false,
  httpResponseStatusMessage: '',
  isLoading: false,
  isValidPromoCode: false,
  locations: {
    isLoading: false,
    page: 0,
    mergedLocations: [],
    locationMap: new Map(),
    noLocationsFound: false,
    results: [],
  },
  loginModalVisible: false,
  partnerLocationModalVisible: false,
  rentals: {
    isLoading: false,
    page: 0,
    results: [],
  },
  loyaltyInfoModalVisible: false,
  loyalty: {
    earningPoints: true,
  },
  stagedBooking: {
    staged: false,
    fleetId: 0,
    payload: {
      pickup_at: '',
      dropoff_at: '',
      coverages: [],
      promo: null,
    },
  },
  spentLoyaltyPointsModalVisible: false,
  texasWaiverModalVisible: false,
  coverageWarningModalVisible: false,
  thirdPartyBooking: {
    id: 0,
  },
  thirdPartyConfirmationNumber: '',
  deleteBookingModalVisible: false,
  modifyBooking: modifyBookingInitialState,
};

function bookingReducer(state: State = initialState, action: Object) {
  switch (action.type) {
    // COMMON ERROR HANDLING
    case BookingTypes.DELETE_BOOKING_ERROR:
    case BookingTypes.CREATE_USER_POST_BOOKING_ERROR:
    case BookingTypes.GET_FLEET_ERROR:
    case BookingTypes.GET_FLEET_PRICING_ERROR:
    case BookingTypes.GET_LOCATIONS_FLEET_AVAILABILITY_ERROR:
    case BookingTypes.PUT_GDS_ERROR:
    case BookingTypes.CREATE_BOOKING_ERROR:
    case ModalTypes.CREATE_CURBSIDE_REQUEST_ERROR:
    case BookingTypes.CLAIM_BOOKING_POST_SIGNUP_ERROR:
    case BookingTypes.GET_UPCOMING_FLEETS_ERROR:
      return {
        ...state,
        httpResponseError: true,
        isLoading: false,
        httpResponseStatusMessage: action.payload,
      };
    // GET ACTIONS
    case BookingTypes.DELETE_BOOKING_SUCCESS:
      return {
        ...state,
        httpResponseError: false,
        httpResponseStatusMessage: 'Your reservation has been cancelled.',
        deleteBookingModalVisible: initialState.deleteBookingModalVisible,
        joinReserveBooking: initialState.joinReserveBooking,
        activeBooking: initialState.activeBooking,
        joinReserveModalVisible: initialState.joinReserveModalVisible,
      };
    case BookingTypes.GET_BOOKINGS:
      return {
        ...state,
        bookings: {
          ...initialState.bookings,
          isLoading: true,
        },
      };
    case BookingTypes.GET_BOOKINGS_ERROR:
      return {
        ...state,
        bookings: {
          ...initialState.rentals,
          isLoading: false,
        },
        httpResponseError: true,
        httpResponseStatusMessage: action.payload,
      };
    case BookingTypes.GET_BOOKINGS_SUCCESS:
      return {
        ...state,
        bookings: {
          ...state.bookings,
          ...action.payload,
          isLoading: false,
        },
        activeBooking: {
          ...state.activeBooking,
          isProcessing: false,
        },
      };
    case BookingTypes.TOGGLE_PROCESSING:
      return {
        ...state,
        activeBooking: {
          ...state.activeBooking,
          isProcessing: true,
        },
      };
    case BookingTypes.TOGGLE_EARN_OR_SPEND_POINTS:
      return {
        ...state,
        loyalty: {
          ...state.loyalty,
          earningPoints: !state.loyalty.earningPoints,
        },
      };
    case BookingTypes.RESET_EARNING_POINTS:
      return {
        ...state,
        loyalty: {
          ...state.loyalty,
          earningPoints: initialState.loyalty.earningPoints,
        },
      };
    case BookingTypes.GET_PROMO_ERROR:
      return {
        ...state,
        isValidPromoCode: false,
        httpResponseError: true,
        httpResponseStatusMessage: action.payload,
      };
    case BookingTypes.GET_PROMO_SUCCESS:
      return {
        ...state,
        httpResponseStatusMessage: 'Promo code successfully redeemed',
        isValidPromoCode: true,
      };
    case BookingTypes.GET_COVERAGE_OPTIONS_ERROR:
      return {
        ...state,
        coverage: [],
        httpResponseError: true,
        httpResponseStatusMessage: action.payload,
      };
    case BookingTypes.GET_COVERAGE_OPTIONS_SUCCESS:
      return {
        ...state,
        coverage: action.payload,
        httpResponseError: false,
        httpResponseStatusMessage: '',
      };
    case BookingTypes.GET_FLEET_SUCCESS:
      return {
        ...state,
        fleets: {
          ...state.fleets,
          [action.payload.id]: action.payload,
        },
      };
    case BookingTypes.GET_CURBSIDE_DETAIL_SUCCESS:
      return {
        ...state,
        activeBooking: {
          ...state.activeBooking,
          curbsideDetails: action.payload,
        },
        httpResponseError: false,
        httpResponseStatusMessage: '',
      };
    case BookingTypes.GET_CURBSIDE_DETAIL_ERROR:
      return {
        ...state,
        activeBooking: {
          ...state.activeBooking,
          curbsideDetails: null,
        },
        httpResponseError: true,
        httpResponseStatusMessage: action.payload,
        isLoading: false,
      };
    case BookingTypes.GET_UPCOMING_FLEETS_SUCCESS:
      return {
        ...state,
        upcomingRentalFleets: [...(state.upcomingRentalFleets || []), action.payload],
      };
    case BookingTypes.GET_FLEET_PRICING_SUCCESS:
      return {
        ...state,
        fleetPricing: action.payload,
        isLoading: false,
        activeBooking: {
          ...state.activeBooking,
          isProcessing: false,
        },
      };
    case BookingTypes.SET_SELECTED_FLEET_PRICING:
      return {
        ...state,
        activeBooking: {
          ...state.activeBooking,
          selectedFleetPricing: action.payload,
        },
      };
    case BookingTypes.GET_LOCATIONS_SUCCESS:
      return {
        ...state,
        locations: {
          ...state.locations,
          ...action.payload,
          noLocationsFound: false,
        },
      };
    case BookingTypes.GET_LOCATIONS_AVAILABILITY_SUCCESS:
      return {
        ...state,
        availability: action.payload,
        httpResponseError: false,
        httpResponseStatusMessage: '',
        isLoading: false,
      };
    case BookingTypes.GET_LOCATIONS_AVAILABILITY_ERROR:
      return {
        ...state,
        availability: {},
        httpResponseError: true,
        httpResponseStatusMessage: action.payload,
        isLoading: false,
      };
    case BookingTypes.GET_LOCATIONS_FLEET_AVAILABILITY_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case BookingTypes.GET_LOCATIONS_FLEET_AVAILABILITY_SUCCESS:
      return {
        ...state,
        // reset fleetPricing so they're re-populated when the
        // individual fleet pricing successes are fired
        fleetPricing: [],
        fleetAvailability: action.payload,
      };
    case BookingTypes.GET_RENTALS_ERROR:
      return {
        ...state,
        rentals: {
          ...initialState.rentals,
        },
        httpResponseError: true,
        httpResponseStatusMessage: action.payload,
      };
    case BookingTypes.GET_RENTALS_SUCCESS:
      return {
        ...state,
        rentals: {
          ...state.rentals,
          ...action.payload,
          isLoading: false,
        },
      };
    case BookingTypes.HIDE_MODALS:
      return {
        ...state,
        loginModalVisible: false,
        partnerLocationModalVisible: false,
        texasWaiverModalVisible: false,
        coverageWarningModalVisible: false,
        costSummaryModalVisible: false,
        estimatedTotalModalVisible: false,
        reservationReviewModalVisible: false,
        deleteBookingModalVisible: false,
      };

    // COMMON SUCCESS
    case BookingTypes.PUT_GDS_SUCCESS:
    case BookingTypes.CLAIM_BOOKING_POST_SIGNUP_SUCCESS:
    case BookingTypes.CREATE_BOOKING_SUCCESS:
    case ModalTypes.CREATE_CURBSIDE_REQUEST_SUCCESS:
      return {
        ...state,
        isValidPromoCode: false,
        httpResponseError: false,
        httpResponseStatusMessage: action.payload,
        thirdPartyBooking: {
          ...initialState.thirdPartyBooking,
        },
        thirdPartyConfirmationNumber: initialState.thirdPartyConfirmationNumber,
      };

    // SEARCH ACTIONS
    case BookingTypes.SEARCH_GDS_SUCCESS:
      return {
        ...state,
        httpResponseError: false,
        httpResponseStatusMessage: 'Your confirmation number has been validated. Click apply to preview your booking.',
        thirdPartyBooking: action.payload.booking,
        thirdPartyConfirmationNumber: action.payload.confirmationNumber,
      };
    case BookingTypes.SEARCH_GDS_ERROR:
      return {
        ...state,
        httpResponseError: true,
        httpResponseStatusMessage: action.payload,
        thirdPartyBooking: {
          ...initialState.thirdPartyBooking,
        },
      };

    case BookingTypes.SEARCH_LOCATIONS_SUCCESS:
      return {
        ...state,
        locations: {
          ...state.locations,
          mergedLocations: action.payload,
          noLocationsFound: !action.payload.length,
        },
      };
    // SET ACTIONS
    case BookingTypes.SET_JOIN_RESERVE_BOOKING_SUCCESS:
      return {
        ...state,
        joinReserveBooking: action.payload,
      };
    case BookingTypes.TOGGLE_LOGIN_MODAL_VISIBLE:
      return {
        ...state,
        loginModalVisible: !state.loginModalVisible,
      };
    case BookingTypes.TOGGLE_PARTNER_LOCATION_MODAL_VISIBLE:
      return {
        ...state,
        partnerLocationModalVisible: !state.partnerLocationModalVisible,
      };
    case BookingTypes.TOGGLE_JOIN_RESERVE_MODAL_VISIBLE:
      return {
        ...state,
        joinReserveModalVisible: !state.joinReserveModalVisible,
      };
    case BookingTypes.SET_COVERAGE:
      return {
        ...state,
        activeBooking: {
          ...state.activeBooking,
          coverage: {
            ...state.activeBooking.coverage,
            coverageOptions: initialState.activeBooking.coverage.coverageOptions,
            ...action.payload,
          },
        },
      };
    case BookingTypes.SET_COVERAGE_OPTIONS:
      return {
        ...state,
        activeBooking: {
          ...state.activeBooking,
          coverage: {
            ...state.activeBooking.coverage,
            coverageOptions: [...action.payload],
          },
        },
      };
    case BookingTypes.SET_PROMO:
      return {
        ...state,
        activeBooking: {
          ...state.activeBooking,
          promo: action.payload,
        },
      };
    case BookingTypes.SET_PROMO_CODE:
      return {
        ...state,
        activeBooking: {
          ...state.activeBooking,
          promo: {
            ...state.activeBooking.promo,
            promo_code: action.payload,
          },
        },
      };
    case BookingTypes.REMOVE_PROMO:
      return {
        ...state,
        isValidPromoCode: false,
        httpResponseError: false,
        httpResponseStatusMessage: 'Your promo code has been removed',
        activeBooking: {
          ...state.activeBooking,
          promo: initialState.activeBooking.promo,
        },
      };
    case BookingTypes.SET_DROPOFF_DATE:
      return {
        ...state,
        activeBooking: {
          ...state.activeBooking,
          dropoffDate: action.payload,
          pickupTime: initialState.activeBooking.pickupTime,
          dropoffTime: initialState.activeBooking.dropoffTime,
          coverage: {
            ...initialState.activeBooking.coverage,
          },
          vehicle: {
            ...initialState.activeBooking.vehicle,
          },
        },
      };
    case BookingTypes.SET_PICKUP_DATE:
      return {
        ...state,
        activeBooking: {
          ...state.activeBooking,
          pickupDate: action.payload,
          pickupTime: initialState.activeBooking.pickupTime,
          dropoffDate: initialState.activeBooking.dropoffDate,
          dropoffTime: initialState.activeBooking.dropoffTime,
          coverage: {
            ...initialState.activeBooking.coverage,
          },
          selectedFleetPricing: initialState.activeBooking.selectedFleetPricing,
          vehicle: {
            ...initialState.activeBooking.vehicle,
          },
        },
      };
    case BookingTypes.SET_PICKUP_TIME:
      return {
        ...state,
        activeBooking: {
          ...state.activeBooking,
          pickupTime: action.payload,
        },
      };
    case BookingTypes.SET_DROPOFF_TIME:
      return {
        ...state,
        activeBooking: {
          ...state.activeBooking,
          dropoffTime: action.payload,
        },
      };
    case BookingTypes.SET_LOCATION_MAP:
      return {
        ...state,
        locations: {
          ...state.locations,
          locationMap: action.payload,
        },
      };
    case BookingTypes.SET_PICKUP_LOCATION:
      return {
        ...initialState,
        locations: state.locations,
        bookings: state.bookings,
        activeBooking: {
          ...initialState.activeBooking,
          pickupLocation: action.payload,
          promo: state.activeBooking.promo,
          selectedFleetPricing: initialState.activeBooking.selectedFleetPricing,
        },
      };
    case BookingTypes.SET_LOCATIONS:
      return {
        ...state,
        locations: {
          ...state.locations,
          mergedLocations: action.payload,
        },
      };
    case BookingTypes.SET_STAGED_BOOKING:
      return {
        ...state,
        stagedBooking: {
          ...action.payload,
          staged: true,
        },
      };
    case BookingTypes.SET_VEHICLE:
      return {
        ...state,
        activeBooking: {
          ...state.activeBooking,
          vehicle: action.payload,
          coverage: initialState.activeBooking.coverage,
        },
      };
    case BookingTypes.FULL_PRICE_BREAKDOWN:
      return {
        ...state,
      };
    case BookingTypes.CLEAR_STAGED_BOOKING:
      return {
        ...state,
        stagedBooking: {
          ...initialState.stagedBooking,
        },
      };
    case BookingTypes.TOGGLE_SPENT_LOYALTY_POINTS_MODAL_VISIBLE:
      return {
        ...state,
        spentLoyaltyPointsModalVisible: !state.spentLoyaltyPointsModalVisible,
      };
    case BookingTypes.TOGGLE_TEXAS_WAIVER_MODAL_VISIBLE:
      return {
        ...state,
        texasWaiverModalVisible: !state.texasWaiverModalVisible,
      };
    case BookingTypes.TOGGLE_COVERAGE_WARNING_MODAL_VISIBLE:
      return {
        ...state,
        coverageWarningModalVisible: !state.coverageWarningModalVisible,
      };
    case BookingTypes.TOGGLE_COST_SUMMARY_MODAL_VISIBLE:
      return {
        ...state,
        costSummaryModalVisible: !state.costSummaryModalVisible,
      };
    case BookingTypes.TOGGLE_ESTIMATED_TOTAL_MODAL_VISIBLE:
      return {
        ...state,
        estimatedTotalModalVisible: !state.estimatedTotalModalVisible,
      };
    case BookingTypes.TOGGLE_RESERVATION_REVIEW_MODAL_VISIBLE:
      return {
        ...state,
        reservationReviewModalVisible: !state.reservationReviewModalVisible,
      };
    case BookingTypes.TOGGLE_LOYALTY_INFO_MODAL:
      return {
        ...state,
        loyaltyInfoModalVisible: !state.loyaltyInfoModalVisible,
      };
    case BookingTypes.TOGGLE_AUDI_OWNER_PROGRAM_MODAL:
      return {
        ...state,
        audiOwnerProgramModalVisible: !state.audiOwnerProgramModalVisible,
      };
    case BookingTypes.TOGGLE_DELETE_BOOKING_MODAL_VISIBLE:
      return {
        ...state,
        deleteBookingModalVisible: !state.deleteBookingModalVisible,
      };
    case BookingModifyTypes.MODIFY_BOOKING:
      return {
        ...state,
        modifyBooking: modifyBookingReducer(state.modifyBooking, action),
      };
    // ETC ACTIONS
    case BookingTypes.RESET_AVAILABILITY_AND_PRICING:
      return {
        ...state,
        fleetAvailability: initialState.fleetAvailability,
        fleetPricing: initialState.fleetPricing,
      };
    case BookingTypes.NETWORK_RESET:
      return {
        ...state,
        httpResponseError: false,
        httpResponseStatusMessage: '',
      };
    case BookingTypes.RESET_BOOKING:
      return {
        ...state,
        activeBooking: {
          ...initialState.activeBooking,
        },
        joinReserveModalVisible: initialState.joinReserveModalVisible,
        texasWaiverModalVisible: initialState.texasWaiverModalVisible,
        coverageWarningModalVisible: initialState.coverageWarningModalVisible,
      };
    case BookingTypes.RESET_GDS:
      return {
        ...state,
        thirdPartyBooking: initialState.thirdPartyBooking,
        thirdPartyConfirmationNumber: initialState.thirdPartyConfirmationNumber,
      };
    default:
      return state;
  }
}

export default bookingReducer;
