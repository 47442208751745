// @flow
import React from 'react';
import { connect } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { Box } from '@material-ui/core';
import { getHomeUrl } from '../../utils/http/config';
import { trackBookingStart } from '../../actions/booking';
import { toggleSideNav } from '../../actions';
import AudiLogo from '../../assets/images/icons/ic-audi.svg';
import AudiText from '../nav-header/audi-text';
import { MOBILE_HEADER_HEIGHT } from '../../theme/constants';

type Props = {
  toggleSideNav: Function,
}

export function NavMobile(props: Props) {
  const {
    toggleSideNav,
  } = props;

  return (
    <Box
      style={{
        display: 'flex',
        padding: '1.1rem 1.25rem',
        boxSizing: 'border-box',
        justifyContent: 'space-between',
        height: MOBILE_HEADER_HEIGHT,
      }}
    >
      <Box
        style={{
          display: 'flex',
        }}
      >
        <a
          href={getHomeUrl()}
          style={{
            alignSelf: 'flex-end',
          }}
        >
          <img
            src={AudiLogo}
            alt="Audi"
            style={{
              height: '1.5rem',
            }}
          />
        </a>
        <span style={{ position: 'relative', left: '5%' }}><AudiText /></span>
      </Box>
      <Box>
        <IconButton
          className="hamburger-icon"
          onClick={toggleSideNav}
          aria-label="menu"
        >
          <MenuIcon
            className="menu-icon"
          />
        </IconButton>
      </Box>
    </Box>
  );
}

export const mapDispatchToProps = dispatch => ({
  toggleSideNav: () => dispatch(toggleSideNav()),
  trackBookingStart: () => dispatch(trackBookingStart()),
});

export default connect(null, mapDispatchToProps)(NavMobile);
