// @flow
import { Button, Text } from '@audi/audi-ui-react';
import React, { Component } from 'react';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { connect } from 'react-redux';
import {
  createCurbsideRequest,
  trackDeliveryRequestSubmitted,
  trackDeliveryRequestCancelled,
} from '../../../actions/booking';
import { openIncompleteProfileModal } from '../../../actions/modal';
import CheckboxOptional from '../../checkbox-optional';

import './index.css';

type State = {
  deliveryAddress: string,
  deliveryZipCode: string,
  deliveryInstructions: string,
  retrievalAddress: string,
  retrievalZipCode: string,
  retrievalInstructions: string,
  sameRetrievalAddress: boolean,
};

type Props = {
  createCurbsideRequest: (string, Object) => void,
  trackDeliveryRequestSubmitted: () => void,
  trackDeliveryRequestCancelled: () => void,
  incompleteProfileHasBeenPrompted: boolean,
  openIncompleteProfileModal: () => void,
  history: {
    goBack: () => void;
  },
  match: {
    params: {
      token: string,
    },
  }
};

export class CurbsideRequestForm extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      deliveryAddress: '',
      deliveryZipCode: '',
      deliveryInstructions: '',
      retrievalAddress: '',
      retrievalZipCode: '',
      retrievalInstructions: '',
      sameRetrievalAddress: true,
    };
  }

  handleCancel = () => {
    const {
      trackDeliveryRequestCancelled,
      openIncompleteProfileModal,
      incompleteProfileHasBeenPrompted,
      history: {
        goBack,
      },
    } = this.props;
    trackDeliveryRequestCancelled();
    goBack();
    if (incompleteProfileHasBeenPrompted) {
      openIncompleteProfileModal();
    }
  }

  handleCancelKeydown = (event) => {
    if (event.which === 13) {
      const {
        trackDeliveryRequestCancelled,
        openIncompleteProfileModal,
        incompleteProfileHasBeenPrompted,
        history: {
          goBack,
        },
      } = this.props;
      trackDeliveryRequestCancelled();
      goBack();
      if (incompleteProfileHasBeenPrompted) {
        openIncompleteProfileModal();
      }
    }
  }

  handleToggle = () => {
    const { sameRetrievalAddress } = this.state;
    this.setState({ sameRetrievalAddress: !sameRetrievalAddress });
  }

  submitForm = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const {
      match: {
        params: {
          token,
        },
      },
      createCurbsideRequest,
      trackDeliveryRequestSubmitted,
      openIncompleteProfileModal,
      incompleteProfileHasBeenPrompted,
    } = this.props;
    const {
      deliveryAddress,
      deliveryZipCode,
      deliveryInstructions,
      retrievalAddress,
      retrievalZipCode,
      retrievalInstructions,
      sameRetrievalAddress,
    } = this.state;

    const fullDeliveryAddress = `${deliveryAddress}, ${deliveryZipCode}`;

    const payload = {
      delivery_address: fullDeliveryAddress,
      delivery_instructions: deliveryInstructions,
      retrieval_address: sameRetrievalAddress ? fullDeliveryAddress : `${retrievalAddress}, ${retrievalZipCode}`,
      retrieval_instructions: retrievalInstructions,
    };
    createCurbsideRequest(token, payload);
    trackDeliveryRequestSubmitted();
    if (incompleteProfileHasBeenPrompted) {
      openIncompleteProfileModal();
    }
  }

  render() {
    const {
      deliveryAddress,
      deliveryZipCode,
      deliveryInstructions,
      retrievalAddress,
      retrievalZipCode,
      retrievalInstructions,
      sameRetrievalAddress,
    } = this.state;

    return (
      <div className="sc-form">
        <section>
          <header className="curbside">
            <Text as="h2" variant="order2" weight="bold">Request Delivery & Pick Up</Text>
          </header>
          <section>
            <ValidatorForm ref={this.form} onSubmit={this.submitForm} instantValidate>
              <Text as="h3" variant="order4" weight="bold">Delivery Address</Text>
              <TextValidator
                name="deliveryAddress"
                id="delivery-address"
                onChange={e => this.setState({ deliveryAddress: e.target.value })}
                value={deliveryAddress}
                validators={['required']}
                errorMessages={['Address is required.']}
                label="Street Address"
              />
              <TextValidator
                name="deliveryZipCode"
                id="delivery-zip-code"
                onChange={e => this.setState({ deliveryZipCode: e.target.value })}
                value={deliveryZipCode}
                validators={['required']}
                errorMessages={['Zip code is required.']}
                label="Zip Code"
              />
              <TextValidator
                name="deliveryInstructions"
                id="delivery-instructions"
                onChange={e => this.setState({ deliveryInstructions: e.target.value })}
                value={deliveryInstructions}
                label="Delivery Instructions (Optional)"
              />
              <Text as="h3" variant="order4" weight="bold">Pick Up Address</Text>
              <CheckboxOptional checked={sameRetrievalAddress} onChange={this.handleToggle}>
                 Same as delivery address
              </CheckboxOptional>
              {!sameRetrievalAddress && (
                <div>
                  <TextValidator
                    fullWidth
                    name="retrievalAddress"
                    id="retrieval-address"
                    onChange={e => this.setState({ retrievalAddress: e.target.value })}
                    value={retrievalAddress}
                    validators={['required']}
                    errorMessages={['Address is required.']}
                    label="Street Address"
                  />
                  <TextValidator
                    fullWidth
                    name="retrievalZipCode"
                    id="retrieval-zip-code"
                    onChange={e => this.setState({ retrievalZipCode: e.target.value })}
                    value={retrievalZipCode}
                    validators={['required']}
                    errorMessages={['Zip code is required.']}
                    label="Zip Code"
                  />
                </div>
              )}
              <TextValidator
                name="retrievalInstructions"
                id="retrieval-instructions"
                onChange={e => this.setState({ retrievalInstructions: e.target.value })}
                value={retrievalInstructions}
                label="Pick Up Instructions (Optional)"
              />
              <div className="button-group">
                <Button
                  name="submit"
                  type="submit"
                  variant="primary"
                >
                  Submit Request
                </Button>
                <br />
                <Button
                  name="cancel"
                  onClick={this.handleCancel}
                  variant="secondary"
                >
                  Cancel Request
                </Button>
              </div>
            </ValidatorForm>
          </section>
        </section>
      </div>
    );
  }
}

export const mapStateToProps = state => ({
  incompleteProfileHasBeenPrompted: state.modalReducer.incompleteProfileHasBeenPrompted,
});

export const mapDispatchToProps = dispatch => ({
  trackDeliveryRequestSubmitted: () => dispatch(trackDeliveryRequestSubmitted()),
  trackDeliveryRequestCancelled: () => dispatch(trackDeliveryRequestCancelled()),
  createCurbsideRequest: (token, payload) => dispatch(createCurbsideRequest(token, payload)),
  openIncompleteProfileModal: () => dispatch(openIncompleteProfileModal()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CurbsideRequestForm);
