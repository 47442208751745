// @flow
import React from 'react';
import { Text } from '@audi/audi-ui-react';
import './index.css';

type NumberDisplayProps = {
  centVariant?: string;
  customClassName?: string;
  dollarVariant?: string;
  value: string;
}

const NumberDisplay = (props: NumberDisplayProps) => {
  const {
    centVariant,
    customClassName,
    dollarVariant,
    value,
  } = props;
  let dollars;
  let cents;
  if (value) {
    const formattedValue = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);
    [dollars, cents] = formattedValue.split('.');
  }

  const className = customClassName || 'number-display';

  return (
    <div className="number-display-container">
      <Text
        as="h1"
        className={className}
        variant={dollarVariant || 'order1'}
        weight="bold"
      >
        {dollars}
      </Text>
      <Text
        as="span"
        className={className}
        spaceStackStart="s"
        variant={centVariant || 'copy2'}
      >
        {`.${cents}`}
      </Text>
    </div>
  );
};

export default NumberDisplay;
