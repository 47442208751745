// @flow
import React from 'react';
import { Text } from '@audi/audi-ui-react';

type ModelAndDescriptionProps = {
  className: string;
  description: string;
  isMobile: boolean;
  model: string;
}

const ModelAndDescription = (props: ModelAndDescriptionProps) => {
  const {
    className,
    description,
    isMobile,
    model,
  } = props;

  return (
    <div className={className}>
      <Text
        as="h2"
        spaceStackStart={isMobile ? 'xl' : ''}
        variant={isMobile ? 'order3' : 'order2'}
        weight="bold"
      >
        { model }
      </Text>
      <Text
        as="p"
        variant={isMobile ? 'copy2' : 'copy1'}
      >
        { description }
      </Text>
    </div>
  );
};

export default ModelAndDescription;
