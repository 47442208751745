// @flow
import { Text } from '@audi/audi-ui-react';
import Icon from '@material-ui/core/Icon';
import { Box } from '@material-ui/core';
import React from 'react';
import { LocationProps } from './types';

const renderLocationAddress = (location) => {
  const {
    line1, city, state, zip,
  } = location.address;
  return `${line1}, ${city}, ${state}, ${zip}`;
};

const renderPartnerLocation = handleB2cHovering => (
  <Box className="partner-location">
    <Icon
      onMouseOver={handleB2cHovering}
      onFocus={handleB2cHovering}
      className="partner-location-info"
    >
          info_outlined
    </Icon>
    <Text
      as="span"
      className="address"
      variant="copy2"
    >
          PARTNER LOCATION
    </Text>
  </Box>
);

const Location: React.FC<LocationProps> = (props: LocationProps): React.ReactElement => {
  const {
    handleSetLocation,
    location,
    handleB2cHovering,
  } = props;

  return (
    <Box
      className="location clickable menu-hover"
      key={location.id}
      mr={0.5}
      p="1.5%"
      onClick={() => handleSetLocation(location)}
      tabIndex="0"
      width="100%"
      height="5rem"
    >
      <Text as="h6" id={`location-name-${location.id}`} variant="copy1">
        {location.multi_car_display_name}
      </Text>
      <Text
        as="p"
        className="address"
        id={`location-address-${location.id}`}
        variant="copy2"
      >
        {location.address && renderLocationAddress(location)}
      </Text>
      {location.b2c_url
        && renderPartnerLocation(handleB2cHovering)}
    </Box>
  );
};

export default Location;
