// @flow
import { Box } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import { toggleCostSummaryModalVisible } from '../../../actions/booking';
import CostSummary from '../cost-summary';
import Join from '../../join';
import SCDialog from '../../sc-dialog';

import './index.css';

type Props = {
  costSummaryModalVisible: boolean,
  toggleCostSummaryModalVisible: Function,
  isLoggedIn: boolean,
}

export function SummaryModal(props: Props) {
  const { costSummaryModalVisible, toggleCostSummaryModalVisible, isLoggedIn } = props;

  const stateClass = isLoggedIn ? 'loggedIn-summary-container' : 'loggedOut-summary-container';
  const className = isLoggedIn ? 'dialog' : 'dialog-split';

  return (
    <Box className="summary">
      <SCDialog
        onClose={toggleCostSummaryModalVisible}
        open={costSummaryModalVisible}
        showCloseButton
        title=""
        width="lg"
        classProp={className}
      >
        <div className="dialog-container">
          <div className={stateClass}>
            <CostSummary />
          </div>

          {!isLoggedIn
            && (
              <div className="join-container">
                <Join />
              </div>
            )
          }

        </div>
      </SCDialog>
    </Box>
  );
}

export const mapStateToProps = state => ({
  costSummaryModalVisible: state.bookingReducer.costSummaryModalVisible,
  isLoggedIn: state.appReducer.isLoggedIn,
});

export const mapDispatchToProps = dispatch => ({
  toggleCostSummaryModalVisible: () => dispatch(toggleCostSummaryModalVisible()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SummaryModal);
