import React from 'react';
import './four-oh-four.css';

function FourOhFour() {
  return (
    <div className="fof-container">
      <div className="fof-message">
        <h1>404</h1>
        <h5>Hit the brakes! Looks like there is nothing here.</h5>
        <br />
        <a href="/">Go Home</a>
      </div>
    </div>
  );
}

export default FourOhFour;
