// @flow
import {
  Button, Text, TextField,
} from '@audi/audi-ui-react';
import { Box } from '@material-ui/core';
import React, { Component, Fragment } from 'react';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { SUBMIT_SIGNUP_SUCCESS } from '../../../actions/account/constants';
import { submitSignup } from '../../../actions/account';
import { validatePassword, validateEmail } from '../../../utils/validators';
import CheckboxOptional from '../../checkbox-optional';
import FormWrapper from '../../layout/form-wrapper';
import '../forms.css';
import HTTPFormStatus from '../http-form-status';
import LogInLink from './LogInLink';
import TermsAndPrivacyPolicy from './TermsAndPrivacyPolicy';
import { SignUpFormProps, SignUpFormState } from './types';
import './index.css';

export class SignupForm extends Component<SignUpFormProps, SignUpFormState> {
  constructor(props: Object) {
    super(props);

    this.state = {
      email: '',
      password: '',
      isValidEmail: true,
      isValidPassword: true,
      silvercar_marketing: true,
      third_party_marketing: true,
    };
  }

  componentDidUpdate() {
    const { history, httpResponseError, httpResponseStatusMessage } = this.props;
    if (
      httpResponseStatusMessage === SUBMIT_SIGNUP_SUCCESS
      && !httpResponseError
    ) {
      history.push('/signup/thankyou');
    }
  }

  handleChange(event: Object) {
    const { name, value } = event.target;
    let { isValidEmail, isValidPassword } = this.state;
    isValidEmail = name === 'email' ? validateEmail(value) : isValidEmail;
    isValidPassword = name === 'password' ? validatePassword(value) : isValidPassword;
    this.setState({ [name]: value, isValidEmail, isValidPassword });
  }

  handleScMarketingCheck(value: boolean) {
    this.setState({ silvercar_marketing: value });
  }

  handleThirdPartyCheck(value: boolean) {
    this.setState({ third_party_marketing: value });
  }

  render() {
    const {
      email,
      password,
      isValidEmail,
      isValidPassword,
      silvercar_marketing,
      third_party_marketing,
    } = this.state;
    const data = { ...this.state };
    const { submitSignup, httpResponseError, httpResponseStatusMessage } = this.props;

    return (
      <Fragment>
        {httpResponseStatusMessage && (
          <HTTPFormStatus
            category=""
            type={httpResponseError ? 'error' : 'success'}
            message={httpResponseStatusMessage}
          />
        )}
        <Box className="signup-form" mt="10.37rem" mb="11.93rem">
          <FormWrapper>
            <Text as="h2" variant="order2" weight="bold" spaceStackEnd="xl">
              Sign up
            </Text>
            <Box mt="0.625rem" mb="2.5625rem">
              <ValidatorForm onSubmit={() => submitSignup(data)}>
                <TextField
                  inputId="text-field__email"
                  label="Email Address"
                  name="email"
                  type="email"
                  inputMode="email"
                  invalid={!isValidEmail}
                  required
                  value={email}
                  onChange={e => this.handleChange(e)}
                  validationMessage="Please enter a valid email address."
                  labelEffect="none"
                />

                <Box mt="2rem" />
                <TextField
                  inputId="text-field__password"
                  label="Password"
                  name="password"
                  onChange={e => this.handleChange(e)}
                  required
                  type="password"
                  value={password}
                  invalid={!isValidPassword}
                  validationMessage="Please enter a password that contains at least one upper and lowercase letter, and at least one number."
                  labelEffect="none"
                />
                <Box mt="2.5rem">
                  <CheckboxOptional
                    defaultChecked
                    inputId="demand-offer-checkbox"
                    value={silvercar_marketing}
                    onChange={event => this.handleScMarketingCheck(event.target.checked)
                  }
                  >
                  Yes, send me Audi on demand offers
                  </CheckboxOptional>
                </Box>
                <Box mt="2.25rem">
                  <CheckboxOptional
                    defaultChecked
                    value={third_party_marketing}
                    inputId="third-party-marketing-checkbox"
                    onChange={event => this.handleThirdPartyCheck(event.target.checked)
                  }
                  >
                  Yes, send me partner offers
                  </CheckboxOptional>
                </Box>
                <TermsAndPrivacyPolicy />
                <Box mt="2rem">
                  <Button
                    disabled={!(email.length && password.length)}
                    type="submit"
                    variant="primary"
                    stretch
                  >
                    Sign up
                  </Button>
                </Box>
              </ValidatorForm>
            </Box>
            <LogInLink />
          </FormWrapper>
        </Box>
      </Fragment>
    );
  }
}

export const mapStateToProps = state => ({
  httpResponseError: state.accountReducer.httpResponseError,
  httpResponseStatusMessage: state.accountReducer.httpResponseStatusMessage,
  user: state.accountReducer.user.id,
});

export const mapDispatchToProps = dispatch => ({
  submitSignup: data => dispatch(submitSignup(data)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SignupForm),
);
