// @flow
import React from 'react';
import ForgotPasswordForm from '../../components/forms/forgot-password';

function ForgotPassword() {
  return (
    <ForgotPasswordForm />
  );
}

export default ForgotPassword;
