// @flow
import { apiUrl, headers } from '../config';
import axiosInstance from '../axiosInstance';

function post(data: Object) {
  return axiosInstance({
    method: 'POST',
    url: `${apiUrl.v2}/user/drivers_license`,
    headers: headers(),
    data,
  });
}

function put(data: Object) {
  return axiosInstance({
    method: 'PUT',
    url: `${apiUrl.v2}/user/drivers_license`,
    headers: headers(),
    data,
  });
}

function get() {
  return axiosInstance({
    url: `${apiUrl.v2}/user/drivers_license`,
    headers: headers(),
  });
}

const driversLicenses = {
  post,
  get,
  put,
};

export default driversLicenses;
