// @flow
import React from 'react';
import { Text } from '@audi/audi-ui-react';

type Props = {
  title: string,
};

const FooterHeader = ({ title }: Props) => (
  <Text as="h4" className="global-footer-header" variant="order4">
    {title}
  </Text>
);

export default FooterHeader;
