// @flow
import React from 'react';
import { Text } from '@audi/audi-ui-react';
import NumberDisplay from '../../../number-display';
import './index.css';

type PreviousPriceProps = {
  total: string;
};

const PreviousPrice = ({ total }: PreviousPriceProps) => (
  <div className="previous-price">
    <Text variant="copy2" as="span">
      Previous
    </Text>
    <NumberDisplay
      centVariant="copy2"
      customClassName="previous-price-number-display"
      dollarVariant="order2"
      value={total}
    />
  </div>
);

export default PreviousPrice;
