// @flow
import { apiUrl, headers } from '../config';
import axiosInstance from '../axiosInstance';

function get(data: Object) {
  return axiosInstance({
    method: 'GET',
    url: `${apiUrl.v2}/user/points`,
    headers: headers(),
    data,
  });
}

const userPoints: Object = {
  get,
};

export default userPoints;
