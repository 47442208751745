// @flow
import { Button } from '@audi/audi-ui-react';
import React from 'react';

type PoliciesLinkProps = {
  size: string;
}

const PoliciesLink: React.FC<PoliciesLinkProps> = ({ size }: PoliciesLinkProps) => (
  <Button
    className="view-policies-link-button"
    href="https://support.audiondemand.com/hc/en-us/sections/200875094-Info"
    size={size}
    variant="text"
  >
    View policies
  </Button>
);

export default PoliciesLink;
