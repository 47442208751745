// @flow
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Button } from '@audi/audi-ui-react';
import {
  redirectToDates,
  setDropoffTime,
  setPickupTime,
  updateBookingPricing,
  updateRentalPricing,
} from '../../../../actions/booking/modify';
import { getReservationDetailsHref } from '../../../../containers/reservations/reservation-detail/helpers';
import history from '../../../../history';
import SCTimePicker from '../../../sc-time-picker';
import Header from '../shared-components/header';
import Subheader from '../shared-components/subheader';
import './index.css';

type Props = {
  booking: Object,
  availability: Object,
  id: number,
  location: Object,
  modified: boolean,
  redirectToDates: Function,
  reservationState: string,
  setDropoffTime: Function,
  setPickupTime: Function,
  updateBookingPricing: Function,
  updateRentalPricing: Function,
}

const ReservationEditTime = (props: Props) => {
  const {
    availability,
    booking,
    id,
    location,
    modified,
    redirectToDates,
    reservationState,
    setDropoffTime,
    setPickupTime,
    updateBookingPricing,
    updateRentalPricing,
  } = props;

  useEffect(() => {
    if (!modified) {
      history.push(getReservationDetailsHref({
        id,
        type: reservationState,
      }));
    }
  }, [id, modified, reservationState]);

  const {
    pickupDate,
    dropoffDate,
    pickupTime,
    dropoffTime,
  } = booking;

  const updatePricing = (reservationState === 'active') ? updateRentalPricing : updateBookingPricing;

  return (
    <section className="reservation-edit-times">
      <div className="header-container">
        <Header type="times" />
        <Button
          className="modify-dates-cancel-button"
          variant="secondary"
          name="cancel"
          onClick={() => history.goBack()}
          size="small"
          disabled={pickupTime && dropoffTime}
        >
          Cancel
        </Button>
      </div>
      <Subheader
        dropoffDate={dropoffDate}
        onClick={redirectToDates}
        pickupDate={pickupDate}
        type="times"
      />
      <section>
        <SCTimePicker
          availability={availability}
          booking={booking}
          location={location}
          setDropoffTime={setDropoffTime}
          setPickupTime={setPickupTime}
          reservationState={reservationState}
          timeAction={updatePricing}
        />
      </section>
    </section>
  );
};

export const mapDispatchToProps = dispatch => ({
  redirectToDates: () => dispatch(redirectToDates()),
  setDropoffTime: date => dispatch(setDropoffTime(date)),
  setPickupTime: date => dispatch(setPickupTime(date)),
  updateBookingPricing: () => dispatch(updateBookingPricing()),
  updateRentalPricing: () => dispatch(updateRentalPricing()),
});

export default connect(null, mapDispatchToProps)(ReservationEditTime);
