// @flow
import React from 'react';
import { Text } from '@audi/audi-ui-react';

import './index.css';

type CostAfterPointsProps = {
  disabled: string;
  isMobile: boolean;
  showCostAfterPoints: boolean;
  vehicleCost: React.Node;
}

const CostAfterPoints = (props: CostAfterPointsProps) => {
  const {
    disabled,
    isMobile,
    showCostAfterPoints,
    vehicleCost,
  } = props;

  if (!showCostAfterPoints) return null;

  const variant = isMobile ? 'copy3' : 'copy2';
  const disabledClass = disabled || '';

  return (
    <Text
      as="p"
      className={`spend-total ${disabledClass}`}
      spaceStackEnd="s"
      variant={variant}
    >
      Est. balance to be paid:
      {' '}
      <Text as="span" variant={variant}>
        {vehicleCost}
      </Text>
    </Text>
  );
};

export default CostAfterPoints;
