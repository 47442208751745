// @flow
import React from 'react';
import { Text } from '@audi/audi-ui-react';

type VehicleUnavailableNoticeProps = {
  isMobile: boolean;
}

const VehicleUnavailableNotice = (props: VehicleUnavailableNoticeProps) => {
  const { isMobile } = props;

  return (
    <Text
      as="h4"
      className="vehicle-unavailable-notice"
      spaceStackStart={isMobile ? '' : 's'}
      spaceStackEnd={isMobile ? '' : 'xxxl'}
      variant="order4"
      weight="bold"
    >
      Not available for your dates
    </Text>
  );
};

export default VehicleUnavailableNotice;
