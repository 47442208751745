import React from 'react';
import { formatComponentStackTrace, noticeError } from '../../utils/error';
import FallbackComponent from './fallbackComponent';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ hasError: error });
    noticeError(error, {
      react: formatComponentStackTrace(errorInfo.componentStack),
    });
  }

  render() {
    // eslint-disable-next-line react/prop-types
    const { children } = this.props;
    const { hasError } = this.state;

    if (hasError) {
      return <FallbackComponent />;
    }

    return children;
  }
}

export default ErrorBoundary;
