// @flow
import React, { Fragment } from 'react';
import Box from '@material-ui/core/Box';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import IconButton from '@material-ui/core/IconButton';
import { COLORS, FONT_WEIGHTS } from '../../../../theme/constants';
import './index.css';

type Props = {
  handleClick: Function,
  forwardRef: Object,
  title?: string,
}

function ReservationMenu({
  handleClick,
  forwardRef,
  title,
}: Props) {
  return (
    <Fragment>
      <Box
        mt="1rem"
      >
        <span
          style={{
            textDecoration: 'none',
            fontSize: '1rem',
            fontFamily: 'AudiType-Variable',
            display: 'flex',
            color: COLORS.GREY1,
            fontWeight: FONT_WEIGHTS.NORMAL,
            justifyContent: 'space-between',
          }}
          onClick={(event) => {
            event.preventDefault();
            handleClick(event);
          }}
          onKeyDown={(event) => {
            event.preventDefault();
            handleClick(event);
          }}
          className="reservation-drop-down"
          ref={forwardRef}
          tabIndex={0}
          role="button"
        >
          {title}
          <IconButton
            aria-label="expand-reservation-menu"
          >
            <ExpandMoreIcon
              style={{
                fontSize: '1.5rem',
                margin: '0 0 0.0625rem 0.25rem',
              }}
            />
          </IconButton>
        </span>
      </Box>
    </Fragment>
  );
}

export default ReservationMenu;
