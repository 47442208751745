// @flow
import { Text } from '@audi/audi-ui-react';
import React from 'react';
import appScreen from '../../assets/images/booking/home-app-screen-01.png';
import appIphone from '../../assets/images/booking/home-app-iphone.svg';
import badgeAppStore from '../../assets/images/booking/badge-app-store.svg';
import badgeGooglePlay from '../../assets/images/booking/badge-google-play.svg';
import './index.css';

const ConfirmBookingApp = () => (
  <div className="app-booking">
    <div className="app-screen-container">
      <div className="app-screen-image">
        <img
          src={appIphone}
          alt="app-screen"
          className="app-iphone"
        />
        <img
          src={appScreen}
          alt="app-screen"
          className="app-screen"
        />
      </div>
    </div>
    <div className="app-screen-text">
      <Text as="h2" variant="display2" weight="bold" spaceStackEnd="m" spaceStackStart="xxxl">
        Be prepared, grab the app
      </Text>
      <Text as="p" variant="copy1" spaceStackEnd="xxxl">
        Our app makes picking up your car a seamless experience.
        Use the app to locate and unlock your car.
      </Text>
      <div className="app-screen-text-image">
        <a href="https://play.google.com/store/apps/details?id=com.silvercar.mobi&hl=en_US&gl=US" target="_blank" rel="noreferrer noopener">
          <img
            src={badgeGooglePlay}
            alt="google-play"
            className="google-play"
          />
        </a>
        <a href="https://apps.apple.com/us/app/audi-on-demand-car-rental/id587128247" target="_blank" rel="noreferrer noopener">
          <img
            src={badgeAppStore}
            alt="app-store"
            className="app-store"
          />
        </a>
      </div>
    </div>

  </div>
);

export default ConfirmBookingApp;
