// @flow
import { Text } from '@audi/audi-ui-react';
import React from 'react';
import {
  useMediaQuery,
} from '@material-ui/core';
import {
  formatDate,
  formatTime,
} from '../../../../utils/formatters';
import './index.css';

type ConfirmBookingDateTimeProps = {
    confirmDateTime: string,
    timeZone: string;
    type: string;
}

const ConfirmDateTime = (props: ConfirmBookingDateTimeProps) => {
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('xs'));
  const {
    confirmDateTime, timeZone, type,
  } = props;

  return (
    <div className="confirm-booking-date-time">
      <Text as="p" variant="copy2">
        {type}
      </Text>
      <Text as="h2" variant="order2" spaceInlineEnd="s">
        {formatDate(confirmDateTime)}
      </Text>
      <Text as="p" variant="copy1" spaceStackEnd={isMobile ? 'xl' : ''}>
        {formatTime(confirmDateTime, timeZone)}
      </Text>
    </div>
  );
};

export default ConfirmDateTime;
