import { COLORS } from '../../../theme/constants';

export const getMobileStyles = ({ isReview }) => ({
  containerStyles: {
    display: 'flex',
    flexDirection: 'column',
    p: '0 20px',
  },
  headerStyles: {
    display: isReview ? 'block' : '',
  },
  coverageStyles: {
    flex: 1,
    marginX: 1.25,
  },
  vehicleStyles: {
    pr: 1.5,
    pl: 1.5,
    borderBottom: `1px solid ${COLORS.GREY7}`,
  },
  costSummaryStyles: {
    flex: 1,
    mt: 1,
    pt: 2,
    pb: 1,
    marginX: 1.25,
    borderTop: `1px solid ${COLORS.GREY3}`,
  },
});

export const getDesktopStyles = () => ({
  containerStyles: {
    display: 'flex',
    maxWidth: '83rem',
    margin: 'auto',
    mb: 4,
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  headerStyles: {
    flex: '1 0 100%',
    mb: 2.25,
  },
  coverageStyles: {
    flex: 1,
    border: `1px solid ${COLORS.GREY3}`,
    mr: 1,
    p: 3.75,
    pt: 1.75,
    pb: 7,
    height: 'fit-content',
  },
  vehicleStyles: {
    width: '100%',
  },
  costSummaryStyles: {
    flex: 1,
    border: `1px solid ${COLORS.GREY3}`,
    borderTop: `1px solid ${COLORS.GREY3}`,
    ml: 1,
    px: 3.75,
    pt: 3.75,
    pb: 3.75,
    height: 'fit-content',
  },
});
