// @flow
import React, { Fragment } from 'react';
import Box from '@material-ui/core/Box';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import IconButton from '@material-ui/core/IconButton';
import { COLORS, FONT_WEIGHTS } from '../../theme/constants';
import { formatUserNameOrEmail } from '../../utils/formatters/index';
import './index.css';

type Props = {
  handleClick: Function,
  forwardRef: Object,
  user: Object,
}

function AccountMenu({
  handleClick,
  forwardRef,
  user,
}: Props) {
  return (
    <Fragment>
      <Box
        mt="1rem"
      >
        <span
          style={{
            textDecoration: 'none',
            fontSize: '1rem',
            color: COLORS.GREY1,
            fontWeight: FONT_WEIGHTS.THICK,
            fontFamily: 'AudiType-Variable',
          }}
          onClick={(event) => {
            event.preventDefault();
            handleClick(event);
          }}
          onKeyDown={(event) => {
            event.preventDefault();
            handleClick(event);
          }}
          className="account-drop-down"
          ref={forwardRef}
          tabIndex={0}
          role="button"
        >
          {formatUserNameOrEmail(user)}
          <IconButton
            aria-label="expand-account-menu"
          >
            <ExpandMoreIcon
              style={{
                fontSize: '1.5rem',
                margin: '0 0 0.0625rem 0.25rem',
              }}
            />
          </IconButton>
        </span>
      </Box>
    </Fragment>
  );
}

export default AccountMenu;
