// @flow
import { Text, TextField, RadioButton } from '@audi/audi-ui-react';
import { useMediaQuery, Box } from '@material-ui/core';
import React, { useState, useCallback, useEffect } from 'react';
import debounce from 'lodash.debounce';
import { coverageStrings } from './constants';
import HeaderTitle from './header-title';
import './index.css';

type Props = {
  activeCoverage: {
    coverageOptions: [],
    coverageType: string,
    policyNumber: string,
    carrier: string,
  },
  handleUpdateCoverageType: () => void,
}

const Coverage = (props: Props) => {
  const {
    activeCoverage,
    handleUpdateCoverageType,
  } = props;

  const { coverageType, carrier: activeCarrier, policyNumber: activePolicyNumber } = activeCoverage;

  const [policyNumber, setPolicyNumber] = useState('');
  const [isValidPolicyNumber, validateDatePolicyNumber] = useState(false);
  const [carrier, setCarrier] = useState('');
  const [isValidCarrier, validateCarrier] = useState(false);
  const [isInitialRender, setIsInitialRender] = useState(true);
  const [personalCoverage, setPersonalCoverageChecked] = useState(true);
  const [corporateCoverage, setCorporateCoverageChecked] = useState(false);

  const isMobile = useMediaQuery(theme => theme.breakpoints.down('xs'));

  const handleDefaultCoverageChecked = useCallback(() => {
    handleUpdateCoverageType({ coverageType: 'personal_coverage' });
  }, [
    handleUpdateCoverageType,
  ]);

  useEffect(() => {
    if (!coverageType && isInitialRender) {
      setIsInitialRender(false);
      handleDefaultCoverageChecked();
    } else if (coverageType && activeCarrier && activePolicyNumber && isInitialRender) {
      setCarrier(activeCarrier);
      setPolicyNumber(activePolicyNumber);

      if (coverageType === 'personal_coverage') {
        setPersonalCoverageChecked(true);
        setCorporateCoverageChecked(false);
      } else {
        setCorporateCoverageChecked(true);
        setPersonalCoverageChecked(false);
      }
      setIsInitialRender(false);
    }
  }, [
    coverageType,
    activeCarrier,
    activePolicyNumber,
    isInitialRender,
    setIsInitialRender,
    handleDefaultCoverageChecked,
  ]);

  const handleValueValidation = (e) => {
    const { name } = e.target;
    const value = e.target.value.trim();
    if (name === 'carrier') validateCarrier(!value);
    if (name === 'policyNumber') validateDatePolicyNumber(!value);
  };

  const handleChange = (event) => {
    const { value, name } = event.target;

    if (name === 'carrier') {
      setCarrier(value);
      debounceUpdateCoverageType('carrier', value);
    }

    if (name === 'policyNumber') {
      setPolicyNumber(value);
      debounceUpdateCoverageType('policyNumber', value);
    }
  };

  const debounceUpdateCoverageType = debounce((name, value) => {
    handleUpdateCoverageType({ ...activeCoverage, [name]: value.trim() });
  }, 500);

  const handleCoverageChecked = (event) => {
    const { checked, name } = event.target;

    if (name === 'personalCoverage') setPersonalCoverage(checked);

    if (name === 'corporateCoverage') setCorporateCoverage(checked);
  };

  const setPersonalCoverage = (checked) => {
    setPersonalCoverageChecked(checked);
    setCorporateCoverageChecked(!checked);
    handleUpdateCoverageType({ ...activeCoverage, coverageType: 'personal_coverage' });
  };

  const setCorporateCoverage = (checked) => {
    setCorporateCoverageChecked(checked);
    setPersonalCoverageChecked(!checked);
    handleUpdateCoverageType({ ...activeCoverage, coverageType: 'corporate_coverage' });
  };


  return (
    <>
      {isMobile ? (
        <>
          <div className="mobile-text-area">
            <div className="inner-row mobile-header">
              <HeaderTitle />
            </div>
            <Text as="h1" variant="order3" weight="bold" spaceStackEnd="m">
              {coverageStrings.selectCoverage}
            </Text>
            <Text as="p" variant="copy1" spaceStackEnd="m">
              {coverageStrings.protectRentalFirstParagraph}
            </Text>
            <Text as="p" variant="copy1" spaceStackEnd="xxl">
              {coverageStrings.protectRentalSecondParagraph}
            </Text>

            <div className="inner-column">
              <RadioButton
                inputId="personal-coverage-radio-button"
                checked={personalCoverage}
                value={personalCoverage}
                name="personalCoverage"
                onChange={handleCoverageChecked}
                spaceStackEnd="xxl"
              >
                Personal coverage
              </RadioButton>

              <RadioButton
                inputId="corporate-coverage-radio-button"
                checked={corporateCoverage}
                value={corporateCoverage}
                name="corporateCoverage"
                onChange={handleCoverageChecked}
                spaceStackEnd="xxl"
              >
                Corporate coverage
              </RadioButton>

              <Box mb="1.875rem" height="3.563rem">
                <TextField
                  inputId="text-field_carrier"
                  label="Enter insurance carrier"
                  className="fix-red-label"
                  inputMode="text"
                  value={carrier}
                  name="carrier"
                  onChange={handleChange}
                  validators={['required', 'hasValue']}
                  required
                  invalid={isValidCarrier}
                  onBlur={handleValueValidation}
                  validationMessage="Insurance carrier info is required"
                  labelEffect={carrier ? 'none' : 'float'}
                />
              </Box>
              <Box mb="6rem" height="3.563rem">
                <TextField
                  inputId="text-field_policy_number"
                  label="Enter policy number"
                  className="fix-red-label"
                  inputMode="text"
                  value={policyNumber}
                  name="policyNumber"
                  onChange={handleChange}
                  validators={['required', 'hasValue']}
                  required
                  invalid={isValidPolicyNumber}
                  onBlur={handleValueValidation}
                  validationMessage="Policy number is required"
                  labelEffect={policyNumber ? 'none' : 'float'}
                />
              </Box>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="inner-row header-section">
            <HeaderTitle />
          </div>
          <div className="outer-coverage-container">
            <div className="inner-row sub-header">
              <div className="inner-column left-column">
                <Text as="h1" variant="order3" weight="bold" spaceStackEnd="m">
                  {coverageStrings.selectCoverage}
                </Text>
                <Text
                  as="p"
                  variant="copy1"
                  spaceInlineEnd="xxl"
                  spaceStackEnd="xl"
                >
                  {coverageStrings.protectRentalFirstParagraph}
                </Text>
                <Text
                  as="p"
                  variant="copy1"
                  spaceInlineEnd="xxl"
                  spaceStackEnd="xxl"
                >
                  {coverageStrings.protectRentalSecondParagraph}
                </Text>
              </div>

              <div className="inner-column right-column">
                <Box mb="1.875rem">
                  <div className="inner-row">
                    <RadioButton
                      inputId="personal-coverage-radio-button"
                      checked={personalCoverage}
                      value={personalCoverage}
                      name="personalCoverage"
                      onChange={handleCoverageChecked}
                      spaceInlineEnd="xxl"
                    >
                      Personal coverage
                    </RadioButton>

                    <RadioButton
                      inputId="corporate-coverage-radio-button"
                      checked={corporateCoverage}
                      value={corporateCoverage}
                      name="corporateCoverage"
                      onChange={handleCoverageChecked}
                    >
                      Corporate coverage
                    </RadioButton>
                  </div>
                </Box>

                <Box mb="1.875rem" height="3.563rem">
                  <TextField
                    inputId="text-field_carrier"
                    label="Enter insurance carrier"
                    className="fix-red-label"
                    inputMode="text"
                    value={carrier}
                    name="carrier"
                    onChange={handleChange}
                    validators={['required', 'hasValue']}
                    required
                    invalid={isValidCarrier}
                    onBlur={handleValueValidation}
                    validationMessage="Insurance carrier info is required"
                    labelEffect={carrier ? 'none' : 'float'}
                  />
                </Box>
                <Box mb="1.875rem" height="3.563rem">
                  <TextField
                    inputId="text-field_policy_number"
                    label="Enter policy number"
                    className="fix-red-label"
                    inputMode="text"
                    value={policyNumber}
                    name="policyNumber"
                    onChange={handleChange}
                    validators={['required', 'hasValue']}
                    required
                    invalid={isValidPolicyNumber}
                    onBlur={handleValueValidation}
                    validationMessage="Policy number is required"
                    labelEffect={policyNumber ? 'none' : 'float'}
                  />
                </Box>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Coverage;
