export const BORDER_RADIUS = '.25rem';

export const MOBILE_HEADER_HEIGHT = '65px';

export const COLORS = {
  ALERT: '#D35656',
  BLACK: '#000000',
  CONFIRM: '#00A442',
  GREY7: '#E5E5E5',
  GREY6: '#FAFAFA',
  GREY5: '#F2F2F2',
  GREY4: '#D9D9D9',
  GREY3: '#B3B3B3',
  GREY2: '#707070',
  GREY1: '#333333',
  PRIMARY: '#000000',
  PRIMARY_CONTRAST: '#FFFFFF',
  SECONDARY: '#FFFFFF',
  SECONDARY_CONTRAST: '#000000',
  WHITE: '#FFFFFF',
};

export const FONT_WEIGHTS = {
  EXTRA_LIGHT: 100,
  LIGHT: 300,
  NORMAL: 400,
  THICK: 600,
  BOLD: 700,
  HEAVY: 900,
};

const CONSTANTS = {
  COLORS,
  FONT_WEIGHTS,
  BORDER_RADIUS,
};

export default CONSTANTS;
