// @flow
import React from 'react';
import { useSelector } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { getIsReady } from '../../selectors';
import Loading from '../loading';
import ConfirmBookingHeader from './header';
import ConfirmVehicleBooked from './vehicle-booked';
import ConfirmationNumber from './confirmation-number';
import ConfirmVehicleLocation from './location';
import ConfirmBookingDateTime from './date-times';
import ConfirmBookingPoints from './points-pending';
import ConfirmAudiProtection from './audi-protection';
import RequestPickUpDelivery from './request-delivery-buttons';
import ManageReservations from './manage-reservation';
import './index.css';

interface Props extends RouteComponentProps {
  confirmationNumber: string;
  dropoffDateTime: string;
  hideBorder: boolean;
  isMobile: boolean;
  pickupDateTime: string;
  pickupLocation: Object;
  showConfirmation: boolean;
  showModifyButton: boolean;
  vehicle: Object;
  prevStep: () => void;
  earningPoints: boolean;
  points: any;
  coverageOptions: string;
  id: string;
  isLoggedIn: boolean;
  reservationState: string;
}

const ConfirmedBookingDetails = (props: Props) => {
  const {
    confirmationNumber,
    dropoffDateTime,
    vehicle,
    pickupLocation,
    pickupDateTime,
    points,
    earningPoints,
    coverageOptions,
    id,
    reservationState,
  } = props;
  const isReady = useSelector(getIsReady);

  if (!(isReady && pickupLocation.time_zone && vehicle)) {
    return <Loading />;
  }

  const { earn_total } = points;

  return (
    <div className="confirmed-booking-container">
      <div className="confirmed-booking-section">
        <ConfirmBookingHeader model={vehicle.model} />
        <div className="subheader">
          <div className="booking-border" />
          <ManageReservations
            id={id}
            reservationState={reservationState}
          />
        </div>
        <div className="booking-details">
          <div className="booking-details-first-column">
            <ConfirmVehicleLocation pickupLocation={pickupLocation} />
            <ConfirmBookingDateTime
              pickupLocation={pickupLocation}
              pickupDateTime={pickupDateTime}
              dropoffDateTime={dropoffDateTime}
            />
          </div>
          <div className="booking-details-second-column">
            <ConfirmationNumber confirmationNumber={confirmationNumber} />
            <ConfirmVehicleBooked vehicle={vehicle} />
          </div>
          <div className="booking-details-third-column">
            <ConfirmAudiProtection coverageOptions={coverageOptions} />
            <ConfirmBookingPoints earn_total={earn_total} earningPoints={earningPoints} />
          </div>
        </div>
        <RequestPickUpDelivery />
        <img className="booking-details-img" src={vehicle.confirmation_image} alt="Booking" />
      </div>
    </div>
  );
};

export default withRouter(ConfirmedBookingDetails);
