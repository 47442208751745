// @flow
import React from 'react';
import { Text } from '@audi/audi-ui-react';
import { Box } from '@material-ui/core';

const TermsAndPrivacyPolicy: React.FC = (): React.ReactElement => (
  <Box
    maxWidth="23.125rem"
    my="2rem"
  >
    <Text as="p" variant="copy2">
      By signing up, you agree to the Audi on demand
      {' '}
    </Text>
    <Text as="p" variant="copy2">
      <a
        rel="noopener noreferrer"
        target="_blank"
        href="https://support.audiondemand.com/hc/en-us/articles/204790284-Terms-of-Use"
      >
        Terms of Use
      </a>
      {' and '}
      <a
        rel="noopener noreferrer"
        target="_blank"
        href="https://support.audiondemand.com/hc/en-us/articles/204791464-Privacy-Policy"
      >
        Privacy Policy
      </a>
      {'.'}
    </Text>
  </Box>
);

export default TermsAndPrivacyPolicy;
