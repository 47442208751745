// @flow

const MuiFormHelperText = {
  root: {
    position: 'relative',
    top: '-1rem',
  },
};

export default MuiFormHelperText;
