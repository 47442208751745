// @flow
import { Text } from '@audi/audi-ui-react';
import { Box, IconButton } from '@material-ui/core';
import CreateIcon from '@material-ui/icons/Create';
import React, { Fragment } from 'react';
import { capitalizeEachWord, removeSnakeCase } from '../../../utils/formatters';
import { EditItemProps } from './types';

const EditItem = (props: EditItemProps) => {
  const {
    handleClick,
    title,
    subtitle,
  } = props;
  return (
    <Fragment>
      <Box>
        <Text
          as="p"
          id="title"
          variant="copy1"
          weight="bold"
        >
          {capitalizeEachWord(title)}
        </Text>
        {subtitle.map(s => (
          <Text
            as="p"
            id="subtitle"
            key={s}
            variant="copy1"
          >
            {capitalizeEachWord(removeSnakeCase(s))}
          </Text>
        ))}
      </Box>
      <Box>
        <IconButton id="create-icon" onClick={handleClick}>
          <CreateIcon fontSize="large" />
        </IconButton>
      </Box>
    </Fragment>
  );
};

export default EditItem;
