// @flow
import React, { Component } from 'react';
import { DayPickerRangeController, isInclusivelyAfterDay } from 'react-dates';
import moment from 'moment';

import { connect } from 'react-redux';

import './index.css';

const START_FOCUS = 'startDate';

type Props = {
  booking: Object,
  disablePickup: boolean,
  location: Object,
  nextStep: () => void,
  setDropoffDate: Function,
  setPickupDate: Function,
  getAvailability: Function,
}

type State = {
  openDate: moment,
  closeDate: moment,
  focusedInput: moment,
  startDate: moment,
  endDate: moment,
  numberOfMonths: number,
  useEndDate: boolean,
}

export class SCDatePicker extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      closeDate: moment().add(180, 'days').startOf('day'),
      openDate: moment().startOf('day'),
      focusedInput: START_FOCUS,
      startDate: null,
      endDate: null,
      numberOfMonths: 7,
      useEndDate: false,
    };
    this.onFocusChange = this.onFocusChange.bind(this);
    this.isDayHighlighted = this.isDayHighlightedFactory();
  }

  onChange =
    ({ startDate, endDate }: any) => {
      /* If the datepicker already has both a start and endDate,
      treat the new date selected as a fresh start. (pun)
      */
      const {
        getAvailability,
        disablePickup,
        setPickupDate,
        setDropoffDate,
      } = this.props;

      const {
        startDate: selectedStartDate,
        endDate: selectedEndDate,
        useEndDate,
      } = this.state;

      const oldStartDate = selectedStartDate;
      const oldEndDate = selectedEndDate;

      let newStartDate = startDate;
      let newEndDate = endDate;

      // if there is already a selected start and end date
      // set the new start date and clear the end date


      // This is not an active booking
      if (!disablePickup) {
        if (oldStartDate && oldEndDate && newStartDate) {
          newEndDate = null;
          this.setState({
            startDate: newStartDate,
            endDate: null,
          }, () => {
            this.isDayHighlighted = this.isDayHighlightedFactory();
          });
        }

        // IN A STANDARD BOOKING
        // if the startDate is selected and no end date is passed
        if (newStartDate && !newEndDate && !disablePickup) {
          setPickupDate(startDate);
          this.setState({
            startDate: newStartDate,
            endDate: null,
          }, () => {
            this.isDayHighlighted = this.isDayHighlightedFactory();
          });
          return false;
        }
        // if the startDate is selected and end date is passed
        if (newStartDate && newEndDate && !disablePickup) {
          setDropoffDate(newEndDate);
          this.setState({
            startDate: newStartDate,
            endDate: newEndDate,
          }, () => {
            this.isDayHighlighted = this.isDayHighlightedFactory();
          });
        }
      } else {
        if (!useEndDate) {
          newStartDate = moment(selectedStartDate).clone();
          newEndDate = moment(startDate).clone();
          this.setState({
            useEndDate: true,
          });
        } else {
          newStartDate = moment(selectedStartDate).clone();

          this.setState({
            useEndDate: false,
          });
        }

        setDropoffDate(newEndDate);
        this.setState({
          endDate: newEndDate,
        }, () => {
          this.isDayHighlighted = this.isDayHighlightedFactory();
        });
      }

      // if the start date and end date are set, let's check availability
      if (newStartDate && newEndDate) {
        const {
          booking,
          location,
          nextStep,
        } = this.props;
        const { id } = booking;
        const getLocationIds = () => {
          const ids = [location.id];
          if (location.multi_car_location_ids) {
            ids.push(...location.multi_car_location_ids);
          }
          return ids;
        };
        const params = {
          pickup_on: moment(newStartDate).format('YYYY-MM-DD'),
          dropoff_on: moment(newEndDate).format('YYYY-MM-DD'),
        };

        if (id) {
          getAvailability(
            {
              ...params,
              id,
            },
            nextStep,
          );
        } else {
          getAvailability(
            {
              ...params,
              location_ids: getLocationIds(),
            },
            nextStep,
          );
        }
      }
      return false;
    };

  onFocusChange =
    (focusedInput: any) => (
      this.setState({
        focusedInput: !focusedInput ? START_FOCUS : focusedInput,
      })
    );

  isDayHighlighted = () => {}

  isDayHighlightedFactory = () => {
    const { startDate, endDate } = this.state;
    return (day: any) => (
      isInclusivelyAfterDay(day, moment(startDate))
      && !isInclusivelyAfterDay(day, moment(startDate).add(14, 'days'))
      && !endDate
    );
  }

  isOutsideRange = (day: any) => {
    const { openDate, closeDate } = this.state;
    return (
      !isInclusivelyAfterDay(day, openDate)
      || isInclusivelyAfterDay(day, moment(closeDate))
    );
  }

  UNSAFE_componentWillMount() {
    const { booking, location } = this.props;
    const { openDate, closeDate } = this.state;
    this.setState({ startDate: null });

    // set the openDate if the location's open date is in the future
    if (booking && location && location.open_date) {
      const openLocationDate = moment(location.open_date);
      if (openLocationDate.isAfter(openDate)) {
        this.setState({
          closeDate: openLocationDate.clone().add(180, 'days'),
          openDate: openLocationDate.clone(),
        });
      }
    }
    // set the closeDate if the location's open date set
    if (booking && location && location.close_date) {
      if (moment(location.close_date).isBefore(closeDate)) {
        this.setState({
          closeDate: moment(location.close_date),
        });
      }
    }

    if (booking.pickupDate) {
      this.setState({
        startDate: (moment(booking.pickupDate)),
      }, () => {
        this.isDayHighlighted = this.isDayHighlightedFactory();
      });
    }

    if (booking.dropoffDate) {
      this.setState({
        endDate: (moment(booking.dropoffDate)),
      });
    }
  }

  render() {
    const {
      startDate,
      endDate,
      focusedInput,
      openDate,
      numberOfMonths,
    } = this.state;

    return (
      <div className="sc-date-picker">
        <div>
          <DayPickerRangeController
            daySize={50}
            endDate={endDate}
            firstDayOfWeek={0}
            focusedInput={focusedInput}
            hideKeyboardShortcutsPanel
            initialVisibleMonth={() => moment(openDate)}
            isDayHighlighted={this.isDayHighlighted}
            isOutsideRange={this.isOutsideRange}
            minimumNights={0}
            numberOfMonths={numberOfMonths}
            onDatesChange={this.onChange}
            onFocusChange={this.onFocusChange}
            orientation="verticalScrollable"
            startDate={startDate}
          />
        </div>
      </div>
    );
  }
}

export default connect(null, null)(SCDatePicker);
