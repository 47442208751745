// @flow
import React, { Fragment } from 'react';
import { Text } from '@audi/audi-ui-react';
import Loading from '../../loading';
import { formatNumberWithCommas } from '../../../utils/formatters/index';
import './index.css';

type Props = {
  userLoyaltyPoints: Number,
  userAudiOwnerProgramDays: Number,
}

const renderLoyaltyPoints = points => (points !== null ? (
  <span className="sc-rewards-item loyalty-points points-text-with-numbers">
    <Text as="p" variant="copy1" className="points-text">Points</Text>
    <Text as="p" variant="copy1" className="numbers" weight="bold">{ formatNumberWithCommas(points) }</Text>
  </span>
) : (
  <Loading size={20} />
));

const renderAudiOwnerProgramDays = days => (days !== null ? (
  <div className="sc-rewards-item loyalty-points always-audi-days-outer-container">
    <div className="column-flexbox">
      <div className="horizontal-rule-dropdown" />
      <div className="always-audi-days-container">
        <Text as="p" variant="copy1" className="points-text">Always Audi Days</Text>
        <Text as="p" variant="copy1" className="numbers" weight="bold">
          {days}
        </Text>
      </div>
    </div>
  </div>
) : (
  <Loading size={20} />
));

const SCRewardsItem = (props: Props) => {
  const { userLoyaltyPoints, userAudiOwnerProgramDays } = props;
  const hasAudiOwnerDays = userAudiOwnerProgramDays > 0;
  return (
    <Fragment>
      {renderLoyaltyPoints(userLoyaltyPoints)}
      {hasAudiOwnerDays
        ? renderAudiOwnerProgramDays(userAudiOwnerProgramDays) : null}

    </Fragment>
  );
};

export default SCRewardsItem;
