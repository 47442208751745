// @flow
import React, { Fragment } from 'react';
import { Box, Typography, IconButton } from '@material-ui/core';
import CreateIcon from '@material-ui/icons/Create';

type Props = {
  handleClick: Function,
  title: string,
  subtitle: string[]
}

const EditPromotionItem = (props: Props) => {
  const {
    handleClick,
    title,
    subtitle,
  } = props;
  return (
    <Fragment>
      <Box>
        <Typography
          id="title"
          variant="body1"
          component="p"
          style={{
            fontSize: '1.125rem',
          }}
        >
          {title}
        </Typography>
        {subtitle.map(s => (
          <Typography
            id="subtitle"
            key={s}
            variant="subtitle2"
            component="p"
          >
            {s}
          </Typography>
        ))}
      </Box>
      <Box>
        <IconButton id="create-icon" onClick={handleClick}>
          <CreateIcon fontSize="large" />
        </IconButton>
      </Box>
    </Fragment>
  );
};

export default EditPromotionItem;
