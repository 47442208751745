// @flow
import { AudiPlatformProvider, Button, Text } from '@audi/audi-ui-react';
import React, { Component } from 'react';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { connect } from 'react-redux';
import CheckboxOptional from '../../checkbox-optional';
import { joinAndReserve, setThirdPartyBooking, toggleCostSummaryModalVisible } from '../../../actions/booking';
import '../forms.css';
import './join-reserve-form.css';

type Props = {
  joinAndReserve: Function,
  bookingType: string,
  setThirdPartyBooking: Function,
  toggleCostSummaryModalVisible: () => void,
};

type State = {
  email: string,
  phone: string,
}

export class JoinReserveForm extends Component<Props, State> {
  constructor(props: Object) {
    super(props);

    this.state = {
      email: '',
      phone: '',
    };
  }

  cancelButton = () => {
    const { toggleCostSummaryModalVisible } = this.props;
    toggleCostSummaryModalVisible();
  }

  // eslint-disable-next-line class-methods-use-this
  UNSAFE_componentWillMount() {
    ValidatorForm.addValidationRule('isValidPhoneNumber', value => value && value.length && (value.length >= 7));
  }

  handleSubmit() {
    const { email, phone } = this.state;
    const {
      joinAndReserve,
      bookingType,
      setThirdPartyBooking,
    } = this.props;

    const data = {
      email_address: email,
      phone_number: phone,
    };
    if (bookingType === 'thirdParty') {
      setThirdPartyBooking();
    }
    joinAndReserve(data);
  }


  render() {
    const {
      phone,
      email,
    } = this.state;

    return (
      <AudiPlatformProvider>
        <div className="sc-form join-reserve-form">
          <ValidatorForm onSubmit={() => this.handleSubmit()}>
            <TextValidator
              fullWidth
              value={email}
              name="email"
              id="email"
              type="email"
              validators={['required', 'isEmail']}
              errorMessages={['Email is required.', 'Please enter a valid email address.']}
              onChange={e => this.setState({ email: e.target.value })}
              label="Renter's email address"
            />
            <TextValidator
              fullWidth
              value={phone}
              name="phone"
              id="phone-number"
              type="text"
              validators={['isValidPhoneNumber']}
              errorMessages={['Mobile Number must be at least 7 numbers in length.']}
              onChange={e => this.setState({ phone: e.target.value })}
              label="Renter's mobile number"
            />
            <CheckboxOptional
              name="marketing-check"
              inputId="app-text-checkbox"
              defaultChecked
              spaceStackEnd="m"
            >
              Text me a link to download the app
            </CheckboxOptional>
            <CheckboxOptional
              name="marketing-check"
              inputId="text-updates-checkbox"
              defaultChecked
            >
              I agree to receive text updates
            </CheckboxOptional>
            <Text as="p" variant="copy2" className="policy-section">
              By signing up, you agree to the Audi on demand&#39;s
              {' '}
              <a
                rel="noopener noreferrer"
                target="_blank"
                href="https://support.audiondemand.com/hc/en-us/articles/204790284-Terms-of-Use"
              >
                Terms of Use
              </a>
              {' '}
              and
              {' '}
              <a
                rel="noopener noreferrer"
                target="_blank"
                href="https://support.audiondemand.com/hc/en-us/articles/204791464-Privacy-Policy"
              >
                Privacy Policy
              </a>
            </Text>
            <section className="join-button-container">
              <Button
                variant="primary"
                type="submit"
                spaceStackStart="xxl"
                spaceInlineEnd="xxs"
              >
                Confirm booking
              </Button>
              <Button
                name="cancel"
                variant="secondary"
                spaceStackStart="xxl"
                onClick={this.cancelButton}
              >
                Cancel
              </Button>
            </section>
          </ValidatorForm>
        </div>
      </AudiPlatformProvider>
    );
  }
}

export const mapDispatchToProps = dispatch => ({
  joinAndReserve: data => dispatch(joinAndReserve(data)),
  setThirdPartyBooking: () => dispatch(setThirdPartyBooking()),
  toggleCostSummaryModalVisible: () => dispatch(toggleCostSummaryModalVisible()),
});

export default connect(null, mapDispatchToProps)(JoinReserveForm);
