// @flow
import React from 'react';
import { connect, useSelector } from 'react-redux';
import Box from '@material-ui/core/Box';
import {
  getEarningPoints,
  getLocationMap,
} from '../../../../selectors';
import { getLocationFromLinks } from '../../../../actions/booking/utils';
import UpcomingConfirmedBookingDetails from './upcoming-booking-details';
import ConfirmBookingApp from '../../../confirmed-booking-app';
import './index.css';
import DeleteBookingModal from '../../../modals/delete-booking';
import { getDesktopStyles } from './styles';

type Props = {
  booking: Object,
  vehicle: Object,
  isLoggedIn: boolean,
}

export function UpcomingReservation(props: Props) {
  const {
    booking,
    vehicle,
    isLoggedIn,
  } = props;

  const {
    confirmation_token,
    coverageSelection,
    dropoff_at,
    id,
    links,
    pickup_at,
    pricing,
    token,
    type,
  } = booking;

  const { total, per_day } = pricing;
  const { coverageOptions } = coverageSelection;

  const {
    containerStyles,
  } = getDesktopStyles();

  const locationMap = useSelector(getLocationMap);
  const earningPoints = useSelector(getEarningPoints);
  const locationId = getLocationFromLinks(links);
  const pickupLocation = (locationMap[locationId]) ? locationMap[locationId] : {
    airport_code: 'ORD',
    multi_car_display_name: '',
    name: 'Chicago',
    time_zone: 'America/Chicago',
  };
  const { name } = pickupLocation;

  return (
    <Box
      style={{
        display: 'flex',
        flex: '1',
      }}
    >
      <DeleteBookingModal
        id={id}
        confirmation_token={confirmation_token}
        total={total}
      />
      <Box {...containerStyles}>
        <>
          <UpcomingConfirmedBookingDetails
            vehicle={vehicle}
            per_day={per_day}
            total={total}
            location={name}
            earningPoints={earningPoints}
            pickupDateTime={pickup_at}
            dropoffDateTime={dropoff_at}
            coverageOptions={coverageOptions}
            pickupLocation={pickupLocation}
            isLoggedIn={isLoggedIn}
            id={id}
            recentBookingToken={token}
            reservationState={type}
            confirmationNumber={confirmation_token}
          />
          <ConfirmBookingApp />
        </>
      </Box>
    </Box>
  );
}

export const mapStateToProps = state => ({
  isLoggedIn: state.appReducer.isLoggedIn,
});

export default connect(mapStateToProps, null)(UpcomingReservation);
