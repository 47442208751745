// @flow
import Mui from './Mui';
import MuiBackdrop from './MuiBackdrop';
import MuiButton from './MuiButton';
import MuiDialog from './MuiDialog';
import MuiCheckbox from './MuiCheckbox';
import MuiFormControlLabel from './MuiFormControlLabel';
import MuiFormHelperText from './MuiFormHelperText';
import MuiFormLabel from './MuiFormLabel';
import MuiIconButton from './MuiIconButton';
import MuiInput from './MuiInput';
import MuiListItem from './MuiListItem';
import MuiLink from './MuiLink';
import MuiMenu from './MuiMenu';
import MuiMenuItem from './MuiMenuItem';
import MuiPaper from './MuiPaper';
import MuiTypography from './MuiTypography';
import MuiSelect from './MuiSelect';
import MuiSvgIcon from './MuiSvgIcon';

const overrides = {
  Mui,
  MuiBackdrop,
  MuiButton,
  MuiDialog,
  MuiCheckbox,
  MuiFormControlLabel,
  MuiFormHelperText,
  MuiFormLabel,
  MuiIconButton,
  MuiInput,
  MuiLink,
  MuiListItem,
  MuiMenu,
  MuiMenuItem,
  MuiPaper,
  MuiTypography,
  MuiSelect,
  MuiSvgIcon,
};

export default overrides;
