// @flow
import * as React from 'react';

type Props = {
  width?: string;
  children: React.ReactNode;
}

const defaultProps = {
  width: '28.125rem',
};

function FormWrapper({ width, children }: Props) {
  return (
    <div
      style={{
        margin: '6.25rem auto',
        padding: '0 1.5rem',
        display: 'flex',
        flexFlow: 'column',
        flex: `0 1 ${width}`,
        maxWidth: width,
      }}
    >
      {children}
    </div>
  );
}

FormWrapper.defaultProps = defaultProps;
export default FormWrapper;
