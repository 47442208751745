// @flow
import { COLORS } from '../constants';

const MuiFormLabel = {
  root: {
    color: COLORS.GREY2,
  },
};

export default MuiFormLabel;
