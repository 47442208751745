// @flow
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import baseTheme from 'material-ui/styles/baseThemes/lightBaseTheme';
import PropTypes from 'prop-types';
import { Button, Text } from '@audi/audi-ui-react';
import { logout, toggleChevron, toggleSideNav } from '../../actions';
import { trackBookingStart } from '../../actions/booking';
import { getHomeUrl } from '../../utils/http/config';
import { formatUserNameOrEmail } from '../../utils/formatters/index';
import './index.css';
import AudiText from '../nav-header/audi-text';

type State = {
  navigation: Array<Object>,
  help: Array<Object>,
}

type Props = {
  isLoggedIn: boolean,
  sideNavVisible: boolean,
  toggleSideNav: () => void,
  toggleChevron: () => void,
  trackBookingStart: () => void,
  logout: () => void,
  pathname: string,
  user: Object,
  userLoyaltyPoints: number,
  chevronExpanded: boolean,
}

type NavLinkProps = {
  isLoggedIn: boolean,
  nav: Object,
  rootProps: Object,
};

export function dispatchActions(props: Props, actions) {
  actions.forEach((x) => {
    props[x].call();
  });
}

export function NavLink(props: NavLinkProps) {
  const {
    isLoggedIn,
    nav: {
      external,
      path,
      actions,
      title,
      showOnLoggedOut,
      showOnLogin,
    },
    rootProps,
  } = props;

  if (!external) {
    if (((!isLoggedIn && showOnLoggedOut)
      || (isLoggedIn && showOnLogin))) {
      return (
        <li>
          <Link
            to={path}
            onClick={() => dispatchActions(rootProps, actions)}
          >
            <span>{ title }</span>
          </Link>
        </li>
      );
    }
  } else {
    return (
      <li>
        <a
          href={path}
          onClick={() => dispatchActions(rootProps, actions)}
        >
          <span>{ title }</span>
        </a>
      </li>
    );
  }
  return null;
}

const renderUserNameOrEmail = user => (
  <p>
    { formatUserNameOrEmail(user) }
  </p>
);

export class SideNav extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
    };
  }

  getChildContext() {
    return { muiTheme: getMuiTheme(baseTheme) };
  }

  render() {
    const {
      isLoggedIn,
      sideNavVisible,
      toggleSideNav,
      toggleChevron,
      chevronExpanded,
      user,
      pathname,
      logout,
      trackBookingStart,
      userLoyaltyPoints,
    } = this.props;

    const editingReservation = pathname && (pathname.includes('edit-date') || pathname.includes('edit-coverage') || pathname.includes('booking') || pathname.includes('long-term-drive'));

    const handleTrackBookingStart = () => {
      trackBookingStart('header-promo-ribbon');
    };

    return (
      <div className={`sc-sidenav ${(sideNavVisible) ? 'open' : ''}`}>
        <nav
          aria-hidden={sideNavVisible ? 'false' : 'true'}
          aria-expanded={sideNavVisible ? 'true' : 'false'}
          role="navigation"
        >
          <span className="header">
            <span className="audi-text-outer">
              <AudiText href={getHomeUrl()} />
            </span>
            <IconButton onClick={toggleSideNav}>
              <CloseIcon className="close-icon icon-sizing" />
            </IconButton>
          </span>
          <span className="dropdown-links">
            <a className="audi-owners" href={`${getHomeUrl()}always-audi/`}>
              <Text as="h2" variant="copy1">Audi Owners</Text>
            </a>
            {isLoggedIn
              ? (
                <>
                  <span className="username-email-dropdown">
                    <Text as="h2" variant="copy1" weight="bold" className="username-or-email-text">
                      { renderUserNameOrEmail(user) }
                    </Text>
                    <IconButton onClick={toggleChevron} className="clickable">
                      {chevronExpanded
                        ? <ChevronRightIcon className="chevron icon-sizing chevron-up" />
                        : <ChevronRightIcon className="chevron icon-sizing chevron-down" />
                      }
                    </IconButton>
                  </span>
                  {chevronExpanded && (
                  <>
                    <div className="loyalty-points-container">
                      <span className="loyalty-points-text spacing">
                        <Text as="p" variant="copy1">Points</Text>
                      </span>
                      <span className="loyalty-points-number spacing">
                        <Text as="p" variant="copy1">{userLoyaltyPoints}</Text>
                      </span>
                    </div>
                    <a className="spacing" href="/reservations">
                      <Text as="h2" variant="copy1">Reservations</Text>
                    </a>
                    <a className="spacing" href="/account">
                      <Text as="h2" variant="copy1">Account</Text>
                    </a>
                    <button type="button" className="spacing button-that-looks-like-a-link" href="" onClick={logout}>
                      <Text as="h2" variant="copy1">Log out</Text>
                    </button>
                  </>
                  )}
                </>
              ) : (
                <a
                  className="login spacing"
                  onClick={toggleSideNav}
                  href="/login"
                >
                  <Text as="p" variant="copy1">Log in</Text>
                </a>
              )
              }
            {!editingReservation
              && (
              <span className="mobile-booking">
                <Button
                  id="top-nav-book-now-button"
                  href="/booking"
                  isExternal={false}
                  noBorder
                  size="small"
                  onClick={handleTrackBookingStart}
                  variant="primary"
                >
                  Book now
                </Button>
              </span>
              )
          }
          </span>
        </nav>
        <button
          type="button"
          className="overlay"
          onClick={toggleSideNav}
        />
      </div>
    );
  }
}

SideNav.childContextTypes = {
  muiTheme: PropTypes.shape().isRequired,
};

export const mapDispatchToProps = dispatch => ({
  logout: () => dispatch(logout()),
  trackBookingStart: () => dispatch(trackBookingStart()),
  toggleSideNav: () => dispatch(toggleSideNav()),
  toggleChevron: () => dispatch(toggleChevron()),
});

export const mapStateToProps = state => ({
  user: state.accountReducer.user,
  userLoyaltyPoints: state.accountReducer.userPoints.total,
  sideNavVisible: state.appReducer.sideNavVisible,
  chevronExpanded: state.appReducer.chevronExpanded,
  isLoggedIn: state.appReducer.isLoggedIn,
});

export default connect(mapStateToProps, mapDispatchToProps)(SideNav);
