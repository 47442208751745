/* eslint-disable no-use-before-define,no-unused-vars,no-nested-ternary */
// @flow
import moment from 'moment';
import 'moment-timezone';
import * as BookingActions from '../index';
import * as Actions from '../../index';

import { getFleetFromLinks, getLocationfromBooking } from '../utils';
import { getErrorMessage, setToastTimeout } from '../../../utils';
import { filterCoverageOptions } from '../../../utils/filters';

import HTTP from '../../../utils/http';
import history from '../../../history';
import * as BookingModifyTypes from './constants';
import { getReservationDetailsHref } from '../../../containers/reservations/reservation-detail/helpers';

export const SILVERCAR_OPTIONS = ['everything_you_need', 'just_the_car', 'other_people_and_their_things', 'you_and_your_things'];

export const clearBooking = () => ({
  type: BookingModifyTypes.MODIFY_BOOKING,
  subType: BookingModifyTypes.CLEAR_BOOKING,
});

export const getBookingError = (payload: Object) => ({
  type: BookingModifyTypes.MODIFY_BOOKING,
  subType: BookingModifyTypes.GET_BOOKING_ERROR,
  payload: getErrorMessage(payload),
});

export const getBookingSuccess = (payload: Object) => {
  let booking = Object.assign({}, payload);
  if (booking.pickup_at) {
    booking = {
      ...booking,
      dropoffDate: moment(booking.dropoff_at).utc().format(),
      dropoffTime: moment(booking.dropoff_at).utc().format(),
      pickupDate: moment(booking.pickup_at).utc().format(),
      pickupTime: moment(booking.pickup_at).utc().format(),
    };
  }
  if (booking && booking.coverages) {
    const { coverages } = booking;
    const { carrier, policy_number } = {
      carrier: coverages.find((x => x.carrier)) ? coverages.find((x => x.carrier)).carrier : '',
      policy_number: coverages.find((x => x.policy_number)) ? coverages.find((x => x.policy_number)).policy_number : '',
    };
    let params = {
      carrier: '',
      coverageType: '',
      policy_number: '',
      type: '',
    };

    const coverageArr = [];
    coverages.forEach(a => coverageArr.push(a.type));
    params = {
      ...params,
      carrier,
      policy_number,
      type: coverageArr.includes('personal_coverage')
        ? 'personal'
        : coverageArr.includes('corporate_coverage')
          ? 'corporate'
          : 'silvercar',
    };
    params = {
      ...params,
      coverageType: (params.type === 'silvercar') ? params.type : `${params.type}_coverage`,
    };

    booking = {
      ...booking,
      coverageSelection: {
        ...params,
        coverageOptions: coverageArr.includes('everything_you_need')
          ? [
            'everything_you_need',
            'just_the_car',
            'other_people_and_their_things',
            'you_and_your_things',
          ] : coverageArr,
      },
    };
  }
  return {
    type: BookingModifyTypes.MODIFY_BOOKING,
    subType: BookingModifyTypes.GET_BOOKING_SUCCESS,
    payload: booking,
  };
};

export const getBooking = (id: number, type: string) => (
  (dispatch: Function, getStore: Function) => (
    HTTP.bookings.getById(id)
      .then((response) => {
        const { locationMap } = getStore().bookingReducer.locations;
        const location = getLocationfromBooking(response.data, locationMap);
        dispatch(setPickupLocation(location));
        dispatch(getBookingSuccess({
          ...response.data,
          type,
        }));
      })
      .catch(error => dispatch(getBookingError(error)))
  )
);

export const getBookingPricingSuccess = (payload: Object) => ({
  type: BookingModifyTypes.MODIFY_BOOKING,
  subType: BookingModifyTypes.GET_BOOKING_PRICING_SUCCESS,
  payload,
});

export const getBookingPricingError = (payload: Object) => ({
  type: BookingModifyTypes.MODIFY_BOOKING,
  subType: BookingModifyTypes.GET_BOOKING_PRICING_ERROR,
  payload: getErrorMessage(payload),
});

export const getBookingPricing = (params: Object) => (
  (dispatch: Function) => (
    HTTP.bookings.getBookingPricing({
      ...params,
    })
      .then((resp) => {
        dispatch(setModifiedBookingPricing(resp.data));
        dispatch(getBookingPricingSuccess(resp.data));
        dispatch(toggleUpdateBookingModalVisible());
      })
      .catch((error) => {
        setToastTimeout(setNetworkReset());
        dispatch(getBookingPricingError(error));
      }))
);

export const getCoverageOptionsSuccess = (payload: Array<Object>) => (
  (dispatch: Function, getStore: Function) => {
    const {
      links,
    } = getStore().bookingReducer.modifyBooking.booking;

    const fleetId = getFleetFromLinks(links);

    const filteredCoverage = payload
      .filter(({ fleet_id: coverageFleetId }) => String(coverageFleetId) === fleetId)
      .sort((a, b) => b.price - a.price);

    return dispatch({
      type: BookingModifyTypes.MODIFY_BOOKING,
      subType: BookingModifyTypes.GET_COVERAGE_OPTIONS_SUCCESS,
      payload: filteredCoverage,
    });
  }
);

export const getCoverageOptionsError = (payload: Object) => ({
  type: BookingModifyTypes.MODIFY_BOOKING,
  subType: BookingModifyTypes.GET_COVERAGE_OPTIONS_ERROR,
  payload: getErrorMessage(payload),
});

export const getCoverageOptionsLoading = (status: boolean) => ({
  type: BookingModifyTypes.MODIFY_BOOKING,
  subType: BookingModifyTypes.GET_COVERAGE_OPTIONS_LOADING,
  payload: status,
});

export const getCoverageOptions = (fleetId: Number, pickup: string) => (
  (dispatch: Function, getStore: Function) => {
    dispatch(getCoverageOptionsLoading(true));
    return HTTP.coverage.getPayLaterOptions(fleetId, pickup)
      .then((response) => {
        dispatch(getCoverageOptionsLoading(false));
        return dispatch(getCoverageOptionsSuccess(response.data));
      })
      .catch((error) => {
        dispatch(getCoverageOptionsLoading(false));
        return dispatch(getCoverageOptionsError(error));
      });
  }
);

export const getCoveragePricingSuccess = (payload: Object) => ({
  type: BookingModifyTypes.MODIFY_BOOKING,
  subType: BookingModifyTypes.GET_COVERAGE_PRICING_SUCCESS,
  payload,
});

export const getCoveragePricingError = (payload: Object) => ({
  type: BookingModifyTypes.MODIFY_BOOKING,
  subType: BookingModifyTypes.GET_COVERAGE_PRICING_ERROR,
  payload: getErrorMessage(payload),
});

export const getCoveragePricing = (params: Object) => (
  (dispatch: Function) => {
    const { coverageType, id, pricing: { total } } = params;
    let { coverages } = params;
    if (coverageType === 'silvercar') {
      coverages = filterCoverageOptions(coverages);
    } else {
      coverages = [coverageType];
    }

    return HTTP.bookings.getCoveragePricing({
      coverages,
      id,
    })
      .then(({ data }) => {
        dispatch(setModifiedBookingPricing({ ...data, total }));
        dispatch(getCoveragePricingSuccess({ ...data, total }));
        dispatch(toggleUpdateBookingModalVisible());
      })
      .catch(error => dispatch(getCoveragePricingError(error)));
  }
);

export const getRentalPricingSuccess = (payload: Object) => ({
  type: BookingModifyTypes.MODIFY_BOOKING,
  subType: BookingModifyTypes.GET_RENTAL_PRICING_SUCCESS,
  payload,
});

export const getRentalPricingError = (payload: Object) => ({
  type: BookingModifyTypes.MODIFY_BOOKING,
  subType: BookingModifyTypes.GET_RENTAL_PRICING_ERROR,
  payload: getErrorMessage(payload),
});

export const getRentalPricing = (params: Object) => (
  (dispatch: Function) => (
    HTTP.rentals.getRentalPricing({ ...params })
      .then((resp) => {
        dispatch(setModifiedBookingPricing(resp.data));
        dispatch(getBookingPricingSuccess(resp.data));
        dispatch(toggleUpdateBookingModalVisible());
      })
      .catch(error => dispatch(getBookingPricingError(error)))
  )
);

export const getBookingAvailabilitySuccess = (payload: Array<Object>) => (
  (dispatch: Function, getStore: Function) => {
    const { booking } = getStore().bookingReducer.modifyBooking;
    history.push(getReservationDetailsHref({
      id: booking.id,
      path: 'edit-time',
      type: booking.type,
    }));
    dispatch({
      type: BookingModifyTypes.MODIFY_BOOKING,
      subType: BookingModifyTypes.GET_BOOKING_AVAILABILITY_SUCCESS,
      payload,
    });
  }
);

export const getBookingAvailabilityError = (payload: Object) => {
  setToastTimeout(setNetworkReset());
  return {
    type: BookingModifyTypes.MODIFY_BOOKING,
    subType: BookingModifyTypes.GET_BOOKING_AVAILABILITY_ERROR,
    payload: getErrorMessage(payload),
  };
};

export const getBookingAvailability = (id: number, data: Object) => (
  (dispatch: Function) => (
    HTTP.bookings.getBookingAvailability(id, data)
      .then(response => dispatch(getBookingAvailabilitySuccess(response.data)))
      .catch(error => dispatch(getBookingAvailabilityError(error)))
  )
);

export const getRentalAvailabilitySuccess = (payload: Array<Object>) => (
  (dispatch: Function, getStore: Function) => {
    const { booking } = getStore().bookingReducer.modifyBooking;
    history.push(getReservationDetailsHref({
      id: booking.id,
      path: 'edit-time',
      type: booking.type,
    }));
    dispatch({
      type: BookingModifyTypes.MODIFY_BOOKING,
      subType: BookingModifyTypes.GET_RENTAL_AVAILABILITY_SUCCESS,
      payload,
    });
  }
);

export const getRentalAvailabilityError = (payload: Object) => {
  setToastTimeout(setNetworkReset());
  return {
    type: BookingModifyTypes.MODIFY_BOOKING,
    subType: BookingModifyTypes.GET_RENTAL_AVAILABILITY_ERROR,
    payload: getErrorMessage(payload),
  };
};

export const getRentalAvailability = (params: Object) => (
  (dispatch: Function) => (
    HTTP.rentals.availability(params)
      .then(response => dispatch(getRentalAvailabilitySuccess(response.data)))
      .catch(error => dispatch(getRentalAvailabilityError(error)))
  )
);

export const getReceiptSuccess = (payload: Object) => ({
  type: BookingModifyTypes.MODIFY_BOOKING,
  subType: BookingModifyTypes.GET_RECEIPT_SUCCESS,
  payload,
});

export const getReceiptError = (payload: Object) => ({
  type: BookingModifyTypes.MODIFY_BOOKING,
  subType: BookingModifyTypes.GET_RECEIPT_ERROR,
  payload: getErrorMessage(payload),
});

export const getReceipt = (id: number, type: string) => (
  (dispatch: Function) => (
    HTTP.rentals.getReceipt(id)
      .then(response => dispatch(getReceiptSuccess(response.data)))
      .catch(error => dispatch(getReceiptError(error)))
  )
);

export const getRental = (id: number, type: string) => (
  (dispatch: Function, getStore: Function) => (
    HTTP.rentals.getById(id)
      .then((response) => {
        const { locationMap } = getStore().bookingReducer.locations;
        const location = getLocationfromBooking(response.data, locationMap);
        dispatch(setPickupLocation(location));
        dispatch(getBookingSuccess({
          ...response.data,
          type,
        }));
      })
      .catch(error => dispatch(getBookingError(error)))
  )
);

export const redirectToDates = () => (
  (dispatch: Function, getStore: Function) => {
    const { booking } = getStore().bookingReducer.modifyBooking;
    history.push(getReservationDetailsHref({
      id: booking.id,
      path: 'edit-date',
      type: booking.type,
    }));
    return '';
  }
);

// setters
export const setModifyBookingSubmitting = (status: boolean) => ({
  type: BookingModifyTypes.MODIFY_BOOKING,
  subType: BookingModifyTypes.SET_MODIFY_BOOKING_SUBMITTING,
  payload: status,
});

export const setCoverage = (payload: Object) => (
  (dispatch: Function) => (
    Promise.resolve().then(() => {
      const type = payload.coverageType.replace('_coverage', '');
      return dispatch({
        type: BookingModifyTypes.MODIFY_BOOKING,
        subType: BookingModifyTypes.SET_COVERAGE,
        payload: {
          ...payload,
          type,
        },
      });
    })
  )
);

export const setCoverageOptions = (payload: Object) => (
  (dispatch: Function, getStore: Function) => (
    Promise.resolve().then(() => {
      const option = payload;
      let newOptions = [];
      const {
        coverageOptions,
      } = getStore().bookingReducer.modifyBooking.modifiedBooking.coverageSelection;

      if (option === 'everything_you_need') {
        if (coverageOptions.includes('everything_you_need')) {
          newOptions = [];
        } else {
          newOptions = SILVERCAR_OPTIONS;
        }
      } else {
        newOptions = [...coverageOptions];
        if (newOptions.includes(option)) {
          newOptions.splice(newOptions.indexOf(option), 1);
          if (coverageOptions.includes('everything_you_need')) {
            newOptions.splice(newOptions.indexOf('everything_you_need'), 1);
          }
        } else {
          newOptions.push(option);
          if (
            newOptions.includes('just_the_car')
            && newOptions.includes('other_people_and_their_things')
            && newOptions.includes('you_and_your_things')
            && !newOptions.includes('everything_you_need')
          ) {
            newOptions = SILVERCAR_OPTIONS;
          }
        }
      }
      return dispatch({
        type: BookingModifyTypes.MODIFY_BOOKING,
        subType: BookingModifyTypes.SET_COVERAGE_OPTIONS,
        payload: newOptions,
      });
    })
  )
);

export const setDropoffDate = (payload: string) => ({
  type: BookingModifyTypes.MODIFY_BOOKING,
  subType: BookingModifyTypes.SET_DROPOFF_DATE,
  payload: moment(payload).utc().format(),
});

export const setDropoffTime = (payload: Object) => ({
  type: BookingModifyTypes.MODIFY_BOOKING,
  subType: BookingModifyTypes.SET_DROPOFF_TIME,
  payload: moment(payload).utc().format(),
});

export const setModifiedBooking = () => ({
  type: BookingModifyTypes.MODIFY_BOOKING,
  subType: BookingModifyTypes.SET_MODIFIED_BOOKING,
});

export const setModifiedBookingPricing = (payload: Object) => {
  const { average_base_price, currency_code, total } = payload;
  const pricing = {
    currency_code,
    per_day: average_base_price,
    total,
  };
  return {
    type: BookingModifyTypes.MODIFY_BOOKING,
    subType: BookingModifyTypes.SET_MODIFIED_BOOKING_PRICING,
    payload: pricing,
  };
};

export const setNetworkError = (payload: Object) => ({
  type: BookingModifyTypes.MODIFY_BOOKING,
  subType: BookingModifyTypes.NETWORK_ERROR,
  payload: getErrorMessage(payload),
});

export const setNetworkReset = () => ({
  type: BookingModifyTypes.MODIFY_BOOKING,
  subType: BookingModifyTypes.NETWORK_RESET,
});

export const setPickupDate = (payload: string) => ({
  type: BookingModifyTypes.MODIFY_BOOKING,
  subType: BookingModifyTypes.SET_PICKUP_DATE,
  payload: moment(payload).utc().format(),
});

export const setPickupTime = (payload: Object) => ({
  type: BookingModifyTypes.MODIFY_BOOKING,
  subType: BookingModifyTypes.SET_PICKUP_TIME,
  payload: moment(payload).utc().format(),
});

export const setPickupLocation = (payload: Object) => ({
  type: BookingModifyTypes.MODIFY_BOOKING,
  subType: BookingModifyTypes.SET_PICKUP_LOCATION,
  payload,
});

export const setSelectedFleetPricing = (payload: Object) => ({
  type: BookingModifyTypes.MODIFY_BOOKING,
  subType: BookingModifyTypes.SET_SELECTED_FLEET_PRICING,
  payload,
});

export const setVehicle = (payload: Object) => ({
  type: BookingModifyTypes.MODIFY_BOOKING,
  subType: BookingModifyTypes.SET_VEHICLE,
  payload,
});

export const toggleUpdateBookingModalVisible = () => ({
  type: BookingModifyTypes.MODIFY_BOOKING,
  subType: BookingModifyTypes.TOGGLE_MODIFY_BOOKING_MODAL_VISIBLE,
});

// updaters
export const updateBookingCancel = () => ({
  type: BookingModifyTypes.MODIFY_BOOKING,
  subType: BookingModifyTypes.UPDATE_BOOKING_CANCEL,
  payload: 'Your updates have been cancelled.',
});

export const cancelBookingUpdate = (booking: Object) => (
  (dispatch: Function) => {
    history.push(getReservationDetailsHref({
      id: booking.id,
      type: booking.type,
    }));
    dispatch(toggleUpdateBookingModalVisible());
    dispatch(updateBookingCancel());
    setToastTimeout(setNetworkReset());
  }
);

export const updateBookingSuccess = (booking: Object) => {
  history.push(getReservationDetailsHref({
    id: booking.id,
    type: booking.type,
  }));
  return {
    type: BookingModifyTypes.MODIFY_BOOKING,
    subType: BookingModifyTypes.UPDATE_BOOKING_SUCCESS,
    payload: 'Your reservation has been updated.',
  };
};

export const updateBookingError = (payload: Object, dispatch: Function) => {
  dispatch(toggleUpdateBookingModalVisible());
  return {
    type: BookingModifyTypes.MODIFY_BOOKING,
    subType: BookingModifyTypes.UPDATE_BOOKING_ERROR,
    payload: getErrorMessage(payload),
  };
};

export const updateBooking = (id: Number, data: Object) => (
  (dispatch: Function) => {
    dispatch(setModifyBookingSubmitting(true));
    return HTTP.bookings.updateBooking(id, data)
      .then(() => {
        dispatch(clearBooking());
        dispatch(updateBookingSuccess(data));
        dispatch(toggleUpdateBookingModalVisible());
        dispatch(BookingActions.getBookings(1));
        dispatch(BookingActions.getRentals(1));
        dispatch(setModifyBookingSubmitting(false));
        setToastTimeout(setNetworkReset());
      })
      .catch((error) => {
        dispatch(setModifyBookingSubmitting(false));
        dispatch(updateBookingError(error, dispatch));
      });
  }
);

export const updateCoverage = (id: Number, data: Object) => (
  (dispatch: Function) => {
    const { coverageSelection } = data;
    const {
      carrier,
      coverageOptions,
      coverageType,
      policy_number,
    } = coverageSelection;

    let coverage = [];

    if (coverageOptions.includes('everything_you_need')) {
      coverage = [{
        carrier: '',
        policy_number: '',
        type: 'everything_you_need',
      }];
    } else if (coverageType === 'personal_coverage' || coverageType === 'corporate_coverage') {
      coverage = [{
        carrier: carrier || 'Personal Auto Coverage',
        policy_number: policy_number || '',
        type: coverageType,
      }];
    } else {
      coverageOptions.forEach((x) => {
        coverage.push({
          carrier: '',
          policy_number: '',
          type: x,
        });
      });
    }

    dispatch(setModifyBookingSubmitting(true));
    return HTTP.bookings.updateCoverage(id, coverage)
      .then(() => {
        dispatch(clearBooking());
        dispatch(updateBookingSuccess({
          ...data,
          type: 'upcoming',
        }));
        dispatch(toggleUpdateBookingModalVisible());
        dispatch(BookingActions.getBookings(1));
        dispatch(BookingActions.getRentals(1));
        dispatch(setModifyBookingSubmitting(false));
        setToastTimeout(setNetworkReset());
      })
      .catch((error) => {
        dispatch(setModifyBookingSubmitting(false));
        return dispatch(updateBookingError(error, dispatch));
      });
  }
);


export const extendRental = (id: Number, data: Object) => (
  (dispatch: Function) => {
    dispatch(setModifyBookingSubmitting(true));
    return HTTP.rentals.extendRental(id, data)
      .then(() => {
        dispatch(clearBooking());
        dispatch(updateBookingSuccess(data));
        dispatch(toggleUpdateBookingModalVisible());
        dispatch(BookingActions.getBookings(1));
        dispatch(BookingActions.getRentals(1));
        dispatch(setModifyBookingSubmitting(false));
        setToastTimeout(setNetworkReset());
      })
      .catch((error) => {
        dispatch(setModifyBookingSubmitting(false));
        return dispatch(updateBookingError(error, dispatch));
      });
  }
);

export const updateBookingPricing = () => (
  (dispatch: Function, getStore: Function) => {
    const { modifiedBooking } = getStore().bookingReducer.modifyBooking;
    const {
      pickupTime,
      dropoffTime,
      id,
    } = modifiedBooking;

    dispatch(getBookingPricing({
      id,
      pickup_at: pickupTime,
      dropoff_at: dropoffTime,
    }));
  }
);

export const updateRentalPricing = () => (
  (dispatch: Function, getStore: Function) => (
    Promise.resolve().then(() => {
      const { modifiedBooking } = getStore().bookingReducer.modifyBooking;
      const {
        pickupTime,
        dropoffTime,
        id,
      } = modifiedBooking;

      return dispatch(getRentalPricing({
        id,
        pickup_at: pickupTime,
        dropoff_at: dropoffTime,
      }));
    })
  )
);

export const updateFlightDetailsSuccess = () => ({
  type: BookingModifyTypes.MODIFY_BOOKING,
  subType: BookingModifyTypes.UPDATE_FLIGHT_DETAILS_SUCCESS,
});

export const updateFlightDetailsError = (payload: Object) => ({
  type: BookingModifyTypes.MODIFY_BOOKING,
  subType: BookingModifyTypes.UPDATE_FLIGHT_DETAILS_ERROR,
  payload: getErrorMessage(payload),
});

export const updateFlightDetails = (id: Number, data: Object) => (
  (dispatch: Function) => (
    HTTP.bookings.updateFlightDetails(id, data)
      .then(() => {
        dispatch(updateFlightDetailsSuccess());
        dispatch(getBooking(id, 'upcoming'));
        setToastTimeout(setNetworkReset());
      })
      .catch(error => dispatch(updateFlightDetailsError(error)))
  )
);

export function getLocationsFleetAvailabilitySuccess(payload: Array<Object>) {
  return {
    type: BookingModifyTypes.MODIFY_BOOKING,
    subType: BookingModifyTypes.GET_LOCATIONS_FLEET_AVAILABILITY_SUCCESS,
    payload,
  };
}


export function getLocationsFleetAvailabilityError(payload: Object) {
  setToastTimeout(setNetworkReset());
  return {
    type: BookingModifyTypes.MODIFY_BOOKING,
    subType: BookingModifyTypes.GET_LOCATIONS_FLEET_AVAILABILITY_ERROR,
    payload: getErrorMessage(payload),
  };
}

export const getLocationsFleetAvailability = (params: Object) => (
  (dispatch: Function) => (
    HTTP.locations.fleetAvailability(params)
      .then(({ data }) => {
        dispatch(getLocationsFleetAvailabilitySuccess(data));
        dispatch(getFleetPricing(data, params));
      })
      .catch((error) => {
        dispatch(getLocationsFleetAvailabilityError(error));
      })
  )
);

export const resetLocationsFleetAvailability = () => ({
  type: BookingModifyTypes.MODIFY_BOOKING,
  subType: BookingModifyTypes.RESET_LOCATIONS_FLEET_AVAILABILITY,
});

export const getFleetPricingSuccess = (payload: Object) => ({
  type: BookingModifyTypes.MODIFY_BOOKING,
  subType: BookingModifyTypes.GET_FLEET_PRICING_SUCCESS,
  payload,
});

export function getFleetPricingError(payload: Object) {
  setToastTimeout(setNetworkReset());
  return {
    type: BookingModifyTypes.MODIFY_BOOKING,
    subType: BookingModifyTypes.GET_FLEET_PRICING_ERROR,
    payload: getErrorMessage(payload),
  };
}

export const getFleetPricing = (fleets: Array<Object>) => (
  (dispatch: Function, getStore: Function) => {
    try {
      const {
        booking: {
          id: bookingId,
          links,
        },
      } = getStore().bookingReducer.modifyBooking;

      const bookingFleetId = getFleetFromLinks(links);

      const fleetPromises = fleets.map(async ({ id: fleetId }) => {
        let response;

        try {
          response = await HTTP.bookings.getBookingFleetPricing(
            bookingId,
            fleetId,
          );
        } catch ({ response: res }) {
          response = res;
        }

        const { data, status } = response;

        return {
          fleetId,
          data,
          status,
        };
      });

      return Promise.all(fleetPromises)
        .then((responses) => {
          responses.forEach((resp) => {
            const {
              status,
              data,
              fleetId,
            } = resp;

            if (status === 200) {
              dispatch(getFleetPricingSuccess({
                ...data,
                id: fleetId,
              }));

              // if vehicle has been selected, set fleet pricing for that vehicle
              if (fleetId === bookingFleetId) {
                dispatch(setSelectedFleetPricing({ ...data, id: fleetId }));
              }
            }
          });

          if (responses.filter(resp => resp.status === 200).length === 0) {
            dispatch(getFleetPricingError({
              response: responses[0],
            }));
          }
        });
    } catch (debugMessage) {
      return dispatch(Actions.logDebug({
        debugAction: BookingModifyTypes.GET_FLEET_PRICING_ERROR,
        debugMessage,
      }));
    }
  });

export const updateBookingFleet = (bookingId: string, fleetId: string) => (
  (dispatch: Function) => {
    dispatch(setModifyBookingSubmitting(true));
    return HTTP.bookings.updateBookingFleet(bookingId, fleetId)
      .then(() => {
        dispatch(clearBooking());
        dispatch(updateBookingFleetSuccess(bookingId));
        dispatch(toggleUpdateBookingModalVisible());
        dispatch(setModifyBookingSubmitting(false));
        setToastTimeout(setNetworkReset());
      })
      .catch((error) => {
        dispatch(setModifyBookingSubmitting(false));
        return dispatch(updateBookingFleetError(error));
      });
  }
);

export const updateBookingFleetSuccess = (bookingId: string) => {
  history.push(getReservationDetailsHref({
    id: bookingId,
    type: 'upcoming',
  }));
  return {
    type: BookingModifyTypes.MODIFY_BOOKING,
    subType: BookingModifyTypes.UPDATE_BOOKING_FLEET_SUCCESS,
    payload: 'Your reservation has been updated.',
  };
};

export const updateBookingFleetError = (payload: Object) => (
  (dispatch: Function) => {
    dispatch(toggleUpdateBookingModalVisible());
    dispatch({
      type: BookingModifyTypes.MODIFY_BOOKING,
      subType: BookingModifyTypes.UPDATE_BOOKING_FLEET_ERROR,
      payload: getErrorMessage(payload),
    });
  });
