// GET /promotions/:code
// @flow
import { apiUrl, headers } from '../config';
import axiosInstance from '../axiosInstance';

function get(code) {
  return axiosInstance({
    url: `${apiUrl.v2}/promotions/${code}`,
    headers: headers(),
  });
}

const promotions: Object = {
  get,
};

export default promotions;
