// @flow
import React, { useEffect, useState, Fragment } from 'react';
import { useSelector, connect } from 'react-redux';

import { Box, useMediaQuery } from '@material-ui/core';
import moment from 'moment';
import { ValidatorForm } from 'react-material-ui-form-validator';
import Loading from '../../../components/loading';
import history from '../../../history';

import {
  getCoverageOptions,
  toggleJoinReserveModalVisible,
  toggleTexasWaiverModalVisible,
  toggleCoverageWarningModalVisible,
  toggleProcessing,
  createBooking,
  trackCoverageType,
  setCoverage,
  setCoverageOptions,
  getLocationCurbsideDetail,
} from '../../../actions/booking';
import { shouldShowCoverageWarning } from '../../../utils';
import { formatMobileDateTime } from '../../../utils/formatters';
import {
  getActiveBooking,
  getEarningPoints,
  getActiveBookingIsProcessing,
  getIsLoggedIn,
  getSelectedFleetPricing,
  getCoverage,
  getIsReady,
  getRemainingAudiDays,
  getCurbsideDetails,
} from '../../../selectors';
import { setupCoverages } from '../../../components/coverage/utils';

import { SCVehicle } from '../../../components/vehicle';
import SelectCoverage from '../../../components/coverage-select';
import ReviewCoverage from '../../../components/review-coverage';
import Coverage from './constants';
import StickyFooter from '../sticky-footer';
import RebrandingMobileHeader from '../../../components/rebranding-mobile-header';
import ModifySelectionInputs from '../../../components/modify-vehicle-select';

import { getMobileStyles, getDesktopStyles } from './styles';

type Props = {
  fetchCoverageOptions: (fleetId: Number, date: string) => void;
  fetchCurbsideDetail: (locationId: number) => void;
  toggleCoverageWarningModal: () => void;
  toggleTexasWaiverModal: () => void;
  setCoverage: (type: string) => void;
  submitBooking: (
    fleetId: number | null,
    booking: Object,
    curbsideActive: boolean,
    curbsideDescription: string
  ) => void;
  trackCoverage: (value: Object) => void;
  toggleCoverageWarningModal: () => void;
  toggleIsProcessing: () => void;
  toggleJoinReserveModal: () => void;
  toggleTexasWaiverModal: () => void;
  updateCoverage: (value: Object) => void;
  updateCoverageOptions: (type: string) => void;
}

export const Review = (props: Props) => {
  const {
    fetchCoverageOptions,
    submitBooking,
    trackCoverage,
    toggleCoverageWarningModal,
    toggleIsProcessing,
    toggleJoinReserveModal,
    toggleTexasWaiverModal,
    updateCoverage,
    updateCoverageOptions,
    fetchCurbsideDetail,
  } = props;
  const activeBooking = useSelector(getActiveBooking);
  const curbsideDetails = useSelector(getCurbsideDetails);
  const earningPoints = useSelector(getEarningPoints);
  const isLoggedIn = useSelector(getIsLoggedIn);
  const coverages = useSelector(getCoverage);
  const isProcessing = useSelector(getActiveBookingIsProcessing);
  const fleetPricing = useSelector(getSelectedFleetPricing);
  const isReady = useSelector(getIsReady);
  const userAudiOwnerProgramDays = useSelector(getRemainingAudiDays);

  const [autoOnlyState, setAutoOnlyState] = useState(false);
  const [isReview, updateReviewState] = useState(false);
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('xs'));
  const {
    containerStyles,
    coverageStyles,
    vehicleStyles,
  } = isMobile ? getMobileStyles({ isReview }) : getDesktopStyles();

  const {
    coverage,
    vehicle,
    dropoffTime,
    pickupTime,
    pickupLocation,
    promo: {
      id: promoCode,
    },
  } = activeBooking;

  const {
    duration,
  } = fleetPricing;

  const {
    time_zone,
    address,
    multi_car_display_name,
  } = pickupLocation;

  const { fleet_id } = vehicle || {};

  const handleGetCoverageOptions = () => {
    const { pickupDate, vehicle: { data_key } } = activeBooking;
    if (pickupDate && data_key) {
      fetchCoverageOptions(fleet_id, moment(pickupDate).format());
    }
  };

  const shouldShowTexasWaiver = () => (
    address && coverage.type === Coverage.SILVERCAR
      ? address.state === 'TX'
      : false);

  const handleUpdateCoverageType = (coverageType) => {
    updateCoverage(coverageType);
    trackCoverage(coverageType);
  };

  const renderHeader = () => (
    <div className="section-subheader">
      <header>
        {isMobile ? renderMobileHeader() : <ModifySelectionInputs />}
      </header>
    </div>
  );

  const renderMobileHeader = () => {
    const pickupDateTime = formatMobileDateTime(
      pickupTime, pickupTime, pickupLocation.time_zone,
    );
    const dropoffDateTime = formatMobileDateTime(
      dropoffTime, dropoffTime, pickupLocation.time_zone,
    );

    return (
      <RebrandingMobileHeader
        showBackButton
        onBackButtonClicked={() => history.push('/booking/location-date-time')}
        title={pickupLocation && multi_car_display_name}
        secondTitle={`${pickupDateTime} - ${dropoffDateTime}`}
      />
    );
  };

  const handleSubmit = () => {
    if (!isReview) {
      return updateReviewState(true);
    }

    const { curbside_detail } = curbsideDetails;
    const curbsideActive = curbside_detail && curbside_detail.active;
    const curbsideDescription = curbsideActive && curbside_detail.description;
    const fleetId = vehicle ? vehicle.fleet_id : null;
    const booking = {
      pickup_at: pickupTime,
      dropoff_at: dropoffTime,
      coverages: setupCoverages(coverage),
      promo_code_id: promoCode,
      points: !earningPoints,
    };
    switch (true) {
      case shouldShowCoverageWarning(coverage.type, vehicle.data_key): {
        return toggleCoverageWarningModal();
      }
      case shouldShowTexasWaiver(): {
        return toggleTexasWaiverModal();
      }
      case (!shouldShowTexasWaiver() && !isLoggedIn): {
        return toggleJoinReserveModal();
      }
      case (!shouldShowTexasWaiver() && isLoggedIn && !isProcessing): {
        toggleIsProcessing();
        return submitBooking(
          fleetId,
          booking,
          curbsideActive,
          curbsideDescription,
        );
      }
      case (!shouldShowTexasWaiver() && isLoggedIn && isProcessing): {
        return '';
      }
      default:
        return [];
    }
  };

  const coverageContainer = (
    <>
      {isReview
        ? (
          <Box {...coverageStyles}>
            <ReviewCoverage
              isMobile={isMobile}
              editCoverage={() => updateReviewState(false)}
            />
          </Box>
        )
        : (
          <SelectCoverage
            autoOnlyState={autoOnlyState}
            duration={duration}
            handleUpdateCoverageType={handleUpdateCoverageType}
            updateCoverageOptions={updateCoverageOptions}
            activeCoverage={coverage}
            coverages={coverages}
            vehicleKey={vehicle.data_key}
          />
        )
      }
    </>
  );

  useEffect(() => {
    const pickupState = pickupLocation.address ? pickupLocation.address.state : null;
    const autoOnlyStates = ['MA', 'NY', 'VA'];
    setAutoOnlyState(autoOnlyStates.includes(pickupState));
    if (pickupLocation.id) fetchCurbsideDetail(pickupLocation.id);
  }, [pickupLocation.address, pickupLocation.id, fetchCurbsideDetail]);
  useEffect(handleGetCoverageOptions, [activeBooking.vehicle.data_key]);

  if (!(isReady && time_zone && vehicle)) {
    return <Loading />;
  }

  const hasAudiOwnerDays = userAudiOwnerProgramDays > 0;

  return (
    <Fragment>
      <ValidatorForm
        id="select-coverage"
        onSubmit={handleSubmit}
        style={{ width: '100%' }}
      >
        <Box {...containerStyles}>
          {renderHeader()}
          <Box {...vehicleStyles}>
            <SCVehicle
              key={fleet_id}
              vehicle={fleet_id ? vehicle : null}
              earningPoints={earningPoints}
              pricing={fleetPricing || {}}
              isLoggedIn={isLoggedIn}
              hasAudiOwnerDays={hasAudiOwnerDays}
              userAudiOwnerProgramDays={userAudiOwnerProgramDays}
              isMobile={isMobile}
              changeVehicle
            />
          </Box>
          {coverageContainer}
        </Box>
      </ValidatorForm>
      <StickyFooter />
    </Fragment>
  );
};

export const mapDispatchToProps = dispatch => ({
  submitBooking: (
    fleetId,
    booking,
    curbsideActive,
    curbsideDescription,
  ) => dispatch(createBooking(fleetId, booking, curbsideActive, curbsideDescription)),
  fetchCoverageOptions: (fleetId, pickup) => dispatch(getCoverageOptions(fleetId, pickup)),
  fetchCurbsideDetail: locationId => dispatch(getLocationCurbsideDetail(locationId)),
  updateCoverageOptions: options => dispatch(setCoverageOptions(options)),
  trackCoverage: coverageType => dispatch(trackCoverageType(coverageType)),
  toggleJoinReserveModal: () => dispatch(toggleJoinReserveModalVisible()),
  toggleTexasWaiverModal: () => dispatch(toggleTexasWaiverModalVisible()),
  toggleCoverageWarningModal: () => dispatch(toggleCoverageWarningModalVisible()),
  toggleIsProcessing: () => dispatch(toggleProcessing()),
  updateCoverage: coverage => dispatch(setCoverage(coverage)),
});

export default connect(null, mapDispatchToProps)(Review);
