// @flow
import { Focus, Section, Reducer } from './constants';
import { formatLocationName } from '../../../utils/formatters';

type State = {
  activeSection: string;
  continueDisabled: boolean;
  currentFocus: string;
  filteredLocations: [];
  location: {};
  locationName: string;
  pickupDisabled: boolean;
  pickupDate: string;
  resetDateTime: boolean;
  returnDisabled: boolean;
  returnDate: string;
}

type Action = {
  type: string;
  payload: any
}

export const initialState = {
  activeSection: Section.LOCATION,
  continueDisabled: true,
  currentFocus: Focus.LOCATION,
  filteredLocations: [],
  location: {},
  locationName: '',
  pickupDisabled: true,
  pickupDate: '',
  resetDateTime: false,
  returnDisabled: true,
  returnDate: '',
};

export const reducer = (state: State = initialState, action: Action) => {
  switch (action.type) {
    case Reducer.SET_ACTIVE_SECTION:
      return {
        ...state,
        activeSection: action.payload,
      };
    case Reducer.SET_LOCATION:
      return {
        ...state,
        activeSection: Section.DATE,
        location: action.payload,
        locationName: formatLocationName(action.payload),
        pickupDisabled: false,
        currentFocus: Focus.PICKUP,
        resetDateTime: false,
      };
    case Reducer.SET_LOCATION_NAME:
      return {
        ...state,
        locationName: action.payload,
      };
    case Reducer.SET_FILTERED_LOCATIONS:
      return {
        ...state,
        filteredLocations: action.payload,
      };
    case Reducer.SET_FOCUS:
      return {
        ...state,
        currentFocus: action.payload,
      };
    case Reducer.SET_PICKUP_DATE:
      return {
        ...state,
        pickupDate: action.payload,
        returnDisabled: false,
        returnDate: '',
        currentFocus: Focus.RETURN,
        activeSection: Section.DATE,
      };
    case Reducer.SET_PICKUP_TIME:
      return {
        ...state,
        pickupDate: action.payload,
        returnDisabled: false,
        activeSection: Section.TIME,
        currentFocus: Focus.TIME,
      };
    case Reducer.SET_RETURN_TIME:
      return {
        ...state,
        returnDate: action.payload,
        currentFocus: Focus.TIME,
      };
    case Reducer.SET_RETURN_DATE:
      return {
        ...state,
        activeSection: Section.TIME,
        returnDate: action.payload,
        currentFocus: Focus.TIME,
      };
    case Reducer.CLEAR_LOCATION:
      return {
        ...state,
        activeSection: Section.LOCATION,
        location: {},
        locationName: '',
        pickupDisabled: true,
        pickupDate: '',
        returnDisabled: true,
        returnDate: '',
        currentFocus: Focus.LOCATION,
        resetDateTime: true,
        continueDisabled: true,
      };
    case Reducer.DISABLE_SUBMIT:
      return {
        ...state,
        continueDisabled: true,
      };
    case Reducer.ENABLE_SUBMIT:
      return {
        ...state,
        continueDisabled: false,
      };
    default:
      return state;
  }
};
