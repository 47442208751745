export const errorMessages = {
  genericShort: 'Something went wrong.',
  refreshBrowser: 'Please refresh the browser and try again.',
};

export const APP_MESSAGE_TYPES = {
  NETWORK: 'network',
  SEARCH: 'search',
  PROCESSING: 'processing',
  LINK_EXPIRED: 'link_expired',
  SUCCESS: 'success',
  INFORMATION: 'information',
};

export const FAQ_LINK = 'https://support.audiondemand.com/hc/en-us/sections/360005225193-Always-Audi-FAQ';

export const SUPPORT_LINK = 'https://support.audiondemand.com/hc/en-us/requests/new';
