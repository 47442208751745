// @flow
import { apiUrl, headers } from '../config';
import axiosInstance from '../axiosInstance';

function claimBooking(confirmationNumber: string) {
  return axiosInstance({
    method: 'PUT',
    url: `${apiUrl.v2}/bookings/claims/${confirmationNumber}`,
    headers: headers(),
    data: null,
  });
}

function deleteById(id: Number | string) {
  return axiosInstance({
    method: 'DELETE',
    url: `${apiUrl.v2}/bookings/${+id}`,
    headers: headers(),
  });
}

function get(pg: Number) {
  const page = pg || 1;
  return axiosInstance({
    method: 'GET',
    url: `${apiUrl.v2}/bookings?page=${+page}&sort=pickup_at`,
    headers: headers(),
  });
}

function getById(id: Number | string) {
  return axiosInstance({
    method: 'GET',
    url: `${apiUrl.v2}/bookings/${+id}`,
    headers: headers(),
  });
}

function getByToken(token: string) {
  return axiosInstance({
    method: 'GET',
    url: `${apiUrl.v2}/bookings/token/${token}`,
    headers: headers(),
  });
}

function searchGDS(confirmationNumber: string) {
  return axiosInstance({
    method: 'GET',
    url: `${apiUrl.v2}/bookings/claims/search/${confirmationNumber}`,
    headers: headers(),
  });
}

function deleteByToken(token: string) {
  const newHeaders = headers();
  newHeaders.Authorization = token;
  return axiosInstance({
    method: 'DELETE',
    url: `${apiUrl.v2}/bookings/token/${token}`,
    headers: newHeaders,
  });
}

function claimBookingPostSignup(confirmationNumber: number, data) {
  return axiosInstance({
    method: 'PUT',
    url: `${apiUrl.v2}/bookings/claims/${confirmationNumber}/email`,
    headers: headers(),
    data,
  });
}

function updateBooking(id: number, data) {
  return axiosInstance({
    method: 'PUT',
    url: `${apiUrl.v2}/bookings/${id}`,
    headers: headers(),
    data,
  });
}

function updateBookingFleet(bookingId, fleetId) {
  return axiosInstance({
    method: 'PUT',
    url: `${apiUrl.v2}/bookings/${bookingId}/fleet`,
    headers: headers(),
    data: {
      id: fleetId,
    },
  });
}

function getBookingAvailability(params: Object) {
  const { id } = params;
  return axiosInstance({
    method: 'GET',
    url: `${apiUrl.v2}/bookings/${id}/availability`,
    headers: headers(),
    params,
  });
}

function getBookingPricing(params: Object) {
  const { id } = params;
  return axiosInstance({
    method: 'GET',
    url: `${apiUrl.v2}/bookings/${id}/pricing`,
    headers: headers(),
    params,
  });
}

function getBookingFleetPricing(bookingId, fleetId) {
  return axiosInstance({
    method: 'GET',
    url: `${apiUrl.v2}/bookings/${bookingId}/fleet/pricing?fleet_id=${fleetId}`,
    headers: headers(),
  });
}

function getCoveragePricing(params: Object) {
  const { id } = params;
  return axiosInstance({
    method: 'GET',
    url: `${apiUrl.v2}/bookings/${id}/coverages/pricing`,
    headers: headers(),
    params,
  });
}

function updateCoverage(id: number, data) {
  return axiosInstance({
    method: 'PUT',
    url: `${apiUrl.v2}/bookings/${id}/coverages`,
    headers: headers(),
    data,
  });
}

function updateFlightDetails(id: number, data) {
  return axiosInstance({
    method: 'PUT',
    url: `${apiUrl.v2}/bookings/${id}/flight`,
    headers: headers(),
    data,
  });
}

function attachTravelAgent(token: string, data) {
  return axiosInstance({
    method: 'POST',
    url: `${apiUrl.v2}/bookings/token/${token}/agent`,
    headers: headers(),
    data,
  });
}

function attachDeliveryRequest(token: string, data) {
  return axiosInstance({
    method: 'POST',
    url: `${apiUrl.v2}/bookings/token/${token}/delivery`,
    headers: headers(),
    data,
  });
}

function getCurbsideDetailByLocationId(id: number) {
  return axiosInstance({
    method: 'GET',
    url: `${apiUrl.v2}/locations/${id}/curbside_detail`,
    headers: headers(),
  });
}

const bookings: Object = {
  attachDeliveryRequest,
  attachTravelAgent,
  claimBooking,
  claimBookingPostSignup,
  deleteById,
  deleteByToken,
  get,
  getBookingAvailability,
  getBookingPricing,
  getBookingFleetPricing,
  getById,
  getByToken,
  getCoveragePricing,
  searchGDS,
  updateBooking,
  updateBookingFleet,
  updateCoverage,
  updateFlightDetails,
  getCurbsideDetailByLocationId,
};

export default bookings;
