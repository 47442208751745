// @flow
import Menu from '@material-ui/core/Menu';
import React, { useRef, useState } from 'react';
import { AudiPlatformProvider } from '@audi/audi-ui-react';
import { connect } from 'react-redux';
import {
  toggleDeleteBookingModalVisible,
} from '../../../../actions/booking';
import history from '../../../../history';
import ReservationMenu from './reservation-menu';
import ReservationMenuItem from './reservation-item';

type Props = {
  id: string,
  reservationState: string,
  isLoggedIn: boolean;
  toggleDeleteBookingModalVisible: () => void,
}

export function ReservationDropDown(props: Props) {
  const onModifyVehicleClick = () => history.push(`/reservations/${id}/edit-vehicle?type=${reservationState}`);
  const onModifyDateTimeClick = () => history.push(`/reservations/${id}/edit-date?type=${reservationState}`);
  const onModifyProtectionClick = () => history.push(`/reservations/${id}/edit-coverage?type=${reservationState}`);

  const {
    id,
    reservationState,
    isLoggedIn,
    toggleDeleteBookingModalVisible,
  } = props;

  const reservationDropDown = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClose = () => {
    if (reservationDropDown && reservationDropDown.current) {
      reservationDropDown.current.className = reservationDropDown.current.className.replace(' open', '');
    }
    setAnchorEl(null);
  };

  const handleClick = () => {
    reservationDropDown.current.className = `${reservationDropDown.current.className} open`;
    setAnchorEl(reservationDropDown.current);
  };

  const onClickCancelReservation = () => {
    handleClose();
    toggleDeleteBookingModalVisible();
  };

  const onClickViewRentalAgreement = () => {
    handleClose();
    window.open('https://api.silvercar.com/agreements/rental_agreement');
  };

  return (
    <AudiPlatformProvider>
      <ReservationMenu
        handleClick={handleClick}
        forwardRef={reservationDropDown}
        title="Manage your reservations"
        className="grey-font"
      />
      {
        isLoggedIn ? (
          <Menu
            id="account"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: -10,
              horizontal: 'right',
            }}
            getContentAnchorEl={null}
            PaperProps={{
              style: {
                width: '14rem',
                alignItems: 'right',
                display: 'flex',
                columnGap: '2rem',
                height: '12rem',
                overflow: 'hidden',
              },
            }}
          >
            <ReservationMenuItem
              text="Modify Vehicle"
              onClick={onModifyVehicleClick}
              onKeyPress={onModifyVehicleClick}
              handleClose={handleClose}
            />
            <ReservationMenuItem
              text="Modify Date and Time"
              handleClose={handleClose}
              onKeyPress={onModifyDateTimeClick}
              onClick={onModifyDateTimeClick}
            />
            <ReservationMenuItem
              text="Modify Protection"
              handleClose={handleClose}
              onClick={onModifyProtectionClick}
              onKeyPress={onModifyProtectionClick}
            />
            <ReservationMenuItem
              text="View Rental Agreement"
              onClick={onClickViewRentalAgreement}
              onKeyPress={onClickViewRentalAgreement}
            />
            <ReservationMenuItem
              text="Cancel reservation"
              onClick={onClickCancelReservation}
              onKeyPress={onClickCancelReservation}
            />
          </Menu>
        ) : (
          <Menu
            id="account"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: -10,
              horizontal: 'right',
            }}
            getContentAnchorEl={null}
            PaperProps={{
              style: {
                width: '14rem',
                alignItems: 'right',
                display: 'flex',
                columnGap: '2rem',
                height: '2rem',
                overflow: 'hidden',
              },
            }}
          >
            <ReservationMenuItem
              text="Cancel reservation"
              onClick={onClickCancelReservation}
              onKeyPress={onClickCancelReservation}
            />
          </Menu>
        )
      }

    </AudiPlatformProvider>
  );
}

export const mapDispatchToProps = dispatch => ({
  toggleDeleteBookingModalVisible: () => dispatch(toggleDeleteBookingModalVisible()),
});


export const mapStateToProps = state => ({
  isLoggedIn: state.appReducer.isLoggedIn,
});

export default connect(mapStateToProps, mapDispatchToProps)(ReservationDropDown);
