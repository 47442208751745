// @flow
import { COLORS } from '../constants';

const MuiListItem = {
  root: {
    color: COLORS.PRIMARY,
  },
  button: {
    '&:hover': {
      backgroundColor: COLORS.PRIMARY,
      color: COLORS.PRIMARY_CONTRAST,
    },
  },
};

export default MuiListItem;
