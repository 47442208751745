// @flow
import {
  Button,
  Text,
  TextField,
  AudiPlatformProvider,
} from '@audi/audi-ui-react';
import Box from '@material-ui/core/Box';
import baseTheme from 'material-ui/styles/baseThemes/lightBaseTheme';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { connect } from 'react-redux';

import HTTPFormStatus from '../http-form-status';
import { login } from '../../../actions';
import FormWrapper from '../../layout/form-wrapper';
import { validateEmail } from '../../../utils/validators';
import './index.css';

type Props = {
  httpResponseError: Boolean,
  httpResponseStatusMessage: string,
  inBooking: boolean,
  login: Function,
};

type State = {
  email: string,
  password: string,
};

export class LoginForm extends Component<Props, State> {
  constructor(props: Object) {
    super(props);

    this.state = {
      email: '',
      password: '',
    };
  }

  getChildContext() {
    return { muiTheme: getMuiTheme(baseTheme) };
  }

  handleChange(event: Object) {
    const { name, value } = event.target;
    let { isValidEmail } = this.state;
    isValidEmail = name === 'email' ? validateEmail(value) : isValidEmail;
    this.setState({ [name]: value, isValidEmail });
  }

  handleSubmit(event: Object) {
    event.preventDefault();
    const { login, inBooking } = this.props;
    const { email, password } = this.state;
    const data = {
      email,
      password,
    };

    login(data, inBooking);
  }

  render() {
    const { email, password, isValidEmail = true } = this.state;
    const { httpResponseError, httpResponseStatusMessage } = this.props;
    const disabled = !email || !password || !isValidEmail;

    const forgotPasswordLink = (
      <Text
        as="p"
        spaceInlineEnd="l"
        spaceStackEnd="xl"
        variant="copy2"
      >
        <a href="/forgot-password">Forgot password</a>
      </Text>
    );

    const createAccountLink = (
      <Text as="p" variant="copy2">
        <a href="/signup">Create an account</a>
      </Text>
    );

    return (
      <Fragment>
        {httpResponseStatusMessage && (
          <HTTPFormStatus
            category=""
            type={httpResponseError ? 'error' : 'success'}
            message={httpResponseStatusMessage}
          />
        )}
        <AudiPlatformProvider>
          <Box mt="6.25rem">
            <FormWrapper>
              <Text
                as="h2"
                className="log-in-text"
                variant="order2"
                weight="bold"
              >
                Log in
              </Text>
              <Box mt="1.9375rem" mb="2.8125rem">
                <ValidatorForm onSubmit={event => this.handleSubmit(event)}>
                  <TextField
                    inputId="text-field__email"
                    label="Email Address"
                    name="email"
                    type="email"
                    inputMode="email"
                    invalid={!isValidEmail}
                    required
                    value={email}
                    onChange={e => this.handleChange(e)}
                    validationMessage="Please enter a valid email address."
                    labelEffect="none"
                  />

                  <Box mt="1.4375rem" />
                  <TextField
                    inputId="text-field__password"
                    label="Password"
                    name="password"
                    onChange={e => this.handleChange(e)}
                    required
                    type="password"
                    value={password}
                    validationMessage="Password is required."
                    labelEffect="none"
                  />

                  <Box mt="2.5625rem">
                    <Button className="login-button" disabled={disabled} type="submit" variant="primary" stretch>
                      Log in
                    </Button>
                  </Box>
                </ValidatorForm>
              </Box>
              <div className="login-form-links">
                {forgotPasswordLink}
                {createAccountLink}
              </div>
            </FormWrapper>
          </Box>
        </AudiPlatformProvider>
      </Fragment>
    );
  }
}

LoginForm.childContextTypes = {
  muiTheme: PropTypes.shape().isRequired,
};

export const mapStateToProps = state => ({
  httpResponseError: state.appReducer.httpResponseError,
  httpResponseStatusMessage: state.appReducer.httpResponseStatusMessage,
  user: state.accountReducer.user,
});

export const mapDispatchToProps = dispatch => ({
  login: (data, inBooking) => dispatch(login(data, inBooking)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
