// @flow
import React from 'react';

type Props = {
  href: string,
  linkText: string,
  additionalText: string,
}

const SCLink = ({
  href,
  linkText,
  additionalText,
}: Props) => (
  <a
    className="sc-link"
    href={href}
    target="_blank"
    rel="noopener noreferrer"
  >
    { linkText }
    {additionalText
      && (
      <span>
        { additionalText }
      </span>
      )
    }
  </a>
);

export default SCLink;
