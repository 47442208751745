// @flow
import * as BookingModifyTypes from '../../../actions/booking/modify/constants';
import { getFleetFromLinks } from '../../../actions/booking/utils';

type State = {
  availability: Object,
  booking: Object,
  coverage: Array<Object>,
  fleetPricing: Object,
  location: Object,
  modified: boolean,
  modifiedBooking: Object,
  httpResponseError: boolean,
  httpResponseStatusMessage: string,
  isLoading: boolean,
  isSubmitting: boolean,
  modifyBookingModalVisible: boolean,
}

export const booking: Object = {
  confirmation_token: '',
  coverageSelection: {
    carrier: '',
    coverageOptions: [],
    coverageType: '',
    policy_number: '',
    type: '',
  },
  coverages: [],
  dropoff_at: '',
  flight: {
    airline: '',
    number: '',
  },
  id: 0,
  links: [],
  local_rental: false,
  pickup_at: '',
  pricing: {
    total: 0,
    per_day: 0,
    currency_code: 'USD',
    promotion: {},
    self_service: false,
    state: '',
    token: '',
  },
  type: '',
  selectedFleet: null,
};

export const pickupLocation: Object = {
  id: 0,
  name: '',
  airport_code: '',
  phone_number: '',
  text_number: '',
  fleet_type: '',
  multi_car_display_name: '',
  open_date: '',
  close_date: '',
  gds_code: '',
  description: '',
  time_zone: 'America/Chicago',
  bookable: false,
  hours: '',
  asset_code: '',
  address: {
    id: 0,
    line1: '',
    line2: '',
    city: '',
    state: '',
    zip: '',
    country: 'US',
    latitude: '',
    longitude: '',
  },
};

export const receiptPricingItem: Object = {
  items: [],
  label: '',
  total: 0,
};

export const receipt: Object = {
  awards: null,
  balance: 0,
  currency_code: 'USD',
  messages: null,
  payments: null,
  pricing: {
    adjustments: receiptPricingItem,
    coverage: receiptPricingItem,
    credits: receiptPricingItem,
    discount: receiptPricingItem,
    fees_and_concessions: receiptPricingItem,
    fuel: receiptPricingItem,
    rental: receiptPricingItem,
    security_deposit: receiptPricingItem,
    taxes: receiptPricingItem,
    tolls: receiptPricingItem,
  },
  total: 0,
  trip_details: {
    confirmation_number: '',
    pickup_city: '',
    pickup_airport_code: '',
    pickup_at: '',
    dropoff_at: '',
    duration: '',
  },
};

export const initialState: State = {
  booking,
  availability: {},
  coverage: [],
  coverageLoading: false,
  fleetAvailability: null,
  fleetPricing: [],
  httpResponseError: false,
  httpResponseStatusMessage: '',
  isLoading: false,
  isSubmitting: false,
  isValidPromoCode: false,
  modified: false,
  modifiedBooking: booking,
  pickupLocation,
  receipt,
  modifyBookingModalVisible: false,
};

export function modifyBookingReducer(state: State = initialState, action: Object) {
  switch (action.subType) {
    // GET ACTIONS
    case BookingModifyTypes.CLEAR_BOOKING:
      return {
        ...state,
        booking: {
          ...initialState.booking,
        },
        modifiedBooking: {
          ...initialState.booking,
        },
      };
    case BookingModifyTypes.SET_MODIFY_BOOKING_SUBMITTING:
      return {
        ...state,
        isSubmitting: action.payload,
      };
    case BookingModifyTypes.GET_BOOKING_SUCCESS:
      return {
        ...state,
        booking: {
          ...action.payload,
          selectedFleet: getFleetFromLinks(action.payload.links),
        },
        modified: false,
        modifiedBooking: {
          ...action.payload,
          selectedFleet: getFleetFromLinks(action.payload.links),
        },
      };
    case BookingModifyTypes.GET_BOOKING_PRICING_SUCCESS:
      return {
        ...state,
        fleetPricing: {
          ...action.payload,
        },
      };
    case BookingModifyTypes.GET_BOOKING_PRICING_ERROR:
      return {
        ...state,
        httpResponseError: true,
        httpResponseStatusMessage: action.payload,
      };
    case BookingModifyTypes.GET_BOOKING_AVAILABILITY_SUCCESS:
      return {
        ...state,
        availability: action.payload,
        httpResponseError: false,
        httpResponseStatusMessage: '',
      };
    case BookingModifyTypes.GET_BOOKING_AVAILABILITY_ERROR:
      return {
        ...state,
        httpResponseError: true,
        httpResponseStatusMessage: action.payload,
      };
    case BookingModifyTypes.GET_COVERAGE_OPTIONS_ERROR:
      return {
        ...state,
        coverage: [],
        httpResponseError: true,
        httpResponseStatusMessage: action.payload,
      };
    case BookingModifyTypes.GET_COVERAGE_OPTIONS_SUCCESS:
      return {
        ...state,
        coverage: action.payload,
        httpResponseError: false,
        httpResponseStatusMessage: '',
      };
    case BookingModifyTypes.GET_COVERAGE_OPTIONS_LOADING:
      return {
        ...state,
        coverageLoading: action.payload,
      };
    case BookingModifyTypes.GET_COVERAGE_PRICING_SUCCESS:
      return {
        ...state,
        modifiedBooking: {
          ...state.modifiedBooking,
          type: 'coverage',
        },
      };
    case BookingModifyTypes.GET_RECEIPT_SUCCESS:
      return {
        ...state,
        receipt: {
          ...action.payload,
        },
      };
    case BookingModifyTypes.GET_RECEIPT_ERROR:
      return {
        ...state,
        httpResponseError: true,
        httpResponseStatusMessage: action.payload,
      };
    case BookingModifyTypes.GET_RENTAL_AVAILABILITY_SUCCESS:
      return {
        ...state,
        availability: action.payload,
        httpResponseError: false,
        httpResponseStatusMessage: '',
      };
    case BookingModifyTypes.GET_RENTAL_AVAILABILITY_ERROR:
      return {
        ...state,
        httpResponseError: true,
        httpResponseStatusMessage: action.payload,
      };
    case BookingModifyTypes.SET_COVERAGE:
      return {
        ...state,
        modifiedBooking: {
          ...state.modifiedBooking,
          coverageSelection: {
            ...state.modifiedBooking.coverageSelection,
            coverageOptions:
              initialState.modifiedBooking.coverageSelection.coverageOptions,
            ...action.payload,
          },
        },
      };
    case BookingModifyTypes.SET_COVERAGE_OPTIONS:
      return {
        ...state,
        modifiedBooking: {
          ...state.modifiedBooking,
          coverageSelection: {
            ...state.modifiedBooking.coverageSelection,
            coverageOptions: [...action.payload],
          },
        },
      };
    case BookingModifyTypes.SET_PICKUP_LOCATION:
      return {
        ...state,
        modified: true,
        pickupLocation: {
          ...action.payload,
        },
      };
    case BookingModifyTypes.SET_DROPOFF_DATE:
      return {
        ...state,
        modified: true,
        modifiedBooking: {
          ...state.modifiedBooking,
          dropoffDate: action.payload,
          dropoff_at: action.payload,
          dropoffTime: initialState.booking.dropoffTime,
        },
      };
    case BookingModifyTypes.SET_PICKUP_DATE:
      return {
        ...state,
        modified: true,
        modifiedBooking: {
          ...state.modifiedBooking,
          pickupDate: action.payload,
          pickup_at: action.payload,
          pickupTime: initialState.booking.pickupTime,
          dropoff_at: initialState.booking.dropoffDate,
          dropoffDate: initialState.booking.dropoffDate,
          dropoffTime: initialState.booking.dropoffTime,
          selectedFleetPricing: initialState.booking.selectedFleetPricing,
        },
      };
    case BookingModifyTypes.SET_PICKUP_TIME:
      return {
        ...state,
        modified: true,
        modifiedBooking: {
          ...state.modifiedBooking,
          pickup_at: action.payload,
          pickupTime: action.payload,
        },
      };
    case BookingModifyTypes.SET_DROPOFF_TIME:
      return {
        ...state,
        modified: true,
        modifiedBooking: {
          ...state.modifiedBooking,
          dropoff_at: action.payload,
          dropoffTime: action.payload,
        },
      };
    case BookingModifyTypes.SET_MODIFIED_BOOKING:
      return {
        ...state,
        modified: false,
        modifiedBooking: {
          ...state.booking,
        },
      };
    case BookingModifyTypes.SET_MODIFIED_BOOKING_PRICING:
      return {
        ...state,
        modifiedBooking: {
          ...state.modifiedBooking,
          pricing: {
            ...state.modifiedBooking.pricing,
            ...action.payload,
          },
        },
      };
    case BookingModifyTypes.TOGGLE_MODIFY_BOOKING_MODAL_VISIBLE:
      return {
        ...state,
        modifyBookingModalVisible: !state.modifyBookingModalVisible,
      };
    case BookingModifyTypes.UPDATE_BOOKING_ERROR:
      return {
        ...state,
        httpResponseError: true,
        httpResponseStatusMessage: action.payload,
      };
    case BookingModifyTypes.UPDATE_BOOKING_FLEET_ERROR:
      return {
        ...state,
        httpResponseError: true,
        httpResponseStatusMessage: action.payload,
      };
    case BookingModifyTypes.UPDATE_BOOKING_CANCEL:
      return {
        ...state,
        httpResonseError: false,
        httpResponseStatusMessage: action.payload,
      };
    case BookingModifyTypes.UPDATE_BOOKING_SUCCESS:
      return {
        ...state,
        httpResponseError: false,
        isSubmitting: false,
        httpResponseStatusMessage: 'Success! Your reservation has been updated',
      };
    case BookingModifyTypes.UPDATE_FLIGHT_DETAILS_ERROR:
      return {
        ...state,
        httpResponseError: true,
        httpResponseStatusMessage: action.payload,
      };
    case BookingModifyTypes.UPDATE_FLIGHT_DETAILS_SUCCESS:
      return {
        ...state,
        httpResponseError: false,
        httpResponseStatusMessage: 'Success! Your reservation has been updated',
      };
    case BookingModifyTypes.NETWORK_RESET:
      return {
        ...state,
        httpResponseError: false,
        httpResponseStatusMessage: '',
      };
    case BookingModifyTypes.GET_LOCATIONS_FLEET_AVAILABILITY_SUCCESS:
      return {
        ...state,
        // reset fleetPricing so they're re-populated when the
        // individual fleet pricing successes are fired
        isLoading: true,
        fleetPricing: [],
        fleetAvailability: action.payload,
      };
    case BookingModifyTypes.RESET_LOCATIONS_FLEET_AVAILABILITY:
      return {
        ...state,
        fleetAvailability: initialState.fleetAvailability,
      };
    case BookingModifyTypes.GET_LOCATIONS_FLEET_AVAILABILITY_ERROR:
    case BookingModifyTypes.GET_FLEET_PRICING_ERROR:
      return {
        ...state,
        httpResponseError: true,
        httpResponseStatusMessage: action.payload,
      };
    case BookingModifyTypes.GET_FLEET_PRICING_SUCCESS:
      return {
        ...state,
        fleetPricing: {
          ...state.fleetPricing,
          [action.payload.id]: action.payload,
        },
        isLoading: false,
      };
    case BookingModifyTypes.SET_VEHICLE:
      return {
        ...state,
        modified: true,
        modifiedBooking: {
          ...state.modifiedBooking,
          selectedFleet: action.payload,
        },
      };
    case BookingModifyTypes.SET_SELECTED_FLEET_PRICING:
      return {
        ...state,
        modifiedBooking: {
          ...state.modifiedBooking,
          pricing: action.payload,
        },
      };
    default:
      return state;
  }
}

export default modifyBookingReducer;
