// @flow

// Abstracting storage to this single utility in the future event that we may want
// to globally make updates / add analytics / do any sort of logging / global handling
// with localStorage data.

const Storage = {
  set: (key: string, value: string | Object | Array<any>) => {
    localStorage.setItem(key, JSON.stringify(value));
  },

  get: (key: string) => JSON.parse(window.localStorage.getItem(key)),

  remove: (key: string) => {
    localStorage.removeItem(key);
  },
};

export default Storage;
