// @flow
import React from 'react';
import { Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import LoginForm from '../../components/forms/login';
import { getUserId } from '../../selectors';
import parseQueryString from '../../utils/parsers';
import * as Auth from '../../components/auth';
import { redirectPath } from '../../actions';

type Props = {
  history: Object,
  location: Object,
}

function Login(props: Props) {
  const { location, history } = props;
  const { inBooking } = location ? parseQueryString(decodeURIComponent(location.search)) : {};
  const userId = useSelector(getUserId);

  return (
    userId && Auth.isLoggedIn()
      ? <Redirect push path={redirectPath} />
      : (
        <div>
          <LoginForm history={history} inBooking={!!inBooking} />
        </div>
      )
  );
}

export default Login;
