// @flow
import React from 'react';
import { Text } from '@audi/audi-ui-react';

function Login() {
  return (
    <a href="/login" className="audi-owners">
      <Text as="p" variant="copy1" className="grey-font">Log in</Text>
    </a>
  );
}

export default Login;
