// @flow
import React from 'react';
import Typography from '@material-ui/core/Typography';
import Modal from 'react-responsive-modal';
import { connect } from 'react-redux';
import '../modal.css';
import './spent-loyalty-points-modal.css';
import { toggleSpentLoyaltyPointsModalVisible } from '../../../actions/booking';

type Props = {
  spentLoyaltyPointsModalVisible: boolean,
  toggleSpentLoyaltyPointsModalVisible: () => void,
}

export function SpentLoyaltyPointsModal(props: Props) {
  const {
    spentLoyaltyPointsModalVisible,
    toggleSpentLoyaltyPointsModalVisible,
  } = props;

  return (
    <Modal
      center
      onClose={toggleSpentLoyaltyPointsModalVisible}
      open={spentLoyaltyPointsModalVisible}
      classNames={{ modal: 'spent-loyalty-points-modal' }}
    >
      <div className="spent-loyalty-points-modal">
        <Typography variant="h2">Hmmm, something&apos;s not right.</Typography>
        <p>
          Your booking went through, however we are experiencing issues with our loyalty feature.
          To apply your loyalty points to your reservation please call customer service.
        </p>
        <a
          className="button"
          href="https://support.audiondemand.com/hc/en-us"
          rel="noopener noreferrer"
          target="_blank"
        >
          Contact support
        </a>
      </div>
    </Modal>
  );
}

export const mapStateToProps = state => ({
  spentLoyaltyPointsModalVisible: state.bookingReducer.spentLoyaltyPointsModalVisible,
});

export const mapDispatchToProps = dispatch => ({
  toggleSpentLoyaltyPointsModalVisible: () => dispatch(toggleSpentLoyaltyPointsModalVisible()),
});


export default connect(mapStateToProps, mapDispatchToProps)(SpentLoyaltyPointsModal);
