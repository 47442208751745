// @flow
import { Text, Toggle } from '@audi/audi-ui-react';
import React from 'react';
import { connect, useSelector } from 'react-redux';
import {
  toggleEarnOrSpendPoints,
} from '../../actions/booking';
import { getEarningPoints } from '../../selectors';
import SCLoyaltyToolTip from '../sc-loyalty-tool-tip';

import './index.css';

type Props = {
  toggleEarnOrSpendPoints: () => void,
}

export function SCLoyaltyToggle(props: Props) {
  const {
    toggleEarnOrSpendPoints,
  } = props;

  const earningPoints = useSelector(getEarningPoints);

  return (
    <div className="sc-loyalty-toggle">
      <Toggle
        type="button"
        on={!earningPoints}
        onChange={toggleEarnOrSpendPoints}
      />
      <Text as="span" variant="copy1" spaceInlineStart="xs" spaceInlineEnd="s">
        Pay with points
      </Text>
      <SCLoyaltyToolTip />
    </div>
  );
}

export const mapDispatchToProps = dispatch => ({
  toggleEarnOrSpendPoints: () => dispatch(toggleEarnOrSpendPoints()),
});

export default connect(null, mapDispatchToProps)(SCLoyaltyToggle);
