// @flow
import React, { useState, useEffect } from 'react';
import { useSelector, connect } from 'react-redux';
import { Button, audiDarkTheme, ThemeProvider } from '@audi/audi-ui-react';
import { useMediaQuery } from '@material-ui/core';
import { getSelectedFleetPricing, getIsValidCarrierAndPolicyNumber } from '../../../selectors';
import NumberDisplay from '../../../components/number-display';
import PromoField from './promo-field';
import FooterStatusBar from './footer-status-bar';
import EstimatedTotalToggle from '../../../components/modals/estimated-total';
import {
  toggleCostSummaryModalVisible,
} from '../../../actions/booking';
import './index.css';

type Props = {
  toggleCostSummaryModalVisible: () => void,
};

const StickyFooter = (props: Props) => {
  const { toggleCostSummaryModalVisible } = props;
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const { total } = useSelector(getSelectedFleetPricing);
  const isValidCarrierAndPolicyNumber = useSelector(getIsValidCarrierAndPolicyNumber);
  const [isVisible, updateIsVisible] = useState(false);

  useEffect(() => {
    updateIsVisible(false);
  }, [total]);

  const handleVisibility = () => {
    updateIsVisible(!isVisible);
  };

  if (isMobile) {
    return (
      <ThemeProvider theme={audiDarkTheme}>
        <footer className="sticky-footer">
          <FooterStatusBar />
          <div className="sticky-footer-container">
            {isVisible && (
              <div className="promo-field-container">
                <PromoField />
              </div>
            )}
            <div className="sticky-footer-sections">
              <section id="promo-section">
                <Button variant="text" size="small" onClick={handleVisibility}>
                  Add Promo code
                </Button>
              </section>
              <section id="estimate-section">
                <EstimatedTotalToggle />
                <NumberDisplay value={total} />
              </section>
            </div>
            <Button disabled={!isValidCarrierAndPolicyNumber} variant="primary" spaceStackEnd="xl" onClick={() => toggleCostSummaryModalVisible()}>
              Continue
            </Button>
          </div>
        </footer>
      </ThemeProvider>
    );
  }
  return (
    <ThemeProvider theme={audiDarkTheme}>
      <footer className="sticky-footer">
        <FooterStatusBar />
        <div className="sticky-footer-container">
          <div className="sticky-footer-sections">
            <section id="promo-section">
              <PromoField />
            </section>
            <section id="estimate-section">
              <EstimatedTotalToggle />
              <NumberDisplay value={total} />
            </section>
          </div>
          <Button disabled={!isValidCarrierAndPolicyNumber} variant="primary" type="submit" spaceInlineStart="xl" onClick={() => toggleCostSummaryModalVisible()}>
            Continue
          </Button>
        </div>
      </footer>
    </ThemeProvider>
  );
};

export const mapDispatchToProps = dispatch => ({
  toggleCostSummaryModalVisible: () => dispatch(toggleCostSummaryModalVisible()),
});

export default connect(null, mapDispatchToProps)(StickyFooter);
