// @flow
import React, { Component } from 'react';
import moment from 'moment-timezone';

import { connect } from 'react-redux';

import './index.css';
import { getLocationIdsFromLocation } from '../../utils';
import Loading from '../loading';

type Props = {
  availability: Object,
  booking: Object,
  location: Object,
  nextStep: () => void,
  reservationState: string,
  setDropoffTime: Function,
  setPickupTime: Function,
  timeAction: Function,
  earningPoints: boolean,
}

type TimeItemProps = {
  booking: Object,
  pickup: Object | null,
  dropoff: Object | null,
  location: Object,
  onClick: Function,
};

type State = {}

export function TimeItem(props: TimeItemProps) {
  const {
    booking,
    location,
    pickup,
    dropoff,
  } = props;
  let time: Object = {};

  if (pickup) {
    time = pickup;
  } else if (dropoff) {
    time = dropoff;
  }
  const selected = () => {
    if (pickup) {
      return moment(time.start_at).format()
      === moment(booking.pickupTime).format();
    }
    return moment(time.start_at).format() === moment(booking.dropoffTime).format();
  };

  const disabled = !time.available;

  function handleClick() {
    if (!disabled) {
      props.onClick(time.start_at);
    }
  }

  function handleKeydown(event, selectedTime) {
    if (event.which === 13 && !disabled) {
      props.onClick(selectedTime.start_at);
    }
  }

  if (disabled) {
    const disabledStart = moment(time.start_at).tz(location.time_zone).format('h:mm A');
    const disabledEnd = moment(time.end_at).tz(location.time_zone).format('h:mm A');
    return (
      <li className="disabled">
        {disabledStart}
        <br />
        -
        <br />
        {disabledEnd}
      </li>
    );
  }

  return (
    <li
      role="menuitem"
      tabIndex={0}
      className={`${selected() ? 'selected' : ''} ${disabled ? 'disabled' : ''}`}
      onClick={() => handleClick()}
      onKeyDown={event => handleKeydown(event, time)}
    >
      {moment(time.start_at).tz(location.time_zone).format('h:mm A')}
    </li>
  );
}

export class SCTimePicker extends Component<Props, State> {
  handlePickupTime(time: string) {
    const {
      booking,
      location,
      nextStep,
      timeAction,
      setPickupTime,
      earningPoints,
    } = this.props;
    const { dropoffTime, id, promo } = booking;
    setPickupTime(time);
    const promoId = promo ? promo.id : '';
    const params = {
      id,
      location_ids: getLocationIdsFromLocation(location),
      pickup_at: time,
      dropoff_at: dropoffTime,
      promo_code_id: promoId,
      points: !earningPoints,
    };

    if (dropoffTime) {
      timeAction(params);
      if (nextStep) {
        nextStep();
      }
    }
  }

  handleDropoffTime(time: string) {
    const {
      booking,
      location,
      nextStep,
      timeAction,
      setDropoffTime,
      earningPoints,
    } = this.props;
    const { pickupTime, id, promo } = booking;
    setDropoffTime(time);
    const promoId = promo ? promo.id : '';
    const params = {
      id,
      location_ids: getLocationIdsFromLocation(location),
      pickup_at: pickupTime,
      dropoff_at: time,
      promo_code_id: promoId,
      points: !earningPoints,
    };
    if (pickupTime) {
      timeAction(params);
      if (nextStep) {
        nextStep();
      }
    }
  }

  render() {
    const {
      availability,
      booking,
      location,
      reservationState,
    } = this.props;

    const {
      pickups,
      dropoffs,
    } = availability;

    if (dropoffs) {
      return (
        <div className="sc-time-picker">
          <div>
            <ul
              className="return"
              role="menu"
            >
              {reservationState === 'active' && (
                <li className="selected">
                  {moment(booking.pickup_at).tz(location.time_zone).format('h:mm A')}
                </li>
              )}
              {reservationState !== 'active'
                && pickups
                && location.time_zone
                && pickups.map((pickup: Object, idx) => (
                  <TimeItem
                    booking={booking}
                    location={location}
                    pickup={pickup}
                    dropoff={null}
                    key={idx}
                    onClick={time => this.handlePickupTime(time)}
                    onKeyDown={(time, event) => this.handleKeydown(event, time)}
                  />
                ))
              }
            </ul>
            <div />
            <ul
              className="pickup"
              role="menu"
            >
              {/*
                check for location.time_zone to prevent errors when
                deleting an unathenticated booking.

                  1.  The booking route determines where to land when hitting /booking by cascading
                      backward from the last possible booking route.
                  2.  When deleting a booking the location is cleared in
                      local storage, then, if you are logged out, you are directed to /booking.

                  The combination of these two events causes an error.
              */}
              {dropoffs && location.time_zone && (
                dropoffs.map((dropoff: Object, idx) => (
                  <TimeItem
                    role="menuitem"
                    booking={booking}
                    location={location}
                    pickup={null}
                    dropoff={dropoff}
                    key={idx}
                    onClick={time => this.handleDropoffTime(time)}
                    onKeyDown={(time, event) => this.handleKeydown(event, time)}
                  />
                ))
              )}
            </ul>
          </div>
        </div>
      );
    }
    return <Loading />;
  }
}

export default connect(null, null)(SCTimePicker);
