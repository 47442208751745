// @flow
import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import HTTP from '../../../utils/http';

type Props = {};
type State = {
  email: string,
}

class ResendConfirmationForm extends Component<Props, State> {
  constructor(props: Object) {
    super(props);
    this.state = {
      email: '',
    };
  }

  handleUpdateEmail(e: Object) {
    this.setState({ email: e.target.value });
  }

  submit() {
    const { email } = this.state;
    HTTP.users.postResendConfirmation({ email });
  }

  render() {
    const { email } = this.state;
    return (
      <div className="sc-form">
        <section>
          <header className="no-bg">
            <Typography variant="h2">
              Resend Confirmation Instructions
            </Typography>
          </header>
          <div className="sub-header">
            Enter your email below, and we will send you a new confirmation email.
          </div>
          <section>
            <ValidatorForm onSubmit={() => this.submit()}>
              <TextValidator
                value={email}
                name="email"
                id="email"
                type="email"
                validators={['required', 'isEmail']}
                errorMessages={['Email is required.', 'Please enter a valid email address.']}
                onChange={e => this.handleUpdateEmail(e)}
                label="Email"
              />
              <Button type="submit">Resend Email</Button>
            </ValidatorForm>
          </section>
        </section>
      </div>
    );
  }
}

export default ResendConfirmationForm;
