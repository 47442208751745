export const Focus = {
  LOCATION: 'LOCATION',
  PICKUP: 'PICKUP',
  RETURN: 'RETURN',
  TIME: 'TIME',
};

export const Reducer = {
  SET_ACTIVE_SECTION: 'SET_ACTIVE_SECTION',
  SET_HOVER: 'SET_HOVER',
  SET_LOCATION: 'SET_LOCATION',
  SET_LOCATION_NAME: 'SET_LOCATION_NAME',
  SET_FILTERED_LOCATIONS: 'SET_FILTERED_LOCATIONS',
  SET_FOCUS: 'SET_FOCUS',
  SET_PICKUP_DATE: 'SET_PICKUP_DATE',
  SET_PICKUP_TIME: 'SET_PICKUP_TIME',
  SET_RETURN_DATE: 'SET_RETURN_DATE',
  SET_RETURN_TIME: 'SET_RETURN_TIME',
  CLEAR_LOCATION: 'CLEAR_LOCATION',
  DISABLE_SUBMIT: 'DISABLE_SUBMIT',
  ENABLE_SUBMIT: 'ENABLE_SUBMIT',
};

export const Section = {
  LOCATION: 'LOCATION',
  DATE: 'DATE',
  TIME: 'TIME',
};
