// @flow

const MuiDialog = {
  paper: {
    padding: '3.75rem',
    borderRadius: '0',
  },
  paperFullScreen: {
    padding: '1rem',
    marginTop: 'auto',
  },
  paperScrollPaper: {
    maxHeight: '80%',
  },
};

export default MuiDialog;
