// @flow
import React from 'react';
import ConfirmDateTime from './confirm-date-times';
import './index.css';

type ConfirmBookingDateTimeProps = {
  pickupLocation: Object;
  pickupDateTime: String;
  dropoffDateTime: String;
}

const ConfirmBookingDateTime = (props: ConfirmBookingDateTimeProps) => {
  const { pickupDateTime, pickupLocation, dropoffDateTime } = props;
  const { time_zone } = pickupLocation;

  return (
    <div className="date-time">
      <ConfirmDateTime
        type="Start"
        confirmDateTime={pickupDateTime}
        timeZone={time_zone}
      />
      <ConfirmDateTime
        type="Stop"
        confirmDateTime={dropoffDateTime}
        timeZone={time_zone}
      />
    </div>
  );
};

export default ConfirmBookingDateTime;
