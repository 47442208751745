// @flow
import moment from 'moment';

export function validateDate(date: string) {
  function correctFormat(str) {
    const date_regex = /^\d{1,2}\/\d{1,2}\/\d{4}$/;
    return date_regex.test(str);
  }
  return (date === '') || (correctFormat(date) && moment(date).isValid());
}

export function validatePassword(password: string) {
  function hasLowerCase(str) {
    return (/[a-z]/.test(str));
  }

  function hasUppercase(str) {
    return (/[A-Z]/.test(str));
  }

  function hasNumber(str) {
    return (/[0-9]/.test(str));
  }

  return hasLowerCase(password) && hasUppercase(password) && hasNumber(password);
}

export function validateClaimCode(code: string) {
  return code.length
    ? (/^\w+$/).test(code)
    : true;
}

export function comparePasswords(password: string, confirm: string) {
  return password === confirm;
}

export function validateEmail(email: string) {
  if (!email) return false;
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
}

const validators = {
  validateDate,
  validateEmail,
  validatePassword,
  comparePasswords,
  validateClaimCode,
};

export default validators;
