// @flow
import React from 'react';
import { connect, useSelector } from 'react-redux';

import SCDialog from '../../sc-dialog';
import { closeCoverageOptionsModal } from '../../../actions/modal';
import { getCoverageOptionsModalVisible } from '../../../selectors/modal';
import CoverageDetails from '../../coverage/coverage-details';

type Props = {
  closeModal: () => void,
}

export const CoverageOptionsModal = (props: Props) => {
  const { closeModal } = props;
  const coverageOptionsModalVisible = useSelector(getCoverageOptionsModalVisible);

  return (
    <SCDialog
      onClose={closeModal}
      open={coverageOptionsModalVisible}
      showCloseButton
      title="Audi on demand Coverage Options"
      titleVariant="order2"
      width="xs"
    >
      <CoverageDetails />
    </SCDialog>
  );
};

export const mapDispatchToProps = dispatch => ({
  closeModal: () => dispatch(closeCoverageOptionsModal()),
});

export default connect(null, mapDispatchToProps)(CoverageOptionsModal);
