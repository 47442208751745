// @flow
import { useMediaQuery } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import { Button } from '@audi/audi-ui-react';
import moment from 'moment';
import {
  getBookingAvailability,
  getRentalAvailability,
  setDropoffDate,
  setPickupDate,
} from '../../../../actions/booking/modify';
import history from '../../../../history';
import { SCDatePicker } from '../../../sc-date-picker';
import Header from '../shared-components/header';
import Subheader from '../shared-components/subheader';

import './index.css';
import Loading from '../../../loading';

type Props = {
  booking: Object,
  getBookingAvailability: Function,
  getRentalAvailability: Function,
  location: Object,
  setDropoffDate: Function,
  setPickupDate: Function,
}

export function ReservationEditDate(props: Props) {
  const {
    booking,
    getBookingAvailability,
    getRentalAvailability,
    location,
    setDropoffDate,
    setPickupDate,
  } = props;

  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));

  const pickupDate = (booking.pickup_at) ? moment(booking.pickup_at).format('MMMM D YYYY') : '';
  const dropoffDate = (booking.dropoff_at) ? moment(booking.dropoff_at).format('MMMM D YYYY') : '';

  let getAvailability = x => x;
  switch (booking.type) {
    case 'upcoming':
      getAvailability = getBookingAvailability;
      break;
    case 'active':
      getAvailability = getRentalAvailability;
      break;
    default:
      getAvailability = getBookingAvailability;
  }

  if (booking && location) {
    return (
      <section className="reservation-edit-dates">
        <div className="header-container">
          <Header isMobile={isMobile} type="dates" />
          <Button
            className="modify-dates-cancel-button"
            variant="secondary"
            name="cancel"
            onClick={() => history.goBack()}
            size="small"
          >
            Cancel
          </Button>
        </div>
        <Subheader dropoffDate={dropoffDate} pickupDate={pickupDate} type="dates" />
        <section>
          <SCDatePicker
            booking={booking}
            disablePickup={booking.type === 'active'}
            getAvailability={getAvailability}
            location={location}
            setDropoffDate={setDropoffDate}
            setPickupDate={setPickupDate}
          />
        </section>
      </section>
    );
  }
  return <Loading />;
}

export const mapDispatchToProps = dispatch => ({
  getBookingAvailability: (id, data) => dispatch(getBookingAvailability(id, data)),
  getRentalAvailability: availability => dispatch(getRentalAvailability(availability)),
  setDropoffDate: date => dispatch(setDropoffDate(date)),
  setPickupDate: date => dispatch(setPickupDate(date)),
});

export default connect(null, mapDispatchToProps)(ReservationEditDate);
