import decodeComponent from './decode-uri-component';

const strictUriEncode = str => encodeURIComponent(str).replace(/[!'()*]/g, x => `%${x.charCodeAt(0).toString(16).toUpperCase()}`);

export function encode(value, options) {
  if (options.encode) {
    return options.strict ? strictUriEncode(value) : encodeURIComponent(value);
  }

  return value;
}

export function encoderForArrayFormat(options) {
  switch (options.arrayFormat) {
    case 'index':
      return (key, value, index) => (value === null ? [
        encode(key, options),
        '[',
        index,
        ']',
      ].join('') : [
        encode(key, options),
        '[',
        encode(index, options),
        ']=',
        encode(value, options),
      ].join(''));
    case 'bracket':
      return (key, value) => (value === null ? encode(key, options) : [
        encode(key, options),
        '[]=',
        encode(value, options),
      ].join(''));
    default:
      return (key, value) => (value === null ? encode(key, options) : [
        encode(key, options),
        '=',
        encode(value, options),
      ].join(''));
  }
}

export function parserForArrayFormat(options) {
  let result;

  switch (options.arrayFormat) {
    case 'index':
      return (origKey, value, origAccumulator) => {
        let key = origKey;
        const accumulator = origAccumulator;

        result = /\[(\d*)\]$/.exec(key);

        key = key.replace(/\[\d*\]$/, '');

        if (!result) {
          accumulator[key] = value;
          return;
        }

        if (accumulator[key] === undefined) {
          accumulator[key] = {};
        }

        accumulator[key][result[1]] = value;
      };
    case 'bracket':
      return (origKey, value, origAccumulator) => {
        let key = origKey;
        const accumulator = origAccumulator;
        result = /(\[\])$/.exec(key);
        key = key.replace(/\[\]$/, '');

        if (!result) {
          accumulator[key] = value;
          return;
        }

        if (accumulator[key] === undefined) {
          accumulator[key] = [value];
          return;
        }

        accumulator[key] = [].concat(accumulator[key], value);
      };
    default:
      return (key, value, origAccumulator) => {
        const accumulator = origAccumulator;
        if (accumulator[key] === undefined) {
          accumulator[key] = value;
          return;
        }

        accumulator[key] = [].concat(accumulator[key], value);
      };
  }
}

export function keysSorter(input) {
  if (Array.isArray(input)) {
    return input.sort();
  }

  if (typeof input === 'object') {
    return keysSorter(Object.keys(input))
      .sort((a, b) => Number(a) - Number(b))
      .map(key => input[key]);
  }

  return input;
}

export function extract(input) {
  const queryStart = input.indexOf('?');
  if (queryStart === -1) {
    return '';
  }
  return input.slice(queryStart + 1);
}

export function parse(origInput, origOptions) {
  let options = origOptions;
  let input = origInput;

  options = Object.assign({ arrayFormat: 'none' }, options);

  const formatter = parserForArrayFormat(options);

  // Create an object with no prototype
  const ret = Object.create(null);

  if (typeof input !== 'string') {
    return ret;
  }

  input = input.trim().replace(/^[?#&]/, '');

  if (!input) {
    return ret;
  }

  // eslint-disable-next-line no-restricted-syntax
  for (const param of input.split('&')) {
    // eslint-disable-next-line prefer-const
    let [key, value] = !param.includes('@') ? param.replace(/\+/g, ' ').split('=') : param.split('=');

    // Missing `=` should be `null`:
    // http://w3.org/TR/2012/WD-url-20120524/#collect-url-parameters

    // Prevents email decode to avoid spcecial caracters being removed from the email
    // eslint-disable-next-line no-nested-ternary
    value = value === undefined ? null : (key === 'email') ? Buffer.from(value, 'base64').toString() : decodeComponent(value);

    formatter(decodeComponent(key), value, ret);
  }

  return Object.keys(ret).sort().reduce((origResult, key) => {
    const result = origResult;
    const value = ret[key];
    if (Boolean(value) && typeof value === 'object' && !Array.isArray(value)) {
      // Sort object keys, not values
      result[key] = keysSorter(value);
    } else {
      result[key] = value;
    }

    return result;
  }, Object.create(null));
}

export const stringify = (obj, origOptions) => {
  let options = origOptions;
  const defaults = {
    encode: true,
    strict: true,
    arrayFormat: 'none',
  };

  options = Object.assign(defaults, options);

  if (options.sort === false) {
    options.sort = () => {
    };
  }

  const formatter = encoderForArrayFormat(options);

  return obj ? Object.keys(obj).sort(options.sort).map((key) => {
    const value = obj[key];

    if (value === undefined) {
      return '';
    }

    if (value === null) {
      return encode(key, options);
    }

    if (Array.isArray(value)) {
      const result = [];

      // eslint-disable-next-line no-restricted-syntax
      for (const value2 of value.slice()) {
        if (value2 === undefined) {
          // eslint-disable-next-line no-continue
          continue;
        }

        result.push(formatter(key, value2, result.length));
      }

      return result.join('&');
    }

    return `${encode(key, options)}=${encode(value, options)}`;
  }).filter(x => x.length > 0)
    .join('&') : '';
};

export const parseUrl = (input, options) => ({
  url: input.split('?')[0] || '',
  query: parse(extract(input), options),
});
