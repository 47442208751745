// @flow
import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  clearBooking,
  getBooking,
  getReceipt,
  getRental,
  cancelBookingUpdate,
} from '../../../actions/booking/modify';

import parseQueryString from '../../../utils/parsers/index';
import SingleReservation from '../../../components/reservations/single-reservation';
import ReservationEditCoverage from '../../../components/reservations/modify/coverage';
import ReservationEditDate from '../../../components/reservations/modify/date';
import ReservationEditTime from '../../../components/reservations/modify/time';
import ModifyBookingModal from '../../../components/modals/modify-booking';
import HTTPFormStatus from '../../../components/forms/http-form-status';

import './index.css';
import ReservationEditVehicle from '../../../components/reservations/modify/vehicle';
import Loading from '../../../components/loading';

type Props = {
  availability: Object,
  booking: Object | null,
  clearBooking: Function,
  getBooking: Function,
  getReceipt: Function,
  getRental: Function,
  cancelBookingUpdate: Function,
  httpResponseError: boolean,
  httpResponseStatusMessage: string,
  isReady: boolean,
  location: Object,
  match: Object,
  modified: boolean,
  modifiedBooking: Object,
  pickupLocation: Object,
  receipt: Object,
};

export class ReservationDetail extends Component<Props> {
  onModalClose = () => {
    const { booking, cancelBookingUpdate } = this.props;
    cancelBookingUpdate(booking);
  }

  UNSAFE_componentWillMount() {
    const {
      booking,
      clearBooking,
      isReady,
      match: {
        params: {
          id,
        },
      },
    } = this.props;
    const resId = parseInt(id, 10);
    if (isReady && booking && (booking.id !== resId)) {
      clearBooking();
    }
    this.UNSAFE_componentWillUpdate(this.props);
  }

  UNSAFE_componentWillUpdate(props) {
    // eslint-disable-next-line react/prop-types
    const paramId = parseInt(props.match.params.id, 10);
    const queryObject = parseQueryString(props.location.search);
    const { type } = queryObject;
    const {
      booking,
      isReady,
    } = props;

    if (isReady && booking && (booking.id !== paramId)) {
      this.handleBooking(paramId, type);
    }
  }

  handleBooking(id, type) {
    const {
      getBooking,
      getReceipt,
      getRental,
    } = this.props;

    switch (type) {
      case 'upcoming':
        getBooking(id, type);
        break;
      case 'active':
        getRental(id, type);
        break;
      case 'previous':
        getRental(id, type);
        getReceipt(id, type);
        break;
      default:
    }
  }

  render() {
    const {
      availability,
      booking,
      httpResponseError,
      httpResponseStatusMessage,
      isReady,
      match,
      modified,
      modifiedBooking,
      pickupLocation,
      receipt,
      location: {
        search,
      },
    } = this.props;

    const queryObject = parseQueryString(search);
    const { type } = queryObject;
    const resId = parseInt(match.params.id, 10);

    if (booking.id && modifiedBooking.id && pickupLocation && isReady) {
      return (
        <div className="reservation-detail">
          <ModifyBookingModal handleClose={this.onModalClose} />
          {httpResponseStatusMessage && (
            <HTTPFormStatus
              category=""
              type={httpResponseError ? 'error' : 'success'}
              message={httpResponseStatusMessage}
            />
          )}
          <Route
            path="/reservations/:id/edit-coverage"
            render={() => (
              <ReservationEditCoverage
                booking={modifiedBooking}
                location={pickupLocation}
                reservationState={type}
              />
            )}
          />
          <Route
            path="/reservations/:id/edit-date"
            render={() => (
              <ReservationEditDate
                booking={modifiedBooking}
                location={pickupLocation}
                receipt={receipt}
                reservationState={type}
              />
            )}
          />
          <Route
            path="/reservations/:id/edit-time"
            render={() => (
              <ReservationEditTime
                id={resId}
                availability={availability}
                booking={modifiedBooking}
                location={pickupLocation}
                modified={modified}
                receipt={receipt}
                reservationState={type}
              />
            )}
          />
          <Route
            path="/reservations/:id/edit-vehicle"
            render={() => (
              <ReservationEditVehicle
                id={resId}
                availability={availability}
                booking={modifiedBooking}
                location={pickupLocation}
                modified={modified}
                receipt={receipt}
                reservationState={type}
              />
            )}
          />
          <Route
            exact
            path="/reservations/:id"
            render={() => (
              <SingleReservation
                booking={booking}
                location={pickupLocation}
                receipt={receipt}
                reservationState={type}
              />
            )}
          />
        </div>
      );
    }
    return <Loading />;
  }
}

export const mapStateToProps = state => ({
  availability: state.bookingReducer.modifyBooking.availability,
  httpResponseError: state.bookingReducer.modifyBooking.httpResponseError,
  httpResponseStatusMessage: state.bookingReducer.modifyBooking.httpResponseStatusMessage,
  isReady: state.appReducer.isReady,
  booking: state.bookingReducer.modifyBooking.booking,
  modified: state.bookingReducer.modifyBooking.modified,
  modifiedBooking: state.bookingReducer.modifyBooking.modifiedBooking,
  pickupLocation: state.bookingReducer.modifyBooking.pickupLocation,
  receipt: state.bookingReducer.modifyBooking.receipt,
});

export const mapDispatchToProps = dispatch => ({
  clearBooking: () => dispatch(clearBooking()),
  cancelBookingUpdate: (returnHref: string) => dispatch(cancelBookingUpdate(returnHref)),
  getBooking: (id, type) => dispatch(getBooking(id, type)),
  getReceipt: (id, type) => dispatch(getReceipt(id, type)),
  getRental: (id, type) => dispatch(getRental(id, type)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReservationDetail);
