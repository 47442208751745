// eslint-disable-next-line arrow-body-style
import moment from 'moment';
import * as BookingTypes from '../../actions/booking/constants';
import * as Actions from '../../actions';
import * as ModalTypes from '../../actions/modal/constants';

export const GoogleTagManagerEvents = {
  ...BookingTypes,
  ...Actions,
  ...ModalTypes,
};

export const GoogleTagManagerMiddleware = ({ getState }) => next => async (action) => {
  const result = await next(action);

  const event = GoogleTagManagerEvents[action.type];

  if (event && window && window.dataLayer && window.dataLayer.push) {
    switch (action.type) {
      case BookingTypes.TRACK_BOOK_NOW_ECOMM_ACTION:
        {
          const {
            confirmation_token,
            dropoff_at,
            pickup_at,
            pricing: { total },
            promotion,
          } = action.payload;
          const {
            pickupLocation,
            pickupDate,
            dropoffDate,
          } = getState().bookingReducer.activeBooking;

          window.dataLayer.push({
            actionName: 'purchase',
            affiliation: 'Web',
            bookingConfirmation: confirmation_token,
            bookingLocation: pickupLocation.multi_car_display_name,
            bookingToDrive: moment(dropoff_at).diff(moment(pickup_at), 'days'),
            bookingTotal: total,
            coupon: promotion ? promotion.code : null,
            dateRange: `${moment(pickupDate).format('YYYY-MM-DD')} - ${moment(
              dropoffDate,
            ).format('YYYY-MM-DD')}`,
            event: 'reservation-confirmed',
            id: confirmation_token,
            name: pickupLocation.multi_car_display_name,
            revenue: total,
            type: 'purchase',
          });
        }
        break;
      case BookingTypes.TRACK_REFUND_ECOMM_ACTION:
        { const { confirmation_token, total } = action.payload;
          window.dataLayer.push({
            actionName: 'refund',
            affiliation: 'Web',
            id: confirmation_token,
            event: 'cancelled',
            revenue: total,
          }); }
        break;
      case Actions.LOGOUT_SUCCESS:
        window.dataLayer.push({
          event,
          category: 'account',
          action: 'logout',
          label: 'logout',
        });
        break;

      case BookingTypes.GET_LOCATIONS_AVAILABILITY_ERROR:
        window.dataLayer.push({
          event,
          category: 'error-tracking',
          action: 'car-unavailable',
          label: getState().bookingReducer.activeBooking.pickupLocation.multi_car_display_name,
        });
        break;
      case BookingTypes.GET_LOCATIONS_FLEET_AVAILABILITY_SUCCESS:
        {
          let available = true;
          let vehicle = '';
          action.payload.forEach((resp) => {
            if (!resp.available) {
              vehicle = resp.vehicle.model;
              available = false;
            }
          });
          if (available) {
            window.dataLayer.push({
              event,
              category: 'error-tracking',
              action: 'car-unavailable',
              label: vehicle,
            });
          }
        }
        break;
      case BookingTypes.SET_PICKUP_LOCATION:
        window.dataLayer.push({
          event,
          category: 'create-reservation',
          action: 'choose-location',
          label: getState().bookingReducer.activeBooking.pickupLocation.multi_car_display_name,
        });
        break;
      case BookingTypes.SET_DROPOFF_DATE:
        window.dataLayer.push({
          event,
          category: 'create-reservation',
          action: 'choose-dates',
          label: `${moment(getState().bookingReducer.activeBooking.pickupDate).format('YYYY-MM-DD')} - ${moment(getState().bookingReducer.activeBooking.dropoffDate).format('YYYY-MM-DD')}`,
        });
        break;
      case BookingTypes.SET_DROPOFF_TIME:
        window.dataLayer.push({
          event,
          category: 'create-reservation',
          action: 'choose-times',
          label: `${moment(getState().bookingReducer.activeBooking.pickupDate).format()} - ${moment(getState().bookingReducer.activeBooking.dropoffDate).format()}`,
        });
        break;
      case BookingTypes.SET_VEHICLE:
        window.dataLayer.push({
          event,
          category: 'create-reservation',
          action: 'choose-vehicle',
          label: getState().bookingReducer.activeBooking.vehicle.data_key,
        });
        break;
      case BookingTypes.TRACK_COVERAGE_TYPE:
        window.dataLayer.push({
          event,
          category: 'create-reservation',
          action: 'choose-coverage',
          label: getState().bookingReducer.activeBooking.coverage.type,
        });
        break;
      case BookingTypes.TRACK_PROMO:
        window.dataLayer.push({
          event,
          category: 'create-reservation',
          action: 'add-promo-code',
          label: action.payload,
        });
        break;
      case BookingTypes.TRACK_BOOK_NOW_RESERVATION:
        window.dataLayer.push({
          event,
          category: 'create-reservation',
          action: 'reservation-confirmed',
        });
        break;
      case BookingTypes.TRACK_BOOK_NOW_TRANSACTION:
        window.dataLayer.push({
          event,
          category: 'purchase',
          action: action.payload,
        });
        break;
      case BookingTypes.TRACK_BOOK_NOW_ECOMM_PRODUCT:
        {
          const {
            confirmation_token,
            pricing: {
              total,
            },
            promotion,
          } = action.payload;
          const { pickupLocation } = getState().bookingReducer.activeBooking;

          window.dataLayer.push({
            event,
            category: 'create-reservation',
            coupon: promotion ? promotion.code : null,
            id: confirmation_token,
            name: pickupLocation.multi_car_display_name,
            price: total,
            quantity: 1,
          });
        }
        break;
      case BookingTypes.TRACK_BOOKING_START:
        window.dataLayer.push({
          event,
          action: 'click-choose-location',
          label: action.payload,
        });
        break;
      case BookingTypes.TRACK_REFUND_ECOMM_PRODUCT:
        {
          const {
            confirmation_token,
            total,
          } = action.payload;
          window.dataLayer.push({
            event,
            category: 'refund',
            id: confirmation_token,
            price: total,
            quantity: 1,
          });
        }
        break;
      case BookingTypes.SET_JOIN_RESERVE_BOOKING_SUCCESS:
        window.dataLayer.push({
          event,
          category: 'create-reservation',
          action: 'reservation-confirmed',
          label: 'join-and-reserve',
        });
        break;
      case ModalTypes.TRACK_DELIVERY_REQUEST_START:
        window.dataLayer.push({
          event,
          category: 'create-reservation',
          action: 'request-curbside-start',
        });
        break;
      case ModalTypes.TRACK_DELIVERY_REQUEST_DECLINE:
        window.dataLayer.push({
          event,
          category: 'create-reservation',
          action: 'request-curbside-decline',
        });
        break;
      case ModalTypes.TRACK_DELIVERY_REQUEST_SUBMIT:
        window.dataLayer.push({
          event,
          category: 'create-reservation',
          action: 'request-curbside-submit',
        });
        break;
      case ModalTypes.TRACK_DELIVERY_REQUEST_CANCEL:
        window.dataLayer.push({
          event,
          category: 'create-reservation',
          action: 'request-curbside-cancel',
        });
        break;
      default:
        return [];
    }
  }

  return result;
};
