// @flow
import React from 'react';
import { Text } from '@audi/audi-ui-react';
import Box from '@material-ui/core/Box';

type Props = {
  points: Object,
}

export function PointsEarned(props: Props) {
  const { points } = props;
  return (
    <Box className="points-container">
      <Box className="points-header">
        <Text as="p" variant="copy2">Points earned</Text>
      </Box>
      <Box className="points-item">
        <Text as="p" variant="copy1">{points}</Text>
      </Box>
    </Box>
  );
}

export default PointsEarned;
