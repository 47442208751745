// @flow
import { COLORS } from '../constants';

const MuiSvgIcon = {
  root: {
    color: COLORS.BLACK,
    fontSize: '1rem',
  },
  fontSizeLarge: {
    fontSize: '1.5rem',
  },
  fontSizeSmall: {
    fontSize: '.75rem',
  },
};

export default MuiSvgIcon;
