// @flow
import * as ModalTypes from './constants';

export function closeCurbsideAvailableModal() {
  return {
    type: ModalTypes.CLOSE_CURBSIDE_AVAILABLE_MODAL,
  };
}

export function openCurbsideAvailableModal() {
  return {
    type: ModalTypes.OPEN_CURBSIDE_AVAILABLE_MODAL,
  };
}

export function closeIncompleteProfileModal() {
  return {
    type: ModalTypes.CLOSE_INCOMPLETE_PROFILE_MODAL,
  };
}

export const openVehicleVisibleModal = () => ({
  type: ModalTypes.OPEN_VEHICLE_MODAL_VISIBLE,
});

export const closeVehicleVisibleModal = () => ({
  type: ModalTypes.CLOSE_VEHICLE_MODAL_VISIBLE,
});

export function openIncompleteProfileModal() {
  return {
    type: ModalTypes.OPEN_INCOMPLETE_PROFILE_MODAL,
  };
}

export function closeCoverageOptionsModal() {
  return {
    type: ModalTypes.CLOSE_COVERAGE_OPTIONS_MODAL,
  };
}

export function openCoverageOptionsModal() {
  return {
    type: ModalTypes.OPEN_COVERAGE_OPTIONS_MODAL,
  };
}
