// @flow
import { Text } from '@audi/audi-ui-react';
import React from 'react';
import './index.css';

type ConfirmAudiProtectionProps = {
  coverageOptions: string[];
  insurancePrice?: string;
}

const ConfirmAudiProtection = (props: ConfirmAudiProtectionProps) => {
  const { coverageOptions = [], insurancePrice = '' } = props;

  const renderCoverage = (options) => {
    if (options.includes('everything_you_need')) {
      return 'Zero financial responsibility';
    }
    if (options.includes('just_the_car') && options.includes('other_people_and_their_things')) {
      return 'Auto physical damage and Liability coverage';
    }
    if (options.includes('just_the_car') && options.includes('you_and_your_things')) {
      return 'Auto physical damage and Personal accident insurance / Personal effects coverage';
    }
    if (options.includes('other_people_and_their_things') && options.includes('you_and_your_things')) {
      return 'Liability and Personal accident insurance / Personal effects coverage';
    }
    if (options.includes('just_the_car')) {
      return 'Auto physical damage coverage';
    }
    if (options.includes('other_people_and_their_things')) {
      return 'Liability coverage';
    }
    if (options.includes('you_and_your_things')) {
      return 'Personal accident insurance / Personal effects coverage';
    }
    if (options.includes('subscription_auto')) {
      return 'Subscription Auto';
    }
    if (options.includes('personal_coverage')) {
      return 'Personal coverage';
    }
    if (options.includes('corporate_coverage')) {
      return 'Corporate coverage';
    }

    return 'None';
  };

  return (
    <div className={!insurancePrice && 'audi-protection-confirmation'}>
      <Text as="p" variant="copy2" spaceInlineEnd="xxxl" spaceStackEnd="m">
        Protection type
      </Text>
      <Text as="p" variant="copy1" spaceStackEnd="xxxl" spaceInlineEnd="xxxl">
        {renderCoverage(coverageOptions)}
        {insurancePrice && <Text className="insurance-price" as="span">{insurancePrice}</Text>}
      </Text>
    </div>
  );
};

export default ConfirmAudiProtection;
