import axiosInstance from '../axiosInstance';
import { apiUrl, headers } from '../config';

function get(pg) {
  const page = pg || 1;
  const perPage = 100;

  return axiosInstance({
    url: `${apiUrl.v2}/locations?page=${+page}&per_page=${+perPage}`,
    headers: headers(),
    data: null,
  });
}

function getById(id) {
  return axiosInstance({
    url: `${apiUrl.v2}/locations/${id}`,
    headers: headers(),
    data: null,
  });
}

function search(filter) {
  return axiosInstance({
    url: `${apiUrl.v2}/locations/search`,
    params: { filter },
    headers: headers(),
    data: null,
  });
}

function availability(params) {
  return axiosInstance({
    url: `${apiUrl.v2}/locations/availability`,
    headers: headers(),
    params,
    data: null,
  });
}

function fleetAvailability(params) {
  return axiosInstance({
    url: `${apiUrl.v2}/locations/availability/fleets`,
    headers: headers(),
    params,
    data: null,
  });
}

const locations = {
  availability,
  fleetAvailability,
  get,
  getById,
  search,
};
export default locations;
