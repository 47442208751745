/* eslint-disable no-underscore-dangle,function-paren-newline,import/no-named-as-default */
/* Only doing the above for react devtools having a naming convention we have no control over */
import React from 'react';
import ReactDOM from 'react-dom';
import { Router, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import ErrorBoundary from './components/errorBoundary';
import * as datadog from './utils/datadog';
// store
import store from './store';

import './index.css';
import App from './App';

import history from './history';

datadog.init(process.env.REACT_APP_API_HOST, process.env.REACT_APP_VERSION);

// globally log app version
// eslint-disable-next-line no-console
console.log('rac-web-driver version:', process.env.REACT_APP_VERSION);

// history
ReactDOM.render(
  <ErrorBoundary>
    <Provider store={store}>
      <Router history={history}>
        <Route path="/" component={App} />
      </Router>
    </Provider>
  </ErrorBoundary>, document.getElementById('root'));
// registerServiceWorker();
