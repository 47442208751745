import React from 'react';
import { Text } from '@audi/audi-ui-react';

const LogoText = () => (
  <Text as="h1" className="footer-logo-text" variant="order3">
    <strong>Audi</strong>
    {' '}
    on demand
  </Text>
);

export default LogoText;
