// @flow
import { Text } from '@audi/audi-ui-react';
import React from 'react';

type ConfirmationNumberProps = {
    confirmationNumber: String;
}

const ConfirmationNumber = (props: ConfirmationNumberProps) => {
  const { confirmationNumber } = props;

  return (
    <div className="confirmation-number">
      <Text as="p" variant="copy2">
        Confirmation number
      </Text>
      <Text as="h2" variant="order2" spaceStackEnd="xxxl">
        {confirmationNumber}
      </Text>
    </div>
  );
};

export default ConfirmationNumber;
