// @flow
import { Button, Text } from '@audi/audi-ui-react';
import React from 'react';
import { Link } from 'react-router-dom';
import '../index.css';

type EmptyStateProps = {
    trackBookingStart: Function,
    title: string
}

function EmptyBooking(props: EmptyStateProps) {
  const { trackBookingStart, title } = props;
  return (
    <div className="sc-booking empty">
      <div className="bookNow">
        <header>
          <Text
            as="h2"
            variant="order2"
            weight="bold"
            className="title"
          >
            {title}
          </Text>
        </header>
        <section>
          <div>
            <Text
              as="h4"
              variant="copy1"
              className="description"
            >
                            Where would you like to go?
            </Text>
          </div>
          <footer>
            <Link to="/booking">
              <Button
                name="book-now"
                variant="primary"
                size="small"
                onClick={() => trackBookingStart('res-list-book-now')}
                stretch={false}
                className="bookNowButton"
              >
                                Book now
              </Button>
            </Link>
          </footer>
        </section>
      </div>
      <aside>
        <figure style={{ backgroundImage: 'url("/assets/images/reservation-upcoming-empty.jpg")' }} />
      </aside>
    </div>
  );
}

export default EmptyBooking;
