// @flow
import React from 'react';
import { Text } from '@audi/audi-ui-react';
import { formatCurrencyShort, formatNumberWithCommas } from '../../../utils/formatters';

type CostPerDayProps = {
  disabled: string;
  cost: string;
  earningPoints: boolean;
  showPrice: boolean;
}

const CostPerDay = (props: CostPerDayProps) => {
  const {
    cost, disabled, earningPoints, showPrice,
  } = props;

  if (!showPrice) return null;

  const dailyCost = earningPoints ? formatCurrencyShort(cost) : formatNumberWithCommas(cost);
  const disabledClass = disabled || '';

  return (
    <Text
      as="h2"
      className={disabledClass}
      variant="order2"
      weight="bold"
    >
      {dailyCost}
      <Text as="span" variant="copy1">
        { earningPoints ? '/day' : 'pts/day' }
      </Text>
    </Text>
  );
};

export default CostPerDay;
