// @flow
import axiosInstance from '../axiosInstance';
import { apiUrl, headers } from '../config';

function createUserByEmailAndPhoneNumber(data: Object) {
  return axiosInstance({
    method: 'POST',
    url: `${apiUrl.v2}/users/email`,
    headers: headers(),
    data,
  });
}

function get(data: Object) {
  return axiosInstance({
    method: 'GET',
    url: `${apiUrl.v2}/user`,
    headers: headers(),
    data,
  });
}

function post(data: Object) {
  return axiosInstance({
    method: 'POST',
    url: `${apiUrl.v2}/users`,
    headers: headers(),
    data,
  });
}

function postForgotPassword(data: Object) {
  return axiosInstance({
    method: 'POST',
    url: `${apiUrl.v2}/users/emails/forgot_password`,
    headers: headers(),
    data,
  });
}

function postValidateResetPasswordToken(data: Object) {
  return axiosInstance({
    method: 'POST',
    url: `${apiUrl.v2}/reset/login`,
    headers: headers(),
    data,
  });
}

function postConfirm(data: Object) {
  return axiosInstance({
    method: 'POST',
    url: `${apiUrl.v2}/users/confirm?include_user_profile=true`,
    headers: headers(),
    data,
  });
}

function postResendConfirmation(data: Object) {
  return axiosInstance({
    method: 'POST',
    url: `${apiUrl.v2}/users/emails/confirmation`,
    headers: headers(),
    data,
  });
}


function put(data: Object) {
  return axiosInstance({
    method: 'PUT',
    url: `${apiUrl.v2}/user`,
    headers: headers(),
    data,
  });
}

function putResetPassword(data: Object) {
  return axiosInstance({
    method: 'PUT',
    url: `${apiUrl.v2}/users/password/reset`,
    headers: { ...headers(), authorization: data.token },
    data,
  });
}

function claimAudiOwnerCode(code: string) {
  return axiosInstance({
    method: 'PUT',
    url: `${apiUrl.v2}/user/owner/codes/${code}/claim`,
    headers: headers(),
    data: null,
  });
}

const users: Object = {
  createUserByEmailAndPhoneNumber,
  claimAudiOwnerCode,
  get,
  post,
  postConfirm,
  postForgotPassword,
  postResendConfirmation,
  put,
  putResetPassword,
  postValidateResetPasswordToken,
};

export default users;
