// @flow
import React from 'react';
import { useSelector } from 'react-redux';
import { Text } from '@audi/audi-ui-react';
import { useMediaQuery } from '@material-ui/core';
import {
  getActiveBooking,
  getEarningPoints,
  getRemainingAudiDays,
} from '../../../../selectors';
import TotalsItem from '../../../TotalsItem';
import { PointsEarned } from '../../../points-earned';
import { CostBreakdown } from '../../../cost-breakdown';
import NumberDisplay from '../../../number-display';
import './index.css';
import { getFilteredDiscount } from '../../../../actions/booking/utils';

const EstimatedTotalCash = () => {
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('xs'));
  const activeBooking = useSelector(getActiveBooking);
  const { selectedFleetPricing } = activeBooking;
  const {
    receipt, total, duration, points,
  } = selectedFleetPricing;
  const earningPoints = useSelector(getEarningPoints);
  const userAudiOwnerProgramDays = useSelector(getRemainingAudiDays);

  const carName = `Audi ${activeBooking.vehicle.model}`;
  const carPrice = receipt.rental.total;
  const pointsEarned = !earningPoints || userAudiOwnerProgramDays > 0
    ? 0
    : selectedFleetPricing.points.earn_total;
  const { partial_spend, spend_total } = points;
  const { points_spend } = partial_spend;
  const { discount, loyalty } = receipt;

  let filteredDiscount = [];
  if (discount) {
    filteredDiscount = getFilteredDiscount(discount);
  }

  let coveragePrice;
  let coveragePriceDaily;
  let coverageOptions;
  let coverageTitle;

  if (receipt.coverage) {
    coveragePrice = receipt.coverage.total;
    coveragePriceDaily = Math.round(coveragePrice / duration);
    coverageOptions = receipt.coverage;
    coverageTitle = coverageOptions.items.length > 2
      ? 'Zero Financial Responsibility'
      : 'Coverage Options';
  }

  const taxesAndFeesTotal = () => {
    let total = 0;
    if (receipt && receipt.taxes) {
      total += receipt.taxes.total || 0;
    }
    if (receipt && receipt.fees_and_concessions) {
      total += receipt.fees_and_concessions.total || 0;
    }
    return total;
  };

  const taxesAndFees = {
    label: 'Taxes & fees',
    total: taxesAndFeesTotal(),
    items: [
      ...(receipt.taxes ? receipt.taxes.items : []),
      ...(receipt.fees_and_concessions ? receipt.fees_and_concessions.items : []),
    ],
  };

  const renderVehicle = () => {
    let full;
    let partial;

    if (points.partial_spend.percentage === 100) {
      full = points.partial_spend.percentage;
    } else {
      partial = points.partial_spend.percentage;
    }
    const cashDayRate = carPrice / duration;
    const userHasAudiDays = userAudiOwnerProgramDays > 0;
    const hasEnoughAudiDays = userHasAudiDays && duration <= userAudiOwnerProgramDays;
    const additionalDays = userHasAudiDays && duration > userAudiOwnerProgramDays
      ? duration - userAudiOwnerProgramDays : null;
    const audiDaysUsed = userHasAudiDays && hasEnoughAudiDays ? duration : userAudiOwnerProgramDays;

    const dayRate = Math.floor(spend_total / duration);
    let remaining;

    if (receipt && loyalty) {
      remaining = receipt.rental.total + loyalty.total;
    }

    return (
      <div>
        {earningPoints && (
          <TotalsItem
            label="Vehicle"
            item={carName}
            dailyPrice={cashDayRate}
            price={carPrice}
            hasAudiDays={userHasAudiDays}
            audiDaysUsed={audiDaysUsed}
            additionalDays={additionalDays}
          />
        )}
        {!earningPoints && (
          <div>
            <TotalsItem label="Vehicle" item={carName} />
            {full && (
              <TotalsItem
                item="Your points cover 100%"
                dailyPrice={dayRate}
                point={points_spend}
              />
            )}
            {partial && (
              <div>
                <TotalsItem
                  item="Points required"
                  point={spend_total}
                  dailyPrice={dayRate}
                />
                <TotalsItem
                  item={`Your points cover ${partial}%`}
                  point={`-${points_spend}`}
                />
                <TotalsItem
                  subItem="Remaining balance after points"
                  price={remaining}
                />
              </div>
            )}
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="estimated-total-modal-container">
      <section className="totals-section">
        <div className="totalItem">{renderVehicle()}</div>
        <div className="totalItem">
          {coverageOptions && (
            <TotalsItem
              label="Protection"
              item={coverageTitle}
              dailyPrice={coveragePriceDaily}
              price={coveragePrice}
            />
          )}

          {coverageOptions
            && coverageOptions.items.map(x => (
              <TotalsItem key={x.label} subItem={x.label} />
            ))}
        </div>

        {filteredDiscount.length > 0 && (
          <div className="totalItem">
            <TotalsItem
              label="Promo code"
              item={filteredDiscount[0].label}
              price={(partial_spend.percentage < 100 || earningPoints) ? filteredDiscount[0].total : ''}
            />
          </div>
        )}
      </section>
      <section className="total-taxes">
        <CostBreakdown item={taxesAndFees} />
      </section>
      <section className="total-footer">
        <div className="points">
          <PointsEarned points={pointsEarned} />
        </div>
        <div className="estimated-total">
          <Text as="p" variant="copy2" spaceInlineEnd="s">
            {isMobile ? 'Est. total ' : 'Estimated total'}
          </Text>
          <NumberDisplay className="number" dollarVariant="order2" value={total} />
        </div>
      </section>
      <section className="receipt-section" />
    </div>
  );
};

export default EstimatedTotalCash;
