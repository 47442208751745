export const USER_NETWORK_RESET = 'USER_NETWORK_RESET';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_ERROR = 'GET_USER_ERROR';
export const GET_USER_POINTS = 'GET_USER_POINTS';
export const GET_USER_POINTS_SUCCESS = 'GET_USER_POINTS_SUCCESS';
export const GET_USER_POINTS_ERROR = 'GET_USER_POINTS_ERROR';
export const GET_USER_AUDI_DAYS = 'GET_USER_AUDI_DAYS';
export const GET_USER_AUDI_DAYS_SUCCESS = 'GET_USER_AUDI_DAYS_SUCCESS';
export const GET_USER_AUDI_DAYS_ERROR = 'GET_USER_AUDI_DAYS_ERROR';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_ERROR = 'UPDATE_USER_ERROR';
export const SUBMIT_SIGNUP_SUCCESS = 'SUBMIT_SIGNUP_SUCCESS';
export const SUBMIT_SIGNUP_ERROR = 'SUBMIT_SIGNUP_ERROR';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_ERROR = 'FORGOT_PASSWORD_ERROR';
export const POST_CONFIRM_ACCOUNT_ERROR = 'POST_CONFIRM_ACCOUNT_ERROR';
export const POST_CONFIRM_ACCOUNT_SUCCESS = 'POST_CONFIRM_ACCOUNT_SUCCESS';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR';
export const SET_RESET_PASSWORD_TOKEN = 'SET_RESET_PASSWORD_TOKEN';
export const SET_RESET_PASSWORD_EMAIL = 'SET_RESET_PASSWORD_EMAIL';
export const CLAIM_AUDI_OWNER_CODE = 'CLAIM_AUDI_OWNER_CODE';
export const CLAIM_AUDI_OWNER_CODE_SUCCESS = 'CLAIM_AUDI_OWNER_CODE_SUCCESS';
export const CLAIM_AUDI_OWNER_CODE_ERROR = 'CLAIM_AUDI_OWNER_CODE_ERROR';
export const VALIDATE_RESET_PASSWORD_TOKEN_SUCCESS = 'VALIDATE_RESET_PASSWORD_TOKEN_SUCCESS';
export const VALIDATE_RESET_PASSWORD_TOKEN_ERROR = 'VALIDATE_RESET_PASSWORD_TOKEN_ERROR';
