import moment from 'moment';

export const FacebookPixelEvents = {
  SET_PICKUP_LOCATION: 'bookingDataUpdateLocation',
  SET_PICKUP_DATE: 'bookingDataUpdatePickupDate',
  SET_DROPOFF_DATE: 'bookingDataUpdateDropoffDate',
};

export const FacebookPixelMiddleware = ({ getState }) => next => async (action) => {
  const result = await next(action);
  const event = FacebookPixelEvents[action.type];
  if (event && window && window.dataLayer && window.dataLayer.push) {
    const {
      pickupDate,
      dropoffDate,
      pickupLocation,
    } = getState().bookingReducer.activeBooking;

    const locationSelected = Object.keys(pickupLocation).length;
    const { name, gds_code } = pickupLocation;

    const dataLayerParams = {
      event,
      bookingPickupDate: undefined,
      bookingDropoffDate: undefined,
      bookingLocation: undefined,
      bookingLocationId: undefined,
      bookingRegion: undefined,
      bookingCountry: 'USA',
    };

    if (pickupDate) {
      dataLayerParams.bookingPickupDate = moment(pickupDate).format('YYYY-MM-DD');
    }
    if (dropoffDate) {
      dataLayerParams.bookingDropoffDate = moment(dropoffDate).format('YYYY-MM-DD');
    }
    if (locationSelected) {
      dataLayerParams.bookingLocation = name;
      dataLayerParams.bookingLocationId = gds_code;
    }

    window.dataLayer.push(dataLayerParams);
  }
  return result;
};
