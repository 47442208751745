// @flow
import React from 'react';
import { useSelector } from 'react-redux';
import { Text } from '@audi/audi-ui-react';
import { useMediaQuery } from '@material-ui/core';
import { getEarningPoints } from '../../selectors';
import './index.css';

type TotalItemProps = {
  label: String;
  item: String;
  dailyPrice: Number;
  price: Number;
  subItem: String;
  point: String;
  hasAudiDays: Boolean;
  audiDaysUsed: Number;
  additionalDays: Number;
}

const TotalsItem = (props: TotalItemProps) => {
  const {
    label,
    item,
    dailyPrice,
    price,
    subItem,
    point,
    hasAudiDays,
    audiDaysUsed,
    additionalDays,
  } = props;

  const formattedPrice = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(price);
  const earningPoints = useSelector(getEarningPoints);
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));

  const renderCoverage = () => {
    if (subItem === 'Just The Car') {
      return '-Auto physical damage';
    } if (subItem === 'Other People And Their Things') {
      return '-Liability';
    } if (subItem === 'You And Your Things') {
      return '-Personal accident insurance / Personal effects coverage';
    }
    return subItem;
  };

  const totalItem = () => (hasAudiDays ? (<Text as="p" variant="copy1">{item}</Text>) : (
    <div className="total-item-row">
      <div className="item-name">
        <Text as="p" variant="copy1">{item}</Text>
      </div>
      <div className="pricing-items">
        <Text as="span" variant="copy2" spaceInlineEnd={isMobile ? '' : 'xl'}>
          {dailyPrice && (
            <div>
              $
              {Math.round(dailyPrice)}
              /day
            </div>
          )}
        </Text>
        <Text as="span" variant="copy1">{price && formattedPrice}</Text>
      </div>
    </div>
  )
  );

  const totalSubItem = () => (
    <div className="total-subItem-row">
      <div className="item-name">
        <Text as="p" variant="copy2">{renderCoverage() || item}</Text>
      </div>
      <div className="pricing-items">
        <Text as="span" variant="copy2" spaceInlineEnd="xl">
          {' '}
          {dailyPrice && (
            <div>
              $
              {dailyPrice}
              /day
              {' '}
            </div>
          )}
        </Text>
        <Text as="span" variant="copy1">{price && formattedPrice}</Text>
      </div>
    </div>
  );

  const additionalRentalDays = () => (isMobile
    ? (
      <>
        <div className="total-subItem-row">
          <div className="item-name">
            <Text as="p" variant="copy2">
              {' '}
            </Text>
          </div>
          <div className="pricing-items">
            <Text as="span" variant="copy2" spaceInlineEnd="xl">
              {' '}
            </Text>
            <Text as="span" variant="copy2" spaceStackStart="s">
              {' '}
              {dailyPrice && (
              <div>
              $
                {Math.round(dailyPrice)}
              /day
                {' '}
              </div>
              )}
            </Text>
          </div>
        </div>
        <div className="total-subItem-row">
          <div className="item-name">
            <Text as="p" variant="copy2">
              {additionalDays}
              {' '}
          additional rental day
              {additionalDays > 1 ? 's' : ''}
            </Text>
          </div>
          <div className="pricing-items">
            <Text as="span" variant="copy2" spaceInlineEnd="xl">
              {' '}
            </Text>
            <Text as="span" variant="copy1">{price && new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(dailyPrice * additionalDays)}</Text>
          </div>
        </div>
      </>
    )
    : (
      <div className="total-subItem-row additional-rental-days">
        <div className="item-name">
          <Text as="p" variant="copy2">
            {additionalDays}
            {' '}
            additional rental day
            {additionalDays > 1 ? 's' : ''}
          </Text>
        </div>
        <div className="pricing-items">
          <Text as="span" variant="copy2">
            {' '}
            {dailyPrice && (
            <div>
              $
              {Math.round(dailyPrice)}
              /day
              {' '}
            </div>
            )}
          </Text>
          <Text as="span" variant="copy2" spaceInlineEnd="xl">
            {' '}
          </Text>
          <Text as="span" variant="copy1">{price && new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(dailyPrice * additionalDays)}</Text>
        </div>
      </div>
    )
  );

  const alwaysAudiDaysUsed = () => (
    <div className="total-subItem-row">
      <div className="item-name">
        <Text as="p" variant="copy2">Always Audi days used</Text>
      </div>
      <div className="pricing-items">
        <Text as="span" variant="copy1">
          {audiDaysUsed}
          {' '}
          {'days'}
        </Text>
      </div>
    </div>
  );

  const pointItem = () => (
    <div className="total-subItem-row">
      <div className="item-name">
        <Text as="p" variant="copy2">
          {item}
        </Text>
      </div>
      <div className="pricing-items">
        <Text as="span" variant="copy2" spaceInlineEnd="xl">
          {' '}
          {dailyPrice && (
            <div>
              {dailyPrice}
              pts/day
            </div>
          )}
        </Text>
        <Text as="span" variant="copy1">
          {point && point}
          {' '}
          pts
        </Text>
      </div>
    </div>
  );

  return (
    <div>
      <div className="total-item">
        <Text as="h2" variant="copy2">{label}</Text>
        {!subItem && !point && totalItem()}
      </div>
      <div className="total-subItem">
        {hasAudiDays ? alwaysAudiDaysUsed() : point && !earningPoints && pointItem()}
      </div>
      <div className="total-subItem">
        {hasAudiDays ? additionalDays && additionalRentalDays() : subItem && totalSubItem()}
      </div>
    </div>

  );
};

export default TotalsItem;
