/* eslint-disable import/no-named-as-default */
// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import HTTPFormStatus from '../../components/forms/http-form-status';
import LocationDateTime from './location-date-time';
import Review from './review';
import VehicleSelect from './vehicle-select';
import LoginToContinueModal from '../../components/modals/login-to-continue';
import TexasWaiverModal from '../../components/modals/texas-waiver';
import CoverageOptionsModal from '../../components/modals/coverage-options';
import CoverageWarningModal from '../../components/modals/coverage-warning';
import './booking.css';
import SummaryModal from '../../components/modals/summary';
import Loading from '../../components/loading';
import CostSummaryModal from '../../components/modals/cost-summary/cost-summary-modal';
import LoyaltyProgramModal from '../../components/modals/loyalty-program';

type ActiveBooking = {
  dropoffDate: string,
  dropoffTime: string,
  pickupLocation: {
    id: number,
  },
  pickupDate: string,
  pickupTime: string,
  vehicle: {
    fleet_id: number,
  },
}

type Props = {
  activeBooking: ActiveBooking,
  appReady: boolean,
  httpResponseError: boolean,
  httpResponseStatusMessage: string,
  location: {
    pathname: string
  },
  history: {
    push: (string) => void
  },
}

type State = {
  redirect: boolean,
}

// update this to change the order of booking flow
// or to add to booking flow
// add conditions for cascading backward through routes
// to gate keep routes
const bookingFlowRoutes = [
  {
    RouteComponent: LocationDateTime,
    path: '/booking/location-date-time',
    redirect: () => false,
  },
  {
    RouteComponent: VehicleSelect,
    path: '/booking/vehicle',
    redirect: (state: ActiveBooking) => !state.pickupLocation.id
      || !state.pickupDate
      || !state.dropoffDate
      || !state.pickupTime
      || !state.dropoffTime,
  },
  {
    RouteComponent: Review,
    path: '/booking/review',
    redirect: (state: ActiveBooking) => !state.pickupLocation.id
      || !state.pickupDate
      || !state.dropoffDate
      || !state.pickupTime
      || !state.dropoffTime
      || !state.vehicle.fleet_id,
  },
];

// render Loading instead of component while waiting for app to load
// to prevent screen flashing during cascade
function CheckAppReady({ appReady, children }) {
  if (!appReady) {
    return <Loading />;
  }
  return children;
}

export class Booking extends Component<Props, State> {
  state = {
    redirect: false,
  }

  static getDerivedStateFromProps(props: Props) {
    const { location: { pathname } } = props;
    return { redirect: pathname === '/booking' };
  }

  componentDidUpdate() {
    const { activeBooking, appReady } = this.props;
    const currentRouteIndex = this.getCurrentRouteIndex();
    // Must check appReady or will cascade back to 0 index while loading
    if (appReady
      && currentRouteIndex >= 0
      && bookingFlowRoutes[currentRouteIndex].redirect(activeBooking)
    ) {
      this.previousStep();
    }
  }

  getCurrentRouteIndex = () => {
    const {
      location: {
        pathname,
      },
    } = this.props;
    return bookingFlowRoutes
      .map(route => route.path)
      .indexOf(pathname);
  }

  previousStep = () => {
    const { history } = this.props;
    // find index of current step so that you can decrement.
    // pathname is full path ( i.e. /booking/locations )
    // so must match path in bookingFlowRoutes
    const currentRouteIndex = this.getCurrentRouteIndex();
    return history.push(bookingFlowRoutes[currentRouteIndex - 1].path);
  }

  nextStep = () => {
    const { history } = this.props;

    // find index of current step so that you can increment.
    // pathname is full path ( i.e. /booking/locations )
    // so must match path in bookingFlowRoutes
    const currentRouteIndex = this.getCurrentRouteIndex();
    return history.push(bookingFlowRoutes[currentRouteIndex + 1].path);
  }

  render() {
    const {
      appReady,
      httpResponseError,
      httpResponseStatusMessage,
    } = this.props;

    const { redirect } = this.state;
    const lastRoute = [...bookingFlowRoutes].pop();

    return (
      <div className="booking-container">
        <HelmetProvider>
          <Helmet>
            <title>Make a Reservation | Audi on demand</title>
          </Helmet>
        </HelmetProvider>
        {redirect && (
          <Redirect to={lastRoute.path} />
        )}
        {httpResponseStatusMessage && (
          <HTTPFormStatus
            category=""
            type={httpResponseError ? 'error' : 'success'}
            message={httpResponseStatusMessage}
          />
        )}
        <LoginToContinueModal bookingType="staged" />
        <SummaryModal />
        <TexasWaiverModal />
        <CoverageWarningModal />
        <CoverageOptionsModal />
        <CostSummaryModal />
        <LoyaltyProgramModal />
        <section>
          <Switch>
            {bookingFlowRoutes.map((route) => {
              const { RouteComponent, path } = route;
              return (
                <Route
                  key={path}
                  exact
                  path={path}
                  render={() => (
                    <CheckAppReady appReady={appReady}>
                      <RouteComponent nextStep={this.nextStep} prevStep={this.previousStep} />
                    </CheckAppReady>
                  )}
                />
              );
            })}
            <Redirect from="/booking/locations" to="/booking" />
            {!redirect ? <Redirect to="/404" /> : null}
          </Switch>
        </section>
      </div>
    );
  }
}

export const mapStateToProps = state => ({
  activeBooking: state.bookingReducer.activeBooking,
  appReady: state.appReducer.isReady,
  httpResponseError: state.bookingReducer.httpResponseError,
  httpResponseStatusMessage: state.bookingReducer.httpResponseStatusMessage,
});

export default connect(mapStateToProps)(Booking);
