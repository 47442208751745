// @flow

const MuiIconButton = {
  root: {
    padding: '0',
    '&:hover': {
      backgroundColor: 'unset',
    },
  },
};

export default MuiIconButton;
