// @flow
import React from 'react';
import { connect } from 'react-redux';
import SetPasswordForm from '../../components/forms/set-password';
import { postConfirmAccount } from '../../actions/account';

type Props = {
  postConfirmAccount: Function,
  queryParams: Object,
}

export function SetPassword(props: Props) {
  const { queryParams, postConfirmAccount } = props;
  return (
    <div>
      <SetPasswordForm
        postConfirmAccount={postConfirmAccount}
        queryParams={queryParams}
      />
    </div>
  );
}

export const mapStateToProps = state => ({
  queryParams: state.appReducer.queryParams,
});

export const mapDispatchToProps = dispatch => ({
  postConfirmAccount: queryParams => dispatch(postConfirmAccount(queryParams)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SetPassword);
