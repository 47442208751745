// @flow
import { Text } from '@audi/audi-ui-react';
import React, { Fragment, useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import {
  Box, TextField, Button,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { COLORS } from '../../theme/constants';
import { getPromo, resetPromo } from '../../actions/booking';
import {
  getActiveBookingPromo,
  getActiveBookingCoverage,
  getSelectedFleetPricing,
  getBookingHasError,
} from '../../selectors';
import EditItem from './edit-item';
import EditPromotionItem from './edit-promotion-item';
import Coverage from '../../containers/booking/review/constants';
import HTTPFormStatus from '../forms/http-form-status';
import Loading from '../loading';

type Props = {
  editCoverage: () => void,
  fetchPromoCode: (code: string) => void,
  isMobile: boolean,
  removePromoCode: () => void,
  httpResponseStatusMessage: string,
  httpResponseError: string,
}

export const ReviewCoverage = (props: Props) => {
  const {
    fetchPromoCode,
    editCoverage,
    isMobile,
    removePromoCode,
    httpResponseStatusMessage,
    httpResponseError,
  } = props;

  const { type, coverageOptions, carrier } = useSelector(getActiveBookingCoverage);
  const { description, promo_code } = useSelector(getActiveBookingPromo);
  const { total } = useSelector(getSelectedFleetPricing);
  const bookingError = useSelector(getBookingHasError);

  const [showPromoCode, updateShowPromoCode] = useState(false);
  const [promoCode, updatePromoCode] = useState('');
  const [isSubmitting, updateIsSubmitting] = useState(false);

  const handleAddPromoCode = () => {
    if (!promoCode) {
      removePromoCode();
    }
    updateIsSubmitting(true);
    fetchPromoCode(promoCode);
  };

  const handleValidPromoCode = () => {
    updateIsSubmitting(false);
    updateShowPromoCode(false);
  };

  const setCoverageSubtitle = () => {
    if (type !== Coverage.SILVERCAR) {
      return [carrier];
    }
    return coverageOptions;
  };

  useEffect(() => updateIsSubmitting(false), [bookingError]);
  // wait until the new price is returned before updating
  useEffect(handleValidPromoCode, [total]);
  // if a promo_code already exists in redux set it to the local state
  useEffect(() => updatePromoCode(promo_code), [promo_code]);

  const RenderPromoCode = () => {
    if (promo_code) {
      return (
        <Box display="flex" justifyContent="space-between" pt={1.3}>
          <EditPromotionItem
            title="Promo Code"
            subtitle={[description]}
            handleClick={() => updateShowPromoCode(true)}
          />
        </Box>
      );
    }
    return (
      <Box id="add-promo-code" pt={1.3} className="clickable" onClick={() => updateShowPromoCode(true)}>
        <Text as="span" variant="copy1" weight="bold">
          <AddIcon fontSize="small" />
          Add Promo Code
        </Text>
      </Box>
    );
  };

  return (
    <div>
      {httpResponseStatusMessage && (
        <HTTPFormStatus
          category=""
          type={httpResponseError
            ? 'error'
            : 'success'
          }
          message={httpResponseStatusMessage}
        />
      )}
      <Fragment>
        <Box
          display="flex"
          justifyContent="space-between"
          pb={1.3}
          borderBottom={`1px solid ${COLORS.GREY3}`}
        >
          <EditItem
            title={`${type} Coverage`}
            subtitle={setCoverageSubtitle()}
            handleClick={editCoverage}
          />
        </Box>
        {(showPromoCode || isSubmitting) && (
          <Box
            pt={2}
            display="flex"
            flexDirection={isMobile ? 'column' : 'row'}
          >
            <Box
              display="flex"
              flexDirection="column"
              mr={1}
              width={isMobile ? '100%' : '60%'}
            >
              <TextField
                id="promo-code"
                label="Promo Code"
                autoFocus
                onChange={(e) => { updatePromoCode(e.target.value); }}
                value={promoCode}
              />
            </Box>
            <Button
              id="submit-promo-code"
              variant="outlined"
              disabled={promo_code === promoCode}
              onClick={handleAddPromoCode}
            >
              {isSubmitting ? <Loading size={20} padding={0} /> : 'Apply'}
            </Button>
          </Box>
        )}
        {!showPromoCode && !isSubmitting && <RenderPromoCode />}
      </Fragment>
    </div>
  );
};

export const mapStateToProps = state => ({
  httpResponseError: state.bookingReducer.httpResponseError,
  httpResponseStatusMessage: state.bookingReducer.httpResponseStatusMessage,
});

export const mapDispatchToProps = dispatch => ({
  fetchPromoCode: value => dispatch(getPromo(value)),
  removePromoCode: () => dispatch(resetPromo()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReviewCoverage);
