import { COLORS } from '../../../../theme/constants';

export const getMobileStyles = () => ({
  containerStyles: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  headerStyles: {
    display: 'block',
    width: '100%',
  },
  costSummaryStyles: {
    flex: 1,
    pb: 1,
    marginX: 1.25,
  },
  rentalInfoStyles: {
    margin: '0 1.25rem 1rem',
    padding: '1.5rem 0',
    borderTop: `1px solid ${COLORS.GREY3}`,
    borderBottom: `0.625rem solid ${COLORS.PRIMARY}`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '100%',
  },
  confirmationNumberStyles: {
    margin: '0 1.25rem',
    padding: '0.5rem 0 1.25rem',
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  lineItemStyles: {
    display: 'flex',
    justifyContent: 'space-between',
    pt: '1.5rem',
    pb: '1.5rem',
    borderBottom: `1px solid ${COLORS.GREY3}`,
  },
});

export const getDesktopStyles = () => ({
  containerStyles: {
    display: 'flex',
    maxWidth: '83rem',
    margin: '0 auto 4rem',
    flexDirection: 'row',
    flexWrap: 'wrap',
    height: 'fit-content',
  },
  headerStyles: {
    flex: '1 0 100%',
    mb: 2.25,
  },
  costSummaryStyles: {
    border: `1px solid ${COLORS.GREY3}`,
    ml: 1,
    px: 3.75,
    py: 3.75,
    width: 'calc(50% - 8.5rem)',
    height: 'fit-content',
  },
  rentalInfoStyles: {
    width: 'calc(50% - 7.5rem)',
    borderTop: `1px solid ${COLORS.GREY3}`,
    borderBottom: `1px solid ${COLORS.GREY3}`,
    borderLeft: `1px solid ${COLORS.GREY3}`,
    borderRight: `1px solid ${COLORS.GREY3}`,
    margin: '0 1rem 0 0',
    p: 3.125,
    height: 'fit-content',
    display: 'flex',
    flexDirection: 'column',
  },
  confirmationNumberStyles: {},
  lineItemStyles: {
    display: 'flex',
    justifyContent: 'space-between',
    pt: '1.875rem',
    pb: '1.5rem',
    borderBottom: `1px solid ${COLORS.GREY3}`,
  },
});
