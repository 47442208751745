// @flow
import React from 'react';
import { Text, LayoutItem } from '@audi/audi-ui-react';
import './index.css';

type Props = {
  text: String,
  handleClose?: Function,
  onClick?: Function,
}

function ReservationMenuItem({
  text,
  handleClose,
  onClick = () => {},
}: Props) {
  return (
    <div>
      <LayoutItem onClick={handleClose}>
        <span role="button" tabIndex={0} onKeyPress={onClick} onClick={onClick} className="reservation-menu-item">
          <Text variant="copy1" as="p" className="reservation-menu-text">{text}</Text>
        </span>
      </LayoutItem>
    </div>
  );
}

export default ReservationMenuItem;
