/* eslint-disable no-use-before-define */
// @flow
import HTTP from '../../utils/http';
import history from '../../history';
import * as Actions from '../index';
import * as BookingActions from '../booking';
import * as DriversLicenseActions from './drivers-license';
import * as CreditCardActions from './credit-cards';
import Storage from '../../utils/storage';
import { getErrorMessage, setToastTimeout } from '../../utils';
import * as AccountTypes from './constants';
import Cookies from '../../utils/services/cookies';
import * as datadog from '../../utils/datadog';

export const setResetPasswordToken = (payload: string) => ({
  type: AccountTypes.SET_RESET_PASSWORD_TOKEN,
  payload,
});

export const setResetPasswordEmail = (payload: string) => ({
  type: AccountTypes.SET_RESET_PASSWORD_EMAIL,
  payload,
});

// USER NETWORK RESET //////////////////
export const userNetworkReset = () => ({
  type: AccountTypes.USER_NETWORK_RESET,
});

// FETCH USER DATA  //////////
export const getUserData = () => (dispatch: Function) => (
  dispatch(getUser())
    .then(() => dispatch(CreditCardActions.creditCardsFetchBraintreeToken()))
    .then(() => dispatch(CreditCardActions.creditCardsFetchData()))
    .then(() => dispatch(DriversLicenseActions.getDriversLicense()))
    .then(() => dispatch(getUserAudiDays()))
    .then(() => dispatch(getUserPoints()))
);

export const getUserAccountData = () => (dispatch: Function) => {
  setToastTimeout(userNetworkReset());
  return dispatch(getUser())
    .then(() => dispatch(CreditCardActions.creditCardsFetchBraintreeToken()))
    .then(() => dispatch(CreditCardActions.creditCardsFetchData()))
    .then(() => dispatch(DriversLicenseActions.getDriversLicense()))
    .then(() => dispatch(getUserPoints()));
};

export const getUserAudiDays = () => (
  (dispatch: Function) => (
    HTTP.userAudiDays.get()
      .then(({ data }) => dispatch(getUserAudiDaysSuccess(data)))
      .catch(error => dispatch(getUserAudiDaysError(error)))
  )
);

export const getUserAudiDaysSuccess = (payload: Object) => (dispatch: Function) => {
  const { remaining } = payload;

  Storage.set('userDays', payload.remaining);
  Cookies.add('userDays', remaining);
  dispatch({
    type: AccountTypes.GET_USER_AUDI_DAYS_SUCCESS,
    payload,
  });
};

export const getUserAudiDaysError = (payload: string) => ({
  type: AccountTypes.GET_USER_AUDI_DAYS_ERROR,
  payload: getErrorMessage(payload),
});

export const getUserPoints = () => (
  (dispatch: Function) => (
    HTTP.userPoints.get()
      .then(({ data }) => dispatch(getUserPointsSuccess(data)))
      .catch(error => dispatch(getUserPointsError(error)))
  )
);

export const getUserPointsSuccess = (payload: Object) => (dispatch: Function) => {
  const { total } = payload;

  Storage.set('userPoints', total);
  Cookies.add('userPoints', total);
  dispatch({
    type: AccountTypes.GET_USER_POINTS_SUCCESS,
    payload,
  });
};

export const getUserPointsError = (payload: string) => {
  setToastTimeout(userNetworkReset());
  return {
    type: AccountTypes.GET_USER_POINTS_ERROR,
    payload: getErrorMessage(payload),
  };
};

export const getUserSuccess = (payload: Object) => (
  (dispatch: Function) => (
    Promise.resolve().then(() => {
      const {
        id,
        complete_profile,
        email,
        first_name,
        last_name,
      } = payload;

      datadog.setUserAttributes({
        id,
        email,
        name: `${first_name} ${last_name}`,
      });

      Storage.set('user', payload);
      Cookies
        .add('complete_profile', complete_profile)
        .add('email', email)
        .add('first_name', first_name)
        .add('last_name', last_name);

      dispatch({
        type: AccountTypes.GET_USER_SUCCESS,
        payload,
      });
    })
  )
);

export const getUserError = (payload: Object) => (
  (dispatch: Function) => (
    Promise.resolve().then(() => (
      dispatch({
        type: AccountTypes.GET_USER_ERROR,
        payload: getErrorMessage(payload),
      })
    ))
  )
);

export const getUser = () => (
  (dispatch: Function) => (
    HTTP.users.get()
      .then(({ data }) => dispatch(getUserSuccess(data)))
      .catch(error => dispatch(getUserError(error)))
  )
);

// USER UPDATE DATA //////////////////
export const updateUserSuccess = (payload: Object) => {
  setToastTimeout(userNetworkReset());
  return {
    type: AccountTypes.UPDATE_USER_SUCCESS,
    payload,
  };
};

export const updateUserError = (payload: Object) => {
  setToastTimeout(userNetworkReset());
  return {
    type: AccountTypes.UPDATE_USER_ERROR,
    payload: getErrorMessage(payload),
  };
};

export const updateUser = (params: Object) => (
  (dispatch: Function) => (
    HTTP.users.put(params)
      .then(() => dispatch(updateUserSuccess(params)))
      .catch(error => dispatch(updateUserError(error)))
  )
);

// USER CONFIRM ACCOUNT
export const postConfirmAccountError = (payload: String) => {
  history.push('/login');
  return {
    type: AccountTypes.POST_CONFIRM_ACCOUNT_ERROR,
    payload: getErrorMessage(payload),
  };
};

export const postConfirmAccountSuccess = () => (
  (dispatch: Function) => (
    Promise.resolve().then(() => (
      dispatch({
        type: AccountTypes.POST_CONFIRM_ACCOUNT_SUCCESS,
      })
    ))
  )
);

export const postConfirmAccount = (data: Object) => (
  (dispatch: Function) => (
    Promise.resolve().then(() => {
      if (data.needs_password) {
        history.replace('/set-password');
      } else {
        return dispatch(postConfirmAccountAction(data));
      }
      return false;
    })
  )
);

export const postConfirmAccountAction = (payload: Object) => (
  (dispatch: Function) => (
    HTTP.users.postConfirm(payload)
      .then(response => dispatch(Actions.loginSuccess(response.data)))
      .then(() => dispatch(getUserData()))
      .then(() => dispatch(BookingActions.getBookings(1)))
      .then(() => dispatch(BookingActions.getRentals(1)))
      .then(() => dispatch(BookingActions.hideModals(1)))
      .then(() => dispatch(postConfirmAccountSuccess()))
      .then(() => history.replace('/reservations'))
      .catch(error => dispatch(postConfirmAccountError(error)))
  )
);

// User Sign Up
export function submitSignupSuccess(payload: Object) {
  setToastTimeout(userNetworkReset());
  return {
    type: AccountTypes.SUBMIT_SIGNUP_SUCCESS,
    payload,
  };
}

export const submitSignupError = (payload: String) => ({
  type: AccountTypes.SUBMIT_SIGNUP_ERROR,
  payload: getErrorMessage(payload),
});

export const submitSignup = (data: Object) => (
  (dispatch: Function) => (
    HTTP.users.post(data)
      .then((response) => {
        const user = response.data;
        dispatch(submitSignupSuccess(user));
        dispatch(BookingActions.createUserPostBooking(response, data));
        dispatch(BookingActions.claimBookingPostSignup(data.email));
      })
      .catch(error => dispatch(submitSignupError(error)))
  )
);

// Reset Password
export const resetPasswordSuccess = () => {
  history.push('/login');
  return {
    type: Actions.RESET_PASSWORD_SUCCESS,
  };
};

export const resetPasswordError = (payload: Object) => ({
  type: AccountTypes.RESET_PASSWORD_ERROR,
  payload: getErrorMessage(payload),
});

export const resetPassword = (data: Object) => (
  (dispatch: Function) => (
    HTTP.users.putResetPassword(data)
      .then(() => dispatch(resetPasswordSuccess()))
      .catch(error => dispatch(resetPasswordError(error)))
  )
);

// Forgot Password
export const forgotPasswordSuccess = (payload: Object) => ({
  type: AccountTypes.FORGOT_PASSWORD_SUCCESS,
  payload,
});

export const forgotPasswordError = (payload: String) => ({
  type: AccountTypes.FORGOT_PASSWORD_ERROR,
  payload: getErrorMessage(payload),
});

export const forgotPassword = (email: Object) => (
  async (dispatch: Function) => {
    try {
      await HTTP.users.postForgotPassword(email);
      return dispatch(forgotPasswordSuccess(email));
    } catch (error) {
      return dispatch(forgotPasswordError(error));
    }
  }
);

// Validate the reset password token
export const validatePasswordTokenSuccess = (payload: Object) => ({
  type: AccountTypes.VALIDATE_RESET_PASSWORD_TOKEN_SUCCESS,
  payload,
});

export const validatePasswordTokenError = (payload: String) => ({
  type: AccountTypes.VALIDATE_RESET_PASSWORD_TOKEN_ERROR,
  payload: getErrorMessage(payload),
});

export const validateResetPasswordToken = (payload: Object) => (
  async (dispatch: Function) => {
    try {
      const response = await HTTP.users.postValidateResetPasswordToken(payload);
      return dispatch(validatePasswordTokenSuccess(response.data.authentication_token));
    } catch (error) {
      return dispatch(validatePasswordTokenError(error));
    }
  }
);

// Audi Owner Claim Codes
export const claimAudiOwnerCodeError = (payload) => {
  setToastTimeout(userNetworkReset());
  return {
    type: AccountTypes.CLAIM_AUDI_OWNER_CODE_ERROR,
    payload: getErrorMessage(payload),
  };
};

export const claimAudiOwnerCodeSuccess = () => {
  setToastTimeout(userNetworkReset());
  return (dispatch: Function) => {
    dispatch({
      type: AccountTypes.CLAIM_AUDI_OWNER_CODE_SUCCESS,
      payload: 'Success! Your code has been validated and claimed!',
    });
  };
};

export function claimAudiOwnerCode(payload: string) {
  return (dispatch: Function) => {
    dispatch({
      type: AccountTypes.CLAIM_AUDI_OWNER_CODE,
    });
    return HTTP.users.claimAudiOwnerCode(payload)
      .then(({ status }) => {
        dispatch(claimAudiOwnerCodeSuccess());
        if (status === 204) dispatch(getUserAudiDays());
      })
      .catch(error => dispatch(claimAudiOwnerCodeError(error)));
  };
}
