// @flow
export const ENV_URL = process.env.REACT_APP_API_HOST || 'rac-tst';
export function getHomeUrl() {
  switch (ENV_URL) {
    case 'audiondemand':
      return 'https://www.audiondemand.com/';
    case 'rac-stg':
      return 'https://rac-stg.com/';
    default:
      return 'https://rac-tst.com/';
  }
}
// Unfortunately we can't use ENV_URL because we don't have a 'staging' branch to set builds off of
// (yet, at least) so we have to manually switch / case over the hostname to see where we are
// (for now)
function headerAppSource() {
  const { hostname } = window.location;
  switch (hostname) {
    case 'app.rac-tst.com':
      return 'QA';
    case 'app.rac-stg.com':
      return 'Stage';
    case 'app.audiondemand.com':
      return 'Official';
    case 'localhost':
      return 'Dev';
    default:
      return `Unknown Source with hostname of: ${hostname}`;
  }
}

function headerPlatform() {
  const screenWidth = window.innerWidth;
  switch (true) {
    case (screenWidth < 600):
      return 'Web Mobile';
    case (screenWidth >= 600 && screenWidth <= 960):
      return 'Web Tablet';
    case (screenWidth > 960):
      return 'Web Desktop';
    default:
      return `Unknown sc-platform for device width: ${screenWidth}`;
  }
}

export function headers() {
  return {
    'x-sc-app-name': 'Driver-ACR',
    'x-sc-app-version': process.env.REACT_APP_VERSION,
    'x-sc-app-source': headerAppSource(),
    'x-sc-platform': headerPlatform(),
    'x-sc-platform-version': window.navigator.userAgent,
    'Api-Version': '2',
    'Content-Type': 'application/json',
    Accept: 'application/json',
  };
}

function getAPIDomain() {
  return ENV_URL === 'audiondemand' ? 'silvercar' : ENV_URL;
}

export const apiUrl = {
  v1: `https://api.${getAPIDomain()}.com/api/v1`,
  v2: `https://api.${getAPIDomain()}.com`,
  zendeskProd: 'https://www.audiondemand.com/wp-json/aod/v1/send_zd_request',
};
