import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const GoogleTagManager = () => {
  const location = useLocation();

  useEffect(() => {
    if (window.dataLayer) {
      window.dataLayer.push({
        event: 'page_view',
        eventCategory: `${location.pathname}`,
        eventAction: 'click',
        eventLabel: `${location.pathname}`,
      });
    }
  }, [location]);

  return null;
};

export default GoogleTagManager;
