// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Text, TextField, Button,
} from '@audi/audi-ui-react';
import { claimAudiOwnerCode } from '../../../../actions/account';
import { trackBookingStart as trackBookingStartAction } from '../../../../actions/booking';
import './index.css';

type Props = {
  claimAudiOwnerCode: () => void,
  userAudiOwnerProgramDays: number,
  trackBookingStart: () => {},
}

type State = {
  claimCode: string,
}

const url = 'https://www.audiondemand.com/always-audi';

export class AlwaysAudiProgram extends Component<Props, State> {
  state = {
    buttonDisabled: true,
    claimCode: '',
  }

  handleTextInput = (e) => {
    const { value } = e.target;
    this.setState({
      claimCode: value,
      buttonDisabled: value.length === 0,
    });
  };

  handleSubmit = () => {
    const { claimAudiOwnerCode } = this.props;
    const { claimCode } = this.state;
    claimAudiOwnerCode(claimCode);
  };

  alwaysAudiCode() {
    const {
      buttonDisabled,
      isLoading,
      claimCode,
    } = this.state;

    return (
      <>
        <Text as="span" variant="order4" weight="bold" spaceStackEnd="s" className="account-tab-text">Buy an Audi and your next rental is on us</Text>
        <Text as="span" variant="copy1" className="account-tab-text">When you purchase or lease a new Audi, you&apos;ll get rental car days with</Text>
        <Text as="span" variant="copy1" spaceStackEnd="xxl" className="account-tab-text">Audi on demand. Claim by submitting your redemption code here.</Text>
        <div className="claim-code-input-flexbox">
          <TextField required className="claim-code-input" spaceInlineEnd="xs" inputId="claim-code" label="Claim code" value={claimCode} onChange={this.handleTextInput} labelEffect={claimCode ? 'none' : 'float'} />
          <Button className="submit-always-audi-button" spaceStackStart="s" spaceInlineEnd="xs" variant="primary" size="small" disabled={buttonDisabled} onClick={this.handleSubmit} loading={isLoading}>Submit</Button>
        </div>
        <Text as="p" variant="copy2" spaceStackEnd="xxxl" spaceStackStart="l" className="account-tab-text">
          Visit our
          {' '}
          <a className="info-page-link" href={url} target="_blank" rel="noopener noreferrer">info page</a>
          {' '}
          for more details and full terms and conditions.
        </Text>
      </>
    );
  }

  render() {
    const {
      userAudiOwnerProgramDays,
      trackBookingStart,
    } = this.props;

    return (
      <>
        {userAudiOwnerProgramDays > 0 ? (
          <div className="account-page-tab-content always-audi-program" id="always-audi-program">
            <Text as="span" variant="order2" weight="bold" spaceStackEnd="l" className="account-tab-text">Always Audi Program</Text>
            <div className="inner-row-column">
              <div className="inner-column left-column">
                {this.alwaysAudiCode()}
              </div>
              <hr className="always-audi-program-rule" />
              <div className="inner-column right-column">
                <Text as="span" variant="order4" weight="bold">Thank you! Now you can enjoy your days.</Text>
                <Text as="span" variant="copy1" spaceStackStart="l">Total remaining days</Text>
                <Text id="audi-program-days" as="h1" variant="display1" spaceStackEnd="l">{userAudiOwnerProgramDays}</Text>
                <div>
                  <Button
                    id="profile-book-now-button"
                    className="book-now-button"
                    href="/booking"
                    isExternal={false}
                    noBorder
                    size="small"
                    onClick={() => trackBookingStart('header-promo-ribbon')}
                    variant="primary"
                  >
                  Book now
                  </Button>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="account-page-tab-content always-audi-program" id="always-audi-program">
            <Text as="span" variant="order2" weight="bold" spaceStackEnd="l" className="account-tab-text">Always Audi Program</Text>
            { this.alwaysAudiCode() }
          </div>
        )}
      </>
    );
  }
}

export const mapStateToProps = state => ({
  buttonDisabled: state.buttonDisabled,
  claimCode: state.claimCode,
  isLoading: state.accountReducer.isLoading,
  userAudiOwnerProgramDays: state.accountReducer.userAudiDays.remaining,
});

export const mapDispatchToProps = dispatch => ({
  claimAudiOwnerCode: code => dispatch(claimAudiOwnerCode(code)),
  trackBookingStart: () => dispatch(trackBookingStartAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AlwaysAudiProgram);
