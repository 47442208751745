// @flow
import { Text } from '@audi/audi-ui-react';
import React from 'react';

type ConfirmVehicleBookedProps = {
    vehicle: Object,
}

const ConfirmVehicleBooked = (props: ConfirmVehicleBookedProps) => {
  const {
    vehicle,
  } = props;

  const { model, description } = vehicle;
  return (
    <>
      <Text as="p" variant="copy2">
        Vehicle
      </Text>
      <Text as="h2" variant="order2">
        Audi
        {' '}
        {model}
      </Text>
      <Text as="p" variant="copy1" spaceStackEnd="xxxl">
        {description}
      </Text>
    </>
  );
};

export default ConfirmVehicleBooked;
