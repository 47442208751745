// @flow
import React from 'react';
import Box from '@material-ui/core/Box';
import { Button } from '@audi/audi-ui-react';

type Props = {
  trackBookingStart: Function,
}

function DesktopBookingButton({ trackBookingStart }: Props) {
  return (
    <Box
      ml="1rem"
      mt="1rem"
    >
      <Button
        id="top-nav-book-now-button"
        href="/booking"
        isExternal={false}
        noBorder
        size="small"
        onClick={() => trackBookingStart('header-promo-ribbon')}
        variant="primary"
      >
        Book now
      </Button>
    </Box>
  );
}

export default DesktopBookingButton;
