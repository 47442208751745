// @flow
import { createSelector } from 'reselect';
import type { State as AccountReducer } from '../reducers/account';

const accountReducer = (state: { accountReducer: AccountReducer }) => state.accountReducer;

const userAudiDaysRemaining = (
  accountReducer: AccountReducer,
) => accountReducer.userAudiDays.remaining;

const userDriversLicense = (
  accountReducer: AccountReducer,
) => accountReducer.drivers_license;

const userLoyaltyPoints = (
  accountReducer: AccountReducer,
) => accountReducer.userPoints.total;

const userId = (
  accountReducer: AccountReducer,
) => accountReducer.user.id;

export const getRemainingAudiDays = createSelector(
  accountReducer,
  userAudiDaysRemaining,
);

export const getUserLoyaltyPoints = createSelector(
  accountReducer,
  userLoyaltyPoints,
);

export const getUserId = createSelector(
  accountReducer,
  userId,
);

export const getDriversLicense = createSelector(
  accountReducer,
  userDriversLicense,
);
