export const ATTACH_TRAVEL_AGENT_ERROR = 'ATTACH_TRAVEL_AGENT_ERROR';
export const ATTACH_TRAVEL_AGENT_SUCCESS = 'ATTACH_TRAVEL_AGENT_SUCCESS';
export const ATTACH_TRAVEL_AGENT = 'ATTACH_TRAVEL_AGENT';
export const SPEND_USER_LOYALTY_POINTS = 'SPEND_USER_LOYALTY_POINTS';
export const SPEND_USER_LOYALTY_POINTS_SUCCESS = 'SPEND_USER_LOYALTY_POINTS_SUCCESS';
export const SPEND_USER_LOYALTY_POINTS_ERROR = 'SPEND_USER_LOYALTY_POINTS_ERROR';
export const CLAIM_BOOKING_POST_SIGNUP = 'CLAIM_BOOKING_POST_SIGNUP';
export const CLAIM_BOOKING_POST_SIGNUP_SUCCESS = 'CLAIM_BOOKING_POST_SIGNUP_SUCCESS';
export const CLAIM_BOOKING_POST_SIGNUP_ERROR = 'CLAIM_BOOKING_POST_SIGNUP_ERROR';
export const CREATE_BOOKING = 'CREATE_BOOKING';
export const CREATE_BOOKING_SUCCESS = 'CREATE_BOOKING_SUCCESS';
export const CREATE_BOOKING_ERROR = 'CREATE_BOOKING_ERROR';
export const CLEAR_STAGED_BOOKING = 'CLEAR_STAGED_BOOKING';
export const CREATE_STAGED_BOOKING = 'CREATE_STAGED_BOOKING';
export const CREATE_STAGED_GDS = 'CREATE_STAGED_GDS';
export const CREATE_USER_POST_BOOKING = 'CREATE_USER_POST_BOOKING';
export const CREATE_USER_POST_BOOKING_SUCCESS = 'CREATE_USER_POST_BOOKING_SUCCESS';
export const CREATE_USER_POST_BOOKING_ERROR = 'CREATE_USER_POST_BOOKING_ERROR';
export const DELETE_BOOKING = 'DELETE_BOOKING';
export const DELETE_BOOKING_ERROR = 'DELETE_BOOKING_ERROR';
export const DELETE_BOOKING_SUCCESS = 'DELETE_BOOKING_SUCCESS';
export const FULL_PRICE_BREAKDOWN = 'FULL_PRICE_BREAKDOWN';
export const GENERATE_BOOKING = 'GENERATE_BOOKING';
export const GET_BOOKINGS = 'GET_BOOKINGS';
export const GET_BOOKINGS_ERROR = 'GET_BOOKINGS_ERROR';
export const GET_BOOKINGS_SUCCESS = 'GET_BOOKINGS_SUCCESS';
export const GET_BOOKINGS_BY_TOKEN = 'GET_BOOKINGS_BY_TOKEN';
export const GET_BOOKINGS_BY_TOKEN_SUCCESS = 'GET_BOOKINGS_BY_TOKEN_SUCCESS';
export const GET_BOOKINGS_BY_TOKEN_ERROR = 'GET_BOOKINGS_BY_TOKEN_ERROR';
export const GET_BOOKINGS_BY_ID_ERROR = 'GET_BOOKINGS_BY_ID_ERROR';
export const GET_BOOKINGS_BY_ID_SUCCESS = 'GET_BOOKINGS_BY_ID_SUCCESS';
export const GET_COVERAGE_OPTIONS = 'GET_COVERAGE_OPTIONS';
export const GET_COVERAGE_OPTIONS_SUCCESS = 'GET_COVERAGE_OPTIONS_SUCCESS';
export const GET_COVERAGE_OPTIONS_ERROR = 'GET_COVERAGE_OPTIONS_ERROR';
export const GET_FLEET_SUCCESS = 'GET_FLEET_SUCCESS';
export const GET_FLEET_ERROR = 'GET_FLEET_ERROR';
export const GET_FLEET_PRICING = 'GET_FLEET_PRICING';
export const GET_FLEET_PRICING_SUCCESS = 'GET_FLEET_PRICING_SUCCESS';
export const GET_FLEET_PRICING_ERROR = 'GET_FLEET_PRICING_ERROR';
export const GET_UPCOMING_FLEETS_SUCCESS = 'GET_UPCOMING_FLEETS_SUCCESS';
export const GET_UPCOMING_FLEETS_ERROR = 'GET_UPCOMING_FLEETS_ERROR';
export const GET_LOCATIONS = 'GET_LOCATIONS';
export const GET_LOCATIONS_SUCCESS = 'GET_LOCATIONS_SUCCESS';
export const GET_LOCATIONS_ERROR = 'GET_LOCATIONS_ERROR';
export const GET_LOCATIONS_FLEET_AVAILABILITY = 'GET_LOCATIONS_FLEET_AVAILABILITY';
export const GET_LOCATIONS_FLEET_AVAILABILITY_LOADING = 'GET_LOCATIONS_FLEET_AVAILABILITY_LOADING';
export const GET_LOCATIONS_FLEET_AVAILABILITY_SUCCESS = 'GET_LOCATIONS_FLEET_AVAILABILITY_SUCCESS';
export const GET_LOCATIONS_FLEET_AVAILABILITY_ERROR = 'GET_LOCATIONS_FLEET_AVAILABILITY_ERROR';
export const GET_LOCATIONS_AVAILABILITY_SUCCESS = 'GET_LOCATIONS_AVAILABILITY_SUCCESS';
export const GET_LOCATIONS_AVAILABILITY_ERROR = 'GET_LOCATIONS_AVAILABILITY_ERROR';
export const GET_PROMO_SUCCESS = 'GET_PROMO_SUCCESS';
export const GET_PROMO_ERROR = 'GET_PROMO_ERROR';
export const GET_RENTALS = 'GET_RENTALS';
export const GET_RENTALS_ERROR = 'GET_RENTALS_ERROR';
export const GET_RENTALS_SUCCESS = 'GET_RENTALS_SUCCESS';
export const HIDE_MODALS = 'HIDE_MODALS';
export const INIT_BOOKING = 'INIT_BOOKING';
export const PUT_GDS = 'PUT_GDS';
export const PUT_GDS_SUCCESS = 'PUT_GDS_SUCCESS';
export const PUT_GDS_ERROR = 'PUT_GDS_ERROR';
export const RESET_AVAILABILITY_AND_PRICING = 'RESET_AVAILABILITY_AND_PRICING';
export const RESET_BOOKING = 'RESET_BOOKING';
export const RESET_EARNING_POINTS = 'RESET_EARNING_POINTS';
export const RESET_GDS = 'RESET_GDS';
export const REMOVE_PROMO = 'REMOVE_PROMO';
export const SEARCH_GDS = 'SEARCH_GDS';
export const SEARCH_GDS_SUCCESS = 'SEARCH_GDS_SUCCESS';
export const SEARCH_GDS_ERROR = 'SEARCH_GDS_ERROR';
export const SEARCH_LOCATIONS_SUCCESS = 'SEARCH_LOCATIONS_SUCCESS';
export const SEARCH_LOCATIONS_ERROR = 'SEARCH_LOCATIONS_ERROR';
export const SET_SELECTED_FLEET_PRICING = 'SET_SELECTED_FLEET_PRICING';
export const SET_USER_LOYALTY_POINTS_TO_SPEND = 'SET_USER_LOYALTY_POINTS_TO_SPEND';
export const SET_COVERAGE = 'SET_COVERAGE';
export const SET_COVERAGE_OPTIONS = 'SET_COVERAGE_OPTIONS';
export const SET_PROMO = 'SET_PROMO';
export const SET_PROMO_CODE = 'SET_PROMO_CODE';
export const SET_DROPOFF_DATE = 'SET_DROPOFF_DATE';
export const SET_DROPOFF_TIME = 'SET_DROPOFF_TIME';
export const SET_GDS_BOOKING = 'SET_GDS_BOOKING';
export const SET_LOCATIONS = 'SET_LOCATIONS';
export const SET_JOIN_RESERVE_BOOKING_SUCCESS = 'SET_JOIN_RESERVE_BOOKING_SUCCESS';
export const SET_LOCATION_MAP = 'SET_LOCATION_MAP';
export const SET_NETWORK_ERROR = 'SET_NETWORK_ERROR';
export const SET_PICKUP_DATE = 'SET_PICKUP_DATE';
export const SET_PICKUP_LOCATION = 'SET_PICKUP_LOCATION';
export const SET_PICKUP_TIME = 'SET_PICKUP_TIME';
export const SET_STAGED_BOOKING = 'SET_STAGED_BOOKING';
export const SET_THIRD_PARTY_BOOKING = 'SET_THIRD_PARTY_BOOKING';
export const SET_VEHICLE = 'SET_VEHICLE';
export const NETWORK_RESET = 'NETWORK_RESET';
export const TRACK_BOOK_NOW_ECOMM_PRODUCT = 'TRACK_BOOK_NOW_ECOMM_PRODUCT';
export const TRACK_BOOK_NOW_ECOMM_ACTION = 'TRACK_BOOK_NOW_ECOMM_ACTION';
export const TRACK_BOOK_NOW_RESERVATION = 'TRACK_BOOK_NOW_RESERVATION';
export const TRACK_BOOK_NOW_TRANSACTION = 'TRACK_BOOK_NOW_TRANSACTION';
export const TRACK_BOOKING_START = 'TRACK_BOOKING_START';
export const TRACK_COVERAGE_TYPE = 'TRACK_COVERAGE_TYPE';
export const TRACK_PROMO = 'TRACK_PROMO';
export const TRACK_REFUND_ECOMM_ACTION = 'TRACK_REFUND_ECOMM_ACTION';
export const TRACK_REFUND_ECOMM_PRODUCT = 'TRACK_REFUND_ECOMM_PRODUCT';
export const TOGGLE_COST_SUMMARY_MODAL_VISIBLE = 'TOGGLE_COST_SUMMARY_MODAL_VISIBLE';
export const TOGGLE_ESTIMATED_TOTAL_MODAL_VISIBLE = 'TOGGLE_ESTIMATED_TOTAL_MODAL_VISIBLE';
export const TOGGLE_RESERVATION_REVIEW_MODAL_VISIBLE = 'TOGGLE_RESERVATION_REVIEW_MODAL_VISIBLE';
export const TOGGLE_EARN_OR_SPEND_POINTS = 'TOGGLE_EARN_OR_SPEND_POINTS';
export const TOGGLE_PROCESSING = 'TOGGLE_PROCESSING';
export const TOGGLE_LOGIN_MODAL_VISIBLE = 'TOGGLE_LOGIN_MODAL_VISIBLE';
export const TOGGLE_JOIN_RESERVE_MODAL_VISIBLE = 'TOGGLE_JOIN_RESERVE_MODAL_VISIBLE';
export const TOGGLE_TEXAS_WAIVER_MODAL_VISIBLE = 'TOGGLE_TEXAS_WAIVER_MODAL_VISIBLE';
export const TOGGLE_COVERAGE_WARNING_MODAL_VISIBLE = 'TOGGLE_COVERAGE_WARNING_MODAL_VISIBLE';
export const TOGGLE_DELETE_BOOKING_MODAL_VISIBLE = 'TOGGLE_DELETE_BOOKING_MODAL_VISIBLE';
export const TOGGLE_SPENT_LOYALTY_POINTS_MODAL_VISIBLE = 'TOGGLE_SPENT_LOYALTY_POINTS_MODAL_VISIBLE';
export const TOGGLE_LOYALTY_INFO_MODAL = 'TOGGLE_LOYALTY_INFO_MODAL';
export const TOGGLE_AUDI_OWNER_PROGRAM_MODAL = 'TOGGLE_AUDI_OWNER_PROGRAM_MODAL';
export const UPDATE_FLEET_PRICING = 'UPDATE_FLEET_PRICING';
export const UPDATE_SELECTED_FLEET_PRICING = 'UPDATE_SELECTED_FLEET_PRICING';
export const GET_CURBSIDE_DETAIL_SUCCESS = 'GET_CURBSIDE_DETAIL_SUCCESS';
export const GET_CURBSIDE_DETAIL_ERROR = 'GET_CURBSIDE_DETAIL_ERROR';
export const TOGGLE_PARTNER_LOCATION_MODAL_VISIBLE = 'TOGGLE_PARTNER_LOCATION_MODAL_VISIBLE';
