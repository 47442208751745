// @flow
import React from 'react';
import ResendConfirmationForm from '../../components/forms/resend-confirmation';

function ResendConfirmation() {
  return (
    <div>
      <ResendConfirmationForm />
    </div>
  );
}

export default ResendConfirmation;
