// @flow
import { apiUrl, headers } from '../config';
import axiosInstance from '../axiosInstance';

function getBrainTreeClientToken() {
  return axiosInstance({
    method: 'GET',
    url: `${apiUrl.v2}/payment_methods/token`,
    headers: headers(),
  });
}

function get() {
  return axiosInstance({
    method: 'GET',
    url: `${apiUrl.v2}/user/credit_card`,
    headers: headers(),
  });
}

function post(data: Object) {
  return axiosInstance({
    method: 'POST',
    url: `${apiUrl.v2}/user/credit_card`,
    headers: headers(),
    data,
  });
}

const creditCards: Object = {
  getBrainTreeClientToken,
  get,
  post,
};

export default creditCards;
