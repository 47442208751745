// @flow
import { Text } from '@audi/audi-ui-react';
import React from 'react';

type ConfirmBookingHeaderProps = {
    model: string
}

const ConfirmBookingHeader = (props: ConfirmBookingHeaderProps) => {
  const {
    model,
  } = props;

  return (
    <>
      <Text as="h2" variant="display2" weight="bold" spaceStackEnd="s">
        Your Audi
        {' '}
        {model}
        {' '}
        is booked!
      </Text>
      <Text as="p" variant="copy1">
        A confirmation of this reservation has been sent to the renter&apos;s email.
      </Text>
    </>
  );
};

export default ConfirmBookingHeader;
