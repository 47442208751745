// @flow
import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import { ValidatorForm } from 'react-material-ui-form-validator';
import AutoComplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { connect } from 'react-redux';
import { updateFlightDetails } from '../../../actions/booking/modify';
import './index.css';

type State = {
  flight: Object,
  stateChanged: boolean,
  airlines: Array,
};

type Props = {
  updateFlightDetails: Function,
  flight: Object,
  id: Number,
};

export class FlightDetailsForm extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      flight: {
        ...props.flight,
      },
      stateChanged: false,
      airlines: [
        'Air Canada',
        'Alaska Airlines',
        'American Airlines',
        'American Eagle',
        'British Airways',
        'Cayman Airways',
        'Delta Air Lines',
        'Emirates',
        'Frontier Airlines',
        'JetBlue',
        'KLM Royal Dutch Airlines',
        'Korean Air',
        'Lufthansa',
        'Qantas Airways',
        'Spirit Airlines',
        'Sun Country Airlines',
        'Taca International Airlines',
        'United Airlines',
        'US Airways',
        'Virgin America',
        'Aloha Airlines',
        'Bemidji Airlines',
        'Scenic Airlines',
        'Great Lakes Aviation',
        'Hawaiian Airlines',
        'Southwest Airlines',
        'Air Sunshine',
        'Nature Air',
        'Smokey Bay Air',
        'Island Airlines',
        'Air Wisconsin',
        'Island Air',
        'Sky West Airlines',
        'New England Airways',
        'Allegiant Air',
        'Horizon Air',
        'Universal Airlines',
        'Atlantic Express Airline',
        'Mesa Airlines',
        'Expressjet Airlines',
      ],
    };

    this.isDisabled = this.isDisabled.bind(this);
  }

  UNSAFE_componentWillMount() {
    const { flight } = this.props;
    this.setState({
      flight: {
        ...flight,
      },
    });
  }

  UNSAFE_componentWillReceiveProps(props: Object) {
    this.setState({
      flight: {
        ...props.flight,
      },
    });
  }

  updateFlightDetails(id: Number, flight: Object) {
    const { updateFlightDetails } = this.props;
    updateFlightDetails(id, flight);
    this.setState({
      stateChanged: false,
    });
  }

  renderAutoCompleteTextField = (params: any) => (
    <TextField
      fullWidth
      label="Airline"
      // Don't send in (capital) InputProps - it breaks something w/ Facebook Pixel
      ref={params.ref}
      inputProps={params.inputProps}
      disabled={params.disabled}
      InputLabelProps={params.InputLabelProps}
      // We need additional onChange here for when freeSolo text is
      // entered (onChange from Autocomplete won't fire)
      onChange={(event, newValue) => this.setState(prevState => ({
        flight: {
          ...prevState.flight,
          airline: newValue,
        },
        stateChanged: true,
      }))}
    />
  )

  isDisabled() {
    const { flight } = this.state;
    const { number, airline } = flight;
    return (!number || !airline);
  }

  render() {
    const { flight, stateChanged, airlines } = this.state;
    const { airline, number } = flight;
    const { id } = this.props;

    return (
      <ValidatorForm
        onSubmit={() => this.updateFlightDetails(id, flight)}
        className="flight-details"
      >
        <div className="form">
          <AutoComplete
            freeSolo
            name="airline"
            filter={AutoComplete.caseInsensitiveFilter}
            disableOpenOnFocus
            options={airlines}
            getOptionLabel={String}
            label="Airline"
            value={airline}
            onChange={(event, newValue) => this.setState({
              flight: {
                ...flight,
                airline: newValue,
              },
              stateChanged: true,
            })}
            renderInput={this.renderAutoCompleteTextField}
          />
          <TextField
            fullWidth
            label="Flight Number"
            name="number"
            onChange={event => this.setState({
              flight: {
                ...flight,
                number: event.target.value,
              },
              stateChanged: true,
            })}
            value={number}
          />
        </div>
        <div className="submit">
          <Button
            type="submit"
            name="submit"
            variant="outlined"
            disabled={!stateChanged || this.isDisabled()}
          >
            Save
          </Button>
        </div>
      </ValidatorForm>
    );
  }
}

export const mapStateToProps = state => ({
  flight: state.bookingReducer.modifyBooking.booking.flight,
  id: state.bookingReducer.modifyBooking.booking.id,
});

export const mapDispatchToProps = dispatch => ({
  updateFlightDetails: (id, flight) => dispatch(updateFlightDetails(id, flight)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FlightDetailsForm);
