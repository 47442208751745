import React from 'react';
import { Text } from '@audi/audi-ui-react';

function AudiText() {
  return (
    <Text as="h2" variant="copy1" className="audi-on-demand">
      <strong>Audi</strong>
      {' on demand'}
    </Text>
  );
}

export default AudiText;
