// @flow
import { Button, Text } from '@audi/audi-ui-react';
import { Box, useMediaQuery } from '@material-ui/core';
import moment from 'moment';
import React, { useEffect, useState, useReducer } from 'react';
import { connect, useSelector } from 'react-redux';
import debounce from 'lodash.debounce';
import {
  getLocationsAvailability,
  getLocationsFleetAvailability,
  resetAvailabilityAndPricing,
  setDropoffDate,
  setDropoffTime,
  setNetworkReset,
  setPickupDate,
  setPickupTime,
  setPickupLocation,
  togglePartnerLocationModal,
} from '../../../actions/booking';
import DatePicker from '../../../components/date-picker';
import Loading from '../../../components/loading';
import PartnerLocationModal from '../../../components/modals/partner-location';
import TimeSlider from '../../../components/time-slider';
import {
  getActiveBooking,
  getActiveBookingPickupLocation,
  getAvailability,
  getFleetAvailability,
  getFleetPricingValue,
  getIsReady,
  getIsLoading,
  getGroupLocationsByState,
  getHttpResponseStatusMessage,
  getPartnerLocationModalVisibility,
} from '../../../selectors';
import { MOBILE_HEADER_HEIGHT } from '../../../theme/constants';
import { getLocationIdsFromLocation, findMatchedLocationList } from '../../../utils';
import { formatNewDateTime } from '../../../utils/formatters';
import * as Actions from './actions';
import { Section, Focus } from './constants';
import Location from './Location';
import { initialState, reducer } from './reducer';
import SelectionInputs from './selection-inputs';
import { LocationDateTimeProps } from './types';
import './index.css';

const stickyButtonStyles = {
  container: {
    height: '100%',
    maxHeight: `calc(100vh - ${MOBILE_HEADER_HEIGHT})`,
    display: 'flex',
    flexDirection: 'column',
  },
  inputContainer: {
    flex: '1 1 auto',
  },
  activityContainer: {
    height: '100%',
    flex: '1 1 auto',
    overflow: 'auto',
  },
};

const LocationDateTime = (props: LocationDateTimeProps) => {
  // Global State
  const activeBooking = useSelector(getActiveBooking);
  const { dropoffs, pickups } = useSelector(getAvailability);
  const fleetAvailability = useSelector(getFleetAvailability);
  const fleetPricing = useSelector(getFleetPricingValue);
  const isLoading = useSelector(getIsLoading);
  const isReady = useSelector(getIsReady);
  const groupedLocationsByState = useSelector(getGroupLocationsByState);
  const pickupLocation = useSelector(getActiveBookingPickupLocation);
  const availabilityLoading = isLoading && !fleetPricing.length;
  const hasError = useSelector(getHttpResponseStatusMessage);
  const partnerLocationModalVisible = useSelector(getPartnerLocationModalVisibility);
  const continueDisabled = () => isLoading
      || !dropoffDate || !dropoffTime || !pickupDate || !pickupTime
    || validationMessage() || (fleetAvailability
      && !(fleetAvailability.filter(fleet => fleet.available === true).length > 0));

  // Local State
  const [state, dispatch] = useReducer(reducer, initialState);
  const [isLoadingB2c, setIsLoading] = useState(false);

  const dateFormat = 'MM/DD/YY';
  const {
    activeSection,
    location,
    locationName,
    pickupDate,
    returnDate,
    pickupDisabled,
    returnDisabled,
    currentFocus,
    filteredLocations,
    resetDateTime,
  } = state;

  const {
    addReturnDate,
    addReturnTime,
    addPickupDate,
    addPickupLocation,
    addPickupTime,
    getLocAvailability,
    getLocFleetAvailability,
    nextStep,
    resetAvailabilityAndPricing,
    setNetworkReset,
    togglePartnerLocationModal,
  } = props;

  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const contentMargin = isMobile ? 2 : 0;

  const debounceLocationSearch = debounce((value) => {
    const locationsList = groupedLocationsByState.filter(
      values => findMatchedLocationList(values, value),
    );
    dispatch(Actions.setFilteredLocations(locationsList));
  }, 500);

  const filterLocations = (value: string) => {
    dispatch(Actions.setLocationName(value));
    debounceLocationSearch(value);
  };

  const handleClearLocation = () => {
    dispatch(Actions.clearLocation());
    dispatch(Actions.setFilteredLocations(groupedLocationsByState));
  };

  const handleFleetPricing = () => {
    if (fleetPricing.length) {
      dispatch(Actions.enableSubmit());
    }
  };

  const validationMessage = () => {
    const dropOffMoment = moment(dropoffTime);
    const pickupMoment = moment(pickupTime);
    if (dropOffMoment.isBefore(pickupMoment)) {
      return 'Pick up must be before drop off.';
    }

    if (dropOffMoment.diff(pickupMoment, 'hours') < 2) {
      return 'The reservation\'s length must be at least 2 hours.';
    }
    return undefined;
  };

  const handleContinue = () => {
    const { dropoffTime, pickupLocation, pickupTime } = activeBooking;
    if (validationMessage()) {
      return undefined;
    }
    return getLocFleetAvailability({
      dropoff_at: dropoffTime,
      location_ids: getLocationIdsFromLocation(pickupLocation),
      pickup_at: pickupTime,
    });
  };

  const handleGetLocAvailability = () => {
    const { pickupDate, dropoffDate, pickupLocation } = activeBooking;

    if (!dropoffDate || !pickupDate || fleetAvailability) {
      return;
    }

    const params = {
      pickup_on: moment(pickupDate).format('YYYY-MM-DD'),
      dropoff_on: moment(dropoffDate).format('YYYY-MM-DD'),
    };

    if (activeBooking.id) {
      getLocAvailability({
        ...params,
        id: activeBooking.id,
      });
    } else {
      getLocAvailability({
        ...params,
        location_ids: getLocationIdsFromLocation(pickupLocation),
      });
    }
  };

  const handleQueryParam = () => {
    if (pickupLocation.id !== location.id) {
      handleSetLocation(pickupLocation, false);
    }
    if (activeBooking.pickupTime) {
      handleSetPickupTime(moment(activeBooking.pickupTime));
    }
    if (activeBooking.dropoffTime) {
      handleSetReturnTime(moment(activeBooking.dropoffTime));
    }
  };

  const handleSetLocation = (loc: Object, add: boolean = true) => {
    dispatch(Actions.setLocation(loc));
    if (add) {
      addPickupLocation(loc);
    }
    if (loc && loc.b2c_url) {
      setIsLoading(true);
      redirectToB2c(loc);
    }
  };

  const handleB2cHovering = (event) => {
    event.preventDefault();
    togglePartnerLocationModal();
  };

  const redirectToB2c = (location) => {
    const B2C_URL = `${location.b2c_url}`;
    window.location.replace(B2C_URL);
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
    return null;
  };

  const handleSetFocus = (value: string) => {
    dispatch(Actions.setFocus(value));
    if (value === Focus.PICKUP || value === Focus.RETURN) {
      dispatch(Actions.setActiveSection(Section.DATE));
    }
  };

  const handleSetPickupDate = (pickupDate: moment) => {
    dispatch(Actions.setPickupDate(pickupDate.format(dateFormat)));
    addPickupDate(pickupDate);
  };

  const handleSetPickupTime = (newPickupTime: moment) => {
    const {
      pickupDate,
      pickupTime,
      pickupLocation: { time_zone },
    } = activeBooking;

    if (newPickupTime === pickupTime || availabilityLoading) {
      return;
    }

    dispatch(setNetworkReset());
    dispatch(resetAvailabilityAndPricing());

    dispatch(
      Actions.setPickupTime(
        formatNewDateTime(pickupDate, newPickupTime, time_zone),
      ),
    );
    addPickupTime(newPickupTime);
  };

  const handleSetReturnDate = (returnDate: moment) => {
    dispatch(Actions.setReturnDate(returnDate.format(dateFormat)));
    addReturnDate(returnDate);
  };

  const handleSetReturnTime = (newReturnTime: moment) => {
    const {
      dropoffDate,
      dropOffTime,
      pickupLocation: { time_zone },
    } = activeBooking;

    if (newReturnTime === dropOffTime || availabilityLoading) {
      return;
    }

    dispatch(setNetworkReset());
    dispatch(resetAvailabilityAndPricing());

    dispatch(
      Actions.setReturnTime(
        formatNewDateTime(dropoffDate, newReturnTime, time_zone),
      ),
    );
    addReturnTime(newReturnTime);
  };

  const { dropoffDate, dropoffTime, pickupTime } = activeBooking;

  useEffect(() => dispatch(Actions.setFilteredLocations(groupedLocationsByState)), [
    groupedLocationsByState,
  ]);
  useEffect(handleQueryParam, [pickupLocation, dropoffTime, pickupTime]);
  useEffect(handleGetLocAvailability, [dropoffDate]);
  useEffect(handleFleetPricing, [fleetPricing]);
  useEffect(() => {
    if (!isLoading
        && dropoffDate && dropoffTime
        && pickupDate && pickupTime
        && fleetAvailability
        && (fleetAvailability.filter(fleet => fleet.available === true).length > 0)
        && !hasError) {
      nextStep();
    }
  }, [isLoading,
    dropoffDate,
    dropoffTime,
    pickupDate,
    pickupTime,
    fleetAvailability,
    hasError,
    nextStep]);

  const renderLocationSection = ({ groupedLocationsByState }) => (
    !!groupedLocationsByState.length && (
    <div>
      {
        groupedLocationsByState.map(([stateName, locations], index) => (
          <React.Fragment key={index}>
            <Box className="location-title-container">
              <Text as="h2" weight="bold" variant="order2">
                {stateName}
              </Text>
            </Box>
            <Box className="location-section">
              {locations.map(location => (
                <React.Fragment key={location.id}>
                  <Location
                    handleSetLocation={handleSetLocation}
                    handleB2cHovering={handleB2cHovering}
                    isMobile={isMobile}
                    location={location}
                  />
                </React.Fragment>
              ))}
            </Box>
          </React.Fragment>
        ))
      }
    </div>
    )
  );

  const renderDatePicker = () => {
    if (
      (!isMobile && activeSection !== Section.LOCATION)
      || activeSection === Section.DATE
    ) {
      return (
        <Box
          display="flex"
          flexDirection="row"
          height="20.25rem"
          marginLeft="1.0625rem"
          marginRight="1.0625rem"
          flexWrap="wrap"
          justifyContent="center"
          mt={isMobile ? 0 : '74px'}
        >
          <DatePicker
            booking={activeBooking}
            location={location}
            getAvailability={getLocAvailability}
            setDropoffDate={handleSetReturnDate}
            setPickupDate={handleSetPickupDate}
            disablePickup={pickupDisabled}
            resetDateTime={resetDateTime}
          />
        </Box>
      );
    }
    return null;
  };

  const renderTimeSlider = () => ((!isMobile && activeSection !== Section.LOCATION)
  || activeSection === Section.TIME ? (
    <Box
      ml={isMobile ? 0 : '3.5rem'}
      mt={isMobile ? 2 : '74px'}
      display="flex"
      flexDirection="row"
      height={isMobile ? '100%' : '20.25rem'}
      flexWrap="wrap"
      overflow={isMobile ? 'auto' : 'initial'}
      className={activeSection !== Section.TIME ? 'disabled' : ''}
    >
      <>
        <div className={`time-slider ${!(pickups || dropoffs) ? 'disabled' : ''}`}>
          { pickups
            && location.time_zone
            && (
              <TimeSlider
                booking={activeBooking}
                className="pickup-time-slider"
                initialTime={pickupTime}
                location={location}
                setTime={handleSetPickupTime}
                times={pickups}
                type="pickup"
                disabled={isLoading}
              />
            )
          }
          { dropoffs
            && location.time_zone
            && (
              <TimeSlider
                booking={activeBooking}
                initialTime={dropoffTime}
                location={location}
                setTime={handleSetReturnTime}
                times={dropoffs}
                type="dropoff"
                disabled={isLoading}
              />
            )
          }
        </div>
      </>
    </Box>
    ) : null);

  if (!isReady || isLoadingB2c) {
    return <Loading />;
  }

  const withStickyButton = isMobile && activeSection === Section.TIME;
  const styleOverrides = withStickyButton ? stickyButtonStyles : {};

  return (
    <div className="locationDateTimeContainer">
      <Box
        ml={isMobile ? 2 : 0}
        mr={isMobile ? 2 : 0}
        display="flex"
        flexDirection={isMobile ? 'column' : 'row'}
        justifyContent="space-between"
        style={styleOverrides.activityContainer}
      >
        <SelectionInputs
          filteredLocations={filteredLocations}
          activeSection={activeSection}
          locationChange={filterLocations}
          locationName={locationName}
          pickupDate={pickupDate}
          returnDate={returnDate}
          handleFocus={handleSetFocus}
          continueDisabled={continueDisabled()}
          pickupDisabled={pickupDisabled}
          returnDisabled={returnDisabled}
          focusedField={currentFocus}
          clearLocation={handleClearLocation}
          onContinue={handleContinue}
          errorMessage={validationMessage()}
        />
      </Box>
      <Box>
        {activeSection === Section.LOCATION ? (
          <Box mx={contentMargin} pb={2.5}>
            {activeSection === Section.LOCATION
            && filteredLocations.length === 0 ? null : (
                renderLocationSection({
                  groupedLocationsByState: filteredLocations,
                })
              )}
          </Box>
        ) : (
          <Box
            ml={isMobile ? 2 : 0}
            mr={isMobile ? 2 : 0}
            display="flex"
            flexDirection={isMobile ? 'column' : 'row'}
            height={isMobile ? '80%' : '30.5rem'}
            style={styleOverrides.activityContainer}
          >
            {renderDatePicker()}
            {renderTimeSlider()}
            {isMobile && activeSection === Section.TIME && (
            <Box
              display="flex"
              justifyContent="center"
              my={0.5}
              p={1}
            >
              <Button
                className="continue-btn"
                disabled={continueDisabled()}
                loading={isLoading}
                onClick={handleContinue}
                type="submit"
                variant="primary"
              >
                  Continue
              </Button>
            </Box>
            )}
          </Box>
        )}
      </Box>

      <PartnerLocationModal
        togglePartnerLocationModal={togglePartnerLocationModal}
        partnerLocationModalVisible={partnerLocationModalVisible}
      />
    </div>
  );
};

const mapDispatchToProps = {
  addPickupDate: setPickupDate,
  addPickupLocation: setPickupLocation,
  addPickupTime: setPickupTime,
  addReturnDate: setDropoffDate,
  addReturnTime: setDropoffTime,
  getLocAvailability: getLocationsAvailability,
  getLocFleetAvailability: getLocationsFleetAvailability,
  resetAvailabilityAndPricing,
  setNetworkReset,
  togglePartnerLocationModal,
};

export default connect(null, mapDispatchToProps)(LocationDateTime);
