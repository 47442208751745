// @flow
import { Text, List, ListItem } from '@audi/audi-ui-react';
import React from 'react';
import { connect } from 'react-redux';
import '../modal.css';
import './index.css';
import { togglePartnerLocationModal } from '../../../actions/booking';
import SCDialog from '../../sc-dialog';
import SCLink from '../../sc-link';

type Props = {
  partnerLocationModalVisible: boolean,
  togglePartnerLocationModal: () => void,
}

export function PartnerLocationModal(props: Props) {
  const {
    partnerLocationModalVisible,
    togglePartnerLocationModal,
  } = props;

  const loyaltyTermsHref = 'https://www.audiondemand.com/terms/';

  return (
    <SCDialog
      open={partnerLocationModalVisible}
      title=""
      titleVariant="order2"
      onClose={togglePartnerLocationModal}
      showCloseButton
    >
      <div className="partner-location-modal">

        <section>
          <Text as="p" variant="copy1">
            This is an Audi on demand partner location,
            offering a simplified version of our Audi on demand rental service.
          </Text>
        </section>

        <ul>
          <Text as="p" variant="copy1">Here you can:</Text>
          <Text as="p" variant="copy1">
            <List variant="bullet">
              <ListItem>
              Pay one flat daily rate, bring your own car insurance
              </ListItem>

              <ListItem>
            Rent from 4 hours to 30 days, no mobile app required
              </ListItem>

              <ListItem>
            Use your non-expired Always Audi rental days
              </ListItem>

              <ListItem>
            Enjoy support provided by this dealership
              </ListItem>

            </List>
          </Text>
          <SCLink
            href={loyaltyTermsHref}
            isExternal
            className="link"
          >
            <Text as="p" variant="copy1">
              Learn more
            </Text>
          </SCLink>
        </ul>
      </div>
    </SCDialog>
  );
}

export const mapStateToProps = state => ({
  partnerLocationModalVisible: state.bookingReducer.partnerLocationModalVisible,
});

export const mapDispatchToProps = dispatch => ({
  togglePartnerLocationModal: () => dispatch(togglePartnerLocationModal()),
});


export default connect(mapStateToProps, mapDispatchToProps)(PartnerLocationModal);
