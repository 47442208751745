// @flow
import { Text } from '@audi/audi-ui-react';
import React, { Fragment, useState } from 'react';
import Box from '@material-ui/core/Box';
import { IconButton } from 'material-ui';
import Icon from '@material-ui/core/Icon';
import { formatCurrency } from '../../utils/formatters';

import './index.css';

type Props = {
  item: Object,
  loyaltyPoints: boolean | null,
}

export function CostBreakdown(props: Props) {
  const [expanded, setExpanded] = useState(false);
  const { item, loyaltyPoints } = props;

  if (!item) return null;
  const { items = [] } = item;

  const receiptLabels = (subItemLabel, subItemSecondaryLabel) => {
    const {
      label,
      secondary_label,
    } = item;
    const secondaryLabel = subItemSecondaryLabel || secondary_label;

    return (
      <Box>
        <Text
          variant="copy2"
          as="p"
        >
          {subItemLabel || label}
        </Text>
        {secondaryLabel && (
          <Text variant="copy1" as="p">
            {secondaryLabel}
          </Text>
        )}
      </Box>
    );
  };

  const receiptIcon = () => {
    const icon = expanded
      ? <Icon color="primary">expand_less</Icon>
      : <Icon>expand_more</Icon>;

    const handleClick = items.length
      ? () => setExpanded(!expanded)
      : () => { };

    const ariaLabel = items.length
      ? 'expand receipt item'
      : 'show more info';

    return (
      <Box className="expand-arrow">
        <IconButton
          aria-label={ariaLabel}
          onClick={handleClick}
        >
          {icon}
        </IconButton>
      </Box>
    );
  };

  const receiptTotal = () => {
    const {
      total,
    } = item;

    const formattedTotal = loyaltyPoints
      ? total
      : formatCurrency(total);

    return (
      <Box>
        <Text
          variant="copy1"
          as="p"
        >
          {formattedTotal}
          {loyaltyPoints}
        </Text>
      </Box>
    );
  };

  const receiptSubItems = () => {
    if (!expanded) return null;

    return items.map(({ label, secondary_label, total }, idx) => (
      <Fragment key={idx}>
        <Box className="breakdown-subItems">
          <Box className="subItems-label">
            {receiptLabels(label, secondary_label)}
          </Box>
          <Box>
            <Box>
              <Text
                variant="copy2"
                as="p"
              >
                {formatCurrency(total)}
              </Text>
            </Box>
          </Box>
        </Box>
      </Fragment>
    ));
  };

  return (
    <Box>
      <Box className="breakdown-header">
        <Box className="breakdown-label">
          {receiptLabels()}
          {receiptIcon()}
        </Box>
        <Box>
          {receiptTotal()}
        </Box>
      </Box>
      <Box>
        {receiptSubItems()}
      </Box>
    </Box>
  );
}

export default CostBreakdown;
