// @flow
import { Text } from '@audi/audi-ui-react';
import IconButton from 'material-ui/IconButton';
import moment from 'moment';
import React from 'react';

type SubheaderProps = {
  dropoffDate: string;
  onClick: Function;
  pickupDate: string;
  type: string;
}

const Subheader = (props: SubheaderProps) => {
  const {
    dropoffDate,
    onClick,
    pickupDate,
    type,
  } = props;
  const className = `modify-${type}-subheader`;

  const handleOnClick = () => (onClick ? onClick() : undefined);

  return (
    <div className={className}>
      <div>
        <Text
          as="p"
          className={`${!pickupDate && !dropoffDate ? 'subsection-active' : ''}`}
          variant="copy2"
          weight="bold"
        >
          Start
        </Text>
        { moment(pickupDate).isValid() ? <Text as="span" variant="copy1">{moment(pickupDate).format('MM/DD/YY')}</Text> : null }
      </div>
      <div>
        <IconButton
          iconClassName="material-icons"
          onClick={handleOnClick}
          style={{ paddingTop: 0 }}
        >
          trending_flat
        </IconButton>
      </div>
      <div>
        <Text
          as="p"
          className={`${pickupDate && !dropoffDate ? 'subsection-active' : ''}`}
          variant="copy2"
          weight="bold"
        >
          Stop
        </Text>
        { moment(dropoffDate).isValid() ? <Text as="span" variant="copy1">{moment(dropoffDate).format('MM/DD/YY')}</Text> : null }
      </div>
    </div>
  );
};

export default Subheader;
