// @flow
import { Button } from '@audi/audi-ui-react';
import React from 'react';
import { SelectVehicleButtonProps } from './types';

// eslint-disable-next-line max-len
const SelectVehicleButton: React.FC<SelectVehicleButtonProps> = (props: SelectVehicleButtonProps): React.ReactElement => {
  const { onClick, pathName = '', vehicle } = props;

  return (
    <Button
      fullWidth
      name="select-vehicle-button"
      className="select-vehicle-button"
      onClick={() => onClick(vehicle)}
      variant={pathName.includes('edit-vehicle') ? 'secondary' : 'primary'}
      size="small"
    >
      Select
    </Button>
  );
};

export default SelectVehicleButton;
