// @flow
import axios from 'axios';
import { apiUrl } from './config';
import axiosInterceptors from './axiosInterceptors';

const axiosInstance = axios.create({
  baseURL: apiUrl.v2,
});

const { requestInterceptor, responseInterceptor } = axiosInterceptors;
const { requestSuccessHandler, requestErrorHandler } = requestInterceptor;
const { responseSuccessHandler, responseErrorHandler } = responseInterceptor;

axiosInstance.interceptors.request.use(requestSuccessHandler, requestErrorHandler);
axiosInstance.interceptors.response.use(responseSuccessHandler, responseErrorHandler);

export default axiosInstance;
