// @flow
import { getHomeUrl } from '../../utils/http/config';

const homeUrl = getHomeUrl();

const navConfig = [
  {
    catName: 'Booking',
    links: [
      { title: 'Locations', url: `${homeUrl}car-rentals/` },
      { title: 'Vehicles', url: `${homeUrl}fleet/` },
      { title: 'e-tron', url: `${homeUrl}fleet/audi-e-tron-sportback/` },
    ],
  },
  {
    catName: 'Services',
    links: [
      { title: 'Delivery', url: `${homeUrl}delivery-to-you/` },
      { title: 'Business Travel', url: `${homeUrl}business-car-rental/` },
      { title: 'Audi Owners', url: `${homeUrl}always-audi/` },
    ],
  },
  {
    catName: 'Reward',
    links: [
      { title: 'Loyalty', url: `${homeUrl}premium-rewards/` },
      { title: 'Promotions', url: `${homeUrl}deals-and-promotions/` },
    ],
  },
  {
    catName: 'Support',
    links: [
      { title: 'Help', url: 'https://support.audiondemand.com/hc/en-us/' },
      { title: 'Careers', url: 'https://silvercar-inc.prismhr-hire.com/' },
      {
        title: 'Terms',
        url: 'https://support.audiondemand.com/hc/en-us/articles/204790284-Terms-of-Use/',
      },
      {
        title: 'Privacy',
        url: 'https://support.audiondemand.com/hc/en-us/articles/204791464-Privacy-Policy/',
      },
    ],
  },
];

export default navConfig;
