import moment from 'moment/moment';

const env = (process && process.env && process.env.REACT_APP_API_HOST) ? process.env.REACT_APP_API_HOST : '';

export default class CookieService {
  static path = CookieService.formatParam('path', '/');

  static isLocalEnv = env === 'rac-tst' && window.location.hostname === 'localhost';

  static domain = this.isLocalEnv
    ? CookieService.formatParam('domain', 'localhost')
    : CookieService.formatParam('domain', `.${env}.com`);

  static expiration = CookieService.formatParam('expires', CookieService.getExpiration());

  static getExpiration() {
    return moment().add(1, 'days').utc().toString();
  }

  static buildParams(newParam, expiration, path, domain) {
    return [
      newParam,
      expiration,
      path,
      domain,
    ].join('');
  }

  static formatParam(name, value) {
    return `${name}=${value};`;
  }

  static set(name, value, expiresAt?) {
    let { expiration } = CookieService;
    const { path, domain } = CookieService;
    const newEntry = CookieService.formatParam(name, value);
    expiration = expiresAt ? `expires=${expiresAt};` : expiration;
    document.cookie = CookieService.buildParams(newEntry, expiration, path, domain);
    return CookieService;
  }

  static remove(name) {
    return CookieService.set(name, '');
  }

  static add(name, value, expiration?) {
    return CookieService.set(name, value, expiration);
  }

  static get(name) {
    const cookie = document.cookie.match(`(^|; )${name}=([^;]*)`);
    return cookie ? cookie[2] : null;
  }

  static removeAllCookies() {
    document.cookie.split(';')
      .forEach((c) => {
        document.cookie = c
          .replace(/^ +/, '')
          .replace(/=.*/, `=;expires=${new Date().toUTCString()};path=/`);
      });
  }
}
