/* eslint-disable no-use-before-define,no-unused-vars */
// @flow
import moment from 'moment';
import 'moment-timezone';
import * as Actions from '../index';
import HTTP from '../../utils/http';
import {
  filterCoverageOptions,
  filterBookableLocations,
} from '../../utils/filters';
import { getErrorMessage, getLocationIdsFromLocation, setToastTimeout } from '../../utils';
import { generateBooking } from './utils';
import { setupCoverages } from '../../components/coverage/utils';
import { openCurbsideAvailableModal } from '../modal';
import * as ModalTypes from '../modal/constants';

import history from '../../history';

import { initialState } from '../../reducers/booking';

import { isLoggedIn } from '../../components/auth';
import Fleet from '../../utils/models/fleets/fleet';
import * as BookingTypes from './constants';

export const createBookingSuccess = () => ({
  type: BookingTypes.CREATE_BOOKING_SUCCESS,
  payload: 'Success! Your reservation has been created',
});

export const createBookingError = (payload: Object) => ({
  type: BookingTypes.CREATE_BOOKING_ERROR,
  payload: getErrorMessage(payload),
});

export const createBooking = (
  id: Number,
  data: Object,
  curbsideActive: boolean,
  curbsideDescription: string,
) => (
  (dispatch: Function, getStore: Function) => {
    const { earningPoints } = getStore().bookingReducer.loyalty;
    return HTTP.fleets.createBooking(id, data)
      .then((response) => {
        const { location } = response.headers;
        const reservationId = location.split('/').pop();
        // dispatch tracking events
        if (isLoggedIn()) {
          if (curbsideActive && curbsideDescription) {
            dispatch(Actions.setCurbsideText(curbsideDescription));
            dispatch(openCurbsideAvailableModal());
          }
          dispatch(getBookingsById(reservationId));
          dispatch(createBookingSuccess());
          history.push('/reservations');
          dispatch(getBookings(1));
          dispatch(getRentals(1));
          setToastTimeout(setNetworkReset());
          // clear activeBooking data
        } else {
          dispatch((toggleCostSummaryModalVisible()));
        }
      })
      .catch(((error) => {
        dispatch((toggleCostSummaryModalVisible()));
        dispatch(createBookingError(error));
        setToastTimeout(setNetworkReset());
      }));
  }
);

function createCurbsideRequestError(payload: Object) {
  return {
    type: ModalTypes.CREATE_CURBSIDE_REQUEST_ERROR,
    payload: getErrorMessage(payload),
  };
}

function createCurbsideRequestSuccess() {
  return {
    type: ModalTypes.CREATE_CURBSIDE_REQUEST_SUCCESS,
    payload: 'Success! Your request for delivery & pick up has been sent.',
  };
}

export const createCurbsideRequest = (token: string, payload: Object) => (
  (dispatch: Function) => (
    HTTP.bookings.attachDeliveryRequest(token, payload)
      .then(() => {
        history.goBack();
        dispatch(createCurbsideRequestSuccess());
        setToastTimeout(setNetworkReset());
      })
      .catch(error => dispatch(createCurbsideRequestError(error)))
  )
);

export const createUserPostBookingSuccess = () => ({
  type: BookingTypes.CREATE_USER_POST_BOOKING_SUCCESS,
});

export const createUserPostBookingError = (payload: Object) => {
  setToastTimeout(setNetworkReset());
  return {
    type: BookingTypes.CREATE_USER_POST_BOOKING_ERROR,
    payload: getErrorMessage(payload),
  };
};

export const createUserPostBooking = (unusedObj: Object, loginData: Object) => (
  (dispatch: Function, getStore: Function) => {
    const { email } = loginData;
    const { fleetId, payload, staged } = getStore().bookingReducer.stagedBooking;
    payload.email_address = email;
    if (staged) {
      return HTTP.fleets.createUserPostBooking(fleetId, payload)
        .then((response) => {
          dispatch(createUserPostBookingSuccess());
          const { location } = response.headers;
          const reservationId = location.split('/').pop();
          // dispatch tracking events
          dispatch(getBookingsById(reservationId));
        })
        .catch(error => dispatch(createUserPostBookingError(error)));
    }
    return null;
  }
);

export const toggleProcessing = () => ({
  type: BookingTypes.TOGGLE_PROCESSING,
});

export const toggleEarnOrSpendPoints = () => (dispatch: Function, getStore: Function) => {
  const { fleetPricing } = getStore().bookingReducer;

  dispatch({
    type: BookingTypes.TOGGLE_EARN_OR_SPEND_POINTS,
  });
  if (fleetPricing.length) {
    dispatch(updateFleetPricing());
  }
};

export const toggleDeleteBookingModalVisible = () => ({
  type: BookingTypes.TOGGLE_DELETE_BOOKING_MODAL_VISIBLE,
});

export const toggleSpentLoyaltyPointsModalVisible = () => ({
  type: BookingTypes.TOGGLE_SPENT_LOYALTY_POINTS_MODAL_VISIBLE,
});

export const deleteBookingError = (payload: Object) => {
  setToastTimeout(setNetworkReset());
  return {
    type: BookingTypes.DELETE_BOOKING_ERROR,
    payload: getErrorMessage(payload),
  };
};

export const deleteBookingSuccess = () => {
  setToastTimeout(setNetworkReset());
  return {
    type: BookingTypes.DELETE_BOOKING_SUCCESS,
  };
};

export const deleteBooking = (
  id: number,
  confirmation_token: number,
  total: number,
) => (
  (dispatch: Function) => {
    dispatch(toggleDeleteBookingModalVisible());
    const data = { confirmation_token, total };
    return HTTP.bookings.deleteById(id)
      .then(() => {
        dispatch(getBookings(1));
        dispatch(deleteBookingSuccess());
        dispatch(trackDeleteBooking(data));
        history.push('/reservations');
      })
      .catch(error => dispatch(deleteBookingError(error)));
  }
);

export const deleteBookingByToken = (
  confirmation_token: number,
  tempAuthToken: string,
  total: number,
) => (
  (dispatch: Function) => {
    const data = { confirmation_token, total };
    return HTTP.bookings.deleteByToken(tempAuthToken)
      .then(() => {
        dispatch(deleteBookingSuccess());
        dispatch(toggleCostSummaryModalVisible());
        dispatch(trackDeleteBooking(data));
        history.push('/booking');
      })
      .catch(error => dispatch(deleteBookingError(error)));
  }
);

export const fullPriceBreakdown = () => ({
  type: BookingTypes.FULL_PRICE_BREAKDOWN,
});

export const getAndSetJoinReserveBooking = (payload: Object) => {
  const booking = payload;
  const { token } = booking;
  return (dispatch: Function, getStore: Function) => {
    dispatch(setRecentBookingToken(token));
    const addressLink = booking.links[0].href;
    return HTTP.hypermedia.get(addressLink)
      .then((response) => {
        const { queryParams } = getStore().appReducer;
        booking.location = response.data;
        dispatch(getAndSetJoinReserveBookingSuccess(booking));
        if (queryParams.iata_number) {
          dispatch(attachTravelAgent(token, queryParams));
        }
        // dispatch tracking events
        dispatch(getBookingsByToken(token));
      });
  };
};

export function getAndSetJoinReserveBookingSuccess(payload: Object) {
  return {
    type: BookingTypes.SET_JOIN_RESERVE_BOOKING_SUCCESS,
    payload,
  };
}

export function getBookingsError(payload: Object) {
  return {
    type: BookingTypes.GET_BOOKINGS_ERROR,
    payload: getErrorMessage(payload),
  };
}

export function getBookingsSuccess(payload: Object, dispatch: Function, getStore: Function) {
  const { bookings } = getStore().bookingReducer;
  const { page, total } = payload.headers;
  const { data } = payload;

  const results = (page > 1) ? [...bookings.results, ...data] : data;

  if (data.length && results.length < total) dispatch(getBookings(parseInt(page, 10) + 1));

  return {
    type: BookingTypes.GET_BOOKINGS_SUCCESS,
    payload: {
      page,
      results,
    },
  };
}

export function getBookings(page: number) {
  return (dispatch: Function, getStore: Function) => {
    try {
      dispatch({
        type: BookingTypes.GET_BOOKINGS,
      });
      HTTP.bookings.get(page)
        .then((response) => {
          dispatch(getBookingsSuccess(response, dispatch, getStore));
        })
        .catch((error) => {
          dispatch(getBookingsError(error));
        });
    } catch (debugMessage) {
      dispatch(Actions.logDebug({
        debugAction: BookingTypes.GET_BOOKINGS,
        debugMessage,
      }));
    }
  };
}

export function getBookingsByTokenError(payload: Object) {
  return {
    type: BookingTypes.GET_BOOKINGS_BY_TOKEN_ERROR,
    payload: getErrorMessage(payload),
  };
}

export function getBookingsByTokenSuccess(payload: Object) {
  return {
    type: BookingTypes.GET_BOOKINGS_BY_TOKEN_SUCCESS,
    payload,
  };
}

export function getBookingsByToken(token: string) {
  return (dispatch: Function) => (
    HTTP.bookings.getByToken(token)
      .then((response) => {
        dispatch(getBookingsByTokenSuccess(response.data));
        dispatch(trackBookNow(response));
      })
      .catch(error => dispatch(getBookingsByTokenError(error)))
  );
}

function setRecentBookingToken(payload: string) {
  return {
    type: ModalTypes.SET_RECENT_BOOKING_TOKEN,
    payload,
  };
}

export function getBookingsByIdError(payload: Object) {
  return {
    type: BookingTypes.GET_BOOKINGS_BY_ID_ERROR,
    payload: getErrorMessage(payload),
  };
}

export function getBookingsById(id: number) {
  return (dispatch: Function) => (
    HTTP.bookings.getById(id)
      .then((response) => {
        dispatch(setRecentBookingToken(response.data.token));
        dispatch(trackBookNow(response));
      })
      .catch(error => dispatch(getBookingsByIdError(error)))
  );
}

// getters
export const getCoverageOptionsSuccess = (payload: Array<Object>) => ({
  type: BookingTypes.GET_COVERAGE_OPTIONS_SUCCESS,
  payload,
});


export function getCoverageOptionsError(payload: Object) {
  return {
    type: BookingTypes.GET_COVERAGE_OPTIONS_ERROR,
    payload: getErrorMessage(payload),
  };
}

export const getCoverageOptions = (fleetId: Number, pickup: string) => (
  (dispatch: Function) => {
    try {
      return HTTP.coverage.getPayLaterOptions(fleetId, pickup)
        .then((response) => {
          const filteredCoverage = response.data
            .sort((a, b) => b.price - a.price);
          dispatch(getCoverageOptionsSuccess(filteredCoverage));
        })
        .catch(error => dispatch(getCoverageOptionsError(error)));
    } catch (debugMessage) {
      return dispatch(Actions.logDebug({
        debugAction: BookingTypes.GET_COVERAGE_OPTIONS,
        debugMessage,
      }));
    }
  }
);

export function getFleetById(id: Number) {
  return (dispatch: Function) => (
    HTTP.fleets.get(id)
      .then(({ data }) => {
        const fleet = new Fleet(data);
        dispatch(getFleetByIdSuccess(fleet));
      })
      .catch(error => dispatch(getFleetByIdError(error)))
  );
}

export function getLocationCurbsideDetail(id: Number) {
  return (dispatch: Function) => (
    HTTP.bookings.getCurbsideDetailByLocationId(id)
      .then(({ data }) => {
        dispatch(getLocationCurbsideDetailSuccess(data));
      })
  );
}

export function getLocationCurbsideDetailSuccess(payload: Object) {
  return {
    type: BookingTypes.GET_CURBSIDE_DETAIL_SUCCESS,
    payload,
  };
}

export function getLocationCurbsideDetailError(error) {
  return {
    type: BookingTypes.GET_CURBSIDE_DETAIL_ERROR,
    payload: getErrorMessage(error),
  };
}

export function getFleetByIdSuccess(payload: Fleet) {
  return {
    type: BookingTypes.GET_FLEET_SUCCESS,
    payload,
  };
}

export function getFleetByIdError(error) {
  setToastTimeout(setNetworkReset());
  return {
    type: BookingTypes.GET_FLEET_ERROR,
    payload: getErrorMessage(error),
  };
}

export function getUpcomingFleetById(ids: [Number]) {
  return (dispatch: Function) => {
    const fleetPromises = ids.map(id => HTTP.fleets.get(id)
      .catch(error => error.response));
    return Promise.all(fleetPromises).then((responses) => {
      responses.forEach((resp) => {
        const { data } = resp;
        const fleet = new Fleet(data);
        dispatch(getUpcomingFleetByIdSuccess(fleet));
      });
    }).catch(error => dispatch(getUpcomingFleetByIdError(error)));
  };
}

export function getUpcomingFleetByIdSuccess(payload: Fleet) {
  return {
    type: BookingTypes.GET_UPCOMING_FLEETS_SUCCESS,
    payload,
  };
}

export function getUpcomingFleetByIdError(error) {
  setToastTimeout(setNetworkReset());
  return {
    type: BookingTypes.GET_UPCOMING_FLEETS_ERROR,
    payload: getErrorMessage(error),
  };
}

export function getFleetPricingSuccess(payload: Object) {
  const { id, fleetPricing, ...data } = payload;
  const fleetIndex = fleetPricing.findIndex(fleet => fleet.id === id);
  const fleetResponse = {
    ...data,
    id,
  };
  let fleetPricings = [...fleetPricing];

  if (fleetIndex > -1) {
    fleetPricings[fleetIndex] = fleetResponse;
  } else {
    fleetPricings = [...fleetPricings, fleetResponse];
  }
  return {
    type: BookingTypes.GET_FLEET_PRICING_SUCCESS,
    payload: fleetPricings,
  };
}

export function getFleetPricingError(payload: Object) {
  setToastTimeout(setNetworkReset());
  return {
    type: BookingTypes.GET_FLEET_PRICING_ERROR,
    payload: getErrorMessage(payload),
  };
}

export function getFleetPricing(fleets: Array<Object>, params: Object) {
  return (dispatch: Function, getStore: Function) => {
    try {
      const fleetPromises = fleets.map(fleet => HTTP.fleets.getPricingById(fleet.id, {
        ...params,
        id: fleet.id,
      }).catch(error => error.response));
      return Promise.all(fleetPromises)
        .then((responses) => {
          responses.forEach((resp) => {
            const {
              activeBooking,
              fleetPricing,
            } = getStore().bookingReducer;
            const { status, data } = resp;
            const { id } = resp.config.params;

            if (status === 200) {
              dispatch(getFleetPricingSuccess({
                ...data,
                id,
                fleetPricing,
              }));

              // if vehicle has been selected, set fleet pricing for that vehicle
              if (id === activeBooking.vehicle.fleet_id) {
                dispatch(setSelectedFleetPricing({ ...data, id }));
              }
            }
          });
          if (responses.filter(resp => resp.status === 200).length === 0) {
            dispatch(getFleetPricingError({
              response: responses[0],
            }));
          }
        })
        .catch((error) => {
          dispatch(getFleetPricingError(error));
        });
    } catch (debugMessage) {
      return dispatch(Actions.logDebug({
        debugAction: BookingTypes.GET_FLEET_PRICING_ERROR,
        debugMessage,
      }));
    }
  };
}

export function getLocationsSuccess(payload: Object, dispatch: Function, getStore: Function) {
  const { locations } = getStore().bookingReducer;
  const { page, total } = payload.headers;
  const { data } = payload;

  const results = (page > 1) ? [...locations.results, ...data] : data;

  if (data.length && results.length < total) {
    dispatch(getLocations(parseInt(page, 10) + 1));
  } else {
    const filteredAndMergedLocations = filterBookableLocations(results);
    const locationMap = results.reduce((map, location) => {
      const mapX = map;
      mapX[location.id] = location;
      return mapX;
    }, {});

    dispatch(setLocations(filteredAndMergedLocations));
    dispatch(setLocationMap(locationMap));
    dispatch(Actions.initAppComplete());
    if (isLoggedIn()) {
      dispatch(getBookings(1));
      dispatch(getRentals(1));
    }
  }

  return {
    type: BookingTypes.GET_LOCATIONS_SUCCESS,
    payload: {
      page,
      results,
    },
  };
}

export function getLocationsError(payload: Object) {
  return {
    type: BookingTypes.GET_LOCATIONS_ERROR,
    payload: getErrorMessage(payload),
  };
}

export const getLocations = (page: number) => (
  (dispatch: Function, getStore: Function) => (
    HTTP.locations.get(page)
      .then(response => dispatch(getLocationsSuccess(response, dispatch, getStore)))
      .catch(error => dispatch(getLocationsError(error)))
  )
);

export function getLocationsAvailabilitySuccess(payload: Array<Object>) {
  return {
    type: BookingTypes.GET_LOCATIONS_AVAILABILITY_SUCCESS,
    payload,
  };
}

export function getLocationsAvailabilityError(payload: Object) {
  setToastTimeout(setNetworkReset());
  return {
    type: BookingTypes.GET_LOCATIONS_AVAILABILITY_ERROR,
    payload: getErrorMessage(payload),
  };
}

export function getLocationsAvailability(params: Object, nextStep: Function) {
  return (dispatch: Function) => {
    HTTP.locations.availability(params)
      .then((response) => {
        dispatch(getLocationsAvailabilitySuccess(response.data));
        if (nextStep) {
          nextStep();
        }
      })
      .catch(error => dispatch(getLocationsAvailabilityError(error)));
  };
}

export function getLocationsFleetAvailabilityLoading() {
  return {
    type: BookingTypes.GET_LOCATIONS_FLEET_AVAILABILITY_LOADING,
  };
}

export function getLocationsFleetAvailabilitySuccess(payload: Array<Object>) {
  return {
    type: BookingTypes.GET_LOCATIONS_FLEET_AVAILABILITY_SUCCESS,
    payload,
  };
}

export function getLocationsFleetAvailabilityError(payload: Object) {
  setToastTimeout(setNetworkReset());
  return {
    type: BookingTypes.GET_LOCATIONS_FLEET_AVAILABILITY_ERROR,
    payload: getErrorMessage(payload),
  };
}

export function getLocationsFleetAvailability(params: Object) {
  return (dispatch: Function) => {
    dispatch(getLocationsFleetAvailabilityLoading());
    HTTP.locations.fleetAvailability(params)
      .then((response) => {
        const fleets = response.data;
        dispatch(getLocationsFleetAvailabilitySuccess(response.data));
        dispatch(getFleetPricing(fleets, params));
      })
      .catch((error) => {
        dispatch(setPickupTime({}));
        dispatch(setDropoffTime({}));
        dispatch(getLocationsFleetAvailabilityError(error));
      });
  };
}

export function getPromoError(payload: Object) {
  return {
    type: BookingTypes.GET_PROMO_ERROR,
    payload,
  };
}

export function getPromoSuccess(payload: Object) {
  return {
    type: BookingTypes.GET_PROMO_SUCCESS,
    payload,
  };
}

export function resetAvailabilityAndPricing() {
  return {
    type: BookingTypes.RESET_AVAILABILITY_AND_PRICING,
  };
}

export function resetPromo() {
  return (dispatch: Function, getStore: Function) => {
    const { fleetPricing } = getStore().bookingReducer;
    setToastTimeout(setNetworkReset());
    dispatch(setPromo({
      promo_code: '',
    }));
    // need to update to remove discount from pricing
    dispatch(removePromo());
    if (fleetPricing.length) {
      dispatch(updateFleetPricing());
    }
  };
}

export function getPromo(payload: Object) {
  return (dispatch: Function, getStore: Function) => {
    const { fleetPricing, activeBooking } = getStore().bookingReducer;
    const code = payload || activeBooking.promo.promo_code;

    dispatch(trackPromoCode(code));
    if (!code) return dispatch(resetPromo());
    return HTTP.promotions.get(code)
      .then((response) => {
        dispatch(getPromoSuccess(response.data));
        dispatch(setPromo(response.data, code));
        if (fleetPricing.length) {
          dispatch(updateFleetPricing());
        }
        setToastTimeout(setNetworkReset());
      })
      .catch((error) => {
        const { status } = error.response;
        setToastTimeout(setNetworkReset());
        dispatch(setPromo({
          promo_code: code,
        }));

        if (fleetPricing.length) {
          dispatch(updateFleetPricing());
        }

        if (status === 404) {
          dispatch(getPromoError('Promo code does not exist'));
        }
      });
  };
}

export function getRentalsError(payload: Object) {
  return {
    type: BookingTypes.GET_RENTALS_ERROR,
    payload: getErrorMessage(payload),
  };
}

export function getRentalsSuccess(payload: Object, dispatch: Function, getStore: Function) {
  const { rentals } = getStore().bookingReducer;
  const { page, total } = payload.headers;
  const { data } = payload;

  const results = (page > 1) ? [...rentals.results, ...data] : data;

  if (data.length && results.length < total) dispatch(getRentals(parseInt(page, 10) + 1));

  return {
    type: BookingTypes.GET_RENTALS_SUCCESS,
    payload: {
      page,
      results,
    },
  };
}

export function getRentals(page: number) {
  return (dispatch: Function, getStore: Function) => {
    try {
      dispatch({
        type: BookingTypes.GET_RENTALS,
      });
      HTTP.rentals.get(page)
        .then((response) => {
          dispatch(getRentalsSuccess(response, dispatch, getStore));
        })
        .catch((error) => {
          dispatch(getRentalsError(error));
        });
    } catch (debugMessage) {
      dispatch(Actions.logDebug({
        debugAction: BookingTypes.GET_RENTALS,
        debugMessage,
      }));
    }
  };
}

export function hideModals() {
  return {
    type: BookingTypes.HIDE_MODALS,
  };
}

// init
export function initBooking() {
  return async (dispatch: Function, getStore: Function) => {
    try {
      const {
        activeBooking: {
          pickupLocation: {
            id,
            multi_car_location_ids,
          },
          pickupDate,
          dropoffDate,
          pickupTime,
          dropoffTime,
          promo,
          vehicle,
          coverage,
        },
        loyalty: {
          earningPoints,
        },
      } = getStore().bookingReducer;

      const validDates = id
        && moment(pickupDate).isValid()
        && moment(dropoffDate).isValid();
      const validTimes = id
        && moment(pickupTime).isValid()
        && moment(dropoffTime).isValid();
      const promoId = promo ? promo.id : initialState.activeBooking.promo;

      if (validDates) {
        await dispatch(getLocationsAvailability({
          pickup_on: moment(pickupDate).format('YYYY-MM-DD'),
          dropoff_on: moment(dropoffDate).format('YYYY-MM-DD'),
          location_ids: [
            id,
            ...multi_car_location_ids,
          ],
        }));
      }
      if (validTimes) {
        await dispatch(getLocationsFleetAvailability({
          pickup_at: pickupTime,
          dropoff_at: dropoffTime,
          promo_code_id: promoId,
          location_ids: [
            id,
            ...multi_car_location_ids,
          ],
        }, true));
      }
      if (validTimes
        && vehicle
        && vehicle.fleet_id) {
        const options = coverage.coverageOptions;
        dispatch(getFleetPricing([{
          available: true,
          id: vehicle.fleet_id,
          vehicle: {
            make: 'Audi',
            model: vehicle.data_key,
          },
        }], {
          pickup_at: pickupTime,
          dropoff_at: dropoffTime,
          promo_code_id: promoId,
          coverages: filterCoverageOptions(options),
          points: !earningPoints,
        }));
      }
    } catch (debugMessage) {
      dispatch(Actions.logDebug({
        debugAction: BookingTypes.INIT_BOOKING,
        debugMessage,
      }));
    }
  };
}

export function joinAndReserve(data: Object) {
  return (dispatch: Function, getStore: Function) => {
    const { activeBooking, thirdPartyConfirmationNumber } = getStore().bookingReducer;
    // create join and reserve user then create booking
    HTTP.users.createUserByEmailAndPhoneNumber(data)
      .then(async () => {
        if (!thirdPartyConfirmationNumber) {
          const {
            vehicle: {
              fleet_id: fleetId,
            },
            coverage,
            pickupLocation: {
              curbside_detail,
            },
          } = activeBooking;

          const curbsideText = curbside_detail
            && curbside_detail.active
            && curbside_detail.description;

          // build the booking
          const newBooking = {
            pickup_at: activeBooking.pickupTime,
            dropoff_at: activeBooking.dropoffTime,
            email_address: data.email_address,
            promo_code_id: activeBooking.promo.id ? activeBooking.promo.id : null,
            coverages: setupCoverages(coverage),
          };
          const createBookingPromise = HTTP.fleets.createUserPostBooking(fleetId, newBooking);
          try {
            const createdBooking = await createBookingPromise;
            const getBookingsByTokenPromise = HTTP.hypermedia.get(createdBooking.headers.location);
            // ticket says to chain these calls but I'm not sure what to do
            // with the awaitGetBookingsByToken response
            const awaitGetBookingsByToken = await getBookingsByTokenPromise;
            dispatch(getAndSetJoinReserveBooking(awaitGetBookingsByToken.data));
            history.push('/reservation-confirmed');
            if (curbsideText) {
              dispatch(Actions.setCurbsideText(curbsideText));
              dispatch(openCurbsideAvailableModal());
            }
          } catch (error) {
            dispatch(toggleCostSummaryModalVisible());
            dispatch(createUserPostBookingError(error));
          }
        } else {
          dispatch(claimBookingPostSignup(data.email_address));
        }
      });
  };
}

export const loadBookingFromURLDates = params => (dispatch: Function, getStore: Function) => {
  const {
    from,
    pickup,
    to,
  } = params;

  if (pickup) {
    const locations = getStore().bookingReducer.locations.mergedLocations;
    const location = locations.find(loc => loc.gds_code === pickup) || {};
    moment.tz.setDefault(location.time_zone);
    dispatch(setPickupLocation(location));

    if (pickup && from && to) {
      const tz = (location && location.time_zone) ? location.time_zone : 'America/Chicago';
      const tzFrom = moment(from).tz(tz).format();
      const tzTo = moment(to).tz(tz).format();
      dispatch(setPickupDate(tzFrom));
      dispatch(setDropoffDate(tzTo));
      dispatch(getLocationsAvailability({
        pickup_on: moment(tzFrom).format('YYYY-MM-DD'),
        dropoff_on: moment(tzTo).format('YYYY-MM-DD'),
        location_ids: [
          location.id,
        ],
      }));
    }
  }
};

export const loadBookingFromLocation = params => (dispatch: Function, getStore: Function) => {
  const {
    pickup,
  } = params;

  if (pickup) {
    const locations = getStore().bookingReducer.locations.mergedLocations;
    const location = locations.find(loc => loc.gds_code === pickup) || {};
    dispatch(setPickupLocation(location));
  }
};

export const loadBookingFromURLDateTimes = params => (dispatch: Function, getStore: Function) => {
  const { activeBooking } = getStore().bookingReducer;
  const {
    from,
    pickup,
    to,
  } = params;
  if (pickup) {
    const locations = getStore().bookingReducer.locations.mergedLocations;
    const location = locations.find(loc => loc.gds_code === pickup) || {};
    moment.tz.setDefault(location.time_zone);
    dispatch(setPickupLocation(location));
    const promoId = activeBooking.promo
      ? activeBooking.promo.id
      : initialState.activeBooking.promo;

    if (pickup && from && to) {
      const tz = (location && location.time_zone) ? location.time_zone : 'America/Chicago';
      const tzFrom = moment(from).tz(tz).format();
      const tzTo = moment(to).tz(tz).format();

      dispatch(setPickupDate(tzFrom));
      dispatch(setDropoffDate(tzTo));
      dispatch(setPickupTime(tzFrom));
      dispatch(setDropoffTime(tzTo));
      dispatch(getLocationsFleetAvailability({
        location_ids: getLocationIdsFromLocation(location),
        pickup_at: tzFrom,
        dropoff_at: tzTo,
        promo_code_id: promoId,
      }, false));
    }
  }
};

// put actions

export function putGDSError(payload: Object) {
  setToastTimeout(setNetworkReset());
  return {
    type: BookingTypes.PUT_GDS_ERROR,
    payload: getErrorMessage(payload),
  };
}

export function putGDSSuccess() {
  setToastTimeout(setNetworkReset());
  return {
    type: BookingTypes.PUT_GDS_SUCCESS,
    payload: 'Success! Your reservation has been created',
  };
}

export function putGDS() {
  return (dispatch: Function, getStore: Function) => {
    try {
      const { thirdPartyBooking, thirdPartyConfirmationNumber } = getStore().bookingReducer;
      if (thirdPartyBooking && thirdPartyBooking.id && thirdPartyConfirmationNumber) {
        HTTP.bookings.claimBooking(thirdPartyConfirmationNumber)
          .then(() => {
            dispatch(putGDSSuccess());
            dispatch(getBookings(1));
            history.push('/reservations');
          })
          .catch((error) => {
            dispatch(putGDSError(error));
          });
      }
    } catch (debugMessage) {
      dispatch(Actions.logDebug({
        debugAction: BookingTypes.PUT_GDS,
        debugMessage,
      }));
    }
  };
}

// reset actions
export function resetBooking() {
  return {
    type: BookingTypes.RESET_BOOKING,
  };
}

export function resetEarningPoints() {
  return {
    type: BookingTypes.RESET_EARNING_POINTS,
  };
}

export function resetGDS() {
  return {
    type: BookingTypes.RESET_GDS,
  };
}

// search
export function searchGDSError(payload: Object) {
  setToastTimeout(setNetworkReset());
  return {
    type: BookingTypes.SEARCH_GDS_ERROR,
    payload: getErrorMessage(payload),
  };
}

export function searchGDSSuccess(payload: Object) {
  setToastTimeout(setNetworkReset());
  return {
    type: BookingTypes.SEARCH_GDS_SUCCESS,
    payload,
  };
}

export function searchGDS(confirmationNumber: string) {
  return (dispatch: Function) => {
    try {
      HTTP.bookings.searchGDS(confirmationNumber)
        .then((response) => {
          const payload = {
            confirmationNumber,
            booking: response.data,
          };
          dispatch(searchGDSSuccess(payload));
        })
        .catch((error) => {
          dispatch(searchGDSError(error));
        });
    } catch (debugMessage) {
      dispatch(Actions.logDebug({
        debugAction: BookingTypes.SEARCH_GDS,
        debugMessage,
      }));
    }
  };
}

// setters
export function setCoverageSuccess(payload: Object) {
  const type = payload.coverageType.replace('_coverage', '');
  return {
    type: BookingTypes.SET_COVERAGE,
    payload: {
      ...payload,
      type,
    },
  };
}

export function setCoverage(payload: Object) {
  return (dispatch: Function) => {
    dispatch(setCoverageSuccess(payload));
  };
}

export function setCoverageOptionsSuccess(payload: Object) {
  return (dispatch: Function, getStore: Function) => {
    const option = payload;
    let newOptions = [];
    const { coverageOptions } = getStore().bookingReducer.activeBooking.coverage;

    if (option === 'everything_you_need') {
      if (coverageOptions.includes('everything_you_need')) {
        newOptions = [];
      } else {
        newOptions = ['everything_you_need', 'just_the_car', 'other_people_and_their_things', 'you_and_your_things'];
      }
    } else {
      newOptions = [...coverageOptions];
      if (newOptions.includes(option)) {
        newOptions.splice(newOptions.indexOf(option), 1);
        if (coverageOptions.includes('everything_you_need')) {
          newOptions.splice(newOptions.indexOf('everything_you_need'), 1);
        }
      } else {
        newOptions.push(option);
        if (newOptions.length === 3) {
          newOptions = ['everything_you_need', 'just_the_car', 'other_people_and_their_things', 'you_and_your_things'];
        }
      }
    }
    dispatch({
      type: BookingTypes.SET_COVERAGE_OPTIONS,
      payload: newOptions,
    });
    dispatch(updateSelectedFleetPricing());
  };
}

export function setCoverageOptions(payload: Object) {
  return (dispatch: Function) => {
    dispatch(setCoverageOptionsSuccess(payload));
  };
}

export function setPromo(payload: Object, code: String) {
  const promoCodePayload = payload;
  // add actual promo code to response object, for pre-filling in breakdown bar
  if (code) promoCodePayload.promo_code = code;
  return {
    type: BookingTypes.SET_PROMO,
    payload: promoCodePayload,
  };
}

export function setPromoCode(payload: Object) {
  return {
    type: BookingTypes.SET_PROMO_CODE,
    payload,
  };
}

export function removePromo() {
  return {
    type: BookingTypes.REMOVE_PROMO,
  };
}

export function setDropoffDate(payload: string) {
  return {
    type: BookingTypes.SET_DROPOFF_DATE,
    payload: moment(payload).utc().format(),
  };
}

export function setDropoffTime(payload: Object) {
  return {
    type: BookingTypes.SET_DROPOFF_TIME,
    payload: moment(payload).utc().format(),
  };
}

export function setNetworkError(payload: Object) {
  return {
    type: BookingTypes.SET_NETWORK_ERROR,
    payload: getErrorMessage(payload),
  };
}

export function setNetworkReset() {
  return {
    type: BookingTypes.NETWORK_RESET,
  };
}

export function setPickupDate(payload: string) {
  return {
    type: BookingTypes.SET_PICKUP_DATE,
    payload: moment(payload).utc().format(),
  };
}

export function setPickupTime(payload: Object) {
  return {
    type: BookingTypes.SET_PICKUP_TIME,
    payload: moment(payload).utc().format(),
  };
}

export function setPickupLocation(payload: Object) {
  return {
    type: BookingTypes.SET_PICKUP_LOCATION,
    payload,
  };
}

export function setLocationMap(payload: Object) {
  return {
    type: BookingTypes.SET_LOCATION_MAP,
    payload,
  };
}

export function setLocations(payload: Array<Object>) {
  return {
    type: BookingTypes.SET_LOCATIONS,
    payload,
  };
}

export function setStagedBooking(url: String) {
  return (dispatch: Function, getStore: Function) => {
    const { bookingReducer, appReducer } = getStore();
    const payload = generateBooking(bookingReducer, appReducer);
    dispatch({
      type: BookingTypes.SET_STAGED_BOOKING,
      payload,
    });
    history.push(url);
  };
}

export function setThirdPartyBooking() {
  return (dispatch: Function, getStore: Function) => {
    const { thirdPartyBooking, thirdPartyConfirmationNumber } = getStore().bookingReducer;
    dispatch({
      type: BookingTypes.SET_THIRD_PARTY_BOOKING,
    });
  };
}

export function setVehicle(payload: Object) {
  return (dispatch: Function) => {
    dispatch({
      type: BookingTypes.SET_VEHICLE,
      payload,
    });
  };
}

export function setSelectedFleetPricing(payload: Object) {
  return {
    type: BookingTypes.SET_SELECTED_FLEET_PRICING,
    payload,
  };
}

// search actions
export function searchLocationsSuccess(payload: Array<Object>) {
  return {
    type: BookingTypes.SEARCH_LOCATIONS_SUCCESS,
    payload,
  };
}

export function searchLocationsError(payload: Object) {
  return {
    type: BookingTypes.SEARCH_LOCATIONS_ERROR,
    payload: getErrorMessage(payload),
  };
}

export function searchLocations(params: string) {
  return (dispatch: Function) => {
    HTTP.locations.search(params)
      .then((response) => {
        if (!response.data.length && !params.split('').length) {
          dispatch(getLocations(1));
        } else {
          dispatch(searchLocationsSuccess(response.data));
        }
      })
      .catch(error => dispatch(searchLocationsError(error)));
  };
}

export function toggleCostSummaryModalVisible() {
  return {
    type: BookingTypes.TOGGLE_COST_SUMMARY_MODAL_VISIBLE,
  };
}

export function toggleEstimatedTotalModalVisible() {
  return {
    type: BookingTypes.TOGGLE_ESTIMATED_TOTAL_MODAL_VISIBLE,
  };
}

export function toggleReservationReviewModalVisible() {
  return {
    type: BookingTypes.TOGGLE_RESERVATION_REVIEW_MODAL_VISIBLE,
  };
}

export function toggleLoginModalVisible() {
  return {
    type: BookingTypes.TOGGLE_LOGIN_MODAL_VISIBLE,
  };
}

export function toggleJoinReserveModalVisible() {
  return {
    type: BookingTypes.TOGGLE_JOIN_RESERVE_MODAL_VISIBLE,
  };
}

export function toggleTexasWaiverModalVisible() {
  return {
    type: BookingTypes.TOGGLE_TEXAS_WAIVER_MODAL_VISIBLE,
  };
}

export function toggleCoverageWarningModalVisible() {
  return {
    type: BookingTypes.TOGGLE_COVERAGE_WARNING_MODAL_VISIBLE,
  };
}

export function toggleLoyaltyInfoModal() {
  return {
    type: BookingTypes.TOGGLE_LOYALTY_INFO_MODAL,
  };
}

export function togglePartnerLocationModal() {
  return {
    type: BookingTypes.TOGGLE_PARTNER_LOCATION_MODAL_VISIBLE,
  };
}

export function toggleAudiOwnerProgramModal() {
  return {
    type: BookingTypes.TOGGLE_AUDI_OWNER_PROGRAM_MODAL,
  };
}

export function trackPromoCode(payload: Object) {
  return {
    type: BookingTypes.TRACK_PROMO,
    payload,
  };
}

export function trackBookingStart(payload: string) {
  return {
    type: BookingTypes.TRACK_BOOKING_START,
    payload,
  };
}

export function trackBookNow(data: Object) {
  const payload = (data && data.data) ? data.data : {};
  return (dispatch: Function) => {
    dispatch(trackBookNowEcommAction(payload));
    dispatch(trackBookNowEcommProduct(payload));
    dispatch(trackBookNowTransaction(payload));
    dispatch(trackBookNowReservation());
    dispatch(resetBooking());
  };
}

export function trackBookNowEcommAction(payload: Object) {
  return {
    type: BookingTypes.TRACK_BOOK_NOW_ECOMM_ACTION,
    payload,
  };
}

export function trackBookNowEcommProduct(payload: Object) {
  return {
    type: BookingTypes.TRACK_BOOK_NOW_ECOMM_PRODUCT,
    payload,
  };
}

export function trackBookNowReservation() {
  return {
    type: BookingTypes.TRACK_BOOK_NOW_RESERVATION,
  };
}

export function trackBookNowTransaction(payload: Object) {
  const { confirmation_token } = payload;
  return {
    type: BookingTypes.TRACK_BOOK_NOW_TRANSACTION,
    payload: confirmation_token,
  };
}

export function trackCoverageType(payload: Object) {
  return {
    type: BookingTypes.TRACK_COVERAGE_TYPE,
    payload,
  };
}

export function trackDeleteBooking(payload: Object) {
  return (dispatch: Function) => {
    dispatch(trackRefundEcommAction(payload));
    dispatch(trackRefundEcommProduct(payload));
  };
}

export function trackRefundEcommAction(payload: Object) {
  return {
    type: BookingTypes.TRACK_REFUND_ECOMM_ACTION,
    payload,
  };
}

export function trackRefundEcommProduct(payload: Object) {
  return {
    type: BookingTypes.TRACK_REFUND_ECOMM_PRODUCT,
    payload,
  };
}

export function trackDeliveryRequestStarted(payload: Object) {
  return (dispatch: Function, getStore: Function) => dispatch({
    type: ModalTypes.TRACK_DELIVERY_REQUEST_START,
  });
}

export function trackDeliveryRequestDeclined(payload: Object) {
  return (dispatch: Function, getStore: Function) => dispatch({
    type: ModalTypes.TRACK_DELIVERY_REQUEST_DECLINE,
  });
}

export function trackDeliveryRequestSubmitted(payload: Object) {
  return (dispatch: Function, getStore: Function) => dispatch({
    type: ModalTypes.TRACK_DELIVERY_REQUEST_SUBMIT,
  });
}

export function trackDeliveryRequestCancelled(payload: Object) {
  return (dispatch: Function, getStore: Function) => dispatch({
    type: ModalTypes.TRACK_DELIVERY_REQUEST_CANCEL,
  });
}
// updaters
export function updateFleetPricing() {
  return (dispatch: Function, getStore: Function) => {
    const {
      activeBooking: {
        pickupTime,
        dropoffTime,
        promo,
        coverage: {
          coverageOptions,
        },
        pointsToBeSpent,
      },
      fleetAvailability,
      loyalty: {
        earningPoints,
      },
    } = getStore().bookingReducer;

    const {
      activeBooking: {
        promo: {
          id: initialPromo,
        },
      },
    } = initialState;

    const promoId = (promo && promo.id) ? promo.id : initialPromo;

    dispatch({
      type: BookingTypes.UPDATE_FLEET_PRICING,
    });

    dispatch(getFleetPricing(fleetAvailability, {
      pickup_at: pickupTime,
      dropoff_at: dropoffTime,
      promo_code_id: promoId,
      coverages: filterCoverageOptions(coverageOptions),
      points: !earningPoints,
    }));
  };
}

export const updateSelectedFleetPricing = () => (
  (dispatch: Function, getStore: Function) => {
    const {
      activeBooking: {
        pickupTime,
        dropoffTime,
        promo,
        coverage: {
          coverageOptions,
        },
        pointsToBeSpent,
        vehicle: {
          fleet_id,
          data_key,
        },
      },
      loyalty: {
        earningPoints,
      },
    } = getStore().bookingReducer;

    const {
      activeBooking: {
        promo: {
          id: initialPromo,
        },
      },
    } = initialState;

    const promoId = (promo && promo.id) ? promo.id : initialPromo;

    dispatch({
      type: BookingTypes.UPDATE_SELECTED_FLEET_PRICING,
    });

    const fleet = [{
      available: true,
      id: fleet_id,
      vehicle: {
        make: 'Audi',
        model: data_key,
      },
    }];

    const params = {
      pickup_at: pickupTime,
      dropoff_at: dropoffTime,
      promo_code_id: promoId,
      coverages: filterCoverageOptions(coverageOptions),
      points: !earningPoints,
    };

    dispatch(getFleetPricing(fleet, params));
  }
);

// put actions
export function claimBookingPostSignupError(payload: Object) {
  setToastTimeout(setNetworkReset());
  return {
    type: BookingTypes.CLAIM_BOOKING_POST_SIGNUP_ERROR,
    payload: getErrorMessage(payload),
  };
}

export function claimBookingPostSignupSuccess() {
  setToastTimeout(setNetworkReset());
  return {
    type: BookingTypes.CLAIM_BOOKING_POST_SIGNUP_SUCCESS,
    payload: '',
  };
}

export function claimBookingPostSignup(email: string) {
  return (dispatch: Function, getStore: Function) => {
    try {
      const { thirdPartyConfirmationNumber, thirdPartyBooking } = getStore().bookingReducer;
      const data = { email_address: email };
      if (thirdPartyConfirmationNumber) {
        HTTP.bookings.claimBookingPostSignup(thirdPartyConfirmationNumber, data)
          .then(() => {
            dispatch(toggleJoinReserveModalVisible());
            dispatch(getAndSetJoinReserveBooking(thirdPartyBooking));
            dispatch(claimBookingPostSignupSuccess());
            history.push('/reservation-confirmed');
          })
          .catch((error) => {
            dispatch(toggleJoinReserveModalVisible());
            dispatch(claimBookingPostSignupError(error));
          });
      }
    } catch (debugMessage) {
      dispatch(Actions.logDebug({
        debugAction: BookingTypes.CLAIM_BOOKING_POST_SIGNUP,
        debugMessage,
      }));
    }
  };
}

export const attachTravelAgentError = (payload: Object) => {
  setToastTimeout(setNetworkReset());
  return {
    type: BookingTypes.ATTACH_TRAVEL_AGENT_ERROR,
    payload: getErrorMessage(payload),
  };
};

export const attachTravelAgentSuccess = () => ({
  type: BookingTypes.ATTACH_TRAVEL_AGENT_SUCCESS,
});

export const attachTravelAgent = (token: string, queryParams: Object) => (
  (dispatch: Function) => (
    HTTP.bookings.attachTravelAgent(token, queryParams)
      .then(() => {
        dispatch(attachTravelAgentSuccess());
      })
      .catch((error) => {
        dispatch(attachTravelAgentError(error));
      })
  )
);
