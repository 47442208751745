const AuthTypes = {
  authToken: 'authToken',
  isLoggedIn: 'isLoggedIn',
  first_name: 'first_name',
  last_name: 'last_name',
  expiresAt: 'expiresAt',
  authenticated: 'authenticated',
  // This is the date that the users session expires at.
  // It is 6 months from the second the user successfully logs in.
  // After this time occurs, the refresh token won't act again,
  // instead forcing the user to log out and log back in again
  sessionExpiresAt: 'sessionExpiresAt',
};

export default AuthTypes;
