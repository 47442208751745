// @flow
import React from 'react';
import CCForm from '../../../../components/forms/credit-card/index';

const CreditCard: React.FC = () => (
  <div className="account-page-tab-content" id="credit-card">
    <CCForm />
  </div>
);

export default CreditCard;
