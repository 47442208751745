// @flow
import React from 'react';
import AppMessage from './appMessage';
import { errorMessages } from './constants';

const FallbackComponent = () => (
  <AppMessage
    bodyText={[errorMessages.refreshBrowser]}
    title={errorMessages.genericShort}
  />
);

export default FallbackComponent;
