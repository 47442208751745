// @flow
import React from 'react';
import { connect, useSelector } from 'react-redux';
import SCDialog from '../../sc-dialog';
import '../modal.css';

import { toggleCostSummaryModalVisible } from '../../../actions/booking';
import { getCostSummaryModalVisible } from '../../../selectors';
import './index.css';
import CostSummaryData from './cost-summary-data';

type Props = {
  toggleCostSummaryModalVisible: () => void,
  isLoggedIn: boolean,
}

export function CostSummaryModal(props: Props) {
  const { toggleCostSummaryModalVisible, isLoggedIn } = props;

  const costSummaryModalVisible = useSelector(getCostSummaryModalVisible);

  return (
    <div>

      {isLoggedIn && (
      <SCDialog
        open={costSummaryModalVisible}
        onClose={toggleCostSummaryModalVisible}
        title="Summary"
        width="m"
        showCloseButton
      >
        <CostSummaryData />
      </SCDialog>
      )}
    </div>
  );
}

export const mapDispatchToProps = dispatch => ({
  toggleCostSummaryModalVisible: () => dispatch(toggleCostSummaryModalVisible()),
});

export default connect(null, mapDispatchToProps)(CostSummaryModal);
