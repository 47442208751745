export const GET_COVERAGE_OPTIONS = 'GET_COVERAGE_OPTIONS';
export const GET_COVERAGE_OPTIONS_SUCCESS = 'GET_COVERAGE_OPTIONS_SUCCESS';
export const GET_COVERAGE_OPTIONS_ERROR = 'GET_COVERAGE_OPTIONS_ERROR';
export const SET_DROPOFF_DATE = 'SET_DROPOFF_DATE';
export const GET_FLEET_PRICING_OPTIONS_ERROR = 'GET_FLEET_PRICING_OPTIONS_ERROR';
export const GET_FLEET_PRICING_OPTIONS_SUCCESS = 'GET_FLEET_PRICING_OPTIONS_SUCCESS';
export const GET_FLEETS_BY_LOCATION_ID = 'GET_FLEETS_BY_LOCATION_ID';
export const GET_FLEETS_BY_LOCATION_ID_ERROR = 'GET_FLEETS_BY_LOCATION_ID_ERROR';
export const GET_FLEETS_BY_LOCATION_ID_SUCCESS = 'GET_FLEETS_BY_LOCATION_ID_SUCCESS';
export const GET_LOCATIONS = 'GET_LOCATIONS';
export const GET_LOCATIONS_SUCCESS = 'GET_LOCATIONS_SUCCESS';
export const GET_LOCATIONS_ERROR = 'GET_LOCATIONS_ERROR';
export const SET_COVERAGE = 'SET_COVERAGE';
export const SET_COVERAGE_SUCCESS = 'SET_COVERAGE_SUCCESS';
export const SET_COVERAGE_OPTIONS = 'SET_COVERAGE_OPTIONS';
export const SET_LOCATIONS = 'SET_LOCATIONS';
export const SET_PICKUP_DATE = 'SET_PICKUP_DATE';
export const SET_PICKUP_LOCATION = 'SET_PICKUP_LOCATION';
export const SET_SELECTED_FLEET_PRICING_OPTION = 'SET_SELECTED_FLEET_PRICING_OPTION';
export const SET_VEHICLE = 'SET_VEHICLE';
export const SET_ESTIMATED_TOTAL = 'SET_ESTIMATED_TOTAL';
export const SET_ESTIMATED_MONTHLY_TOTAL = 'SET_ESTIMATED_MONTHLY_TOTAL';
export const SEND_ORDER_TO_ZENDESK = 'SEND_ORDER_TO_ZENDESK';
export const SEND_ORDER_TO_ZENDESK_SUCCESS = 'SEND_ORDER_TO_ZENDESK_SUCCESS';
export const SEND_ORDER_TO_ZENDESK_ERROR = 'SEND_ORDER_TO_ZENDESK_ERROR';
