// @flow
import { Button } from '@audi/audi-ui-react';
import React from 'react';
import { VehicleUnavailableButtonProps } from './types';
import '../../index.css';

// eslint-disable-next-line max-len
const VehicleUnavailableButton: React.FC<VehicleUnavailableButtonProps> = (props: VehicleUnavailableButtonProps): React.ReactElement => {
  const {
    onClick,
    pathName = '',
    vehicle,
    isMobile,
  } = props;

  return (
    <Button
      disabled
      name="vehicle-unavailable-button"
      className="vehicle-unavailable-button"
      spaceStackStart={isMobile ? '' : 'm'}
      onClick={() => onClick(vehicle)}
      variant={pathName.includes('edit-vehicle') ? 'secondary' : 'primary'}
      size="small"
    >
        Select
    </Button>
  );
};

export default VehicleUnavailableButton;
