// @flow
import { Text } from '@audi/audi-ui-react';
import Button from '@material-ui/core/Button';
import React from 'react';
import { connect } from 'react-redux';
import Modal from 'react-responsive-modal';
import {
  setStagedBooking, setThirdPartyBooking, /* A function that dispatches an action to toggle the
login modal visible state. */
  toggleLoginModalVisible,
} from '../../../actions/booking';
import '../modal.css';

type Props = {
  bookingType: string,
  loginModalVisible: boolean,
  setStagedBooking: Function,
  setThirdPartyBooking: Function,
  toggleLoginModalVisible: Function,
}
export function LoginToContinueModal(props: Props) {
  const {
    bookingType,
    loginModalVisible,
    setStagedBooking,
    setThirdPartyBooking,
    toggleLoginModalVisible,
  } = props;
  function handleOnClick(url) {
    if (bookingType && bookingType === 'staged') {
      setStagedBooking(url);
    } else if (bookingType && bookingType === 'thirdParty') {
      setThirdPartyBooking(url);
    }
  }
  return (
    <Modal
      center
      onClose={toggleLoginModalVisible}
      open={loginModalVisible}
    >
      <div className="modal">
        <Text as="h2" variant="order1" weight="bold">You&#39;re Almost There</Text>
        <Text as="p" variant="copy1">
          Your booking isn&#39;t finished yet! To continue, please sign up for an account.
          If you already have an account, please log in.
        </Text>
        <div>
          <Button
            name="signup"
            variant="outlined"
            onClick={() => handleOnClick('/signup')}
          >
            Sign Up
          </Button>
          <Button
            name="login"
            onClick={() => handleOnClick('/login?inBooking=true')}
          >
            Log In
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export const mapStateToProps = state => ({
  loginModalVisible: state.bookingReducer.loginModalVisible,
});

export const mapDispatchToProps = dispatch => ({
  toggleLoginModalVisible: () => dispatch(toggleLoginModalVisible()),
  setStagedBooking: url => dispatch(setStagedBooking(url)),
  setThirdPartyBooking: url => dispatch(setThirdPartyBooking(url)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginToContinueModal);
