// @flow
import { apiUrl, headers } from '../config';
import axiosInstance from '../axiosInstance';

function get(id: number) {
  return axiosInstance({
    method: 'GET',
    url: `${apiUrl.v2}/fleets/${+id}`,
    headers: headers(),
    data: null,
  });
}

function getPricingById(id: Number, params) {
  return axiosInstance({
    url: `${apiUrl.v2}/fleets/${+id}/pricing`,
    headers: headers(),
    params,
    data: null,
  });
}

function getPricingOptionsById(id: Number, reservation_type: String, pickup_at: String) {
  return axiosInstance({
    method: 'GET',
    url: `${apiUrl.v2}/fleets/${+id}/pricing_options/${reservation_type}?pickup_at=${pickup_at}`,
    headers: headers(),
    data: null,
  });
}

function createBooking(id: Number, data) {
  return axiosInstance({
    method: 'POST',
    url: `${apiUrl.v2}/fleets/${+id}/bookings`,
    headers: headers(),
    data,
  });
}

function createUserPostBooking(id: Number, data) {
  return axiosInstance({
    method: 'POST',
    url: `${apiUrl.v2}/fleets/${+id}/bookings/email`,
    headers: headers(),
    data,
  });
}

function getFleetsByLocationId(locationId: Number) {
  return axiosInstance({
    method: 'GET',
    url: `${apiUrl.v2}/locations/${locationId}/fleets`,
    headers: headers(),
  });
}

const fleets: Object = {
  get,
  getPricingById,
  getPricingOptionsById,
  createBooking,
  createUserPostBooking,
  getFleetsByLocationId,
};

export default fleets;
