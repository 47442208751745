import q5Image from '../../assets/images/booking/q5-blue-small-booking-vehicle-02.png';
import q7Image from '../../assets/images/booking/q7-black-small-booking-vehicle-03.png';
import A5SBImage from '../../assets/images/booking/a5-gray-small-booking-vehicle.png';
import ETRONImage from '../../assets/images/booking/etron-blue-small-booking-vehicle-05.png';
import ETRONSbImage from '../../assets/images/booking/etron-sb-white-small-booking-vehicle-06.png';
import q7Booking from '../../assets/images/booking/q7-booking.png';
import etronBooking from '../../assets/images/booking/etron-sb-booking.png';
import a5Booking from '../../assets/images/booking/a5-booking.png';
import q5Booking from '../../assets/images/booking/q5-booking.png';
// import defaultImage from '../../../assets/images/booking/booking-confirmed.png';

export default [
  {
    model: 'A4',
    data_key: 'A4',
    main_image: A5SBImage,
    confirmation_image: a5Booking,
    description: '5 passenger sedan',
  },
  {
    model: 'Q5',
    data_key: 'Q5',
    main_image: q5Image,
    confirmation_image: q5Booking,
    description: '5 passenger SUV',
  },
  {
    model: 'A5 Cabriolet',
    data_key: 'A5C',
    main_image: A5SBImage,
    confirmation_image: a5Booking,
    description: '4 passenger convertible',
  },
  {
    model: 'Q7',
    data_key: 'Q7',
    main_image: q7Image,
    confirmation_image: q7Booking,
    description: '7 passenger SUV',
  },
  {
    model: 'A5 Sportback',
    data_key: 'A5SB',
    main_image: A5SBImage,
    confirmation_image: a5Booking,
    description: '5 passenger sportback',
  },
  {
    model: 'e-tron® Sportback',
    data_key: 'ETRON',
    main_image: ETRONSbImage,
    confirmation_image: etronBooking,
    description: 'Fully electric vehicle',
  },
  {
    model: 'e-tron®',
    data_key: 'ETRON',
    main_image: ETRONImage,
    confirmation_image: etronBooking,
    description: 'Fully electric vehicle',
  },
];
