// @flow
import React, { Component } from 'react';
import { DayPickerRangeController, isInclusivelyAfterDay } from 'react-dates';
import moment from 'moment';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

import './index.css';

const START_FOCUS = 'startDate';

type Props = {
  booking: Object,
  disablePickup: boolean,
  location: Object,
  resetDateTime: boolean,
  setDropoffDate?: Function,
  setPickupDate: Function,
  getAvailability?: Function,
  width: number,
  minimumNights?: number,
}

type State = {
  openDate: moment,
  closeDate: moment,
  focusedInput: moment,
  startDate: moment,
  endDate: moment,
  useEndDate: boolean,
}

export class DatePicker extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      closeDate: moment().add(180, 'days').startOf('day'),
      openDate: moment().startOf('day'),
      focusedInput: START_FOCUS,
      startDate: null,
      endDate: null,
      useEndDate: false,
    };
  }

  //  So that deeplinking will show the dates selected
  componentDidMount() {
    const {
      booking: {
        pickupDate,
        dropoffDate,
      },
    } = this.props;
    if (pickupDate && dropoffDate) {
      this.setState({
        startDate: moment(pickupDate),
        endDate: moment(dropoffDate),
      });
    }
  }

  componentDidUpdate(prevProps: Props) {
    const { resetDateTime } = this.props;
    if (!prevProps.resetDateTime && resetDateTime) {
      this.clearTimes();
    }
  }

  clearTimes = () => {
    this.setState({
      startDate: null,
      endDate: null,
    });
  }

  onChange =
    ({ startDate, endDate }: { startDate: moment, endDate: moment }) => {
      /* If the datepicker already has both a start and endDate,
      treat the new date selected as a fresh start. (pun)
      */
      const {
        getAvailability,
        disablePickup,
        setPickupDate,
        setDropoffDate,
      } = this.props;

      const {
        startDate: selectedStartDate,
        endDate: selectedEndDate,
        useEndDate,
      } = this.state;

      const oldStartDate = selectedStartDate;
      const oldEndDate = selectedEndDate;

      let newStartDate = startDate;
      let newEndDate = endDate;

      // if there is already a selected start and end date
      // set the new start date and clear the end date

      // This is not an active booking
      if (!disablePickup) {
        if (oldStartDate && oldEndDate && newStartDate) {
          newEndDate = null;
          this.setState({
            startDate: newStartDate,
            endDate: null,
          });
        }

        // IN A STANDARD BOOKING
        // if the startDate is selected and no end date is passed
        if (newStartDate && !newEndDate && !disablePickup) {
          setPickupDate(startDate);
          this.setState({
            startDate: newStartDate,
            endDate: null,
          });
          return false;
        }
        // if the startDate is selected and end date is passed
        if (newStartDate && newEndDate && !disablePickup) {
          setDropoffDate(newEndDate);
          this.setState({
            startDate: newStartDate,
            endDate: newEndDate,
          });
        }
      } else {
        if (!useEndDate) {
          newStartDate = moment(selectedStartDate).clone();
          newEndDate = moment(startDate).clone();
          this.setState({
            useEndDate: true,
          });
        } else {
          newStartDate = moment(selectedStartDate).clone();

          this.setState({
            useEndDate: false,
          });
        }

        setDropoffDate(newEndDate);
        this.setState({
          endDate: newEndDate,
        });
      }

      // if the start date and end date are set, let's check availability
      if (newStartDate && newEndDate && getAvailability) {
        const {
          booking,
          location,
        } = this.props;
        const { id } = booking;
        const getLocationIds = () => {
          const ids = [location.id];
          if (location.multi_car_location_ids) {
            ids.push(...location.multi_car_location_ids);
          }
          return ids;
        };
        const params = {
          pickup_on: moment(newStartDate).format('YYYY-MM-DD'),
          dropoff_on: moment(newEndDate).format('YYYY-MM-DD'),
        };

        if (id) {
          getAvailability(
            {
              ...params,
              id,
            },
          );
        } else {
          getAvailability(
            {
              ...params,
              location_ids: getLocationIds(),
            },
          );
        }
      }
      return false;
    };

  onFocusChange =
    (focusedInput: any) => (
      this.setState({
        focusedInput: !focusedInput ? START_FOCUS : focusedInput,
      })
    );

  isOutsideRange = (day: any) => {
    const { openDate, closeDate } = this.state;
    return (
      !isInclusivelyAfterDay(day, openDate)
      || isInclusivelyAfterDay(day, moment(closeDate))
    );
  }

  render() {
    const {
      startDate,
      endDate,
      focusedInput,
      openDate,
    } = this.state;
    const { width } = this.props;
    const isMobile = isWidthDown('sm', width);
    const isMediumBreakPoint = isWidthDown('md', width);
    const numberOfMonths = isMediumBreakPoint && !isMobile ? 1 : 2;
    const { minimumNights } = this.props;
    // DayPickerRange doesn't include 0 nights by default, but we do
    const minNights = minimumNights || 0;

    return (
      <div className="date-picker">
        <DayPickerRangeController
          daySize={isMobile ? 35 : 50}
          endDate={endDate}
          firstDayOfWeek={0}
          focusedInput={focusedInput}
          hideKeyboardShortcutsPanel
          initialVisibleMonth={() => moment(openDate)}
          isOutsideRange={this.isOutsideRange}
          minimumNights={minNights}
          numberOfMonths={numberOfMonths}
          onDatesChange={this.onChange}
          onFocusChange={this.onFocusChange}
          orientation={isMobile ? 'vertical' : 'horizontal'}
          startDate={startDate}
          navPrev={<ArrowBackIosIcon fontSize="large" />}
          navNext={<ArrowBackIosIcon fontSize="large" />}
        />
      </div>
    );
  }
}

export default withWidth()(DatePicker);
