import { client, hostedFields } from 'braintree-web';
import moment from 'moment';

export default function setupBraintree(authorization, onSubmitCard, creditCard, handleCCChange) {
  const form = document.querySelector('#cc-form');
  if (!authorization) {
    return;
  }
  client.create({
    authorization,
  }, (clientErr, clientInstance) => {
    if (clientErr) {
      return;
    }
    hostedFields.create({
      client: clientInstance,
      fields: {
        number: {
          selector: '#card-number',
          maskInput: true,
          placeholder: creditCard && creditCard.last_four ? '•••• •••• •••• '.concat(creditCard.last_four) : '',
        },
        expirationDate: {
          selector: '#expiration-date',
          prefill: creditCard && moment(creditCard.expires_on).isValid()
            ? moment(creditCard.expires_on, 'YYYY-MM-DD').format('MM/YY')
            : '',
        },
      },
      styles: {
        input: {
          'font-size': '16px',
          color: '#3A3A3A',
        },
        '.invalid': {
          color: '#FF0000',
        },
      },
    }, (hostedFieldsErr, hostedFieldsInstance) => {
      if (hostedFieldsErr) {
        return;
      }
      // initialize as false
      handleCCChange(false);
      hostedFieldsInstance.on('validityChange', (event) => {
        const expyIsValid = event.fields.expirationDate.isValid;
        const numIsValid = event.fields.number.isValid;
        if (expyIsValid && numIsValid) {
          handleCCChange(true);
        } else {
          handleCCChange(false);
        }
      });
      form.addEventListener('submit', (event) => {
        event.preventDefault();
        onSubmitCard(hostedFieldsInstance);
      }, false);
    });
  });
}
