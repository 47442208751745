const auth0 = {
  local: {
    clientId: 'C4975CigVpShXgKoCqgi6VCsVBvDWI44',
    domain: 'login.rac-tst.com',
    rootUrl: 'localhost',
  },
  'rac-dev': {
    clientId: 'C4975CigVpShXgKoCqgi6VCsVBvDWI44',
    domain: 'login.rac-tst.com',
    rootUrl: '.rac-tst.com',
  },
  'rac-tst': {
    clientId: 'C4975CigVpShXgKoCqgi6VCsVBvDWI44',
    domain: 'login.rac-tst.com',
    rootUrl: '.rac-tst.com',
  },
  'rac-stg': {
    clientId: 'C4975CigVpShXgKoCqgi6VCsVBvDWI44',
    domain: 'login.rac-tst.com',
    rootUrl: '.rac-stg.com',
  },
  audiondemand: {
    clientId: 'NZTxmaPOPMZ77HIsLXIYxNOs1qtg4twH',
    domain: 'login.silvercar.com',
    rootUrl: '.audiondemand.com',
  },
};

const environments = {
  'rac-dev': {
    headerEnv: 'Development',
    env: 'rac-tst',
    appEnv: 'rac-tst',
    appName: 'rac-web-driver-DEV',
  },
  'rac-tst': {
    headerEnv: 'QA',
    env: 'rac-tst',
    appEnv: 'rac-tst',
    appName: 'rac-web-driver-QA',
  },
  'rac-stg': {
    headerEnv: 'Staging',
    env: 'rac-stg',
    appEnv: 'rac-stg',
    appName: 'rac-web-driver-STG',
  },
  audiondemand: {
    headerEnv: 'Production',
    env: 'audiondemand',
    appEnv: 'audiondemand',
    appName: 'rac-web-driver-PROD',
  },
};

const targetEnv = process.env.REACT_APP_API_HOST || 'rac-tst';

const env = (appEnv = targetEnv) => {
  const { headerEnv, env, appName } = environments[appEnv];
  const apiDomain = env === 'audiondemand' ? 'silvercar' : env;
  return {
    env,
    apiUrl: {
      v2: `https://api.${apiDomain}.com`,
    },
    auth: {
      clientId: auth0[appEnv].clientId,
      domain: auth0[appEnv].domain,
      redirect: `${window.location.protocol}//${window.location.host}/callback`,
      realm: 'Username-Password-Authentication',
    },
    appEnv,
    headerEnv,
    rootUrl: auth0[appEnv].rootUrl,
    racWebDriverURL: `https://app.${env}.com`,
    unleash: {
      url: 'https://unleash.silvercar.com/api',
      appName,
    },
  };
};

export default env;
